<template>
  <component :is="login_component" />
</template>
<script>

import TouchBusinessLogin from './TouchBusinessLogin.vue';
import TouchPortalLogin from './TouchPortalLogin.vue';
import { resetPinia } from '@/composables/resetPinia';

export default {
  components: {
    'portal-login': TouchPortalLogin,
    'business-login': TouchBusinessLogin,
  },
  async beforeRouteEnter(to, from, next) {
    next(() => {
      resetPinia(['basket', 'touch.isDealerNetworkPartnerOnly'])
    });
  },
  computed: {
    login_component() {
      switch (true) {
        case this.touch_portal_installation:
          return 'portal-login';
        case this.touch_business_installation:
          return 'business-login';
        default:
          throw new Error('Unknown Installation Type, Please check VITE_APP_INSTALLATION_TYPE');
      }
    },
  },
};
</script>
