<template>
  <router-link v-if="!external_link" :to="url">
    <menu-button-inner
      :icon="icon"
      :label="label"
      :url="url"
      :active="isActive"
    />
  </router-link>
  <a v-else-if="external_link" :href="url" target="_blank">
    <menu-button-inner
      :icon="icon"
      :label="label"
      :url="url"
      :active="isActive"
    />
  </a>
  <div v-else>
    <menu-button-inner
      :icon="icon"
      :label="label"
      :url="url"
      :active="isActive"
    />
  </div>
</template>

<script>
import MenuButtonInner from '@/components/shared/nav/MenuButtonInner.vue';

export default {
  components: { MenuButtonInner },
  props: ['icon', 'label', 'url', 'active', 'external_link'],
  computed: {
    isActive() {
      return this.$route.meta.active === this.active;
    },
  },
};
</script>
