<template>
  <div>
    <create-tax-rate
      v-if="create_tax_rate_popup_open"
      :create_tax_rate_popup_open="create_tax_rate_popup_open"
      @close="create_tax_rate_popup_open = false"
      @created="loadTaxRates"
    />
    <edit-tax-rates
      v-if="editing"
      :prop_tax_rates="tax_rates"
      :modal_open="editing"
      :customer="customerStore.customer"
      @close="(editing = false), loadTaxRates"
      @updated="loadTaxRates"
    />
    <hr class="-mx-10">
    <div class="flex flex-wrap-reverse justify-between items-center m-3 xs:m-10 mb-0">
      <div class="text-2xl font-medium" v-text="'Tax Rates'" />
      <div class="flex flex-col justify-around ml-auto">
        <div class="btn-action" @click.stop.prevent="create_tax_rate_popup_open = true">
          Add Tax Rate
        </div>
      </div>
    </div>
    <div class="flex flex-wrap bg-white border m-3 xs:m-10 mt-4 relative pr-16">
      <template v-if="tax_rates.length > 0">
        <div
          v-for="(tax_rate, index) in tax_rates"
          :key="index"
          class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4"
        >
          <div class="font-bold">{{ tax_rate.name }}</div>
          <div class="h-10 flex flex-col justify-around">{{ tax_rate.value }}%</div>
        </div>
      </template>
      <div v-else class="font-bold p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">No Tax Rates to Show</div>
      <template v-if="vatRegistrationNumber">
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold">VAT Registration Number</div>
          <div class="h-10 flex flex-col justify-around">{{ vatRegistrationNumber }}</div>
        </div>
      </template>
      <div class="absolute bottom-0 right-0 p-5">
        <div
          class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
          @click="editing = true"
        >
          <i class="fa fa-pencil" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import EditTaxRates from '@/views/Business/ManageTouchAccount/TaxRate/Edit.vue';
import CreateTaxRate from '@/views/Business/ManageTouchAccount/TaxRate/Create.vue';

export default {
  components: {
    EditTaxRates,
    CreateTaxRate,
  },
  data() {
    return {
      tax_rates: [],
      editing: false,
      create_tax_rate_popup_open: false,
      vatRegistrationNumber: undefined,
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  async mounted() {
    this.loadTaxRates();
  },
  methods: {
    async loadTaxRates() {
      this.tax_rates = await this.customerStore.staffGetTaxRates();
      this.vatRegistrationNumber =
        this.customerStore.customer.eCommerceIntegration.vatRegistrationNumber;
    },
  },
};
</script>
