<template>
  <a
    :class="{ 'sm:hidden': sidebarVisible }"
    class="logo bg-white flex flex-col justify-around"
    :href="href"
  >
    <div v-if="isError" class="text-center">
      <strong>[LOGO NOT FOUND]</strong>
    </div>
    <img v-else-if="src" :src="src" class="m-auto max-h-full" @error="isError = true">
    <div
      v-else-if="customerName"
      class="text-xl text-center flex flex-col justify-around h-full px-6"
    >
      {{ customerName }}
    </div>
  </a>
</template>

<script>

export default {
  props: {
    sidebarVisible: Boolean,
    href: String,
    src: String,
    customerName: String,
  },
  data () {
    return {
      isError: false
    }
  },
};
</script>

<style>
.logo {
  height: 65px;
  width: 200px;
}

@screen sm {
  .logo {
    height: 122px;
    width: 250px;
  }
}
</style>
