<template>
  <aside
    class="bg-white shadow-xl sm:w-1/3 scrolling-touch sm:p-5 xl:p-10 sm:flex-grow sm:relative z-10"
  >
    <div class="xs:p-5 md:p-10 m-auto">
      <div class="hidden sm:block">
        <div class="font-medium">{{ breakdown }} Summary</div>

        <div class="mb-4">
          Basket showing {{ basketStore.basketQty }} item<span v-if="basketStore.basketQty > 1">s</span>
        </div>
        
        <div v-if="basketStore.deliveryCharge && !basketStore?.job?.overrideCalculatedPrice" class="flex border-t border-gray-300 p-6 mt-6">
          <div>Delivery Charge</div>
          <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.deliveryCharge)" />
        </div>

        <div v-if="basketStore.fittingPrice" class="flex border-t border-gray-300 p-6 mt-6">
          <div>Fitting Charge</div>
          <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.fittingPrice)" />
        </div>

        <div v-if="basketStore.surveyPrice" class="flex border-t border-gray-300 p-6 mt-6">
          <div>Survey Charge</div>
          <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.surveyPrice)" />
        </div>

        <div v-if="basketStore?.job?.overrideCalculatedPrice">
          <div v-if="basketStore?.job?.discountApplied">
            <div class="border-t border-gray-300 p-6">
              <div class="flex">
                <div>Subtotal</div>
                <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.job?.calculatedPriceTaxable)" />
              </div>
            </div>
            <div class="border-t border-gray-300 p-6">
              <div class="flex">
                <div>Discount ({{ basketStore.job.discountCode }})</div>
                <span class="ml-auto">-{{ formatCurrency(basketStore.getCurrency, discount) }}</span>
              </div>
            </div> 
          </div>
          <div class="border-t border-gray-300 p-6">
            <div class="flex">
              <div>Subtotal <span v-if="basketStore?.job?.discountApplied">(Discounted)</span></div>
              <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore?.job?.overridePriceTaxable)" />
            </div>
          </div>
          <div class="flex border-t border-grat-600 p-6">
            <div>Delivery Charge</div>
            <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.deliveryCharge)" />
          </div>
        </div>
        <div v-else-if="basketStore.salesSectorType !== enums.salesSectorType.RETAIL" class="border-t border-gray-300 p-6">
          <div class="flex">
            <div>Subtotal</div>
            <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.subtotal)" />
          </div>
        </div>

        <div class="flex border-t border-gray-300 p-6">
          <div>VAT</div>
          <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.vat)" />
        </div>

        <div class="flex border-t border-b border-gray-300 p-6 font-medium">
          <div>Total</div>
          <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.total)" />
        </div>
      </div>
      <div class="m-3 sm:m-0 sm:mb-6">
        <div class="mt-6 mb-2">Reference</div>
        <input
          v-model="reference"
          type="text"
          class="form-control"
          :class="{ 'border-red-600': referenceMissing }"
        >
        <div v-if="referenceMissing" class="text-red-600 mt-3">Please enter a reference</div>
        <slot name="voucher" />
      </div>
      <div class="m-3 xs:m-1 sm:m-0 sm:mb-6 flex flex-col xs:flex xs:flex-row-reverse sm:flex-col">
        <a v-if="!basketStore.isOrder" class="btn-action btn-lg w-full" @click.prevent="placeOrder()">
          Log Order With {{ style.fabricator_name }}
        </a>
        <a v-else class="btn-action btn-lg w-full" @click.prevent="placeOrder()">
          <span
            v-if="basketStore.isPaymentGateway &&
              [enums.PaymentStatus.AWAITING, enums.PaymentStatus.FAILED].includes(basketStore.contractData?.paymentStatus)"
          >
            <span v-if="touch_portal_installation">Make Payment</span>
            <span v-else>Place Order as Pending Payment</span>
          </span>
          <span v-else> Charge to Account With {{ style.fabricator_name }} </span>
        </a>
      </div>
      <div class="m-3 xs:m-1 sm:m-0 sm:mb-6 flex flex-col xs:flex xs:flex-row-reverse sm:flex-col">
        <a class="btn btn-lg w-full" @click.prevent="cancelOrder()"> Cancel </a>
      </div>

      <!--<img src="/images/card-images.png" class="w-64 mt-3 mx-auto">-->
    </div>
  </aside>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import { formatCurrency } from '@/composables/currency';

export default {
  props: {
    breakdown: String,
    customer: Object
  },
  setup() {
    return { formatCurrency }
  },
  data() {
    return {
      referenceMissing: false,
    };
  },
  computed: {
    ...mapStores(useBasketStore),
    reference: {
      get() {
        return this.basketStore.reference;
      },
      set(value) {
        this.basketStore.setReference(value);
      },
    },
    discount() {
      const total = this.basketStore?.job?.calculatedPriceTaxable + this.basketStore.deliveryCharge + this.basketStore.surveyPrice + this.basketStore.fittingPrice
      const discount = total - this.basketStore.subtotal
      return discount
    }
  },
  methods: {
    cancelOrder() {
      this.$emit('cancel-order');
    },
    placeOrder() {
      if (this.reference.trim().length < 1) {
        this.referenceMissing = true;
        return;
      }
      this.referenceMissing = false;

      if (this.customer?.financial?.paymentWithOrder && !this.touch_portal_installation) {
        this.alertBox()
          .fire({
            title: "Order will be marked as 'Placed (Pending Payment)'",
            text: 'Customer will need to login and pay for the order.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('place-order');
            }
          });
      } else {
        this.$emit('place-order');
      }
    },
  },
};
</script>
