import { useAuthStore } from '@/pinia/auth';
import { useBasketStore } from '@/pinia/basket';
import { useBrandedStore } from '@/pinia/branded';
import { useCustomerStore } from '@/pinia/customer';
import { useDesignerStore } from '@/pinia/designer';
import { useFeatureStore } from '@/pinia/feature';
import { useKnowledgeBaseStore } from '@/pinia/knowledge-base';
import { useSandboxStore } from '@/pinia/sandbox';
import { useStyleStore } from '@/pinia/style';
import { useStockPartsStore } from '@/pinia/stock-parts';
import { useTouchStore } from '@/pinia/touch';
import { useVisualiserStore } from '@/pinia/visualiser';

// Existing Vuex storage to Pinia

export function migrationPinia() {
  const storageKey = window.VITE_APP_INSTALLATION_TYPE 
  const storage = localStorage.getItem(storageKey)
  if (storage) {
    const stores = [
      { name: 'auth', data: useAuthStore() },
      { name: 'basket', data: useBasketStore() },
      { name: 'branded', data: useBrandedStore() },
      { name: 'user', data: useCustomerStore() },
      { name: 'designer', data: useDesignerStore() },
      { name: 'feature', data: useFeatureStore() },
      { name: 'knowledgeBase', data: useKnowledgeBaseStore() },
      { name: 'sandbox', data: useSandboxStore() },
      { name: 'style', data: useStyleStore() },
      { name: 'stockParts', data: useStockPartsStore() },
      { name: 'touch', data: useTouchStore() },
      { name: 'visualiser', data: useVisualiserStore() },
    ]
    const storageParsed = JSON.parse(storage)
    Object.keys(storageParsed).forEach((key) => {
      if (stores.some(x => x.name === key)) {
        stores.find(x => x.name === key).data.$patch(storageParsed[key])
      }
    });
    const authStore = stores.find(x => x.name === 'auth').data
    if (authStore.loggedIn) {
      const installation = authStore.usingBrandedModeToken
        ? 'public'
        : window.VITE_APP_INSTALLATION_TYPE === 'portal'
          ? 'customer'
          : 'staff'
      authStore.$patch({ installation })   
      window.touch.setLogin(authStore.authToken, authStore.authTokenExpiry, installation);
    }
    Object.keys(localStorage).forEach((key) => {
      if (['public', 'staff', 'customer', 'portal', 'business'].find(x => x === key)) {
        localStorage.removeItem(key);
      }
    });
  }
}