<template>
  <div>
    <Update
      v-if="editing"
      :modal_open="editing"
      :warning-settings="warningSettings"
      @close="editing = false, getCompanyIssuePresent() /*, getAccountSettings()*/"
    />
    <div class="flex flex-wrap bg-white border m-3 xs:m-10 mt-4 relative pr-16">
      <!-- <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
        <div class="font-bold">Emails</div>
        <div class="flex flex-col justify-around">{{ authStore.accountSettings.emailOff ? 'Off' : 'On' }}</div>
      </div>
      <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
        <div class="font-bold">Extras</div>
        <div class="flex flex-col justify-around">{{ authStore.accountSettings.showFitting ? 'On' : 'Off' }}</div>
      </div> -->
      <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Approve Orders with Warnings</div>
        <div class="flex flex-col justify-around">{{ warningSettings.showIssuePresentWarning ? 'On' : 'Off' }}</div>
      </div>
      <div v-if="warningSettings.issuePresentWarning" class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Disclaimer</div>
        <div class="flex flex-col justify-around">{{ warningSettings.issuePresentWarning }}</div>
      </div>
      <div class="absolute bottom-0 right-0 p-5">
        <div class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600" @click="editing = true">
          <i class="fa fa-pencil" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Update from '@/components/business/manageTouchAccount/accountSettings/approveOrderWarnings/Update.vue';
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';

export default {
  components: {
    Update,
  },
  data() {
    return {
      editing: false,
      warningSettings: {}
    };
  },
  computed: {
    ...mapStores(useAuthStore)
  },
  async mounted() {
    this.getCompanyIssuePresent()
  },
  methods: {
    async getAccountSettings() {
      const accountSettings = await window.touch.getAccountSettings();
      this.authStore.setAccountSettings(accountSettings);
    },
    async getCompanyIssuePresent() {
      const { showIssuePresentWarning, issuePresentWarning } = await window.touch.GetCompanyIssuePresent()
      this.warningSettings = { showIssuePresentWarning, issuePresentWarning }
    }
  },
};
</script>