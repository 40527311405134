<template>
  <div>
    <CreateHoliday
      v-if="editorOpen"
      :id="holidayEditorModel.id"
      :holiday-title="holidayEditorModel.title"
      :holiday-start="holidayEditorModel.dateStart"
      :holiday-end="holidayEditorModel.dateEnd"
      @close="editorOpen = false"
      @created="getHolidays"
    />
    <hr class="-mx-10">
    <div class="flex flex-wrap-reverse justify-between items-center m-3 xs:m-10 mb-0">
      <div class="text-2xl font-medium" v-text="'Holidays'" />
      <div class="flex flex-col justify-around ml-auto">
        <div class="btn-action" @click.stop.prevent="openHolidayEditor(0)">Add Holiday</div>
      </div>
    </div>
    <div class="flex flex-row flex-wrap bg-white border m-3 xs:m-10 mt-4 relative p-3">
      <table v-if="holidays.length" class="table-lined xs:table-unstacked _with-hover w-full">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th class="w-32" />
          </tr>
        </thead>
        <tr v-for="holiday in holidays" :key="holiday.id">
          <td>{{ holiday.id }}</td>
          <td>{{ holiday.title }}</td>
          <td>{{ getFormattedDate(holiday.dateStart) }}</td>
          <td>{{ getFormattedDate(holiday.dateEnd) }}</td>
          <td class="td-action-buttons">
            <div class="table-edit-btn mr-2" @click.stop.prevent="openHolidayEditor(holiday.id)">
              <i class="cursor-pointer text-green-600 fa fa-pencil" />
            </div>
            <div class="table-edit-btn" @click.stop.prevent="deleteHoliday(holiday.id)">
              <i class="cursor-pointer text-red-600 fa fa-trash-can" />
            </div>
          </td>
        </tr>
      </table>
      <div v-else>No Holidays Set</div>
    </div>
  </div>
</template>
<script>

import CreateHoliday from './CreateHoliday.vue';
import { formatDate } from '@/composables/date';

export default {
  components: {
    CreateHoliday,
  },
  data() {
    return {
      holidays: [],
      holidayEditorModel: {},
      editorOpen: false,
    };
  },
  mounted() {
    this.getHolidays();
  },
  methods: {
    async getHolidays() {
      this.holidays = await window.touch.getAllCompanyHolidays();
    },
    getFormattedDate(date) {
      return formatDate(date)
    },
    openHolidayEditor(holidayId) {
      this.holidayEditorModel = holidayId ? this.holidays.find((x) => x.id === holidayId) : {};
      this.editorOpen = true;
    },
    async deleteHoliday(holidayId) {
      await window.touch.deleteCompanyHoliday(holidayId);
      await this.getHolidays();
    },
  },
};
</script>
