<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['My Account', '/my-account'], ['Staff & Licenses']]"
      />
    </template>
    <template #header_page_title> Staff & Licenses </template>
    <template #header_page_actions>
      <div v-if="!demo_mode && isAdmin" class="px-3 md:px-10 flex items-center">
        <a
          class="btn-action"
          @click.stop.prevent="$refs.staffAndLicenses.add_staff_popup_open = true"
        >
          Add Staff
        </a>
      </div>
    </template>
    <Menu />
    <loading
      :loading="false"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden"
    >
      <StaffAndLicenses ref="staffAndLicenses" @set-is-admin="setIsAdmin" />
    </loading>
  </touch-layout>
</template>

<script>
import StaffAndLicenses from '@/components/shared/StaffAndLicenses.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Menu from '@/components/portal/myAccount/Menu.vue';

export default {
  components: {
    StaffAndLicenses,
    Breadcrumbs,
    Menu,
  },
  data() {
    return {
      isAdmin: false,
    };
  },
  methods: {
    setIsAdmin(isAdmin) {
      this.isAdmin = isAdmin;
    },
  },
};
</script>
