<template>
  <div v-if="authStore.isDealerNetworkModeAvailable" class="bg-white rounded-lg mt-5 p-5">
    <div class="mb-5">
      <span class="text-lg">Assign Dealer Network Enquiries</span>
    </div>
    <div class="flex items-center">
      <div class="flex items-center cursor-pointer">
        <span class="font-extralight">from Dealer Network</span>
        <div
          class="rounded-lg text-white text-center ml-2 px-3 bg-gray-400"
          :class="{ 'bg-green-400': total_items > 0 }"
          v-text="total_items"
        />
      </div>
    </div>
    <div class="mt-5">
      <loading :loading="loading">
        <template v-if="enquiries.length > 0">
          <div class="border-solid border-t border-gray-500 mb-4" />
          <table class="table-auto w-full">
            <thead>
              <tr class="text-left">
                <th class="px-4 font-extralight text-gray-500 hidden xs:block">#</th>
                <th class="px-4 font-extralight text-gray-500">Date</th>
                <th class="px-4 font-extralight text-gray-500">Customer</th>
                <th class="px-4 font-extralight text-gray-500 float-right hidden xs:block">Postcode</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="enquiry in enquiries"
                :key="enquiry.id"
                class="cursor-pointer hover:bg-gray-200"
                @click="`/dealer-network/assign/${enquiry.id}`"
              >
                <td class="px-4 py-1 hidden xs:table-cell">{{ enquiry.customerCounter }}</td>
                <td class="px-4 py-1">{{ formatDate(enquiry.date) }}</td>
                <td class="px-4 py-1">
                  {{ enquiry.consumer.firstName + " " + enquiry.consumer.lastName }}
                </td>
                <td class="px-4 py-1  text-right">{{ enquiry.consumer.addressCode }}</td>
                <td class="px-4 py-1 text-right">
                  <button
                    class="px-2 py-1 text-2xs rounded text-white border border-blue-600 cursor-pointer bg-blue-600"
                  >
                    Assign
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <div v-else class="flex flex-col justify-around flex-grow">
          <div class="text-center">
            <p class="text-4xl">
              <i class="fal fa-chart-bar" />
            </p>
            <p class="text-2xl">No Enquiries Received</p>
            <p>Any enquiries from dealer network will land here.</p>
          </div>
        </div>
      </loading>
    </div>
  </div>
  <div v-else class="flex bg-blue-900 rounded-lg mt-5 items-center">
    <div class="p-5 flex flex-col gap-5 pr-0 pl-10">
      <div>
        <span class="text-white text-xl lg:text-4xl whitespace-nowrap"><b>Dealer Network</b> <span style="color: #9fbf43">Upgrade</span></span>
      </div>
      <div>
        <span class="text-white lg:text-lg xl:text-2xl">Generate consumer enquiries to pass to your customers to facilitate.</span>
      </div>
      <div>
        <span class="text-white">
          To upgrade to <span style="color: #9fbf43">Dealer Network</span> contact <br> the BM Group sales team on
          <a class="underline" href="tel:08000599705"><strong>0800 059 9705</strong></a>
        </span>
      </div>
    </div>
    <div class="ml-auto pr-5" style="width: 70%; min-width: 300px; max-width: 700px;">
      <img class="rounded-lg" src="/images/dealer-network/banner.png">
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useTouchStore } from '@/pinia/touch';
import { formatDate } from '@/composables/date';

export default {
  setup() {
    return { formatDate }
  },
  data() {
    return {
      enquiries: [],
      loading: true,
      hovered: null,
      limit: 5,
      total_items: 0,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useTouchStore),
  },
  async mounted() {
    await this.loadFeed();
  },
  methods: {
    async loadFeed(offset = 0) {
      const options = {
        limit: this.limit,
        statusId: [
          this.enums.enquiryStatus.NONE,
          this.enums.enquiryStatus.UNASSIGNED,
          this.enums.enquiryStatus.REFUSED,
          this.enums.enquiryStatus.Expired,
        ],
        offset,
      };
      const response = await this.touchStore.loadDealerNetworkList(options);
      this.enquiries = response.orders;
      this.total_items = response.total_items;
      this.loading = false;
    },
  },
};
</script>
