<template>
  <div
    class="absolute top-0 left-0 right-0 bottom-0 z-50 flex flex-col justify-around bg-black_80 p-1 xs:p-10 h-full"
    @click.prevent="close"
  >
    <div
      class="bg-white m-auto max-w-full relative rounded-lg my-10 w-full h-full overflow-hidden"
      style="z-index: 9999999999; max-width: 800px"
      @click.prevent.stop
    >
      <div class="flex flex-col h-full">
        <div class="overflow-auto flex-grow">
          <div class="flex justify-between items-center p-10 pb-6">
            <div class="text-xl flex flex-col justify-around">{{ title }}</div>
          </div>

          <div class="px-3 xs:px-10 pb-6 flex">
            <label class="flex flex-col justify-around mr-3">Extras Search</label>
            <div class="flex relative flex-grow">
              <div class="absolute py-2 px-3">
                <i class="fal fa-search fa-lg" />
              </div>
              <input v-model="filter" type="text" class="flex-grow p-2 rounded-lg pl-12 border">
            </div>
          </div>

          <div v-for="group in filteredExtras" :key="group.id" class="border border-gray-200 m-3">
            <div
              class="p-3 px-3 xs:px-10 bg-gray-200 text-green-600"
              v-text="group.description"
            />
            <div
              v-for="(item, i) in group.items"
              :key="item.id"
              class=""
              :class="{ 'border-t border-gray-200': i !== 0 }"
            >
              <template v-if="item.options.length === 0">
                <div class="flex items-center px-3 xs:px-10 flex-grow">
                  <div class="w-16 p-2 h-16 flex flex-col justify-around">
                    <api-img
                      :src="item.image"
                      fallback="/images/no-extra-image-provided.png"
                      class="w-16 h-auto max-h-full"
                    />
                  </div>
                  <div class="flex-1 p-2" v-text="item.description" />
                  <div
                    v-if="item.unitOfMeasure !== fittingType"
                    class="p-2"
                    v-html="formatCurrency(basketStore.getCurrency, item.price, '')"
                  />
                  <div v-else class="p-2">Time: {{ item.price }}</div>
                  <div>
                    <button class="ml-4 btn-action" @click="addExtra(item)">Add</button>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="flex items-center p-2 px-3 xs:px-10 flex-grow bg-white z-20 relative">
                  <div class="w-16 p-2 h-16 flex flex-col justify-around">
                    <api-img
                      :src="item.image"
                      fallback="/images/no-extra-image-provided.png"
                      class="w-16 h-auto max-h-full"
                    />
                  </div>
                  <div class="flex-1 p-2" v-text="item.description" />
                </div>
                <div :class="{ '': i % 2 === 0 }" class="flex w-full">
                  <div
                    class="border-l border-gray-300 ml-6 xs:ml-16 flex flex-col items-center mb-6 -mt-4 z-10 relative"
                  >
                    <div
                      v-for="(option, z) in item.options"
                      :key="z"
                      class="border-b border-gray-300 w-10 flex-1"
                    />
                  </div>
                  <div class="flex-grow pb-2">
                    <div
                      v-for="(option, z) in item.options"
                      :key="z"
                      class="pr-3 xs:pr-10 bg-white items-center flex"
                    >
                      <div class="flex-1 p-2" v-text="option.description" />
                      <div
                        v-if="item.unitOfMeasure !== fittingType"
                        class="p-2"
                        v-html="formatCurrency(basketStore.getCurrency, option.price, '')"
                      />
                      <div v-else class="p-2">Time: {{ option.price }}</div>
                      <div class="">
                        <button class="ml-4 btn-action" @click="addExtraWithOption(item, option)">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div>
          <div class="flex justify-between px-3 xs:px-10 pb-6 mt-6">
            <div class="flex flex-col justify-around">
              <transition name="fade-in">
                <div
                  v-show="just_added_text !== ''"
                  class="flex flex-col justify-around text-green-600"
                >
                  {{ just_added_text }}
                </div>
              </transition>
            </div>
            <div>
              <button class="btn" @click.prevent="close">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import { useTouchStore } from '@/pinia/touch';
import { formatCurrency } from '@/composables/currency';

export default {
  props: ['title', 'forJob', 'supplyOnly'],
  setup() {
    return { formatCurrency }
  },
  data() {
    return {
      fittingType: window.enum.unitOfMeasure.FITTING_DAYS,
      extras: [],
      just_added_text: '',
      filter: '',
    };
  },
  computed: {
    ...mapStores(useBasketStore, useTouchStore),
    filteredExtras() {
      return this.extras
        .map((extraGroup) => ({
          ...extraGroup,
          items: extraGroup.items.filter(
            (item) =>
              ((this.supplyOnly && item.type === this.enums.fittingType.SUPPLY_ONLY) ||
                !this.supplyOnly) &&
              (this.forJob !== true || item.useSizesFromProduct === false) &&
              (item.description.toLowerCase().includes(this.filter.toLowerCase()) ||
                (item.options.length > 0 &&
                  item.options.find((option) =>
                    option.description.toLowerCase().includes(this.filter.toLowerCase()),
                  ))),
          ),
        }))
        .filter((extraGroup) => extraGroup.items.length > 0);
    },
  },
  async mounted() {
    this.extras = (await this.touchStore.customerGetAllExtraItems()).groups.filter(
      (extraGroup) => extraGroup.items.length > 0,
    );
  },
  methods: {
    close() {
      this.$emit('close');
    },
    addExtra(item) {
      this.setJustAddedText(item.description);
      this.alertBox().fire(this.just_added_text);
      this.$emit('attach-item', item);
    },
    addExtraWithOption(item, option) {
      this.setJustAddedText(option.description);
      this.alertBox().fire({
        title: item.description,
        text: this.just_added_text,
      });
      this.$emit('attach-item-with-option', {
        item,
        option,
      });
    },
    setJustAddedText(description) {
      this.just_added_text = `${description} added`;
      setTimeout(this.resetJustAddedText, 1500);
    },
    resetJustAddedText() {
      this.just_added_text = '';
    },
  },
};
</script>
