<template>
  <div>
    <div class="flex flex-col gap-10 xs:flex-row border-b pb-10">
      <div class="new-image-zone flex-1">
        <div class="flex flex-col justify-around text-base">
          <strong>{{ setup }} Landing Page Background</strong>
        </div>
        <div class="text-gray-600 text-base my-3">
          <div><strong>Location:</strong> Branded Mode designer introduction page {{ setup }}</div>
          <div>
            <strong>Purpose:</strong> Personalise the landing page for your customers who visit
            {{ setup }}
          </div>
        </div>
        <Dropzone
          :current_file="enquiryWelcomeImageDetails.src"
          @add-file="$emit('addFile', { file: $event, imageType: enquiryWelcomeImageDetails.imageType })"
          @remove-file="$emit('removeFile', enquiryWelcomeImageDetails.imageType)"
        />
        <div class="text-gray-500 py-3">
          <div>Required file type: JPG, PNG.</div>
          <div>Recommended size: 840 × 813 pixels</div>
          <div>Max File Size: 1MB</div>
        </div>
      </div>
      <div class="flex flex-col gap-5 w-full flex-1">
        <div
          v-if="enquiryWelcomeImageDetails.src"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="enquiryWelcomeImageDetails.src">
        </div>
        <div
          v-else-if="!enquiryWelcomeImageDetails.src && enquiryWelcomeImageDetails.default"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="enquiryWelcomeImageDetails.default">
        </div>
      </div>
    </div>
    <div class="flex flex-col xs:flex-row xs:flex-row-reverse gap-10 mt-10 border-b pb-10">
      <div class="flex flex-col gap-5 w-full flex-1">
        <div class="xs:hidden flex flex-col justify-around text-base xs:mb-5">
          <strong>{{ setup }} Landing Page Text</strong>
        </div>
        <div
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img src="/images/guides/intro-text.jpg">
        </div>
      </div>
      <div class="mr-auto flex flex-col gap-10 flex-1">
        <div class="hidden xs:flex flex-col justify-around text-base">
          <strong>{{ setup }} Landing Page Text</strong>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3 max-w-3xl">
            <div>Landing Page Title</div>
            <div class="text-gray-600 text-base">
              This will be displayed on the {{ setup }} designer page as the heading on the landing page.
            </div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.enquiryPageHeading" type="text" class="form-control">
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3 max-w-3xl">
            <div>Landing Page Intro</div>
            <div class="text-gray-600 text-base">
              This will be displayed on the {{ setup }} designer page as the sub-heading on the landing page.
            </div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.enquiryPageSubHeading" type="text" class="form-control">
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3 max-w-3xl">
            <div>Landing Page Body Text</div>
            <div class="text-gray-600 text-base">
              This will be displayed on the {{ setup }} designer page as the welcome text on the landing page.
            </div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.enquiryPageWelcomeText" type="text" class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-10 xs:flex-row mt-10 border-b pb-10">
      <div class="new-image-zone w-full flex-1">
        <div class="flex flex-col justify-around text-base">
          <strong>Virtual Home Banner</strong>
        </div>
        <div class="text-gray-600 text-base my-3">
          <div><strong>Location:</strong> Basket Page</div>
          <div><strong>Purpose:</strong> To launch the Virtual Home feature</div>
        </div>
        <Dropzone
          :current_file="virtualHomeBannerDetails.src"
          @add-file="$emit('addFile', { file: $event, imageType: virtualHomeBannerDetails.imageType })"
          @remove-file="$emit('removeFile', virtualHomeBannerDetails.imageType)"
        />
        <div class="text-gray-500 py-3">
          <div>Required file type: JPG, PNG.</div>
          <div>Recommended Size: 1057 x 239 pixels.</div>
          <div>Max File Size: 1MB</div>
        </div>
      </div>
      <div class="flex flex-col gap-5 w-full flex-1">
        <div
          v-if="virtualHomeBannerDetails.src"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="virtualHomeBannerDetails.src">
        </div>
        <div
          v-else-if="!virtualHomeBannerDetails.src && virtualHomeBannerDetails.default"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="virtualHomeBannerDetails.default">
        </div>
      </div>
    </div>
    <div class="flex flex-col xs:flex-row mt-10 gap-10 border-b pb-10">
      <div class="new-image-zone flex-1">
        <div class="flex flex-col justify-around text-base">
          <strong>Virtual Home Background</strong>
        </div>
        <div class="text-gray-600 text-base my-3">
          <div><strong>Location:</strong> Virtual Home introduction page</div>
          <div>
            <strong>Purpose:</strong> Personalise Virtual Home with a photo displaying your product
          </div>
        </div>
        <Dropzone
          :current_file="virtualHomeBackgroundDetails.src"
          @add-file="$emit('addFile', { file: $event, imageType: virtualHomeBackgroundDetails.imageType })"
          @remove-file="$emit('removeFile', virtualHomeBackgroundDetails.imageType)"
        />
        <div class="text-gray-500 py-3">
          <div>Required file type: JPG, PNG.</div>
          <div>Recommended size: 1440 × 961 pixels</div>
          <div>Max File Size: 1MB</div>
        </div>
      </div>
      <div class="flex flex-col gap-5 w-full flex-1">
        <div
          v-if="virtualHomeBackgroundDetails.src"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="virtualHomeBackgroundDetails.src">
        </div>
        <div
          v-else-if="!virtualHomeBackgroundDetails.src && virtualHomeBackgroundDetails.default"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="virtualHomeBackgroundDetails.default">
        </div>
      </div>
    </div>
    <div class="flex flex-col xs:flex-row mt-10 border-b gap-10 pb-10">
      <div class="new-image-zone  flex-1">
        <div class="flex flex-col justify-around text-base">
          <strong>{{ setup }} Thank You Page Background</strong>
        </div>
        <div class="text-gray-600 text-base my-3">
          <div><strong>Location:</strong> {{ setup }} designer thank you page</div>
          <div>
            <strong>Purpose:</strong> Personalise the landing page for your customers who visit
            {{ setup }}
          </div>
        </div>
        <Dropzone
          :current_file="enquiryCompletionImageDetails.src"
          @add-file="$emit('addFile', { file: $event, imageType: enquiryCompletionImageDetails.imageType })"
          @remove-file="$emit('removeFile', enquiryCompletionImageDetails.imageType)"
        />
        <div class="text-gray-500 py-3">
          <div>Required file type: JPG, PNG.</div>
          <div>Recommended size: 840 × 813 pixels</div>
          <div>Max File Size: 1MB</div>
        </div>
      </div>
      <div class="flex flex-col gap-5 w-full flex-1">
        <div
          v-if="enquiryCompletionImageDetails.src"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="enquiryCompletionImageDetails.src">
        </div>
        <div
          v-else-if="!enquiryCompletionImageDetails.src && enquiryCompletionImageDetails.default"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="enquiryCompletionImageDetails.default">
        </div>
      </div>
    </div>
    <div class="flex flex-col xs:flex-row xs:flex-row-reverse gap-10 mt-10">
      <div class="flex flex-col gap-5 w-full flex-1">
        <div class="xs:hidden flex-col justify-around text-base sm:mb-5">
          <strong>{{ setup }} Thank You Page Text</strong>
        </div>
        <div
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="max-width: 400px;"
        >
          <img src="/images/guides/thank-you-text.jpg">
        </div>
      </div>
      <div class="mr-auto flex flex-col gap-5 flex-1">
        <div class="hidden xs:flex flex-col justify-around text-base">
          <strong>{{ setup }} Thank You Page Text</strong>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3 max-w-3xl">
            <div>Thank You Page Title</div>
            <div class="text-gray-600 text-base">
              This will be displayed on the {{ setup }} designer thank you page as the heading on the landing page.
            </div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.thankYouHeadingText" type="text" class="form-control">
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3 max-w-3xl">
            <div>Thank You Page Intro</div>
            <div class="text-gray-600 text-base">
              This will be displayed on the {{ setup }} designer thank you page as the sub-heading on the landing page.
            </div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.thankYouSubHeadingText" type="text" class="form-control">
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3 max-w-3xl">
            <div>Thank You Page Body Text</div>
            <div class="text-gray-600 text-base">
              This will be displayed on the {{ setup }} designer thank you page as the welcome text on the landing page.
            </div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.thankYouText" type="text" class="form-control">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from '@/components/shared/Dropzone.vue';

export default {
  components: {
    Dropzone
  },
  props: {
    setup: String,
    settings: Object,
    enquiryWelcomeImageDetails: Object,
    virtualHomeBannerDetails: Object,
    virtualHomeBackgroundDetails: Object,
    enquiryCompletionImageDetails: Object,
  },
};
</script>

<style scoped>
.preview-section {
  width: 500px;
}

.new-image-zone {
  width: 100%;
}

@media (min-width: theme("screens.sm")) {
  .new-image-zone {
    width: 700px;
  }
}
</style>
