export async function listCarriers() {
  return (await this.authenticatedPost('carrier/List')).data;
}

export async function createCarrier(name, web, email, phone) {
  await this.authenticatedPost('carrier/Create', {
    Name: name,
    Web: web,
    Email: email,
    Phone: phone,
  });
}

export async function updateCarrier(id, name, web, email, phone) {
  await this.authenticatedPost('carrier/Update', {
    Id: id,
    Name: name,
    Web: web,
    Email: email,
    Phone: phone,
  });
}
