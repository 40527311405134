<template>
  <tr
    :class="{ _hovered: hovered }"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    @click="$emit('edit')"
  >
    <td>
      <div
        style="width: 50px; height: 50px"
        class="flex flex-col justify-around overflow-hidden relative"
      >
        <api-img
          :id="'groupImg_' + group.id"
          class="m-auto"
          :src="group.imageBlob"
          :load-method="positionImage"
        />
      </div>
    </td>
    <td>
      <div class="td-title w-24">Title:</div>
      <div class="td-content">
        {{ group.title }}
      </div>
    </td>
    <td>
      <div class="td-title w-24">Description:</div>
      <div class="td-content">
        {{ group.description }}
      </div>
    </td>
    <td class="xs:text-center">
      <div class="td-title w-24">Order:</div>
      <div class="td-content">
        {{ group.listOrder }}
      </div>
    </td>
    <td class="td-action-buttons">
      <div class="table-edit-btn">
        <i class="fa fa-pencil" />
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: ['group'],
  data() {
    return {
      hovered: false,
    };
  },
  methods: {
    positionImage(event) {
      const img = document.getElementById(event.target.id);
      if (img) {
        if (img.width >= img.height) {
          img.classList.add('h-full');
          img.classList.remove('w-full');
        } else {
          img.classList.add('w-full');
          img.classList.remove('h-full');
        }
      }
    },
  },
};
</script>

<style scoped></style>
