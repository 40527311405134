<template>
  <aside
    tabindex="0"
    class="bg-white shadow-xl sm:w-1/3 overflow-y-auto scrolling-touch sm:p-5 xl:p-10 h-full absolute left-0 right-0 bottom-0 sm:relative z-10 sm:z-auto"
    @focusout="close()"
  >
    <div class="p-5 md:p-10 m-auto">
      <div class="cursor-pointer hover:text-gray-800 mb-6" @click="close()">
        <i class="fal fa-times" /> <span class="pl-4">Close</span>
      </div>
      <div class="mb-6">
        <div class="flex flex-wrap">
          <div class="font-medium pr-2">Product:</div>
          <span v-text="item.description" />
        </div>
      </div>
      <ShowSvg :svg="current_image.image" class="mb-6" style="height: 450px" />
      <div class="flex justify-center">
        <div
          v-for="(image, key) in item.images"
          :key="key"
          :class="{
            'text-black': current_image_index == key,
            'text-gray-600': current_image_index != key,
            'border-l': key != 0,
          }"
          class="text-center capitalize tracking-widest text-sm px-3 py-1 cursor-pointer"
          @click.prevent="current_image_index = key"
        >
          {{ image_types[image.type] }} View
        </div>
      </div>
    </div>
  </aside>
</template>

<script>

import ShowSvg from '@/components/shared/ShowSvg.vue';

export default {
  components: {
    ShowSvg
  },
  props: ['item'],
  data() {
    return {
      current_image_index: 0,
      image_types: window.imageTypes,
    };
  },
  computed: {
    current_image() {
      return this.item.images[this.current_image_index];
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
