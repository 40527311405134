<template>
  <div class="w-full 2xs:w-1/2 md:w-full flex-grow flex flex-col">
    <div
      class="bg-white shadow-lg rounded-lg flex flex-col md:flex-row m-2 md:m-0 md:mt-6 flex-grow"
    >
      <div
        class="flex flex-col justify-center p-3 md:p-6 cursor-pointer md:w-56"
        @click="$emit('show-image')"
      >
        <div class="bg-gray-200 p-6 relative">
          <div
            class="bg-white p-1 rounded-full text-center absolute bottom-0 right-0 m-2"
            style="width: 30px; height: 30px"
          >
            <i class="fal fa-search-plus" />
          </div>
          <ShowSvg
            v-if="image"
            :thumbnail-mode="true"
            :svg="image"
            style="height: 150px"
          />
        </div>
      </div>
      <div class="flex flex-grow justify-between flex-col xl:flex-row px-3 md:p-0">
        <div class="flex flex-col md:flex-row">
          <div class="flex md:my-4 md:w-48">
            <div class="flex flex-col md:py-4">
              <div class="md:mb-1">
                <div class="font-medium mb-1 hidden md:block">Product:</div>
                <span v-text="item.description" />
              </div>
              <div v-if="showSizes">
                <span v-text="item.size" />
              </div>
              <div
                v-if="!isCostsHidden"
                class="mt-3 md:mt-6"
              >
                <slot
                  name="pricing"
                  :basket-currency="basketStore.getCurrency"
                  :price="basketStore.salesSectorType === enums.salesSectorType.RETAIL ? item.priceIncTax : item.price"
                  :quantity="item.quantity"
                />
              </div>
              <div class="flex flex-col gap-2 mt-3 md:mt-6 w-full md:w-auto">
                <loading v-if="qtyLoading" text-size="text-md" style="text-align: left !important; height: 37px" :delay-time="0" />
                <div v-else class="flex items-center">
                  <div class="mr-3 hidden md:block">Qty</div>
                  <div class="flex" style="height: 37px">
                    <input
                      ref="quantity"
                      :value="item.quantity"
                      class="w-8 border text-center"
                      @click.stop
                      @change="setQty($event.target.value)"
                    >
                  </div>
                </div>
                <span v-if="invalidInput" class="text-red-500">Qty must be a number greater than zero</span>
              </div>
            </div>
          </div>
          <div class="border-l border-gray-300 m-6 mx-3 lg:mx-6 hidden md:block" />
          <div class="flex flex-grow md:my-4 pr-5">
            <div class="flex flex-col flex-grow py-4">
              <div class="flex mb-1">
                <div class="font-medium pr-2 md:mb-1">Location:</div>
                <span v-text="item.location" />
              </div>
              <div v-if="item.size" class="flex mb-1">
                <div class="font-medium pr-2 md:mb-1">Size:</div>
                <span v-text="item.size" />
              </div>
              <div v-if="item.colorExternal" class="flex mb-1">
                <div class="font-medium pr-2 md:mb-1">External Colour:</div>
                <span v-text="item.colorExternal" />
              </div>
              <div v-if="item.colorInternal" class="flex mb-1">
                <div class="font-medium pr-2 md:mb-1">Internal Colour:</div>
                <span v-text="item.colorInternal" />
              </div>
              <div v-if="item.colorHardware" class="flex mb-1">
                <div class="font-medium pr-2 md:mb-1">Hardware Colour:</div>
                <span v-text="item.colorHardware" />
              </div>
              <div v-if="item.glazingMethod" class="flex mb-1">
                <div class="font-medium pr-2 md:mb-1">Glazing:</div>
                <span v-text="item.glazingMethod" />
              </div>
              <slot name="voucher" />
            </div>
          </div>
          <div
            v-if="extras.length > 0"
            class="border-l border-gray-300 m-6 mx-3 lg:mx-6 hidden md:block"
          />
          <div v-if="extras.length > 0" class="flex md:my-4 pr-6">
            <div class="flex flex-col py-4">
              <div class="font-medium pr-2 md:mb-1">
                Product Extras <span v-if="item.quantity > 1">Per item</span>
              </div>
              <div v-for="extra in extras" :key="extra.itemKey" class="mb-1 flex">
                <div class="flex flex-wrap">
                  <span class="">{{ extra.quantity }} x {{ extra.description }}</span>
                  <span
                    v-if="extra.optionDescription"
                    class="ml-2"
                  >({{ extra.optionDescription }})</span>
                  <span v-if="extra.size" class="ml-2">{{ extra.size }}</span>
                </div>
                <span v-if="extra.price" class="flex-1 text-right w-16 pl-3">
                  <span v-html="formatCurrency(basketStore.getCurrency, extra.price)" />
                  <span
                    v-if="item.quantity > 1"
                    class="ml-2"
                  >(<span
                    v-html="formatCurrency(basketStore.getCurrency, extra.price / item.quantity)"
                  />
                    per item)</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap-reverse xs:flex-nowrap mb-6 lg:my-4 xl:border-l xl:border-gray-300 xl:pl-6"
        >
          <div class="flex xl:flex-col flex-wrap md:flex-nowrap pr-3">
            <div class="flex flex-col justify-around xl:justify-start">
              <div
                class="border border-gray-300 rounded p-2 ml-0 md:ml-1 m-1 flex hover:bg-gray-200 transition duration-500 ease-in-out w-auto"
                @click.stop.prevent="$emit('show-summary')"
              >
                <i class="fal fa-clipboard-list-check xs:mr-2" />
                <span class="text-xs hidden xs:flex flex-col justify-around cursor-pointer">
                  <span> <span class="hidden md:inline">View</span> Summary </span>
                </span>
              </div>
            </div>
            <div class="flex flex-col justify-around xl:justify-start">
              <router-link
                :to="editLink"
                class="border border-gray-300 rounded p-2 m-1 ml-0 3xs:ml-1 flex hover:bg-gray-200 transition duration-500 ease-in-out"
              >
                <i class="fal fa-pen-square xs:mr-2" />
                <span class="text-xs hidden xs:flex flex-col justify-around">
                  <span>Edit <span class="hidden md:inline">Item</span></span>
                </span>
              </router-link>
            </div>
            <div class="flex flex-col justify-around xl:justify-start">
              <button
                class="border border-gray-300 rounded p-2 m-1 ml-0 3xs:ml-1 flex hover:bg-gray-200 transition duration-500 ease-in-out"
                @click.stop.prevent="$emit('duplicate-line-item', item.key)"
              >
                <i class="fal fa-clone xs:mr-2" />
                <span class="text-xs hidden xs:flex flex-col justify-around">
                  <span>Duplicate <span class="hidden md:inline">Item</span></span>
                </span>
              </button>
            </div>
            <div class="">
              <div
                class="border border-gray-300 rounded p-2 ml-0 md:ml-1 m-1 flex hover:bg-gray-200 transition duration-500 ease-in-out"
                @click.stop.prevent="$emit('delete-line-item', item.key)"
              >
                <i class="fal fa-trash-alt xs:mr-2" />
                <span class="text-xs hidden xs:flex flex-col justify-around">
                  <span>Delete <span class="hidden md:inline">Item</span></span>
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="item.issues && item.issues.length > 0"
            class="md:-ml-3 md:mr-3 flex flex-col justify-around md:block"
          >
            <router-link
              :to="editLink"
              class="xs:h-full md:h-auto border border-red-600 bg-red-600 rounded p-2 ml-0 md:ml-1 m-1 flex hover:bg-gray-800 hover:border-gray-900 transition duration-500 ease-in-out xs:w-23 sm:w-auto md:w-32 text-white"
            >
              <i class="fal fa-exclamation-triangle xs:mr-2 sm:mr-0 md:mr-2" />
              <span class="text-xs flex flex-col justify-around cursor-pointer">
                <span class="hidden xs:inline sm:hidden md:inline">
                  <span class="hidden md:inline">View</span> Issues
                </span>
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import { formatCurrency } from '@/composables/currency';
import ShowSvg from '@/components/shared/ShowSvg.vue';

export default {
  components: {
    ShowSvg
  },
  props: {
    extras: {
      default: [],
    },
    item: {
      required: true,
    },
    showSizes: {
      default: true,
    },
    isCostsHidden: Boolean,
    customerId: Number
  },
  setup() {
    return { formatCurrency }
  },
  data() {
    return {
      qtyLoading: false,
      image: undefined,
      isItemOverride: false,
      itemOverride: undefined,
      invalidInput: false
    };
  },
  computed: {
    ...mapStores(useBasketStore),
    editLink() {
      if (this.touch_business_installation) {
        return `/customer/${this.basketStore.customerId}/sales-dashboard/design/${this.item.key}`;
      }

      return `/design/${this.item.key}`;
    },
  },
  async mounted() {
    this.image = await this.getImage();
    this.itemOverride = this.item.overridePrice
  },
  methods: {
    setQty(qty) {
      if (!this.qtyLoading && !isNaN(qty) && qty > 0) {
        this.qtyLoading = true;
        this.invalidInput = false;
        this.basketStore.updateItemQty({
          itemKey: this.item.key,
          qty,
        })
          .then(() => {
            this.qtyLoading = false;
          });
      } else {
        this.$refs.quantity.value = this.item.quantity
        this.invalidInput = true;
      }
    },
    async getImage() {
      const image = await this.basketStore.getProcessingImage({
        itemKey: this.item.key,
        imageType: this.enums.imageType.EXTERNAL,
      });
      return image;
    },
  },
};
</script>