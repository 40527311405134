<template>
  <div v-show="!loading">
    <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
      * Required Fields
    </div>
    <div class="bg-white mt-5 rounded-lg">
      <div class="flex flex-col xs:flex-row">
        <div class="w-full md:w-1/2 xs:pr-3">
          <table class="w-full">
            <tbody>
              <tr class="flex flex-col md:table-row">
                <td class="p-3 pb-0 md:pb-3 md:w-40">
                  Branch Name: <span class="text-red-600">*</span>
                </td>
                <td class="p-3">
                  <input v-model="branch.name" class="form-control">
                </td>
              </tr>
              <tr class="flex flex-col md:table-row">
                <td class="p-3 pb-0 md:pb-3 md:w-40">Tel: <span class="text-red-600">*</span></td>
                <td class="p-3">
                  <input v-model="branch.phone" class="form-control">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full md:w-1/2 xs:pl-3">
          <table class="w-full">
            <tbody>
              <tr class="flex flex-col md:table-row">
                <td class="p-3 pb-0 md:pb-3 md:w-40">Email: <span class="text-red-600">*</span></td>
                <td class="p-3">
                  <input v-model="branch.email" class="form-control">
                </td>
              </tr>
              <tr class="flex flex-col md:table-row">
                <td class="p-3 pb-0 md:pb-3 md:w-40">Town: <span class="text-red-600">*</span></td>
                <td class="p-3">
                  <input v-model="branch.town" class="form-control">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex flex-col xs:flex-row">
        <div class="w-full md:w-1/2 xs:pr-3">
          <table class="w-full">
            <tbody>
              <tr class="flex flex-col md:table-row">
                <td class="p-3 pb-0 md:pb-3 md:w-40">
                  County: <span class="text-red-600">*</span>
                </td>
                <td class="p-3">
                  <input v-model="branch.county" class="form-control">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveBranch()">Save Branch</button>
      </div>
    </div>
  </div>
</template>

<script>
import validate from 'validate.js';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';

export default {
  data() {
    return {
      loading: true,
      branch: {
        name: '',
        email: '',
        phone: '',
        town: '',
        county: '',
      },
      tax_rates: [],
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  async mounted() {
    this.loading = false;
  },
  methods: {
    async saveBranch() {
      const validationErrors = validate(
        {
          name: this.branch.name,
          email: this.branch.email,
          phone: this.branch.phone,
          town: this.branch.town,
          county: this.branch.county,
        },
        {
          name: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          phone: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          email: {
            presence: { allowEmpty: false },
            length: {
              maximum: 255,
            },
            email: true,
          },
          town: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          county: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }
      const response = await this.customerStore.customerCreateBranch(this.branch);
      if (response?.id) {
        this.routerPush(`/branch/${response.id}/details`);
      }
    },
  },
};
</script>
