import { createI18n } from 'vue-i18n';

function loadLocaleMessages() {
  const locales = import.meta.glob('./locales/*.json', { eager: true });
  const messages = {};
  Object.keys(locales).forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales[key].default; // Access the default export
    }
  });

  return messages;
}

const splitHostname = window.location.hostname.split('.');
const subdomain =
  splitHostname[0] === 'local' ? import.meta.env.VITE_APP_OVERRIDE_SLUG : splitHostname[0];
let product = splitHostname[1] || import.meta.env.VITE_APP_INSTALLATION_TYPE;

if (product === 'admin') {
  product = 'business';
}

const i18n = createI18n({
  legacy: false,
  locale: `${product}-${subdomain}`,
  fallbackLocale: [subdomain, product, 'en'],
  silentFallbackWarn: true,
  messages: loadLocaleMessages(),
});

export default i18n;
