<template>
  <div class="xs:flex justify-between items-center gap-4 w-full px-5 py-2 xs:py-0 px-2">
    <span class="flex flex-grow items-center gap-4">
      <input
        v-if="isPriceSelectable"
        type="checkbox"
        :value="isSelected"
        @change="$emit('price-selected')"
      >
      <div class="py-1 flex flex-1 flex-grow flex-col justify-around">
        <div class="text-xs md:text-sm">
          {{ price.description }}
        </div>
      </div>
      <span v-if="subtitle">
        (<span v-if="!listValue !== null">List Price: {{ formatCurrencyWith0 }} </span> <span v-html="subtitle" />)
      </span>
    </span>
    <span v-if="listValue !== null && !subtitle">
      (<span>List Price: {{ formatCurrencyWith0 }}</span>)
    </span>
    <div class="flex relative py-1">
      <div class="absolute py-2 px-3 border rounded-l-lg" v-html="icon" />
      <input
        class="flex-grow bg-white p-2 rounded-lg pl-16 w-32 border border-l-0"
        :disabled="!editable"
        type="text"
        :value="overrideValue ? overrideValue : listValue"
        :class="{ 'text-gray-400': overrideValue == 0 }"
        @focus="$event.target.select()"
        @keyup="(overrideValue = $event.target.value), $emit('price-updated', { overrideValue: $event.target.value, valueIsNett: price.valueIsNett })"
      >
    </div>
    <span class="flex items-center gap-4">
      <div class="flex items-center relative">
        <div class="flex items-center gap-2" title="Mark as Free Of Charge">
          <div>FOC: </div>
          <input v-model="isFOC" type="checkbox">
        </div>
      </div>
      <div v-if="(typeof price.valueIsNett !== 'undefined')" class="flex items-center relative">
        <div class="flex items-center gap-2" title="Values entered will not have discounts applied">
          <div>Net: </div>
          <input v-model="isNet" type="checkbox">
        </div>
      </div>
      <div
        v-if="price.hasCustomerPrices && !hideCustomerOverrideButton"
        class="flex relative cursor-pointer w-8 justify-center items-center"
        @click="$emit('show-overrides', price)"
      >
        <i class="fa fa-person" />
      </div>
      <div v-else class="w-8" />
    </span>
  </div>
</template>

<script>

export default {
  props: {
    price: {
      required: true,
    },
    editable: {
      default: true,
    },
    listValue: {
      required: false,
    },
    defaultCurrency: {
      required: false,
    },
    hideCustomerOverrideButton: {
      default: false,
      type: Boolean,
    },
    isSelected: {
      default: false,
      type: Boolean,
    },
    icon: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    formatCurrencyWith0: {
      type: String,
    },
    isPriceSelectable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      overrideValue: 0,
    };
  },
  computed: {
    isFOC: {
      get() {
        return this.overrideValue === 0 && !this.price.valueIsNett
      },
      set(value) {
        if (value) {
          this.overrideValue = 0;
          this.$emit('price-updated', { overrideValue: this.overrideValue, valueIsNett: (typeof this.price.valueIsNett !== 'undefined') ? false : undefined });
          return;
        }

        this.overrideValue = this.overrideValue ? this.overrideValue : this.listValue;
        this.$emit('price-updated', { overrideValue: this.overrideValue, valueIsNett: this.price.valueIsNett });
      },
    },
    isNet: {
      get() {
        return this.price.valueIsNett
      },
      set(value) {
        if (value) {
          this.overrideValue = this.overrideValue ? this.overrideValue : this.listValue;
        }
        this.$emit('price-updated', { overrideValue: this.overrideValue, valueIsNett: value });
      },
    },
    priceValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    price: {
      handler(newPrice) {
        this.overrideValue = newPrice.overrideValue;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
