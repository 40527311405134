import { errorCallback, successCallback } from '@/composables/validate';

export async function latestNews({limit, offset, search}) {
  return this.parseResponse(
    // @todo this needs to be moved to `touch-marketing.js` file?
    await this.authenticatedPost('marketing/NewsItems', {
      Limit: limit,
      Offset: offset,
      GetTotal: true,
      Search: search
    }),
    {
      news_items: [],
      total_items: 0,
    },
    (response) => {
      let newsItems = [];
      if (response.data.total > 0) {
        newsItems = response.data.newsItems.map((newsItem) => ({
          ...newsItem,
          url: `/news/${newsItem.id}`,
          edit_url: `/manage-news/edit/${newsItem.id}`,
        }));
      }
      return {
        news_items: newsItems,
        total_items: response.data.total,
      };
    },
  );
}

export async function newsItems({limit, offset, categoryId, search}) {
  return this.parseResponse(
    // @todo this needs to be moved to `touch-marketing.js` file?
    await this.authenticatedPost('marketing/NewsItems', {
      Limit: parseInt(limit, 10),
      Offset: parseInt(offset, 10),
      categoryId: Number(categoryId),
      search,
      GetTotal: true,
    }),
    {
      news_items: [],
      total_items: 0,
    },
    (response) => {
      let articles = [];
      if (response.data.total > 0) {
        articles = response.data.newsItems.map((newsItem) => ({
          ...newsItem,
          url: `/news/${newsItem.id}`,
          edit_url: `/manage-news/edit/${newsItem.id}`,
        }));
      }
      return {
        news_items: articles,
        total_items: response.data.total,
      };
    },
  );
}

export async function getNewsItem(id) {
  return this.parseResponse(
    // @todo this needs to be moved to `touch-marketing.js` file?
    await this.authenticatedPost('marketing/GetNewsItem', { Id: Number(id) }),
    () => {
      this.router.push('/news');
    },
  );
}

export async function addNewsItem(newsItem) {
  return this.parseResponse(
    // @todo this needs to be moved to `touch-marketing.js` file?
    await this.authenticatedPost('marketing/AddNewsItem', {
      NewsCategoryId: newsItem.newsCategoryId,
      Title: newsItem.title,
      Paragraph: newsItem.paragraph,
      Summary: newsItem.summary,
      Image: newsItem.image,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
}

export async function updateNewsItem(newsItem) {
  return this.parseResponse(
    // @todo this needs to be moved to `touch-marketing.js` file?
    await this.authenticatedPost('marketing/UpdateNewsItem', {
      Id: parseInt(newsItem.id, 10),
      NewsCategoryId: newsItem.newsCategoryId,
      Title: newsItem.title,
      Paragraph: newsItem.paragraph,
      Summary: newsItem.summary,
      Image: newsItem.image,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
}

export async function deleteNewsItems(newsPostIds) {
  return this.parseResponse(
    // @todo this needs to be moved to `touch-marketing.js` file?
    await this.authenticatedPost('marketing/DeleteNewsItems', {
      Ids: newsPostIds,
    }),
    errorCallback,
    () => successCallback('Delete'),
  );
}

export async function getNewsCategories() {
  return this.parseResponse(
    // @todo this needs to be moved to `touch-marketing.js` file?
    await this.authenticatedPost('marketing/NewsCategories'),
    [],
  );
}

export async function addNewsCategory(categoryName) {
  return this.parseResponse(
    // @todo this needs to be moved to `touch-marketing.js` file?
    await this.authenticatedPost('marketing/AddNewsCategory', {
      Name: categoryName,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
}

export async function updateNewsCategory(category) {
  return this.parseResponse(
    // @todo this needs to be moved to `touch-marketing.js` file?
    await this.authenticatedPost('marketing/UpdateNewsCategory', {
      Id: Number(category.id),
      Name: category.name,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
}

export async function deleteNewsCategories(categoryIds) {
  return this.parseResponse(
    // @todo this needs to be moved to `touch-marketing.js` file?
    await this.authenticatedPost('marketing/DeleteNewsCategories', {
      Ids: categoryIds,
    }),
    errorCallback,
    () => successCallback('Delete'),
  );
}
