import StartPublicSession from '@/views/Public/StartPublicSession.vue';
import PasswordReset from '@/views/Public/PasswordReset.vue';
import NewUserSetPassword from '@/views/Public/NewUserSetPassword.vue';
import ClearSession from '@/views/Public/ClearSession.vue';
import ErrorPage from '@/views/Public/ErrorPage.vue';
import Login from '@/views/Public/Login.vue';
import Register from '@/views/Public/Register.vue';
import TouchBusinessLogin from '@/views/Public/TouchBusinessLogin.vue';
import TouchSuperUserLogin from '@/views/Public/TouchSuperUserLogin.vue';
import TouchPortalDemoLogin from '@/views/Public/TouchPortalDemoLogin.vue';
import PricingInfo from '@/components/shared/PricingInfo.vue';

import { useCustomerStore } from '@/pinia/customer';

const routes = [
  {
    path: '/clear',
    name: 'Clear Session',
    component: ClearSession,
  },
  {
    path: '/launch/:slug',
    name: 'Design Online',
    component: StartPublicSession,
  },
  {
    path: '/password-reset',
    name: 'Reset Password',
    component: PasswordReset,
  },
  {
    path: '/new-user',
    name: 'Set Password',
    component: NewUserSetPassword,
  },
  {
    path: '/tmp/show-thumbs',
    meta: { active: 'branded-prices' },
    component: PricingInfo,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { isLogin: true },
    beforeEnter: (to, from, next) => {
      const customerStore = useCustomerStore()
      customerStore.freshdeskWidgetEnabled = false;
      next();
    },
  },
  {
    path: '/verify-email',
    name: 'Verify Email',
    redirect: '/Register'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { isLogin: true },
    beforeEnter: (to, from, next) => {
      const customerStore = useCustomerStore()
      customerStore.freshdeskWidgetEnabled = false;
      next();
    },
  },
  {
    path: '/logindemo',
    name: 'DemoLogin',
    component: TouchPortalDemoLogin,
    meta: { isLogin: true },
    beforeEnter: (to, from, next) => {
      const customerStore = useCustomerStore()
      customerStore.freshdeskWidgetEnabled = false;
      next();
    },
  },
  {
    path: '/login-business',
    name: 'Admin Login',
    component: TouchBusinessLogin,
    meta: { isLogin: true },
  },
  {
    path: '/login-admin',
    name: 'Super User Login',
    component: TouchSuperUserLogin,
    meta: { isLogin: true },
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage,
    props: true,
  },
];

export default routes.map((route) => {
  const meta = { ...route.meta, publicRoute: true };
  return { ...route, meta };
});
