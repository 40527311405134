<template>
  <div class="bg-white border p-3 h-full w-full">
    <div class="border-b font-bold mb-3 pb-3">
      <div class="flex flex-col xs:flex-row justify-between items-center">
        <span class="hidden xs:inline">Choose Customer to Assign Enquiry</span>
        <div class="flex flex-col xs:flex-row xs:items-center gap-2 w-full xs:w-auto ml-auto">
          <div class="flex items-center gap-2">
            <input v-model="dealerNetworkPartnerOnly" type="checkbox">
            <span>Dealer Network Partner Only</span>
          </div>
          <input
            id="customer-search"
            v-model="query"
            class="form-control w-full xs:w-auto"
            type="text"
            placeholder="Filter by company name"
            style="min-width: 300px"
          >
        </div>
      </div>
    </div>
    <template v-if="results.length > 0 || selectedResultsNotOnPage.length > 0">
      <table class="table-lined xs:table-unstacked _with-hover w-full">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Name</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Type</th>
            <th>Miles</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="result in selectedResultsNotOnPage"
            :key="result.id"
            class="cursor-pointer"
            @click="toggle(result.id)"
          >
            <td><input type="checkbox" :checked="true"></td>
            <td>
              <div class="td-title w-24"> Name: </div>
              <div>{{ result.name }}</div>
            </td>
            <td v-text="formatAddress(result.address)" />
            <td v-text="result.phone" />
            <td v-text="result.email" />
            <td v-text="salesSectorType[result.salesSectorType]" />
            <td v-text="Number(result.distance * 0.000621371).toFixed(1)" />
          </tr>
          <tr
            v-for="result in results"
            :key="result.id"
            @click="toggle(result.id)"
          >
            <td>
              <div class="flex gap-3">
                <input
                  type="checkbox"
                  :checked="isChecked(result.id)"
                >
                <div v-if="result.customerPreviouslyAssigned" class="text-red-600 text-bold">
                  <span v-if="enums.DealerStatus.REFUSED === result.customerLastAssignedStatus">Refused</span>
                  <span v-else-if="enums.DealerStatus.EXPIRED === result.customerLastAssignedStatus">Expired</span>
                </div>
              </div>
            </td>
            <td>
              <div class="td-title w-24"> Name: </div>
              <div class="td-content">{{ result.name }}</div>
            </td>
            <td>
              <div class="td-title w-24"> Address: </div>
              <div class="td-content w-full">{{ formatAddress(result.address) }}</div>
            </td>
            <td>
              <div class="td-title w-24"> Phone: </div>
              <div class="td-content w-full">{{ result.phone }}</div>
            </td>
            <td class="flex break-all">
              <div class="td-title w-24"> Email: </div>
              <div class="td-content">{{ result.email }}</div>
            </td>
            <td>
              <div class="td-title w-24"> Type: </div>
              <div class="td-content">{{ salesSectorType[result.salesSectorType] }}</div>
            </td>
            <td>
              <div class="td-title w-24"> Miles: </div>
              <div class="td-content">{{ Number(result.distance * 0.000621371).toFixed(1) }}</div>
            </td>     
          </tr>
        </tbody>
      </table>
      <div class="px-5 mt-6 flex gap-5 items-center">
        <div class="flex-1">
          <pagination
            :total_items="total_items"
            :limit="limit"
            @load-feed="loadCustomers"
          />
        </div>
        <button
          v-if="selectedUsers.length > 0"
          class="btn-action"
          :class="{ 'opacity-50 pointer-events-none': loading }"
          @click.once="assignLead"
        >
          Assign
        </button>
        <button v-else disabled="disabled" class="btn cursor-not-allowed">Assign</button>
      </div>
    </template>
    <div v-else>
      No Matching Customers Found
      <button class="btn bg-white" @click="clearSearch()">clear search</button>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import Pagination from '@/components/shared/Pagination.vue';

export default {
  components: {
    pagination: Pagination,
  },
  props: ['contractId'],
  data() {
    return {
      selectedUsers: [],
      salesSectorType: window.salesSectorTypes,
      query: '',
      results: [],
      total_items: 0,
      limit: 10,
      dealerNetworkPartnerOnly: false,
      loading: false,
    };
  },
  computed: {
    ...mapStores(useTouchStore),
    selectedResultsNotOnPage() {
      return this.selectedUsers.filter(
        (selectedUser) => !this.results.some((user) => user.id === selectedUser.id),
      );
    },
  },
  watch: {
    query() {
      this.loadCustomers();
    },
    dealerNetworkPartnerOnly() {
      this.touchStore.setDealerNetworkPartnerOnly(this.dealerNetworkPartnerOnly);
      this.loadCustomers();
    },
  },
  async mounted() {
    this.dealerNetworkPartnerOnly = this.touchStore.isDealerNetworkPartnerOnly;
    await this.loadCustomers();
  },
  methods: {
    isChecked(id) {
      return this.selectedUsers.some((user) => user.id === id);
    },
    toggle(id) {
      const selectedIndex = this.selectedUsers.findIndex((user) => user.id === id);

      if (selectedIndex === -1) {
        // Add id to list of selected users
        const result = this.results[this.results.findIndex((user) => user.id === id)];
        this.selectedUsers = result ? [result] : [];
        return;
      }

      this.selectedUsers = [];
    },
    async assignLead() {
      this.loading = true;
      await this.touchStore.assignLead({
        contractId: this.contractId,
        customerIds: this.selectedUsers.map((user) => user.id),
      });

      this.$router.push('/dealer-network');
      this.loading = false;
    },
    clearSearch() {
      this.query = '';
    },
    formatAddress(address) {
      return [
        address.line1,
        address.line2,
        address.line3,
        address.town,
        address.county,
        address.code,
        address.iso,
      ]
        .filter(Boolean)
        .join(', ');
    },
    async loadCustomers(offset = 0) {
      const results = await this.touchStore.staffListCustomers({
        limit: this.limit,
        offset,
        query: this.query,
        contractId: this.contractId,
        dealerNetworkPartnerOnly: this.dealerNetworkPartnerOnly,
        customerStatus: this.enums.customerStatus.LIVE,
      });

      this.results = results.customers;
      this.total_items = results.total_items;
    },
    selectAssigned(assignee) {
      const id = assignee.customerId;
      this.toggle(id);
      this.selectedUsers = [];
    },
  },
};
</script>
