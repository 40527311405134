import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';

export default async (to, from, next) => {
  const customerStore = useCustomerStore()
  customerStore.freshdeskWidgetEnabled = false;
  const authStore = useAuthStore()
  const installation = window.VITE_APP_INSTALLATION_TYPE === 'business' ? 'staff' : 'customer'
  const isLoginValid = await authStore.validateLogin(installation)
  if (to.name === 'Design Online' && installation === 'staff') {
    next('/');
  } else if (isLoginValid && to.matched.some((record) => record.meta.isLogin === true)) {
    next('/');
  } else {
    next();
  }
};
