<template>
  <Teleport defer to="#portal_popup"> 
    <modal-window
      v-if="modal_open"
      :modal_open="true"
      title="Edit Company Details"
      @close="$emit('close')"
    >
      <div class="flex flex-wrap">
        <div class="w-full xs:w-1/2 xs:pr-5">
          <div class="font-medium">Company</div>
          <input v-model="customer.company.name" class="form-control">
        </div>
        <div class="w-full xs:w-1/2 xs:pl-5">
          <div class="font-medium">Customer Type</div>
          <input
            :value="deliverySalesSectorType[customer.customer.salesSectorType][0].toLowerCase()"
            disabled
            class="form-control text-gray-400 capitalize"
          >
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Contact Number</div>
          <input
            v-if="loggedInUser.isAdmin"
            v-model="customer.company.phone"
            class="form-control"
          >
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">Sales Contact</div>
          <select
            v-model="customer.customer.salesContactId"
            class="form-control bg-white"
            :disabled="false"
          >
            <option :value="0">None</option>
            <option
              v-for="staffContact in staffContacts"
              :key="staffContact.id"
              :value="staffContact.id"
            >
              {{ staffContact.firstName }} {{ staffContact.lastName }} - {{ staffContact.email }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-4 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Email</div>
          <input
            v-if="loggedInUser.isAdmin"
            v-model="customer.company.email"
            class="form-control"
          >
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">Website</div>
          <input v-if="loggedInUser.isAdmin" v-model="customer.company.web" class="form-control">
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-4 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Dealer Network Partner?</div>
          <select v-model="customer.customer.dealerNetworkPartner" class="form-control bg-white">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </div>
        <div v-if="hasDebugToggle" class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">Debug Customer</div>
          <select v-model="customer.customer.debug" class="form-control bg-white">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-4 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Bespoke Freshdesk Contact</div>
          <select v-model="customer.customer.ticketContact" class="form-control bg-white">
            <option :value="0">No bespoke contact (use default)</option>
            <option v-for="contact in contacts" :key="contact.id" :value="contact.id">
              {{ contact.firstName }} {{ contact.lastName }} - {{ contact.email }}
            </option>
          </select>
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">Customer Status</div>
          <select v-model="customer.customer.customerStatus" class="form-control bg-white">
            <option v-for="status in customerStatuses" :key="status.value" :value="status.value">
              {{ status.description }}
            </option>
          </select>
        </div>
        <div v-if="authStore.isBranchManagerAvailable" class="w-full mt-4 xs:w-1/2 xs:pr-5 xs:mt-8">
          <Branch :available-branches="branches" :branches="customer.branches" @set-branches="customer.branches = $event" />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">License Status</div>
          <div
            class="flex items-center cursor-pointer mt-2"
            @click.prevent="toggleCustomerLicenseStatusType()"
          >
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
              <div
                class="switch-toggle"
                :class="{
                  _active:
                    customer.customer.generalLicenseStatus === enums.generalLicenseStatus.ENABLED,
                }"
              />
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">
              Customer
              <span
                v-text="customer.customer.generalLicenseStatus === enums.generalLicenseStatus.ENABLED
                  ? 'Enabled'
                  : 'Disabled'"
              />
            </div>
          </div>
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">Skip Pending Order Stage</div>
          <div
            class="flex items-center cursor-pointer mt-2"
            @click.prevent="
              customer.customer.skipOrderAcceptance = !customer.customer.skipOrderAcceptance"
          >
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
              <div
                class="switch-toggle"
                :class="{ _active: customer.customer.skipOrderAcceptance }"
              />
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">
              Customer Skip
              <span v-text="customer.customer.skipOrderAcceptance ? 'On' : 'Off'" />
            </div>
          </div>
          <div class="flex items-center cursor-pointer mt-2" @click.prevent="validateSOP()">
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
              <div
                class="switch-toggle"
                :class="{ _active: customer.customer.skipSopOrderAcceptance }"
              />
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">
              Staff Skip
              <span v-text="customer.customer.skipSopOrderAcceptance ? 'On' : 'Off'" />
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveCompanyDetails()">
          Save Changes
        </button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import validate from 'validate.js';
import Branch from '@/components/business/manageTouchAccount/staff/branch.vue';

export default {
  components: {
    Branch
  },
  props: ['prop_customer', 'staffContacts', 'loggedInUser'],
  data() {
    return {
      contacts: [],
      customerStatuses: [],
      options: {
        limit: 20,
        offset: 0,
      },
      branches: {},
      branchCompanyId: 0,
      modal_open: false,
      customer: undefined
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore),
    hasDebugToggle() {
      return typeof this.customer?.customer?.debug !== 'undefined';
    },
    deliverySalesSectorType() {
      return Object.keys(this.enums.salesSectorType).map((key) => [
        key,
        this.enums.salesSectorType[key],
      ]);
    },
  },
  created() {
    this.customer = JSON.parse(JSON.stringify(this.prop_customer))
  },
  async mounted() {
    this.contacts = await window.touch.listTicketContacts();
    this.customerStatuses = await window.touch.commonGetCustomerStatuses();
    const response = await this.customerStore.customerListBranches(this.options);
    this.branches = response.branches;
    this.modal_open = true;
  },
  methods: {
    validateSOP() {
      if (this.customer.financial.paymentWithOrder) {
        this.alertBox().fire({
          title: 'Unable to skip SOP and make payment with order.',
          text: 'Payment with order toggle can be found in financial details section.',
          icon: 'error',
        });
      } else {
        this.customer.customer.skipSopOrderAcceptance =
          !this.customer.customer.skipSopOrderAcceptance;
      }
    },
    toggleCustomerLicenseStatusType() {
      if (
        this.customer.customer.generalLicenseStatus === window.enum.generalLicenseStatus.ENABLED
      ) {
        this.customer.customer.generalLicenseStatus = window.enum.generalLicenseStatus.DISABLED;
      } else {
        this.customer.customer.generalLicenseStatus = window.enum.generalLicenseStatus.ENABLED;
      }
    },
    async toggleBrandedMode() {
      if (
        this.customer.customer.brandedFabricator
      ) {
        this.customer.customer.brandedFabricator = window.enum.generalLicenseStatus.DISABLED;
      } else {
        this.customer.customer.generalLicenseStatus = window.enum.generalLicenseStatus.ENABLED;
      }
    },
    async saveCompanyDetails() {
      this.$emit('isLoading', true);

      const web = this.customer.company.web
      if (web?.length > 0 && !web.match(/\/\//)) {
        this.customer.company.web = `http://${web}`;
      }

      const validationErrors = validate(
        {
          name: this.customer.company.name,
          email: this.customer.company.email,
          phone: this.customer.company.phone,
          website: this.customer.company.web,
        },
        {
          name: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          phone: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          website: this.customer.company.web ? { url: { message: 'must be valid and include http:// or https://' } } : undefined,
          email: {
            presence: { allowEmpty: false },
            length: {
              maximum: 255,
            },
            email: true,
          },
        },
      );

      if (validationErrors) {
        this.$emit('isLoading', false);
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      try {
        await this.customerStore.customerUpdateRaw({
          Company: {
            email: this.customer.company.email,
            phone: this.customer.company.phone,
            web: this.customer.company.web,
            name: this.customer.company.name,
          },
          Customer: {
            salesContactId: Number(this.customer.customer.salesContactId),
            dealerNetworkPartner: this.customer.customer.dealerNetworkPartner,
            debug: this.customer?.customer?.debug,
            ticketContact: this.customer?.customer?.ticketContact,
            generalLicenseStatus: this.customer?.customer?.generalLicenseStatus,
            skipOrderAcceptance: this.customer?.customer?.skipOrderAcceptance,
            skipSopOrderAcceptance: this.customer?.customer?.skipSopOrderAcceptance,
            customerStatus: this.customer?.customer?.customerStatus,
          },
          branches: this.customer?.branches,
          CustomerId: this.customer.customerId,
        });
        this.$emit('isLoading', false);
        this.$emit('close');
        this.$emit('updateCustomer');
      } catch (error) {
        const message = error?.message || error?.data?.message
        if (message && message.includes('Customer Limit')) {
          this.customer.customer.generalLicenseStatus = window.enum.generalLicenseStatus.DISABLED
          this.alertBox().fire({
            title: 'Customer Limit Reached',
            html: 'You have reached your Touch Portal Customer License Limit as per your contracted agreement - Please contact <a class="text-blue-600" href="mailto:sales@bm-touch.co.uk">sales@bm-touch.co.uk</a> to increase your limit.',
            icon: 'error',
          });
        } else {
          this.alertBox().fire({
            title: message,
            icon: 'error',
          });
        }
        await this.$emit('updateCustomer');
        this.$emit('isLoading', false);
      }
    },
  },
};
</script>
