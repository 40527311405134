<template>
  <touch-layout>
    <create-category
      v-if="create_category_modal_open"
      @close="(create_category_modal_open = false), loadCategories()"
      @category-created="loadCategories"
    />
    <edit-category
      v-if="edit_category.id !== 0"
      :category="edit_category"
      :modal_open="edit_category.id !== 0"
      @category-updated="categoryUpdated"
      @close="(edit_category.id = 0), loadCategories()"
    />
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Manage News', '/manage-news'],
          ['Categories', '/manage-news/category'],
        ]"
      />
    </template>
    <template #header_page_title> Manage News Categories </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <a class="btn-action mr-1" @click="create_category_modal_open = true"> Add Category </a>
      </div>
    </template>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-10"
    >
      <div class="bg-white p-3 md:p-10 pb-6 border">
        <table class="table-lined _with-hover 3xs:table-unstacked w-full">
          <thead>
            <tr>
              <th>Category Name</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(category, index) in categories"
              :key="index"
              :class="{ _hovered: hovered === category.id }"
              @mouseover="hovered = category.id"
              @mouseleave="hovered = null"
              @click="edit_category = category"
            >
              <td>
                {{ category.name }}
              </td>
              <td class="td-action-buttons">
                <div class="table-edit-btn">
                  <i class="fa fa-pencil" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </loading>
  </touch-layout>
</template>

<script>
import CreateCategory from '@/components/business/manageNews/CategoryCreate.vue';
import EditCategory from '@/components/business/manageNews/CategoryEdit.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
    'create-category': CreateCategory,
    'edit-category': EditCategory,
  },
  data() {
    return {
      categories: [],
      loading: true,
      edit_category: { id: 0, name: '' },
      hovered: null,
      create_category_modal_open: false,
    };
  },
  mounted() {
    this.loadCategories();
    this.loading = false;
  },
  methods: {
    async loadCategories() {
      this.categories = await window.touch.getNewsCategories();
    },
    async categoryUpdated() {
      this.edit_category = { id: 0, name: '' };
      await this.loadCategories();
    },
  },
};
</script>
