<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['My Branded Mode Account', '/setup'],
          ['Company Details'],
        ]"
      />
    </template>
    <template #header_page_title> Company Details & Settings </template>
    <Menu />
    <Company setup="Branded" @after-saved="afterSaved">
      <template #email_address>
        <div class="text-gray-600 text-base mt-3">
          All your quote and order confirmations will be sent here.
        </div>
      </template>
    </Company>
  </touch-layout>
</template>

<script>
import Company from '@/components/shared/setup/Company.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Menu from '@/components/shared/setup/company/Menu.vue';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';

export default {
  components: {
    Company,
    Breadcrumbs,
    Menu,
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  methods: {
    async afterSaved(loadSettings) {
      const customer = await this.customerStore.customerGet()
      this.customerStore.setCustomer(customer)
      await loadSettings()
    },
  },
};
</script>
