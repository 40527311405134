export async function vendorUpdateThisGridAnomaly(
  datasetId,
  id,
  gridId,
  overrideCells,
  customerId,
) {
  const response = await this.authenticatedPost('vendor/UpdateThisGridAnomaly', {
    Id: id,
    CustomerId: customerId,
    OverrideCells: overrideCells,
    DatasetId: datasetId,
    FinancialGridConfigurationId: gridId,
  });

  if (response.status !== 200) {
    throw Error('Unable to save prices');
  }

  return response.data;
}

export async function vendorGetThisGridAnomaly(
  id,
  customerId,
  datasetId,
) {
  return (
    await this.authenticatedPost('vendor/GetThisGridAnomaly', {
      Id: id,
      CustomerId: customerId,
      DatasetId: datasetId,
    })
  ).data;
}
