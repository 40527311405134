import { defineStore } from 'pinia';
import { useAuthStore } from '@/pinia/auth';

export const useCustomerStore = defineStore('customer', {
  persist: true,
  state: () => ({
    freshdeskWidgetEnabled: false,
    customer: undefined,
    lastUpdated: undefined,
  }),
  actions: {
    setCustomer(customer) {
      this.customer = customer;
    },
    resetCustomer() {
      this.customer = undefined;
    },
    async setLastUpdated(lastUpdated) {
      this.lastUpdated = lastUpdated
    },
    async customerGet(customerId) {
      const authStore = useAuthStore();
      return window.touch.customerGet(customerId, authStore.staffLogin);
    },
    async customerUpdate( customer) {
      const authStore = useAuthStore();
      return window.touch.customerUpdate(customer, authStore.staffLogin);
    },
    async customerUpdateRaw(customer) {
      return window.touch.customerUpdateRaw(customer);
    },
    async customerBranchUpdate(branch) {
      return window.touch.customerBranchUpdate(branch);
    },
    async branchUpdateAddress(address) {
      return window.touch.branchUpdateAddress(address);
    },
    async updateCustomerRequoteDetails(customer) {
      return window.touch.updateCustomerRequoteDetails(customer);
    },
    async customerUpdateDeliveryDays(customer) {
      const authStore = useAuthStore();
      return window.touch.customerUpdateDeliveryDays(customer, authStore.staffLogin);
    },
    async customerUpdateUsers({ customer, usersIds }) {
      const authStore = useAuthStore();
      return window.touch.customerUpdateUsers(customer, authStore.staffLogin, usersIds);
    },
    async customerCreateUsers({ customer }) {
      const authStore = useAuthStore();
      return window.touch.customerUpdateUsers(customer, authStore.staffLogin);
    },
    async customerUpdateAddresses({ customer }) {
      const authStore = useAuthStore();
      return window.touch.customerUpdateAddresses(customer, authStore.staffLogin);
    },
    async customerUpdateVendorAddress(customer) {
      const authStore = useAuthStore();
      return window.touch.customerUpdateVendorAddress(customer, authStore.staffLogin);
    },
    async customerCreate(customer) {
      return window.touch.customerCreate(customer);
    },
    async customerCreateBranch(branch) {
      return window.touch.customerCreateBranch(branch);
    },
    async customerListContacts() {
      return window.touch.customerListContacts();
    },
    async customerGetContact(ContactId) {
      return window.touch.customerGetContact(ContactId);
    },
    async customerEditContact(Contact) {
      return window.touch.customerEditContact(Contact);
    },
    async customerCreateContact(Contact) {
      return window.touch.customerCreateContact(Contact);
    },
    async staffListCustomers(options) {
      return window.touch.staffListCustomers(
        options.limit, options.offset, options.search, undefined, undefined,
        options.customerStatus, options.generalLicenseStatus, options.branchCompanyId
      );
    },
    async staffListStaff({ options, branchCompanyId }) {
      return window.touch.staffListStaff(options.limit, options.offset, branchCompanyId);
    },
    async customerListBranches(options) {
      return window.touch.customerListBranches(options.limit, options.offset, options.search);
    },
    async staffGetTaxRates() {
      return window.touch.staffGetTaxRates();
    },
    async staffGetStaffContact(ContactId) {
      return window.touch.staffGetStaffContact(ContactId);
    },
    async staffUpdateTaxRate(TaxRate) {
      return window.touch.staffUpdateTaxRate(TaxRate);
    },
    async staffAddTaxRate(TaxRate) {
      return window.touch.staffAddTaxRate(TaxRate);
    },
    async staffDeleteTaxRate(id) {
      return window.touch.staffDeleteTaxRate(id);
    },
    async formatCustomerUpdateFinancialSettings({ vatEnabled, taxValue, fittingRate, currencyId },) {
      return {
        Financial: {
          vatEnabled: Boolean(vatEnabled),
          taxValue: Number(taxValue),
          fittingRate: Number(fittingRate),
          currencyId: currencyId ? Number(currencyId) : undefined,
        },
      };
    },
    async formatCustomerUpdateCommerceIntegrationSettings({ eCommerceIntegrationSettings }) {
      return {
        ECommerceIntegration: {
          Alias: eCommerceIntegrationSettings.alias,
          WebAddress: eCommerceIntegrationSettings.webAddress,
          Email: eCommerceIntegrationSettings.email,
          mobilePhone: eCommerceIntegrationSettings.mobilePhone,
          privacyPolicyDocumentId: eCommerceIntegrationSettings.privacyPolicyDocumentId,
          addressId: eCommerceIntegrationSettings.addressId,
          companyRegNumber: eCommerceIntegrationSettings.companyRegNumber,
          fensaRegistrationNumber: eCommerceIntegrationSettings.fensaRegistrationNumber,
          vatRegistrationNumber: eCommerceIntegrationSettings.vatRegistrationNumber,
          priceDisplayMode: Number(eCommerceIntegrationSettings.priceDisplayMode),
        },
      };
    },
    async formatCustomerUpdateLogo({ logo }) {
      return {
        Styling: {
          logo,
        },
      };
    },
    async customerUpdateFinancialSettings({ vatEnabled, taxValue, fittingRate }) {
      return window.touch.customerUpdateFinancialSettings({ vatEnabled, taxValue, fittingRate });
    },
    async customerUpdateCommerceIntegrationSettings(
      { eCommerceIntegrationSettings, customerId },
    ) {
      return window.touch.customerUpdateCommerceIntegrationSettings(
        eCommerceIntegrationSettings,
        customerId,
      );
    },
    async customerUpdateLogo({ logo, customerId }) {
      return window.touch.customerUpdateLogo(logo, customerId);
    },
    async customerGetProductOverrides() {
      return window.touch.customerGetProductOverrides();
    },
    async customerGetAllProductsWithOverrides(customerId) {
      return window.touch.customerGetAllProductsWithOverrides(customerId);
    },
    async customerUpdateProductOverrides(overrides) {
      return window.touch.customerUpdateProductOverrides(overrides);
    },
    async getPrivacyPolicyDocument() {
      return window.touch.getPrivacyPolicyDocument();
    },
    async getTermsAndConditionsDocument() {
      return window.touch.getTermsAndConditionsDocument();
    },
    async customerUploadDocumentWithUrl(document) {
      return window.touch.customerUploadDocumentWithUrl(document);
    },
    async staffGetDiscountList(datasetId) {
      return window.touch.staffGetDiscountList(datasetId);
    },
    async staffGetCustomerDiscounts({ customerId, datasetId }) {
      return window.touch.staffGetCustomerDiscounts(customerId, datasetId);
    },
    async staffUpdateDiscounts({ customerId, datasetId, discounts }) {
      return window.touch.staffUpdateDiscounts(customerId, datasetId, discounts);
    },
    async customerGetProcessingLevels() {
      return window.touch.customerGetProcessingLevels();
    },
    async customerGetCompany(companyId) {
      return window.touch.customerGetCompany(companyId);
    },
  },
  getters: {
    getCustomer(state) {
      return state.customer;
    },
    requoteMarkup(state) {
      try {
        return state.customer.financial.requoteMarkup;
      } catch (error) {
        return 0;
      }
    },
    hasBrandedPricingOn(state) {
      try {
        return state.customer.eCommerceIntegration.priceDisplayMode === 0;
      } catch (error) {
        return false;
      }
    },
    customer_has_branded_mode_enabled(state) {
      try {
        return state.customer.eCommerceIntegration.enabled;
      } catch (error) {
        return false;
      }
    },
    has_price_breakdown_enabled(state) {
      try {
        return !state.customer.financial.omitPriceBreakdown;
      } catch (error) {
        return false;
      }
    },
    customer_logo(state) {
      try {
        return `${window.touch.imageBaseURL}${state.customer.styling.logo}`;
      } catch (error) {
        // @todo log the error here
        return '';
      }
    },
    customer_name(state) {
      try {
        return `${state.customer.company.name}`;
      } catch (error) {
        // @todo log the error here
        return '';
      }
    },
  },
});
