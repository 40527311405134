<template>
  <Teleport defer to="#portal_popup">
    <modal-window
      v-if="modal_open"
      :modal_open="true"
      title="Add Staff Member"
      @close="$emit('close')"
    >
      <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
        * Required Fields
      </div>
      <div class="flex flex-wrap mt-5">
        <div class="w-full xs:w-1/2 xs:pr-5">
          <div class="font-medium">First Name <span class="text-green-600">*</span></div>
          <input v-model="user.firstName" type="text" class="form-control">
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-0">
          <div class="font-medium">Last Name <span class="text-green-600">*</span></div>
          <input v-model="user.lastName" type="text" class="form-control">
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Email <span class="text-green-600">*</span></div>
          <input v-model="user.email" type="text" class="form-control">
        </div>
        <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">Mobile</div>
          <input v-model="user.mobile" type="text" class="form-control">
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Departments <span class="text-green-600">*</span></div>
          <select v-model="user.departments" multiple class="form-control multiple">
            <option
              v-for="department in touchStore.contactDepartments"
              :key="department.id"
              :value="department.id"
            >
              {{ department.name }}
            </option>
          </select>
        </div>
        <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <ContactRoles
            :roles="user.roles"
            @set-contact-roles="$event === 0 ? (user.roles = $event) : (user.roles += $event)"
          />
        </div>
      </div>
      <div class="flex-flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Processing Level <span class="text-green-600">*</span></div>
          <select v-model="user.processingLevelId" class="form-control">
            <option
              v-for="(processing_level, index) in processing_levels"
              :key="index"
              :value="processing_level.id"
            >
              {{ processing_level.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="w-full mt-4">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <Dropzone
            @add-file="user.image = $event"
            @remove-file="user.image = null"
          />
        </div>
        <div class="mt-1">Photos help your teammates recognise you in TOUCH.</div>
      </div>
      <div class="xs:flex">
        <div class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3">
          <div
            class="flex items-center cursor-pointer"
            @click.prevent="user.isAdmin = !user.isAdmin"
          >
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
              <div class="switch-toggle" :class="{ _active: user.isAdmin }" />
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-nowrap w-32">
              <span v-text="user.isAdmin ? 'Admin User' : 'Non Admin User'" />
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button
          class="btn-action btn-lg"
          :class="{ 'pointer-events-none opacity-50': loading }"
          @click.prevent.stop="saveUser()"
        >
          Save
        </button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import validate from 'validate.js';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { useTouchStore } from '@/pinia/touch';
import Dropzone from '@/components/shared/Dropzone.vue';
import ContactRoles from '@/components/shared/ContactRoles.vue';

export default {
  components: {
    Dropzone,
    ContactRoles,
  },
  props: ['customerId', 'branchId'],
  data() {
    return {
      user: {
        isAdmin: false,
        licenseStatusType: 1, // this.enums.licenseStatusType.ENABLED,
        processingLevelId: null,
        departments: [],
        branchCompanyId: 0,
        roles: 0,
      },
      customer: null,
      processing_levels: [],
      new_user_image: null,
      modal_open: false,
      loading: true,
    };
  },
  computed: {
    ...mapStores(useCustomerStore, useTouchStore),
  },
  async mounted() {
    await this.loadCustomer();
    this.processing_levels = await this.customerStore.customerGetProcessingLevels();
    if (this.branchId) {
      this.user.branchCompanyId = this.branchId;
    }
    this.modal_open = true;
    this.loading = false;
  },
  methods: {
    async loadCustomer() {
      this.customer = await this.customerStore.customerGet(this.customerId);
    },
    async saveUser() {
      this.loading = true;
      const validationAttributes = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        mobile: this.user.mobile,
        departments: this.user.departments,
      };

      validationAttributes.processingLevel = this.user.processingLevelId;

      const validationConstraints = {
        firstName: {
          presence: { allowEmpty: false },
          length: {
            maximum: 32,
          },
        },
        lastName: {
          presence: { allowEmpty: false },
          length: {
            maximum: 32,
          },
        },
        email: {
          presence: { allowEmpty: false },
          email: true,
          length: {
            maximum: 255,
          },
        },
        departments: {
          presence: { allowEmpty: false },
        },
      };

      validationConstraints.processingLevel = {
        presence: { allowEmpty: false },
      };

      const validationErrors = validate(validationAttributes, validationConstraints);

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        this.loading = false;
        return;
      }

      if (this.new_user_image !== null) {
        this.user.image = this.new_user_image;
      }

      this.customer.users[this.customer.users.length] = this.user;

      await this.customerStore.customerCreateUsers({ customer: this.customer })
        .then(() => {
          this.$emit('user-saved');
          this.$emit('close');
        })
        .catch(() => {
          this.customer.users.pop();
          return false;
        });
      this.loading = false;
    },
  },
};
</script>
