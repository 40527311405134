<template>
  <div
    class="flex hover:bg-gray-300 transition duration-500 ease-in-out"
    :class="{
      'bg-blue-700': active && touch_portal_installation,
      'bg-gray-300': active && !touch_portal_installation,
      'text-white hover:bg-blue-700': touch_portal_installation,
    }"
  >
    <div class="flex transition duration-500 ease-in-out transform">
      <div class="p-1 mr-4">
        <i class="fal fa-fw" :class="icon" />
      </div>
      <div class="flex flex-col justify-around">{{ label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['icon', 'label', 'active'],
};
</script>
