<template>
  <div class="bg-white p-3 xs:p-10 border">
    <div class="p-3 xs:p-0 gap-5 justify-between">
      <div class="flex flex-col mb-10 border-b pb-10">
        <div class="flex flex-col">
          <div class="flex flex-col justify-around text-base mb-3">
            <div>Email Address <span class="text-red-600 ml-1">*</span></div>
          </div>
          <div class="">
            <input
              v-model="settings.email"
              :disabled="!is_admin_user || demo_mode"
              type="text"
              class="form-control max-w-md"
              :class="{ 'border-red-600': !settings.email }"
            >
          </div>
          <slot name="email_address" />
        </div>
      </div>
      <div class="flex flex-col border-b pb-10">
        <div class="flex flex-col justify-around text-base mb-3">
          <div>
            Website address <span class="text-red-600 ml-1">*</span>
            <info-popup
              :id="'vendor_settings_company_details:website'"
              class=""
              :info_message="'This will be the website address that is used on your Web Designer to direct your customers back to your website after completing their designs. It will also be used on the paperwork produced by your Web Designer and Quote & Survey Designer.'"
              :next_id="'vendor_settings_company_details:logo'"
            />
          </div>
        </div>
        <div class="flex relative mr-3">
          <div class="h-10 absolute px-4 border-r border-gray-300">
            <div class="flex flex-col justify-around h-full text-gray-500">https://</div>
          </div>
          <input
            v-model="settings.webAddress"
            :disabled="!is_admin_user || demo_mode"
            type="text"
            class="form-control max-w-md"
            style="padding-left: 100px"
            :class="{ 'border-red-600': !settings.webAddress }"
          >
        </div>
        <div class="text-gray-600 text-base mt-3">
          This will be displayed on your paperwork that will be sent to a customer.
        </div>
      </div>
      <div class="flex flex-col mt-10  border-b pb-10">
        <div class="flex flex-col justify-around text-base mb-3">
          <div>Phone number <span class="text-red-600 ml-1">*</span></div>
        </div>
        <div class="">
          <input
            v-model="settings.mobilePhone"
            :disabled="!is_admin_user || demo_mode"
            type="text"
            class="form-control max-w-md"
            :class="{ 'border-red-600': !settings.mobilePhone }"
          >
        </div>
        <div class="text-gray-600 text-base mt-3">
          This will be displayed on your paperwork that will be sent to a customer.
        </div>
      </div>
      <div class="mt-10 border-b pb-10">
        <div class="flex justify-between text-base mb-3">
          <div class="flex flex-col">
            <div>Your address <span class="text-red-600 ml-1">*</span></div>
          </div>
          <div v-if="!demo_mode" class="flex flex-col justify-around">
            <a class="btn" @click.prevent="show_address_popup = true">Edit Address</a>
          </div>
        </div>
        <div v-if="settings.addressId">
          {{ formattedAddress(selected_address()) }}
        </div>
        <div v-else class="text-red-600">You have no saved addresses</div>
        <div class="text-gray-600 text-base mt-3">
          This will be displayed on your paperwork that will be sent to a customer.
        </div>
        <slot name="company_registration_number" />
      </div>
      <div class="flex flex-col gap-10 xs:flex-row mt-10 border-b pb-10">
        <div class="flex-1">
          <div class="flex flex-col justify-around text-base mb-3">
            <div>
              Logo
              <info-popup
                :id="'vendor_settings_company_details:logo'"
                class=""
                :info_message="'You will need to upload your company logo; this will be displayed on your Web Designer and on any paperwork produced by your Web Designer and Quote & Survey Designer. If you don’t have a logo, your company name will appear in it’s place. Remember to click save after you upload.'"
                :next_id="'vendor_settings_company_details:reg_num'"
              />
            </div>
          </div>
          <div class="text-gray-600 text-base mb-3">
            This will be displayed on your dashboard, paperwork and designer.
          </div>
          <div class="">
            <Dropzone
              :removable="false"
              @add-file="$emit('setLogoFile', $event)"
              @remove-file="new_news_image = null"
            />
          </div>
          <div class="text-gray-500 py-3">
            <div>Recommended file type: Transparent PNG..</div>
            <div>Recommended size: 440px by 220px</div>
            <div>Maximum file size 1MB</div>
          </div>
        </div>
        <div class="flex flex-1 flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12" style="min-height: 300px; max-width: 400px;">
          <img
            v-if="!image_error"
            id="logo_img"
            :src="settings.logo"
            @error="image_error = true"
          >
        </div>
      </div>
    </div>
    <TermsAndPrivacy :setup="setup" :customer-id="settings.customerId" />
    <modal-window
      v-if="show_address_popup"
      :modal_open="show_address_popup"
      title="Your Address"
      @close="show_address_popup = false"
    >
      <popup-address-selection
        v-if="show_address_popup"
        :addresses="settings.addresses"
        :selected-address="settings.addressId"
        :customer-id="settings.customerId"
        @select-address="settings.addressId = $event"
        @new-address="newAddress"
        @close="show_address_popup = false"
      />
    </modal-window>
  </div>
</template>

<script>

import Dropzone from '@/components/shared/Dropzone.vue';
import PopupAddressSelection from '@/components/shared/address/PopupAddressSelection.vue';
import TermsAndPrivacy from '@/components/shared/setup/company/TermsAndPrivacy.vue';

export default {
  components: {
    'popup-address-selection': PopupAddressSelection,
    Dropzone,
    TermsAndPrivacy,
  },
  props: {
    settings: Object,
    setup: String
  },
  data() {
    return {
      loading: true,
      image_error: false,
      has_unsaved_changes: false,
      show_address_popup: false,
      companySettings: [],
    };
  },
  methods: {
    newAddress({ addresses, addressId }) {
      this.settings.addresses = addresses;
      this.settings.addressId = addressId
    },
    selected_address() {
      if (this.settings.addressId) {
        return this.settings.addresses.filter(
          (address) => address.id === this.settings.addressId,
        )[0];
      }
      return {};
    },
    formattedAddress(address) {
      return [
        address.line1,
        address.line2,
        address.line3,
        address.town,
        address.county,
        address.code,
      ]
        .filter((val) => val)
        .join(', ');
    },
  },
};
</script>
