<template>
  <Tooltip v-if="additionalCosts > 0" class="text-black" :is-left="false">
    <div class="flex flex-col gap-1 text-white">
      <span class="opacity-50 mb-2">{{ type }} includes:</span>
      <div>
        <span>
          <span v-html="formatCurrency(currency, productCost)" />
          product charge
        </span>
      </div>
      <div v-if="deliveryCost > 0">
        <span>
          <span v-html="formatCurrency(currency, deliveryCost)" />
          delivery charge
        </span>
      </div>
      <div v-if="fittingPrice > 0">
        <span>
          <span v-html="formatCurrency(currency, fittingPrice)" />
          fitting charge
        </span>
      </div>
      <div v-if="surveyPrice > 0">
        <span>
          <span v-html="formatCurrency(currency, surveyPrice)" />
          survey charge
        </span>
      </div>
      <div v-if="extrasSupplyFitPrice > 0">
        <span>
          <span v-html="formatCurrency(currency, extrasSupplyFitPrice)" />
          supply & fit extras charge
        </span>
      </div>
      <div v-if="extrasSupplyOnlyPrice > 0">
        <span>
          <span v-html="formatCurrency(currency, extrasSupplyOnlyPrice)" />
          supply only extras charge
        </span>
      </div>
    </div>
  </Tooltip>
</template>

<script>

import { formatCurrency } from '@/composables/currency';
import Tooltip from '@/components/shared/Tooltip.vue';

export default {
  components: {
    Tooltip
  },
  props: {
    type: String,
    productCost: Number,
    deliveryCost: Number,
    fittingPrice: Number,
    surveyPrice: Number,
    extrasSupplyFitPrice: Number,
    extrasSupplyOnlyPrice: Number,
    currency: String
  },
  setup() {
    return { formatCurrency }
  },
  computed: {
    additionalCosts() {
      return this.deliveryCost + this.fittingPrice + this.surveyPrice + this.extrasSupplyFitPrice + this.extrasSupplyOnlyPrice
    }
  }
};
</script>