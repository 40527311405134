export async function visualisationListImages(contactId) {
  return this.authenticatedPost('visualisation/ListImages', {
    OnlyForThisContactId: contactId,
  });
}

export async function visualisationUpdateImage(
  id,
  reference,
  view,
) {
  return this.authenticatedPost('visualisation/UpdateImage', {
    Id: id,
    Reference: reference,
    InternalView: view,
  });
}

export async function visualisationDeleteImage(id) {
  return this.authenticatedPost('visualisation/DeleteImage', {
    Id: id,
  });
}


export async function UpdateImageForm(newDocument) {
  return this.authenticatedPost('visualisation/UpdateImageForm', newDocument);
}
