<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full mt-5 xs:mt-8">
        <div class="font-medium">Address <span class="text-green-600">*</span></div>
        <input id="address1" v-model="newAddress.addressLine1" type="text" class="form-control">
        <input
          id="address2"
          v-model="newAddress.addressLine2"
          type="text"
          class="form-control mt-2 xs:mt-4"
        >
        <input
          id="address3"
          v-model="newAddress.addressLine3"
          type="text"
          class="form-control mt-2 xs:mt-4"
        >
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
        <div class="font-medium">Town <span class="text-green-600">*</span></div>
        <input id="town" v-model="newAddress.addressTown" type="text" class="form-control">
      </div>
      <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
        <div class="font-medium">County</div>
        <input id="county" v-model="newAddress.addressCounty" type="text" class="form-control">
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
        <div class="font-medium">Country <span class="text-green-600">*</span></div>
        <country-select
          v-model="newAddress.addressIso"
          class="form-control bg-white"
        />
      </div>
      <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
        <div class="font-medium">Postcode <span class="text-green-600">*</span></div>
        <input id="postcode" v-model="newAddress.addressCode" type="text" class="form-control">
      </div>
    </div>
  </div>
</template>

<script>
import CountrySelect from '@/components/shared/address/CountrySelect.vue';

export default {
  components: {
    'country-select': CountrySelect,
  },
  props: ['consumer'],
  data() {
    if (this.consumer) {
      const {
        addressCode,
        addressIso,
        addressLine1,
        addressLine2,
        addressLine3,
        addressTown,
        addressCounty,
        email,
        mobile,
        addressLatitude,
        addressLongitude,
      } = this.consumer;

      return {
        newAddress: {
          addressLine1,
          addressLine2,
          addressLine3,
          addressTown,
          addressCode,
          addressCounty,
          addressIso,
          addressLatitude,
          addressLongitude,
          email,
          mobile,
        },
      };
    }
    return {
      newAddress: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressTown: '',
        addressCounty: '',
        addressCode: '',
        addressIso: 'GB',
        addressLatitude: '',
        addressLongitude: '',
        email: '',
        mobile: '',
      },
    };
  },
  watch: {
    newAddress: {
      handler(address) {
        this.$emit('address-updated', address);
      },
      deep: true,
    },
  },
  mounted() {
    const self = this;
    this.$nextTick(() => {
      const txt = document.getElementById('address1');
       
      new window.data8.predictiveaddressui(txt, {
        ajaxKey: 'MSS8-FTK3-3VI8-VF8T',
        minDelay: 1000,
        fields: [
          { element: 'address1', field: 'line1' },
          { element: 'address2', field: 'line2' },
          { element: 'address3', field: 'line3' },
          { element: 'town', field: 'town' },
          { element: 'county', field: 'county' },
          { element: 'country', field: 'country' },
          { element: 'postcode', field: 'postcode' },
        ],
        selectAddress(address) {
          try {
            self.newAddress.latitude = address.RawAddress.Location.Latitude;
            self.newAddress.longitude = address.RawAddress.Location.Longitude;
          } catch (e) {
            self.newAddress.latitude = undefined;
            self.newAddress.longitude = undefined;
          }
        },
      });
    });
  },
};
</script>

<style scoped></style>
