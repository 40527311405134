<template>
  <div>
    <hr class="-mx-10">
    <div class="flex flex-wrap-reverse justify-between items-center m-3 xs:m-10 mb-0">
      <div class="text-2xl font-medium">
        Account Settings
      </div>
    </div>
    <ApproveOrderWarnings />
    <BccOrderConfirmation :organisation="organisation" @get-organisation="getOrganisation" />
    <PortalProcessing />
    <div v-if="!isLeadGen && authStore.accountSettings.showFitting">
      <FittingAndSurvey :organisation="organisation" @get-organisation="getOrganisation" />
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import ApproveOrderWarnings from '@/components/business/manageTouchAccount/accountSettings/ApproveOrderWarnings.vue';
import BccOrderConfirmation from '@/components/business/manageTouchAccount/accountSettings/BccOrderConfirmation.vue';
import PortalProcessing from '@/components/business/manageTouchAccount/accountSettings/PortalProcessing.vue';
import FittingAndSurvey from '@/components/business/manageTouchAccount/accountSettings/FittingAndSurvey.vue';

export default {
  components: {
    ApproveOrderWarnings,
    BccOrderConfirmation,
    PortalProcessing,
    FittingAndSurvey
  },
  props: {
    isLeadGen: Boolean
  },
  data() {
    return {
      organisation: {}
    }
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore),
  },
  async created() {
    await this.getOrganisation();
  },
  methods: {
    async getOrganisation() {
      this.organisation = await window.touch.GetOrganisation(
        this.customerStore.customer.companyId,
      );
    },
  }
};
</script>