<template>
  <div class="flex-1 flex flex-col">
    <div class="flex flex-col bg-green-100 border p-3 h-full">
      <div class="border-b font-bold mb-3 pb-3 flex justify-between">
        <div>Quote Email Notification</div>
      </div>
      <table class="w-full">
        <tbody>
          <tr>
            <td class="pb-1 w-32">Status:</td>
            <td class="pb-1 text-brand-primary">
              {{ $t(`quoteEmailStatus.${job.quoteEmailStatusType}`) }}
            </td>
          </tr>
          <tr>
            <td class="pb-1 w-32">Date:</td>
            <td
              v-if="job.quoteEmailStatusDate &&
                job.quoteEmailStatusType === enums.quoteEmailStatusType.SENT"
              class="pb-1 text-brand-primary"
            >
              <span :title="formatHumanDate(job.quoteEmailStatusDate)">{{
                formatDate(job.quoteEmailStatusDate, "L HH:mm")
              }}</span>
            </td>
            <td v-else class="pb-1 text-brand-primary">N/A</td>
          </tr>
        </tbody>
      </table>

      <div class="mt-auto">
        <button class="btn-action w-full mt-5" @click="sendEmailNotification">
          Send Email Notification
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import { formatHumanDate, formatDate } from '@/composables/date';

export default {
  name: 'SendQuoteNotification',
  props: ['job'],
  setup() {
    return { formatHumanDate, formatDate }
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  methods: {
    async sendEmailNotification() {
      await this.touchStore.sendTradeCustomerQuoteReadyEmail({
        contractId: this.job.contractId,
        jobKey: this.job.jobKey,
      });
      this.$emit('reload');
    },
  },
};
</script>

<style scoped></style>
