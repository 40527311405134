<template>
  <touch-layout>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-6 pt-0 xs:p-10"
    >
      <div v-if="!amIRegistered" class="bg-white p-3 xs:p-10 rounded-lg">
        <h1>You are not registered on the issue tracking system.</h1>

        <template v-if="!accessRequested">
          <p>Please click here if you want to request access.</p>

          <p><button class="btn bg-white" @click="requestAccess()">Request Access</button></p>
        </template>
        <template v-else>
          <p>Your request has been received, we will process as soon as possible.</p>
        </template>
      </div>

      <div v-if="amIRegistered && addingFeedback" class="bg-white p-3 xs:p-10 rounded-lg">
        <div class="flex justify-between">
          <span class="text-base flex flex-col justify-around p-3 pb-0">Add Feedback:</span>
          <div class="p-3 pb-0">
            <button class="btn" @click="addingFeedback = false">Cancel</button>
          </div>
        </div>
        <label class="flex flex-col p-3">
          <span>Task Name</span>
          <input
            v-model="task_name"
            placeholder="Task name"
            type="text"
            class="p-2 h-10 rounded border border-gray-300 bg-white"
          >
        </label>
        <label class="flex flex-col p-3">
          <span>Task notes</span>
          <textarea
            v-model="task_note"
            rows="5"
            placeholder="enter any additional notes here"
            class="p-2 rounded border border-gray-300 bg-white"
          />
        </label>
        <button class="ml-3 btn bg-white" @click="addFeedback()">Add Feedback</button>
      </div>

      <div v-if="amIRegistered" class="bg-white p-3 xs:p-10 rounded-lg mt-6">
        <div class="flex justify-between">
          <span class="text-base flex flex-col justify-around p-3 pb-0">
            In Progress Feedback List:
          </span>
          <div class="p-3 pb-0">
            <button v-if="!addingFeedback" class="btn" @click="addingFeedback = true">
              Create Feedback
            </button>
          </div>
        </div>

        <table
          v-if="!loadingTasks"
          class="mt-4 table-striped _hover w-full table-fixed lg:table-auto"
        >
          <thead>
            <tr>
              <th class="text-left font-normal px-3 py-2 md:px-6 md:py-4 text-base font-bold">
                Task Name
              </th>
              <th class="text-right font-normal px-3 py-2 md:px-6 md:py-4 text-base font-bold">
                Submitted At
              </th>
              <th class="text-right font-normal px-3 py-2 md:px-6 md:py-4 text-base font-bold">
                Modified At
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="task in tasks" :key="task.gid" class="table-row">
              <td class="text-left font-normal px-3 py-2 md:p-6 text-base" v-text="task.name" />
              <td
                class="text-right font-normal px-3 py-2 md:p-6 text-base"
                v-text="formatHumanDate(task.created_at)"
              />
              <td
                class="text-right font-normal px-3 py-2 md:p-6 text-base"
                v-text="task.modified_at ? formatHumanDate(task.completed_at) : ''"
              />
              <td class="text-right font-normal px-3 py-2 md:p-6 text-base">
                <a
                  :href="`https://app.asana.com/0/0/${task.gid}`"
                  class="btn bg-white"
                  target="_blank"
                >
                  view
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-else class="p-32 text-center">Loading Your Tasks...</div>
      </div>

      <div
        v-if="amIRegistered && closedTasks.length > 0"
        class="bg-white p-3 xs:p-10 rounded-lg mt-6"
      >
        <div class="flex justify-between">
          <span class="text-base flex flex-col justify-around p-3 pb-0">Completed Feedback:</span>
        </div>

        <table class="mt-4 table-striped _hover w-full table-fixed lg:table-auto">
          <thead>
            <tr>
              <th class="text-left font-normal px-3 py-2 md:px-6 md:py-4 text-base font-bold">
                Task Name
              </th>
              <th class="text-right font-normal px-3 py-2 md:px-6 md:py-4 text-base font-bold">
                Submitted At
              </th>
              <th class="text-right font-normal px-3 py-2 md:px-6 md:py-4 text-base font-bold">
                Completed At
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="task in closedTasks" :key="task.gid" class="table-row">
              <td class="text-left font-normal px-3 py-2 md:p-6 text-base" v-text="task.name" />
              <td
                class="text-right font-normal px-3 py-2 md:p-6 text-base"
                v-text="formatHumanDate(task.created_at)"
              />
              <td
                class="text-right font-normal px-3 py-2 md:p-6 text-base"
                v-text="task.completed_at ? formatHumanDate(task.completed_at) : ''"
              />
              <td class="text-right font-normal px-3 py-2 md:p-6 text-base">
                <a
                  :href="`https://app.asana.com/0/0/${task.gid}`"
                  class="btn bg-white"
                  target="_blank"
                >
                  view
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="!state && redirectLink" class="bg-white p-3 xs:p-10 rounded-lg">
        <h1>Activate your account</h1>

        <a class="btn bg-white" target="_blank" :href="redirectLink">Click here to authenticate</a>
      </div>
    </loading>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { formatHumanDate } from '@/composables/date';
import axios from 'axios';

export default {
  components: {},
  props: [],
  setup() {
    return { formatHumanDate }
  },
  data() {
    return {
      addingFeedback: false,
      task_name: '',
      task_note: '',
      loadingTasks: false,
      tasks: [],
      closedTasks: [],
      attempt: 0,
      loading: true,
      amIRegistered: false,
      accessRequested: false,
      redirectLink: undefined,
      state: undefined,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  async mounted() {
    this.amIRegistered = (
      await axios.get(
        `${import.meta.env.VITE_APP_ISSUE_TRACKER_URL}amIRegistered?email=${encodeURIComponent(this.authStore.email)}`,
        {
          withCredentials: true,
        },
      )
    ).data;

    if (this.amIRegistered) {
      this.loginToTaskSystem();
    } else {
      this.loading = false;
    }
  },
  methods: {
    waitForToken() {
      axios
        .get(
          `${import.meta.env.VITE_APP_ISSUE_TRACKER_URL}getSavedToken?email=${encodeURIComponent(this.authStore.email)}`,
        )
        .then((response) => {
          this.state = response.data;
          this.loadTasks();
        })
        .catch(() => {
          this.attempt += 1;
          window.setTimeout(this.waitForToken, Math.min(10000, 1000 * this.attempt));
        });
    },
    async loadTasks(showLoading = true) {
      this.loadingTasks = showLoading;
      const axiosResponse = await axios.get(
        `${import.meta.env.VITE_APP_ISSUE_TRACKER_URL}tasks?state=${this.state}`,
      );
      this.loadingTasks = false;
      this.tasks = axiosResponse.data.open;
      this.closedTasks = axiosResponse.data.completed;
      window.setTimeout(() => {
        this.loadTasks(false);
      }, 1000 * 180);
    },
    async loginToTaskSystem() {
      const response = (
        await axios.get(
          `${import.meta.env.VITE_APP_ISSUE_TRACKER_URL}getToken?email=${encodeURIComponent(this.authStore.email)}`,
          {
            withCredentials: true,
          },
        )
      ).data;

      this.loading = false;

      if (response.url) {
        this.redirectLink = response.url;
        window.open(this.redirectLink);
        this.waitForToken();
      } else {
        this.state = response.state;
        this.loadTasks();
      }
    },
    requestAccess() {
      axios.get(
        `${import.meta.env.VITE_APP_ISSUE_TRACKER_URL}requestAccess?email=${encodeURIComponent(this.authStore.email)}`,
      );
      this.accessRequested = true;
    },
    addFeedback() {
      axios
        .post(`${import.meta.env.VITE_APP_ISSUE_TRACKER_URL}addTask?state=${this.state}`, {
          name: this.task_name,
          note: this.task_note,
        })
        .then(() => {
          this.task_name = '';
          this.task_note = '';
          this.addingFeedback = false;
          this.loadTasks();
        });
    },
  },
};
</script>
