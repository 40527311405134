<template>
  <div v-if="newPrices.length > 0">
    <table class="table-pricing table-fixed">
      <thead>
        <tr v-if="isWidthByHeight">
          <th class="text-left py-1 px-3">Min Height</th>
          <th class="text-left py-1 px-3">Max Height</th>
          <th class="text-left py-1 px-3">Min Width</th>
          <th class="text-left py-1 px-3">Max Width</th>
          <th class="p-1" style="width: 104px">Price</th>
          <th v-if="isCustomerPricing()" class="p-1" style="width: 78px">FOC</th>
        </tr>
        <tr v-else>
          <th class="text-left py-1 px-3">Value</th>
          <th class="p-1" style="width: 104px">Price</th>
          <th v-if="isCustomerPricing()" class="p-1" style="width: 78px">FOC</th>
        </tr>
      </thead>
      <tbody>
        <BasicLine
          v-for="(price, index) in newPrices"
          :key="price.id"
          :subtitle="lineComponent[financialRateType]"
          :position="financialRateType === enums.priceRateType.PERCENT ? 'suffix' : undefined"
          :icon="financialRateType === enums.priceRateType.PERCENT ? '%' :
            (currencySymbol(touch_business_installation ? defaultCurrency : customerStore.customer.currency))"
          :is-customer-pricing="isCustomerPricing()"
          :is-width-by-height="isWidthByHeight"
          :default-currency="defaultCurrency"
          :price="price"
          :value="valueFor(index)"
          @update="updateValueFor(index, $event)"
          @update-price="updatePrice(index, $event)"
          @update-f-o-c="updateFOC(index, $event)"
          @update-dimensions="updateDimensions(index, $event)"
        />
      </tbody>
    </table>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { currencySymbol } from '@/composables/currency';
import BasicLine from '@/components/shared/priceLines/List/BasicLine.vue';

export default {
  components: {
    BasicLine,
  },
  props: ['grid', 'financialRateType', 'defaultCurrency', 'isWidthByHeight'],
  setup() {
    return { currencySymbol }
  },
  data() {
    return {
      newPrices: [],
      lineComponent: {
        [window.enum.priceRateType.NONE]: '',
        [window.enum.priceRateType.EACH]: 'Each',
        [window.enum.priceRateType.PER_KG]: 'Per KG',
        [window.enum.priceRateType.PERCENT]: 'Percentage',
        [window.enum.priceRateType.PER_METER]: ' Per Metre',
        [window.enum.priceRateType.METER_SQUARED]: 'Per m<sup>2</sup>',
      },
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
    originalPrices() {
      if (this.grid.overrideCells) {
        return this.grid.overrideCells;
      }

      return this.grid.cells;
    },
  },
  mounted() {
    this.newPrices = JSON.parse(JSON.stringify(this.originalPrices));
    this.$emit('new-prices', this.newPrices);
  },
  methods: {
    isCustomerPricing() {
      return this.$route.path !== '/manage-touch-account/pricing';
    },
    updateValueFor(index, value) {
      this.newPrices[index].value = Number(value);
      this.$emit('new-prices', this.newPrices);
    },
    updatePrice(index, { value, foc }) {
      this.newPrices[index].value = Number(value);
      this.newPrices[index].foc = foc;
      this.$emit('new-prices', this.newPrices);
    },
    updateDimensions(index, { property, value }) {
      this.newPrices[index][property] = Number(value);
      this.$emit('new-prices', this.newPrices);
    },
    valueFor(index) {
      if (this.newPrices.length === 0) {
        return 0;
      }

      return Number(this.newPrices[index].value);
    },
  },
};
</script>
