<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['News', '/news'], [news_post.title]]" />
    </template>
    <template #header_page_title>
      {{ news_post.title }}
    </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        {{ formatDate(news_post.publishAt) }}
      </div>
    </template>
    <transition appear name="fade-in">
      <main class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 xs:p-10 relative">
        <loading :loading="loading" class="flex">
          <div class="w-full md:w-2/3 lg:w-3/4">
            <article class="bg-white p-6 xs:p-10 rounded-lg mb-6 xs:mb-10">
              <div class="flex flex-col flex-1 px-10">
                <div class="mb-5">
                  <api-img v-if="news_post.imageUrl" :src="news_post.imageUrl" class="w-full" />
                </div>
                <ProtectedIframe :html="articleContent" />
              </div>
            </article>
          </div>
        </loading>
      </main>
    </transition>
  </touch-layout>
</template>

<script>

import { formatDate } from '@/composables/date';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import ProtectedIframe from '@/components/shared/ProtectedIframe.vue';

export default {
  components: {
    Breadcrumbs,
    ProtectedIframe
  },
  setup() {
    return { formatDate }
  },
  data() {
    return {
      loading: true,
      news_post: [],
    };
  },
  computed: {
    articleContent() {
      return this.news_post.paragraph ? this.news_post.paragraph : this.news_post.summary;
    },
  },
  mounted() {
    Promise.all([window.touch.getNewsItem(this.$route.params.id)]).then(([post]) => {
      this.news_post = post;
      this.loading = false;
    });
  },
};
</script>
