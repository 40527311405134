<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          ['Sales Dashboard', '/customer/' + customer.customerId + '/sales-dashboard'],
          ['Switch Design'],
        ]"
      />
    </template>
    <template #header_page_title> Switch Design </template>
    <SwitchDesign @go-to-u-r-l="goToURL" />
  </touch-layout>
</template>

<script>

import SwitchDesign from '@/components/shared/SwitchDesign.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';

export default {
  components: {
    SwitchDesign,
    Breadcrumbs
  },
  props: {
    customer: Object
  },
  computed: {
    ...mapStores(useBasketStore),
  },
  methods: {
    goToURL() {
      this.routerPush(
        `/customer/${this.basketStore.customerId}/sales-dashboard/design/${this.$route.params.id}`,
      );
    },
  },
};
</script>
