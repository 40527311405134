export async function getAllVehicles() {
  return (await this.authenticatedPost('vehicle/List')).data;
}

export async function createVehicle(description, registration, maxWeight) {
  await this.authenticatedPost('vehicle/Create', {
    Description: description,
    Registration: registration,
    MaxWeight: maxWeight,
  });
}

export async function updateVehicle(
  id,
  description,
  registration,
  maxWeight,
) {
  await this.authenticatedPost('vehicle/Update', {
    Id: id,
    Registration: registration,
    Description: description,
    MaxWeight: maxWeight,
  });
}
