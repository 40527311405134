function formatMoney(currencyCode, amount) {
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: currencyCode || 'GBP',
  }).format(amount);
}

export function currencyLookup() {
  return {
    GBP: 'Pound Sterling',
    EUR: 'Euro',
    USD: 'US Dollar',
    CAD: 'Canadian Dollar',
    AUD: 'Australian Dollar',
  };
}

export function currencyList() {
  return {
    GBP: `(GBP) Pound Sterling (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'GBP', currencyDisplay: 'symbol' }).format(1234.56)})`,
    EUR: `(EUR) Euro (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'EUR', currencyDisplay: 'symbol' }).format(1234.56)})`,
    USD: `(USD) US Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' }).format(1234.56)})`,
    AUD: `(AUD) Australian Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'AUD', currencyDisplay: 'symbol' }).format(1234.56)})`,
    CAD: `(CAD) Canadian Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CAD', currencyDisplay: 'symbol' }).format(1234.56)})`,
  };
}

export function currencySymbol(currencyCode) {
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: currencyCode || 'GBP',
  })
    .formatToParts(0)
    .find((part) => part.type === 'currency').value;
}

export function formatCurrency(currencyCode, value, fallback = '-') {
  const price = Number(value);

  return price === 0 ? fallback : formatMoney(currencyCode, price);
}

export function formatCurrencyWith0(currencyCode, value) {
  const price = Number(value);

  return formatMoney(currencyCode, price);
}

export function roundToNDecimalPlaces(amount, decimals) {
  return +`${Math.round(`${amount}e+${decimals}`)}e-${decimals}`;
}