<template>
  <div>
    <Update
      v-if="editing"
      :organisation="organisation"
      :modal_open="editing"
      @close="(editing = false), $emit('getOrganisation')"
    />
    <div class="flex flex-wrap bg-white border m-3 xs:m-10 mt-4 relative pr-16">
      <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Fitting Cost / Job</div>
        <div class="h-10 flex flex-col justify-around">
          {{ currencySymbol(defaultCurrency) }}{{ organisation.fittingCost || 0 }}
        </div>
      </div>
      <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Fitting Markup</div>
        <div class="h-10 flex flex-col justify-around">{{ organisation.fittingMarkup || 0 }}%</div>
      </div>
      <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Survey Cost</div>
        <div class="h-10 flex flex-col justify-around">
          {{ currencySymbol(defaultCurrency) }}{{ organisation.surveyCost || 0 }}
        </div>
      </div>
      <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Survey Markup</div>
        <div class="h-10 flex flex-col justify-around">{{ organisation.surveyMarkup || 0 }}%</div>
      </div>
      <div class="absolute bottom-0 right-0 p-5">
        <div
          class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
          @click="editing = true"
        >
          <i class="fa fa-pencil" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Update from '@/components/business/manageTouchAccount/accountSettings/fittingAndSurvey/Update.vue';
import { currencySymbol } from '@/composables/currency';
import { useTouchStore } from '@/pinia/touch'
import { mapStores } from 'pinia';

export default {
  components: {
    Update,
  },
  props: {
    organisation: Object
  },
  setup() {
    return { currencySymbol }
  },
  data() {
    return {
      editing: false,
      defaultCurrency: undefined,
    };
  },
  computed: {
    ...mapStores(useTouchStore)
  },
  async mounted() {
    this.defaultCurrency = (await this.touchStore.commonGetCurrencies()).filter(
      (currency) => currency.isDefault,
    )[0].code;
  },
};
</script>
