<template>
  <div class="p-5 pb-0 grid xs:grid-cols-2 gap-5 pb-0" :class="[isBnQ ? 'md:grid-cols-2' : 'md:grid-cols-3']">
    <div class="flex items-center rounded-lg p-5 gap-5" :style="{ 'background-color': '#bbeddc' }">
      <div>
        <div class="bg-white rounded-full h-16 w-16 text-center">
          <i
            class="text-3xl rounded-full fa-light fa-box-check"
            :style="{ 'line-height': '4rem' }"
          />
        </div>
      </div>
      <div class="flex-1 text-center">
        <div>
          <div>
            <span v-if="isLoading" class="text-3xl">-</span>
            <span v-else class="text-3xl"> {{ totalOrders }}</span>
          </div>
          <div class="border-solid border-t border-gray-400 my-2 w-1/2 mx-auto" />
          <div>
            <span class="text-xl">Orders Placed</span>
          </div>
          <div class="my-1">
            <select v-model="ordersRange" class="text-xs bg-white px-2 py-1 rounded">
              <option value="30">Last 30 Days</option>
              <option value="60">Last 60 Days</option>
              <option value="90">Last 90 Days</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center rounded-lg p-5 gap-5" :style="{ 'background-color': '#bbeddc' }">
      <div>
        <div class="bg-white rounded-full h-16 w-16 text-center">
          <i
            class="text-3xl rounded-full fa-light fa-clipboard-list-check"
            :style="{ 'line-height': '4rem' }"
          />
        </div>
      </div>
      <div class="flex-1 text-center">
        <div>
          <div>
            <span v-if="isLoading" class="text-3xl">-</span>
            <span v-else class="text-3xl"> {{ totalQuotes }}</span>
          </div>
          <div class="border-solid border-t border-gray-400 my-2 w-1/2 mx-auto" />
          <div>
            <span class="text-xl">Quotes Created</span>
          </div>
          <div class="my-1">
            <select v-model="quotesRange" class="text-xs bg-white px-2 py-1 rounded">
              <option value="30">Last 30 Days</option>
              <option value="60">Last 60 Days</option>
              <option value="90">Last 90 Days</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isBnQ" class="flex items-center rounded-lg p-5 gap-5" :style="{ 'background-color': '#bbeddc' }">
      <div>
        <div class="bg-white rounded-full h-16 w-16 text-center">
          <i
            class="text-3xl rounded-full fa-light fa-clipboard-question"
            :style="{ 'line-height': '4rem' }"
          />
        </div>
      </div>
      <div class="flex-1 text-center">
        <div>
          <div>
            <span v-if="isLoading" class="text-3xl">-</span>
            <span v-else class="text-3xl"> {{ totalEnquiries }}</span>
          </div>
          <div class="border-solid border-t border-gray-400 my-2 w-1/2 mx-auto" />
          <div>
            <span class="text-xl">Dealer Enquiries</span>
          </div>
          <div>
            <span class="text-xs">generated by you</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    isBnQ: Boolean
  },
  data() {
    return {
      totalOrders: 0,
      totalQuotes: 0,
      totalEnquiries: 0,
      isLoading: true,
      quotesRange: 30,
      ordersRange: 30
    };
  },
  watch: {
    async 'quotesRange'() {
      this.totalQuotes = await this.getNumberOfQuotes(this.quotesRange);
    },
    async 'ordersRange'() {
      this.totalOrders = await this.getNumberOfOrders(this.ordersRange);
    }
  },
  async created() {
    this.totalOrders = await this.getNumberOfOrders(this.quotesRange);
    this.totalQuotes = await this.getNumberOfQuotes(this.ordersRange);
    const { totalDealerEnquiries } = await window.touch.GetTotalDealerEnquiries();
    this.totalEnquiries = totalDealerEnquiries;
    this.isLoading = false;
  },
  methods: {
    async getNumberOfQuotes(range) {
      const resp = await window.touch.loadKPIDashboardQuotes(range * 1440);
      return resp.total;
    },
    async getNumberOfOrders(range) {
      const resp = await window.touch.loadKPIDashboardOrders(range * 1440);
      return resp.total;
    },
  },
};
</script>
