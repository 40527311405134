import { errorCallback, successCallback } from '@/composables/validate';

export async function getAllCompanyHolidays() {
  return (await this.authenticatedPost('company/GetCompanyHolidays')).data;
}

export async function addCompanyHoliday(title, dateStart, dateEnd) {
  this.parseResponse(
    await this.authenticatedPost('company/AddCompanyHoliday', {
      Title: title,
      DateStart: dateStart,
      DateEnd: dateEnd,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
}

export async function updateCompanyHoliday(
  id,
  title,
  dateStart,
  dateEnd,
) {
  this.parseResponse(
    await this.authenticatedPost('company/UpdateCompanyHoliday', {
      Id: id,
      Title: title,
      DateStart: dateStart,
      DateEnd: dateEnd,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
}

export async function deleteCompanyHoliday(id) {
  this.parseResponse(
    await this.authenticatedPost('company/DeleteCompanyHoliday', { Id: id }),
    errorCallback,
    () => successCallback('Delete'),
  );
}

export async function getUserDetails() {
  return (await this.authenticatedPost('company/GetUserDetails')).data;
}

export async function getListCompanies(CompanyType) {
  return this.parseResponse(
    await this.authenticatedPost('company/ListCompanies', {
      CompanyType,
    }),
    errorCallback,
  );
}

export async function GetOrganisation(companyId) {
  return this.parseResponse(await this.authenticatedPost('company/GetOrganisation', { companyId }));
}

export async function CreateOrganisation(
  CompanyId,
  FittingMarkup,
  SupplierCost,
  SupplierMarkup,
) {
  return this.parseResponse(
    await this.authenticatedPost('company/CreateOrganisation', {
      CompanyId,
      FittingMarkup,
      SupplierCost,
      SupplierMarkup,
    }),
  );
}

export async function UpdateOrganisation(organisation) {
  return this.parseResponse(
    await this.authenticatedPost('company/UpdateOrganisation', organisation),
    errorCallback,
    () => successCallback('Save'),
  );
}
