<template>
  <div class="w-full bg-white p-5 rounded-lg">
    <div>
      <span class="text-lg">Customer Support</span>
    </div>
    <div class="border-solid border-t border-gray-400 my-2" />
    <router-link to="/manage-marketing">
      <div class="bg-gray-300 p-5 rounded-lg mt-5">
        <div class="flex items-center gap-3 mb-5">
          <div class="bg-white rounded-full h-8 w-8 text-center">
            <i
              class="text-md rounded-full fa-light fa-book-open"
              :style="{ 'line-height': '2rem' }"
            />
          </div>
          <span class="text-lg">Manage Resources</span>
        </div>
        <div>
          <span>Allow customers to download marketing collateral; Technical guides, brochures, images,
            photos & more</span>
        </div>
      </div>
    </router-link>
    <router-link to="/manage-news">
      <div class="bg-gray-300 p-5 rounded-lg mt-5">
        <div class="flex items-center gap-3 mb-5">
          <div class="bg-white rounded-full h-8 w-8 text-center">
            <i
              class="text-md rounded-full fa-light fa-megaphone"
              :style="{ 'line-height': '2rem' }"
            />
          </div>
          <span class="text-lg">Manage News</span>
        </div>
        <div>
          <span>Upload news that is viewable by customers in their Portal; Promote products, industry,
            and company news.</span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    firstName: String,
    companyName: String,
  },
};
</script>
