import { ref, onUnmounted } from 'vue';

export function useContractPoller() {
  const timeout = ref(null);
  let intervals = ref(0);
  let isTimeOut = ref(true);

  const pollContractStates = async (contractType, contracts) => {
    intervals.value++
    if (intervals.value > 20) {
      isTimeOut.value = false
    }

    contracts.sort((a, b) => {
      if (!a.date && !b.date) {
        return 0;
      }
      return new Date(b.date) - new Date(a.date)
    });
    const contractsInSavingState = contracts.filter(
      (x) => x.saveState === window.enum.orderStatusSaveStates.STARTED,
    );
    if (contractsInSavingState.length > 0) {
      const contractIds = contractsInSavingState.map((x) => x.id);
      const { contracts: updatedContracts } = await window.touch.ListSaveStates({
        type: contractType,
        OnlyTheseContracts: contractIds,
      });
      const contractsInCompleteState = updatedContracts.filter(
        (x) => x.saveState ===  window.enum.orderStatusSaveStates.COMPLETED,
      );
      if (contractsInCompleteState.length > 0) {
        for (let i = 0; i < contractsInCompleteState.length; i++) {
          const index = contracts.findIndex((x) => x.id === contractsInCompleteState[i].id);
          const contract = contracts[index];
          Object.keys(contract).forEach((key) => {
            if (key in contractsInCompleteState[i]) {
              contract[key] = contractsInCompleteState[i][key];
            }
          });
          contracts[index] = contract
        }
      }
      if (updatedContracts.filter((x) => x.saveState ===  window.enum.orderStatusSaveStates.STARTED).length > 0 && isTimeOut.value) {
        timeout.value = setTimeout(() => pollContractStates(contractType, contracts), 5000);
      } else {
        clearTimeout(timeout.value);
      }
    }
  }

  onUnmounted(() => {
    isTimeOut.value = false;
    clearTimeout(timeout.value);
  });

  return {
    pollContractStates,
  };
}
