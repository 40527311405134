<template>
  <div>loading...</div>
</template>
<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';

export default {
  name: 'LoadContract',
  computed: {
    ...mapStores(useBasketStore),
  },
  async mounted() {
    this.basketStore.fromQuote({
      contractId: this.$route.params.contractId,
      jobKey: this.$route.params.jobId,
    })
      .then(() => {
        this.routerPush('/basket');
      })
      .catch((error) => {
        this.alertBox().fire({
          title: 'Error',
          text: error,
        });
      });
  },
};
</script>
