<template>
  <div class="bg-white border text-center p-5 flex flex-col w-full">
    <edit-video
      v-if="edit_video_modal_open"
      :video="video"
      :categories="categories"
      @updated="$emit('updated')"
      @close="edit_video_modal_open = false"
    />
    <div v-if="touch_business_user" class="flex justify-between mb-3">
      <div
        class="bg-white rounded-full flex flex-col justify-around w-8 h-8 cursor-pointer"
        @click="edit_video_modal_open = true"
      >
        <i class="fal fa-pencil" />
      </div>
      <div
        class="bg-white rounded-full flex flex-col justify-around w-8 h-8 cursor-pointer"
        @click="deleteItem()"
      >
        <i class="fal fa-times text-red-600" />
      </div>
    </div>
    <div class="mt-auto">
      <img v-if="!image_error" :src="video.thumbnail" class="w-full" @error="image_error = true">
      <div v-if="image_error" style="font-size: 10rem">
        <i class="fal fa-file" />
      </div>
      <div class="mt-5">
        {{ video.name }}
      </div>
      <div class="mt-5">
        <a class="btn-primary" target="touch_video" @click="openVideo"> Watch </a>
      </div>
    </div>
  </div>
</template>

<script>

import { getYoutubeEmbedUrl } from '@/composables/video';
import EditVideo from '@/components/shared/marketing/EditVideo.vue';

export default {
  components: {
    'edit-video': EditVideo,
  },
  props: ['video', 'categories'],
  data() {
    return {
      image_error: false,
      edit_video_modal_open: false,
    };
  },
  methods: {
    async openVideo() {
      const embedUrl = await getYoutubeEmbedUrl(this.video.url);
      this.alertBox().fire({
        html: `<iframe src="${embedUrl}"
                title="YouTube video player"
                frameborder="0"
                style="min-height: 300px; min-width: 100%; padding: 20px;"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>`,
        showCloseButton: true,
        showConfirmButton: false,
      });
    },
    async deleteItem() {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to delete this item?',
          text: 'This will not be recoverable.',
          showCancelButton: true,
        })
        .then(async (response) => {
          if (response.isConfirmed) {
            await window.touch.deleteMarketingDocuments([this.video.id]).then(() => {
              this.$emit('deleted');
            });
          }
        });
    },
  },
};
</script>
