<template>
  <div class="flex-1 flex flex-col">
    <div class="flex flex-col bg-blue-100 border p-3 h-full">
      <div class="flex items-center border-b font-bold mb-3 pb-3">
        <div v-if="job?.discountApplied" class="flex gap-2 items-center">
          <span>
            Discounted {{ breakdown }}
          </span>
          <Tooltip v-if="!isCostsHidden" :width="200">
            <span class="font-extralight">
              The discount code 
              <span class="text-green-500">{{ job?.discountCode }}</span> has been applied
              <span v-if="job?.items?.some(x => x.override === true)"> to an item in this quote.</span>
              <span v-else> to the subtotal of this quote.</span>
            </span>
          </Tooltip>
        </div>
        <div v-else-if="job?.overridePrice === true || job?.items?.some(x => x.override === true)" class="flex gap-2 items-center">
          <span>
            Manual {{ breakdown }}
          </span>
          <Tooltip v-if="!isCostsHidden" :width="200">
            <span v-if="job?.overridePrice === true" class="font-extralight">The subtotal has been manually updated</span>
            <span v-else class="font-extralight">The price of an item has been manually updated.</span>
          </Tooltip>
        </div>
        <div v-else>
          Calculated {{ breakdown }}
        </div>
        <div class="ml-auto">
          <slot name="cost_toggle" />
        </div>
      </div>
      <table v-if="!isCostsHidden">
        <tbody>
          <tr class="align-baseline">
            <td class="pb-1 w-32">Subtotal:</td>
            <td>
              <span v-if="job.calculatedPriceTaxable">
                <span v-if="job?.discountApplied || job?.overridePrice === true || job?.items?.some(x => x.override === true)" class="line-through opacity-50">
                  {{ formatCurrency(job.customerCurrency, job.calculatedPriceTaxable) }}
                </span>
              </span>
              <div class="flex items-center gap-2 pb-1">
                <span
                  class="text-brand-primary"
                  v-html="formatCurrency(job.customerCurrency, job.priceBeforeTax)"
                />
                <PriceBreakdown
                  type="Subtotal"
                  :currency="job.customerCurrency"
                  :product-cost="job.productCost()"
                  :delivery-cost="job.priceDeliveryCharge"
                  :fitting-price="job.fittingPrice"
                  :survey-price="job.surveyPrice"
                  :extras-supply-fit-price="job.extrasSupplyFitPrice"
                  :extras-supply-only-price="job.extrasSupplyOnlyPrice"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td class="pb-1 w-32">VAT:</td>
            <td
              class="pb-1 text-brand-primary"
              v-html="formatCurrency(job.customerCurrency, job.tax)"
            />
          </tr>
          <tr>
            <td class="w-32">Total:</td>
            <td>
              <span class="flex items-center gap-2">
                <span
                  class="text-brand-primary"
                  v-html="formatCurrency(job.customerCurrency, job.priceIncludingTax)"
                />
                <PriceBreakdown
                  type="Total"
                  :currency="job.customerCurrency"
                  :product-cost="job.productCostIncTax()"
                  :delivery-cost="job.priceDeliveryChargeIncTax"
                  :fitting-price="job.fittingPriceIncTax"
                  :survey-price="job.surveyPriceIncTax"
                  :extras-supply-fit-price="job.extrasSupplyFitPriceIncTax"
                  :extras-supply-only-price="job.extrasSupplyOnlyPriceIncTax"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <slot name="financial_breakdown" />
    </div>
  </div>
</template>

<script>

import { formatCurrency } from '@/composables/currency';
import Tooltip from '@/components/shared/Tooltip.vue';
import PriceBreakdown from '@/components/shared/job/PriceBreakdown.vue';

export default {
  components: {
    Tooltip,
    PriceBreakdown
  },
  props: ['breakdown', 'isCostsHidden', 'job'],
  setup() {
    return { formatCurrency }
  },
};
</script>

<style scoped>
.info:before {
  content: "";
  position: absolute;
  top: 100%;
  right: 20px;
  width: 0;
  border-top: 20px solid black;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}
</style>
