<template>
  <touch-layout>
    <template v-if="!loading" #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Branch Management', '/branch'],
          [`Branch (${branch.name})`, `/branch/${branch.id}`],
          ['Address'],
        ]"
      />
    </template>
    <template #header_page_title> Branch Address </template>
    <branch-navigation-menu />
    <loading
      id="branch_index_scrolling_outer_wrapper"
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch"
    >
      <div class="bg-white border m-3 md:m-10 p-3">
        <table class="table-lined xs:table-unstacked w-full">
          <thead>
            <tr>
              <th>Line 1</th>
              <th>Line 2</th>
              <th>Line 3</th>
              <th>Town</th>
              <th>County</th>
              <th>Country</th>
              <th>Postcode</th>
              <th>Primary</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="td-title w-24">Line 1:</div>
                <div class="td-content">{{ address.line1 }}</div>
              </td>
              <td>
                <div class="td-title w-24">Line 2:</div>
                <div class="td-content">{{ address.line2 }}</div>
              </td>
              <td>
                <div class="td-title w-24">Line 3:</div>
                <div class="td-content">{{ address.line3 }}</div>
              </td>
              <td>
                <div class="td-title w-24">Town:</div>
                <div class="td-content">{{ address.town }}</div>
              </td>
              <td>
                <div class="td-title w-24">County:</div>
                <div class="td-content">{{ address.county }}</div>
              </td>
              <td>
                <div class="td-title w-24">Country:</div>
                <div class="td-content">{{ address.iso }}</div>
              </td>
              <td>
                <div class="td-title w-24">Postcode:</div>
                <div class="td-content">{{ address.code }}</div>
              </td>
              <td>
                <div class="td-title w-24">Primary Address:</div>
                <div class="td-content">
                  <template v-if="address.isPrimary"> Yes </template>
                  <template v-else> No </template>
                </div>
              </td>
              <td>
                <div class="absolute top-0 right-0 p-3">
                  <div
                    v-if="editing_address"
                    class="px-2 py-1 text-2xs rounded text-gray-600 border bordergray-600 cursor-pointer hover:text-white hover:bg-gray-600"
                    @click="editing_address = null"
                  >
                    <i class="fa fa-times" />
                  </div>
                  <div
                    v-if="!editing_address"
                    class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
                    @click="editing_address = true"
                  >
                    <i class="fa fa-pencil" />
                  </div>
                </div>
                <edit-address
                  v-if="editing_address"
                  :branch="branch"
                  :address="address"
                  :logged-in-user="loggedInUser"
                  @updated="loadBranch"
                  @close="editing_address = null"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </loading>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import EditAddress from '@/components/business/branch/EditAddress.vue';
import BranchNavigationMenu from '@/components/business/branch/BranchNavigationMenu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    BranchNavigationMenu,
    EditAddress,
    Breadcrumbs,
  },
  data() {
    return {
      loggedInUser: {},
      branch: {
        delivery: {},
      },
      address: [],
      loading: true,
      editing_address: null,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore),
  },
  async mounted() {
    await this.loadBranch();
  },
  methods: {
    async loadBranch() {
      this.loading = true;
      const response = await this.customerStore.customerGetCompany(this.$route.params.id);
      this.branch = response.data;
      this.address = this.branch.address ?? [];
      if (this.authStore.loggedIn) {
        this.loggedInUser.id = this.authStore.id;
        this.loggedInUser.isAdmin = this.authStore.admin;
      }
      this.loading = false;
    },
  },
};
</script>
