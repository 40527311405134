<template>
  <touch-layout :page_override_class="{ 'trade-designer-choose-product': true }">
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['My Account', '/my-account'], ['Touch Connect']]"
      />
    </template>
    <template #header_page_title> Touch Connect </template>
    <Menu />
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden">
      <TouchConnectSetup />
    </div>
  </touch-layout>
</template>

<script>
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import TouchConnectSetup from '@/components/shared/TouchConnectSetup.vue';
import Menu from '@/components/portal/myAccount/Menu.vue';

export default {
  components: {
    TouchConnectSetup,
    Breadcrumbs,
    Menu
  },
};
</script>
