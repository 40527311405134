<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['My Branded Mode Account', '/setup'], ['Products']]"
      />
    </template>
    <template #header_page_title> Products </template>
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
      <ProductsList v-if="product_overrides" :product_overrides="product_overrides" setup="Branded Mode">
        <template #product_mode="{ product_mode_data }">
          <div class="mt-2">
            <div class="mt-2 flex items-center gap-2">
              <div
                :class="{ 'pointer-events-none opacity-50': demo_mode }"
                class="relative cursor-pointer"
                @click.stop="updatecustomerEnabledRetailFlag(product_mode_data, !product_mode_data.customerEnabledRetail)"
              >
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
                <div class="switch-toggle" :class="{ _active: product_mode_data.customerEnabledRetail }" />
              </div>
              <span>Show on Branded Mode</span>
            </div>
          </div>
        </template>
      </ProductsList>
    </div>
  </touch-layout>
</template>

<script>
import ProductsList from '@/components/shared/setup/products/ProductsList.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';

export default {
  components: {
    Breadcrumbs,
    ProductsList
  },
  data() {
    return {
      product_overrides: undefined,
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  async mounted() {
    this.product_overrides = await this.customerStore.customerGetAllProductsWithOverrides();
    this.product_overrides.sort((a, b) =>
      a.productName.localeCompare(b.productName, undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    );
  },
  methods: {
    updatecustomerEnabledRetailFlag(product, mode) {
      if (mode) {
        product.customerEnabled = true
      }
      product.customerEnabledRetail = mode
    }
  }
};
</script>
