export function staggeredFadeBeforeEnter(el) {
  el.style.opacity = 0;
}

export function staggeredFadeEnter(el, startDelay) {
  const delay = el.dataset.index * 0 + startDelay;
  setTimeout(() => {
    el.animate(
      {
        opacity: [0, 1],
      },
      {
        duration: 500,
        easing: 'ease-in-out',
        iterations: 1,
      },
    );
    el.style.opacity = 1;
  }, delay);
}

export function staggeredFadeLeave(el) {
  const delay = el.dataset.index * 0;
  setTimeout(() => {
    el.animate(
      {
        opacity: [1, 0],
      },
      {
        duration: 500,
        easing: 'ease-in-out',
        iterations: 1,
      },
    );
  }, delay);
}

export function staggeredSlideBeforeEnter(el) {
  el.style.opacity = 0;
}

export function staggeredSlideEnter(el, startDelay) {
  const delay = el.dataset.index * 0 + startDelay;
  setTimeout(() => {
    el.animate(
      {
        opacity: [0, 1],
        transform: ['translateX(100px)', 'translateX(0px)'],
      },
      {
        duration: 500,
        easing: 'ease-in-out',
        iterations: 1,
      },
    );
    el.style.opacity = 1;
    el.style.transform = 'translateX(0px)';
  }, delay);
}

export function staggeredSlideLeave(el) {
  const delay = el.dataset.index * 0;
  setTimeout(() => {
    el.animate(
      {
        opacity: [1, 0],
        transform: ['translateX(0px)', 'translateX(100px)'],
      },
      {
        duration: 500,
        easing: 'ease-in-out',
        iterations: 1,
      },
    );
  }, delay);
}