<template>
  <table class="table-lined xs:table-unstacked _with-hover w-full">
    <thead>
      <tr>
        <th class="w-10">Image</th>
        <th>Title</th>
        <th class="w-1/2">Description</th>
        <th class="w-10">Order</th>
        <th class="w-10">&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <show-product-type
        v-for="type in types"
        :key="type.id"
        :type="type"
        @delete="$emit('delete', type.id)"
        @edit="$emit('edit', type.id)"
      />
    </tbody>
  </table>
</template>

<script>
import ShowProductType from '@/components/business/productSetup/manageProductGroups/ShowProductType.vue';

export default {
  components: {
    'show-product-type': ShowProductType,
  },
  props: ['types'],
};
</script>

<style scoped></style>
