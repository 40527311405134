<template>
  <div class="map-container relative w-full h-full">
    <div v-once id="map" class="w-full h-full" />
    <div
      v-if="hasError && useDefaultErrorBehaviour"
      class="w-full h-full absolute error top-0 bg-white flex items-center justify-center"
    >
      There has been an error initialising the map
    </div>
    <div
      v-else-if="isLoading && useDefaultLoadingBehaviour"
      class="w-full h-full absolute loading top-0 bg-white"
    />
  </div>
</template>
<script>
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';

export default {
  props: {
    initialCenterCoords: {
      type: Object,
      default: () => ({ lat: 54.264591218322145, lng: -4.460922270471243 }),
      validator: (v) =>
        typeof v === 'object' &&
        v !== null &&
        typeof v.lat !== 'undefined' &&
        !Number.isNaN(v.lat) &&
        typeof v.lng !== 'undefined' &&
        !Number.isNaN(v.lng),
    },
    markers: {
      type: Array,
      default: () => [],
      validator: (v) => Array.isArray(v),
    },
    splitClustersOnZoom: {
      type: Boolean,
      default: true,
    },
    useDefaultErrorBehaviour: {
      type: Boolean,
      default: true,
    },
    useDefaultLoadingBehaviour: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      customClusterIcon: window.btoa(`
        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
          <circle cx="120" cy="120" opacity="1" r="75" stroke="red" stroke-width="10" />
        </svg>
      `),
      customClusterRenderer: {
        /* eslint-disable */
        render: ({ count, position }) =>
          new google.maps.Marker({
            label: { text: String(count), color: "#000", fontSize: "10px" },
            position,
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
            icon: {
              url: `data:image/svg+xml;base64,${this.customClusterIcon}`,
              scaledSize: new google.maps.Size(45, 45),
            },
          }),
        /* eslint-enable */
      },
      maxZoom: 20,
      isLoading: true,
      hasError: false,
      markerClusterer: undefined,
    };
  },
  watch: {
    markers: {
      handler() {
        const markers = this.setMarkers();
        this.markerClusterer.clearMarkers();
        this.markerClusterer.addMarkers(markers);
      },
      deep: true,
    },
  },
  async created() {
    try {
      const apiKey = 'AIzaSyA9ccJ2OEtBJ47b2eaqVAEYVf2kZqBZQFo';
      const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.head.appendChild(script);
      this.setGoogleMaps();
    } catch (e) {
      if (this.useDefaultErrorBehaviour) {
        this.hasError = true;
      } else {
        this.$emit('map-loading-error');
      }
    }
    if (this.useDefaultLoadingBehaviour) {
      this.isLoading = false;
    } else {
      this.$emit('map-loaded');
    }
  },
  beforeUnmount() {
    const scripts = document.querySelectorAll("script[src*='maps.googleapis.com']");
    for (let i = 0; i < scripts.length; i += 1) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
    window.google = undefined;
  },
  methods: {
    setGoogleMaps() {
      const initMap = () => {
        const center = this.initialCenterCoords;
        const map = new window.google.maps.Map(document.getElementById('map'), {
          zoom: 6,
          center,
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          maxZoom: this.maxZoom,
        });
        const markers = this.setMarkers();

        // Add a marker clusterer to manage the markers.
        this.markerClusterer = new MarkerClusterer({
          zoomOnClick: false,
          markers,
          map,
          onClusterClick: () => {
            this.alertBox().fire({
              title: 'Feature Coming Soon',
              icon: 'warning',
            });
          },
          renderer: this.customClusterRenderer,
          algorithm: new SuperClusterAlgorithm({
            radius: 40,
            // if we don't want to split clusters, we need to set the maxZoom property to the max zoom of the map.
            maxZoom: this.splitClustersOnZoom ? 16 : this.maxZoom,
          }),
        });
      };

      window.initMap = initMap;
    },
    setMarkers() {
      const markers = this.markers.map((mark) => {
        const marker = new window.google.maps.Marker({
          position: mark.position,
          label: { text: String(1), color: '#000', fontSize: '10px' },
          icon: {
            url: `data:image/svg+xml;base64,${this.customClusterIcon}`,
            scaledSize: new window.google.maps.Size(45, 45),
          },
        });

        marker.addListener('click', () => {
          this.alertBox().fire({
            title: 'Feature Coming Soon',
            icon: 'warning',
          });
        });

        return marker;
      });
      return markers;
    },
  },
};
</script>
