<template>
  <div class="flex flex-col flex-grow p-5">
    <div class="flex flex-col md:flex-row gap-5" style="flex: 1 1 0">
      <div style="flex: 1 1 0" class="flex flex-col gap-5">
        <div class="bg-white px-5 py-2 border border-gray flex items-center">
          <span class="text-lg">Groups</span>
          <div class="btn-action ml-auto" @click="isAddGroup = true">Add Group</div>
        </div>
        <loading v-if="isLoadingGroups" :loading="true" class="h-full bg-white p-5 border border-gray" style="flex: 1 1 0" />
        <div v-else class="flex flex-col flex-grow gap-2 overflow-y-auto bg-white p-5 border border-gray" style="flex: 1 1 0; min-height: 200px;">
          <div
            v-for="group in groups"
            :key="group.id"
            class="flex items-center bg-gray-200 rounded lg p-2 cursor-pointer hover:bg-green-300 hover:text-white"
            :class="{ 'bg-green-600 text-white': groupId === group.id }"
            @drop.prevent="moveItem(group.id)"
            @dragenter.prevent
            @dragover.prevent
            @click="checkForChanges(group.id, true)"
          >
            <span>{{ group.description }}</span>
            <i
              class="ml-auto cursor-pointer fa fa-trash-can hover:text-red-600 ml-auto"
              @click.prevent="deleteGroup(group.id)"
            />
          </div>
        </div>
        <Teleport v-if="isAddGroup" defer to="#portal_popup"> 
          <modal-window :modal_open="true" title="Add Group" @close="isAddGroup = false">
            <div class="flex flex-col container">
              <div class="flex flex-col flex-wrap">
                <div class="w-full xs:w-1/2 xs:pr-5">
                  <div class="font-medium">Name</div>
                  <input v-model="groupName" class="form-control">
                </div>
              </div>
              <div class="text-right mt-10 flex flex-col 2xs:block">
                <button
                  class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0"
                  @click.prevent="isAddGroup = false"
                >
                  Cancel
                </button>
                <button
                  class="btn-action btn-lg"
                  :class="{ 'pointer-events-none opacity-50': isLoadingGroups }"
                  @click.prevent.stop="addGroup"
                >
                  Save Group
                </button>
              </div>
            </div>
          </modal-window>
        </Teleport>
      </div>
      <div style="flex: 1 1 0" class="flex flex-col gap-5">
        <div class="flex items-center px-5 py-2  border border-gray bg-white">
          <span class="text-lg">Extras</span>
          <div
            class="btn-action ml-auto"
            :class="{ 'pointer-events-0 opacity-50': groups.length === 0 }"
            @click="(isAddExtra = true), (extra = undefined)"
          >
            Add Item
          </div>
        </div>
        <loading v-if="isLoadingExtras" :loading="true" class="h-full bg-white p-5 border border-gray" style="flex: 1 1 0" />
        <div v-else class="flex flex-col flex-grow gap-2 overflow-y-auto p-5 border border-gray bg-white" style="flex: 1 1 0; min-height: 200px;">
          <div
            v-for="item in items"
            :key="item.id"
            draggable="true"
            class="flex items-center bg-gray-200 rounded lg p-2 cursor-pointer hover:bg-green-300 hover:text-white"
            :class="{ 'bg-green-600 text-white': extra && item.id === extra.id }"
            @dragstart="() => (itemIdBeingMoved = item.id)"
            @click="checkForChanges(item, false)"
          >
            <span>{{ item.description }}</span>
            <i
              class="ml-auto cursor-pointer fa fa-trash-can hover:text-red-600 ml-auto"
              @click.prevent="deleteItem(item.id)"
            />
          </div>
        </div>
        <Teleport v-if="isAddExtra" defer to="#portal_popup"> 
          <modal-window
            :modal_open="true"
            title="Add Extra"
            @close="(extra = undefined), (isAddExtra = undefined)"
          >
            <updateExtra
              :groups="groups"
              :group-id="groupId"
              :extra="extra"
              :currency="currency"
              @close="(isAddExtra = undefined), loadSettings($event)"
            />
          </modal-window>
        </Teleport>
      </div>
      <div style="flex: 1 1 0" class="flex flex-col p-5 gap-5 bg-white border border-gray">
        <div class="mb-5 flex items-center">
          <span class="text-lg">Options</span>
        </div>
        <div v-if="extra" class="flex flex-col" style="flex: 1 1 0">
          <updateExtra
            ref="update-extra"
            :key="extra.id"
            style="flex: 1 1 0"
            :groups="groups"
            :group-id="groupId"
            :extra="extra"
            :currency="currency"
            @close="loadSettings($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import updateExtra from '@/components/business/manageTouchAccount/extras/UpdateExtra.vue';
import validate from 'validate.js';

export default {
  components: {
    updateExtra,
  },
  data() {
    return {
      groupId: undefined,
      isAddExtra: false,
      isAddGroup: false,
      extra: undefined,
      groups: [],
      items: [],
      groupName: '',
      currency: undefined,
      itemIdBeingMoved: undefined,
      isLoadingGroups: true,
      isLoadingExtras: false,
      isLoadingExtra: false
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  async mounted() {
    await this.loadSettings();
    this.currency = await this.getCurrency();
  },
  methods: {
    async addGroup() {
      const validationErrors = validate(
        {
          'Group Name': this.groupName,
        },
        {
          'Group Name': {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }
      this.isLoadingGroups = true
      const { newIds } = await this.touchStore.AddExtraItemGroup(this.groupName);
      await this.loadSettings();
      const [newId] = newIds;
      this.groupId = newId;
      this.groupName = '';
      this.isAddGroup = false;
      this.isLoadingGroups = false
    },
    async deleteGroup(categoryId) {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to delete this group?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.extra = undefined;
            this.groupId = undefined;
            await this.touchStore.deleteExtraItemGroup(categoryId);
            await this.loadSettings();
          }
        });
    },
    async deleteItem(itemId) {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to delete this item?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await window.touch.customerUpdateExtraItems([
              {
                Id: itemId,
                Delete: true,
              },
            ]);
            this.extra = undefined;
            await this.loadSettings();
          }
        });
    },
    async checkForChanges(data, isByHeading) {
      if (this.items.length > 0 && this.$refs['update-extra'] && this.isChanges()) {
        this.alertBox()
          .fire({
            title: 'There are unsaved changes',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Go Back',
            confirmButtonText: 'Ignore & Continue',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              if (isByHeading) {
                await this.setGroup(data);
              } else {
                this.setItem(data);
              }
            }
          });
      } else if (isByHeading) {
        await this.setGroup(data);
      } else {
        this.setItem(data);
      }
    },
    isChanges() {
      const updateExtraRefs = this.$refs['update-extra'];
      const item = {
        id: updateExtraRefs.id,
        name: updateExtraRefs.name,
        unit_of_measure: updateExtraRefs.unit_of_measure,
        price: updateExtraRefs.price,
        cost: updateExtraRefs.cost,
        type: updateExtraRefs.type,
        useProductMeasurements: updateExtraRefs.useProductMeasurements,
        options: updateExtraRefs.options,
      };
      return JSON.stringify(item) !== JSON.stringify(updateExtraRefs.originalExtra);
    },
    async loadSettings(extraId) {
      this.isLoadingGroups = true
      this.groups = (await window.touch.GetExtraItemGroups()).groups;
      this.groups.sort((a, b) =>
        a.description.localeCompare(b.description, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      );
      this.isLoadingGroups = false
      this.isLoadingExtras = true
      if (!this.groupId && this.groups.length > 0) {
        this.groupId = this.groups[0].id;
        this.items = await this.getExtras(this.groupId);
      } else if (extraId && this.groupId) {
        this.items = await this.getExtras(this.groupId);
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id === extraId) {
            this.extra = this.items[i];
            break;
          }
        }
      } else if (this.groupId) {
        this.items = await this.getExtras(this.groupId);
      }
      this.isLoadingExtras = false
    },
    async getExtras(groupId) {
      const { items } = await window.touch.customerGetExtraItems(groupId);
      items.sort((a, b) =>
        a.description.localeCompare(b.description, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      );
      return items;
    },
    async getCurrency() {
      const currencies = await window.touch.commonGetCurrencies();
      return currencies.filter((currency) => currency.isDefault)[0].code;
    },
    async moveItem(groupId) {
      await window.touch.customerUpdateExtraItems([
        {
          id: this.itemIdBeingMoved,
          GroupId: groupId,
        },
      ]);
      await this.loadSettings();
      this.itemIdBeingMoved = undefined;
    },
    async setGroup(groupId) {
      this.groupId = groupId;
      this.items = await this.getExtras(this.groupId);
      this.extra = undefined;
    },
    setItem(extra) {
      this.extra = extra;
    },
  },
};
</script>
