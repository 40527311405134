<template>
  <div class="w-full 2xs:w-1/2 md:w-full flex flex-col">
    <div class="bg-white shadow-lg rounded-lg flex flex-col md:flex-row m-2 md:m-0 md:mt-6 h-full">
      <div
        class="flex flex-col justify-center p-6 cursor-pointer md:w-64"
        @click="$emit('show-image')"
      >
        <div class="bg-gray-200 p-6 relative">
          <div
            class="bg-white p-1 rounded-full text-center absolute bottom-0 right-0 m-2"
            style="width: 30px; height: 30px"
          >
            <i class="fal fa-search-plus" />
          </div>
          <ShowSvg
            v-if="image"
            :branded-mode="true"
            :show-issues="false"
            :thumbnail-mode="true"
            :svg="image"
            style="height: 150px"
          />
        </div>
      </div>
      <div class="flex flex-grow justify-between flex-col xl:flex-row px-6 md:p-0">
        <div class="flex flex-col md:flex-row">
          <div class="flex md:my-4 md:w-48">
            <div class="flex flex-col md:py-4">
              <div class="md:mb-1 flex-1">
                <div class="font-medium mb-1 hidden md:block">Product:</div>
                <span v-text="item.description" />
              </div>
              <div v-if="showSizes">
                <span v-text="item.size" />
              </div>
              <div v-if="customerStore.hasBrandedPricingOn" class="mt-3 md:mt-6">
                <div>
                  <span class="font-medium md:hidden">Price: </span><span
                    class="font-medium"
                    v-html="formatCurrency(basketStore.getCurrency, item.price)"
                  />
                  <span v-if="item.quantity > 1">
                    (<span
                      class="font-medium"
                      v-html="formatCurrency(basketStore.getCurrency, item.price / item.quantity)"
                    />
                    each)
                  </span>
                </div>
                <div class="text-xs">* Price subject to survey and technical specification</div>
              </div>
            </div>
          </div>
          <div class="border-l border-gray-300 m-6 mx-10 hidden md:block" />
          <div class="flex md:my-4">
            <div class="flex flex-col py-2 md:py-4">
              <div class="flex mb-1">
                <div class="font-medium pr-2 md:mb-1">Location:</div>
                <span v-text="item.location" />
              </div>
              <div v-if="item.colorExternal" class="flex mb-1">
                <div class="font-medium pr-2 md:mb-1">External Colour:</div>
                <span v-text="item.colorExternal" />
              </div>
              <div v-if="item.colorInternal" class="flex mb-1">
                <div class="font-medium pr-2 md:mb-1">Internal Colour:</div>
                <span v-text="item.colorInternal" />
              </div>
              <div v-if="item.colorHardware" class="flex mb-1">
                <div class="font-medium pr-2 md:mb-1">Hardware Colour:</div>
                <span v-text="item.colorHardware" />
              </div>
              <div v-if="item.glazingMethod" class="flex mb-1">
                <div class="font-medium pr-2 md:mb-1">Glazing:</div>
                <span v-text="item.glazingMethod" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex mb-6 lg:my-4">
          <div class="m-1">
            <div class="flex items-center">
              <div class="mr-3 hidden md:block">Qty</div>
              <div class="flex" style="height: 37px">
                <button
                  class="border rounded-l-full p-2"
                  :class="{ 'cursor-not-allowed': item.quantity < 2, 'cursor-wait': qtyLoading }"
                  :disabled="item.quantity < 2 || qtyLoading"
                  @click.stop="setQty(item.quantity - 1)"
                >
                  -
                </button>
                <input
                  :value="item.quantity"
                  class="w-8 border-t border-b text-center"
                  :class="{ 'cursor-wait': qtyLoading }"
                  @click.stop
                  @change="setQty($event.target.value)"
                >
                <button
                  class="border rounded-r-full p-2"
                  :class="{ 'cursor-wait': qtyLoading }"
                  :disabled="qtyLoading"
                  @click.stop="setQty(item.quantity + 1)"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-around xl:justify-start">
            <div
              class="border border-gray-300 rounded p-2 ml-0 md:ml-1 m-1 flex hover:bg-gray-200 transition duration-500 ease-in-out w-auto"
              @click.stop.prevent="$emit('show-summary')"
            >
              <i class="fal fa-clipboard-list-check xs:mr-2" />
              <span class="text-xs hidden xs:flex flex-col justify-around cursor-pointer">
                <span> <span class="hidden md:inline">View</span> Summary </span>
              </span>
            </div>
          </div>
          <div class="flex flex-col justify-around xl:justify-start">
            <router-link
              :to="brandedItemLink(item.key)"
              class="border border-gray-300 rounded p-2 m-1 ml-0 3xs:ml-1 flex hover:bg-gray-200 transition duration-500 ease-in-out"
            >
              <i class="fal fa-pen-square xs:mr-2" />
              <span class="text-xs hidden xs:flex flex-col justify-around">
                <span>Edit <span class="hidden md:inline">Item</span></span>
              </span>
            </router-link>
          </div>
          <div class="mr-3 hidden md:block">
            <div
              class="border border-gray-300 rounded p-2 ml-0 md:ml-1 m-1 flex hover:bg-gray-200 transition duration-500 ease-in-out"
              @click.stop.prevent="$emit('delete-line-item', item.key)"
            >
              <i class="fal fa-trash-alt mr-2" />
              <span class="text-xs hidden md:flex flex-col justify-around cursor-pointer">
                <span>Delete <span class="hidden md:inline">Item</span></span>
              </span>
            </div>
          </div>
          <div class="mr-1 md:hidden">
            <div class="flex absolute bottom-0 right-0 m-3 mb-6">
              <button
                class="p-3 cursor-pointer"
                @click.stop.prevent="$emit('delete-line-item', item.key)"
              >
                <i class="fal fa-times text-red-600" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { formatCurrency } from '@/composables/currency';
import { mapStores } from 'pinia'
import { useBrandedStore } from '@/pinia/branded';
import { useCustomerStore } from '@/pinia/customer';
import { useBasketStore } from '@/pinia/basket';
import ShowSvg from '@/components/shared/ShowSvg.vue';

export default {
  components: {
    ShowSvg
  },
  props: {
    item: {
      required: true,
    },
    showSizes: {
      default: true,
    },
  },
  setup() {
    return { formatCurrency }
  },
  data() {
    return {
      qtyLoading: false,
      image: undefined,
    };
  },
  computed: {
    ...mapStores(useBrandedStore, useCustomerStore, useBasketStore),
  },
  async mounted() {
    this.image = await this.getImage();
  },
  methods: {
    brandedItemLink(itemKey) {
      return this.brandedStore.brandedItemLink.replace(':id', itemKey);
    },
    setQty(qty) {
      if (!this.qtyLoading) {
        this.qtyLoading = true;
        this.basketStore.updateItemQty({
          itemKey: this.item.key,
          qty,
        })
          .then(() => {
            this.qtyLoading = false;
          });
      }
    },
    async getImage() {
      const image = await this.basketStore.getProcessingImage({
        itemKey: this.item.key,
        imageType: this.enums.imageType.EXTERNAL,
      });
      return image;
    },
  },
};
</script>
