export async function testListSandboxes() {
  return (await this.authenticatedPost('test/ListSandboxes')).data;
}

export async function testCreateTestJob(customerId) {
  return (
    await this.authenticatedPost('test/CreateTestJob', {
      customerId,
    })
  ).data;
}

export async function testAcceptChanges(sandboxId) {
  return (
    await this.authenticatedPost('test/AcceptChanges', {
      SandboxId: sandboxId,
    })
  ).data;
}

export async function testAddNote(contractId, sandboxId, note) {
  return (
    await this.authenticatedPost('test/AddNote', {
      ContractId: contractId,
      SandboxId: sandboxId,
      Details: note,
    })
  ).data;
}
