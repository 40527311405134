import { useCustomerStore } from '@/pinia/customer';
import { useRouter } from 'vue-router';

export function forceReload() {
  const customerStore = useCustomerStore()
  const router = useRouter();
  const today = new Date();
  if (customerStore.lastUpdated) {
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    if (new Date(customerStore.lastUpdated) < new Date(yesterday)) {
      customerStore.setLastUpdated(today)
      router.go();
    }
  } else {
    customerStore.setLastUpdated(today)
    router.go();
  }
}
