<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['Manage News', '/manage-news'], [news_post.title]]"
      />
    </template>
    <template #header_page_title>
      {{ news_post.title }}
    </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <router-link to="/manage-news/category" class="btn-action mr-1">
          Manage Categories
        </router-link>
      </div>
    </template>
    <transition appear name="fade-in" mode="out-in">
      <loading v-if="loading || saving_post" :key="1" :loading="loading || saving_post" :label="saving_post ? 'Saving' : 'Loading'" style="flex: 1 1 0" />
      <main v-else :key="2" class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch xs:p-10">
        <div class="bg-white p-6 xs:p-10 rounded-lg xs:mb-10 relative">
          <div class="2xs:flex mb-5">
            <div class="flex-1 flex 2xs:mr-5 mb-5 2xs:mb-0">
              <div class="flex flex-col justify-around whitespace-nowrap mr-3 w-16 2xs:w-auto">
                Title
              </div>
              <div class="flex-grow">
                <input v-model="news_post.title" type="text" class="form-control">
              </div>
            </div>
            <div class="flex-1 flex 2xs:ml-5">
              <div class="flex flex-col justify-around whitespace-nowrap mr-3 w-16 2xs:w-auto">
                Category
              </div>
              <div class="flex-grow">
                <select v-model="news_post.newsCategoryId" class="form-control">
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                    :selected="news_post.newsCategoryId === category.id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <div class="mb-2">Summary</div>
            <TextEditor id="summary" v-model="news_post.summary" />
          </div>
          <div class="mt-6">
            <div class="mb-2">Content</div>
            <TextEditor id="paragraph" v-model="news_post.paragraph" />
          </div>
          <div v-if="!loading" class="mt-5">
            <Dropzone
              :removable="false"
              :current_file="imageURL"
              @add-file="new_news_image = $event"
              @remove-file="new_news_image = null"
            />
          </div>
          <div class="mt-5 text-right">
            <a class="btn-action btn-lg" @click.prevent="saveNewsItem()"> Save News Post </a>
          </div>
          <transition name="fade-in">
            <div v-if="errors.length > 0" class="flex flex-col justify-around mt-3">
              <div
                v-for="(error, index) in errors"
                :key="index"
                class="text-red-600"
                :class="{ 'mt-1': index > 0 }"
              >
                {{ error }}
              </div>
            </div>
          </transition>
        </div>
      </main>
    </transition>
  </touch-layout>
</template>

<script>
import Dropzone from '@/components/shared/Dropzone.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import TextEditor from '@/components/shared/TextEditor.vue';

export default {
  components: {
    Breadcrumbs,
    Dropzone,
    TextEditor
  },
  data() {
    return {
      loading: true,
      news_post: {},
      errors: [],
      categories: [],
      saving_post: false,
      new_news_image: null,
    };
  },
  computed: {
    imageURL() {
      return window.touch.imageBaseURL + this.news_post.imageUrl
    }
  },
  mounted() {
    this.loadCategories();
    this.loadFeed(this.$route.params.id);
  },
  methods: {
    async loadCategories() {
      this.categories = await window.touch.getNewsCategories();
    },
    async loadFeed(id) {
      this.news_post = await window.touch.getNewsItem(id);
      this.loading = false;
    },
    async saveNewsItem() {
      this.saving_post = true;

      if (this.new_news_image !== null) {
        this.news_post.image = this.new_news_image;
      }

      await window.touch.updateNewsItem(this.news_post).then(() => {
        this.routerPush('/manage-news');
      });
    },
  },
};
</script>
