<template>
  <div
    :class="{
      'fixed flex flex-col p-3 p-8-lg z-50 bottom-0 left-0 bg-white w-full xs:w-1/2 sm:w-1/3 custom-offset':
        isOpen,
    }"
  >
    <div v-if="specs.some(x => x.options.some(y => y.isCurrentOption))" :class="{ flex: isOpen }">
      <div v-if="isOpen" class="flex items-center">
        <button class="mr-2" @click="isOpen = false">
          <i class="fal fa-2x fa-arrow-circle-left mt-1" />
        </button>
        <question-label
          ref="label"
        >
          {{ specs[specIndex]?.name }}
        </question-label>
      </div>
      <question-trigger-multi
        v-show="!isOpen"
        v-model="selected"
        :specs="specs"
        :loading="loading"
        :is-open="isOpen"
        @update-parameter="updateParameter($event)"
        @end-advanced-mode="$emit('input', false)"
        @open="openOptions($event)"
        @close="closeOptions"
        @component-highlight="$emit('component-highlight', $event)"
      />
    </div>

    <div
      v-show="isOpen"
      :class="{ flex: specs[0].displayType === 1 }"
      class="flex-col bg-gray-200 mt-2 rounded p-2 overflow-y-auto flex-grow pb-24"
    >
      <search-bar
        v-if="specs[0].options.length > 10"
        v-model="searchQuery"
        :filters="specs[0].filters"
        :filter-id="filterId"
        @set-filter-id="filterId = $event"
      />

      <div class="flex flex-wrap">
        <div
          v-for="option in filteredOptions"
          :key="option.id"
          class="w-full p-2"
          :class="{ 'md:w-1/2 lg:w-1/3 xl:w-1/4': specs[specIndex].displayType === 1 }"
        >
          <component
            :is="specs[specIndex].displayType === 1 ? 'image-option' : 'dropdown-option'"
            :loading="loading"
            :option="option"
            :spec="specs[specIndex]"
            :hide-tick="true"
            @change-value="changeValue($event.heading, $event.value)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import { useDesignerStore } from '@/pinia/designer';
import ImageOption from '@/components/shared/designer/SpecificationGroup/ImageOption.vue';
import DropdownOption from '@/components/shared/designer/SpecificationGroup/DropdownOption.vue';
import QuestionTriggerMulti from '@/components/shared/designer/SpecificationGroup/QuestionTriggerMulti.vue';
import SearchBar from '@/components/shared/designer/SpecificationGroup/SearchBar.vue';
import QuestionLabel from '@/components/shared/designer/SpecificationGroup/QuestionLabel.vue';

export default {
  components: {
    'image-option': ImageOption,
    'dropdown-option': DropdownOption,
    'question-label': QuestionLabel,
    'question-trigger-multi': QuestionTriggerMulti,
    'search-bar': SearchBar,
  },
  props: ['specs', 'loading'],
  data() {
    return {
      open_option_group: null,
      open_search: null,
      selected: [],
      searchQuery: '',
      specIndex: 0,
      filterId: undefined
    };
  },
  computed: {
    ...mapStores(useBasketStore, useDesignerStore),
    productDescription() {
      return this.basketStore.currentItem.description;
    },
    isOpen: {
      get() {
        return (
          this.designerStore.optionHeadingForOpenQuestion ===
          this.specs[0].optionHeadingId
        );
      },
      set(setOpen) {
        if (setOpen) {
          this.designerStore.setOpenQuestion(this.specs[0].optionHeadingId);
          return;
        }

        if (
          this.designerStore.optionHeadingForOpenQuestion ===
          this.specs[0].optionHeadingId
        ) {
          this.designerStore.clearOpenQuestion();
        }
      },
    },
    filteredOptions() {
      let options = this.specs[this.specIndex]?.options
      if (this.searchQuery) {
        options = options.filter((option) =>
          option.description.toLowerCase().includes(this.searchQuery.toLowerCase()),
        );
      }
      if (this.filterId) {
        options = options.filter((option) =>
          option.filterId === this.filterId,
        );
      }
      return options
    },
    all_component_ids() {
      return this.specs[this.specIndex]?.components.map((component) => component.id);
    },
    all_member_ids() {
      return this.specs[this.specIndex]?.members.map((member) => member.id);
    },
    components_or_member_specs() {
      return this.specs
        .map((spec) => {
          if (spec.components.length > 0) {
            return spec.components.map((component) => ({
              ...spec,
              components: [component],
            }));
          } // if no components then must be members
          return spec.members.map((member) => ({
            ...spec,
            members: [member],
          }));
        })
        .flat(1);
    },
  },
  watch: {
    isOpen(opening) {
      if (!opening) {
        this.searchQuery = '';
        this.filterId = undefined
      }
    },
  },
  methods: {
    updateParameter({ heading, option, parameter, value, spec }) {
      this.$emit('change-value', {
        heading,
        value: option,
        components: spec.components.map((component) => component.id),
        members: spec.members.map((member) => member.id),
        parameters: spec.parameters
          .map((param) => {
            if (
              param.componentId === parameter.componentId &&
              param.memberId === parameter.memberId &&
              param.index === parameter.index
            ) {
              return {
                ...param,
                value,
              };
            }

            return false;
          })
          .filter(Boolean),
      });
    },
    closeOptions() {
      this.isOpen = false;
      this.specIndex = 0;
    },
    openOptions($event) {
      this.isOpen = true;
      this.specIndex = $event;
    },
    inUseCount(option) {
      return this.specs.reduce(
        (count, spec) =>
          count +
          (spec.options.find(
            (specOption) => specOption.isCurrentOption && specOption.id === option.id,
          )
            ? spec.components.length + spec.members.length
            : 0),
        0,
      );
    },
    componentId(spec) {
      return spec.components.map((component) => component.id)[0];
    },
    memberId(spec) {
      return spec.members.map((member) => member.id)[0];
    },
    componentOrMemberName(spec) {
      return spec.components
        .map((component) => component.description)
        .concat(spec.members.map((member) => member.description))[0];
    },
    componentOrMemberId(spec) {
      return spec.components
        .map((component) => component.id)
        .concat(spec.members.map((member) => member.id))[0];
    },
    changeValue(heading, value) {
      let components = [];
      let members = [];

      if (this.all_component_ids.length > 0) {
        components = this.selected.length === 0 ? this.all_component_ids : this.selected;
      }
      if (this.all_member_ids.length > 0) {
        members = this.selected.length === 0 ? this.all_member_ids : this.selected;
      }

      this.selected = [];

      this.$emit('change-value', {
        heading,
        value,
        components,
        members,
      });

      this.isOpen = false;
    },
  },
};
</script>

<style>
.custom-offset {
  top: 122px;
}
</style>
