export function setJob(job) {

  function isFit() {
    return job.fittingType === window.enum.fittingType.SUPPLY_AND_FIT;
  }

  function wasRequotedAtJobLevel() {
    return !wasRequotedAtItemLevel();
  }

  function wasRequotedAtItemLevel() {
    return job.items.find((item) => item.override) !== undefined;
  }

  function itemHasExtras(item) {
    return job.items.some((extraItem) => extraItem.parentItemKey === item.itemKey);
  }

  function totalPriceForItem(item) {
    if (wasRequotedAtJobLevel()) {
      return 0;
    }

    return job.items
      .filter((extraItem) => extraItem.parentItemKey === item.itemKey)
      .reduce((total, extraItem) => total + extraItem.price, item.price);
  }

  function productCost() {
    return job.items
      .filter(x => x.inputType !== window.enum.JobLineItemInputType.ExtraItem)
      .reduce((sum, y) => sum + y.calculatedPrice, 0)
  }

  function productCostIncTax() {
    return job.items
      .filter(x => x.inputType !== window.enum.JobLineItemInputType.ExtraItem)
      .reduce((sum, y) => sum + y.calculatedPriceIncTax, 0)
  }
  function markupItemCost(markup) {
    return (job.priceBeforeTax * (markup / 100 + 1)).toFixed(2);
  }

  function fittingCost() {
    return job.items
      .filter((item) => item.fittingItem)
      .reduce((total, item) => total + item.calculatedPrice, 0);
  }

  return { 
    ...job, isFit, wasRequotedAtJobLevel, wasRequotedAtItemLevel, itemHasExtras, 
    totalPriceForItem, productCost, productCostIncTax,
    markupItemCost, fittingCost
  };
}