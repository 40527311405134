<template>
  <select
    class="form-control"
    :value="selectedOption"
    @change="$emit('input', $event.target.value)"
  >
    <option value="" disabled selected>Select your option</option>
    <option
      v-for="option in options"
      :key="option.id"
      :value="option.id"
      v-text="option.title"
    />
  </select>
</template>

<script>
export default {
  props: ['options', 'selectedOption'],
};
</script>

<style scoped></style>
