import { createApp } from 'vue'
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { createRouter } from './router/index'
import * as Sentry from '@sentry/vue';
import Swal from 'sweetalert2';
import i18n from './i18n';

import App from './App.vue';
import ApiImg from '@/components/shared/ApiImg.vue';
import Touch from './touch-api/touch';
import TouchLayout from './components/shared/TouchLayout.vue';
import Loading from './components/shared/Loading.vue';
import ModalWindow from './components/shared/ModalWindow.vue';
import AnimationStaggeredFade from './components/shared/animation/StaggeredFade.vue';
import AnimationStaggeredSlide from './components/shared/animation/StaggeredSlide.vue';

import { useAuthStore } from '@/pinia/auth';
import { useBrandedStore } from '@/pinia/branded';
import { useCustomerStore } from '@/pinia/customer';
import { useStyleStore } from '@/pinia/style';

import brandedRoutes from '@/router/routes/branded';

const pinia = createPinia()
const router = createRouter()
const app = createApp(App)

const setPersistedState = () => {
  const paths = (new URL(window.location.href).pathname).split('/').filter(x => x)
  const branded_routes = brandedRoutes.map(x => x.path.split('/').filter(x => x)[1])
  const isSessionStorage = paths[0] === 'launch' ||  branded_routes.includes(paths[1]) 
  const prefex = import.meta.env.VITE_APP_INSTALLATION_TYPE === 'business'
    ? import.meta.env.VITE_APP_INSTALLATION_TYPE
    : isSessionStorage ? 'branded' : 'portal'
  return { 
    key: id => `${prefex}_${id}`, 
    storage: isSessionStorage  ? sessionStorage : localStorage
  }
}

pinia.use(createPersistedState(setPersistedState()))
pinia.use(Sentry.createSentryPiniaPlugin());

app.use(i18n)
app.use(router)
app.use(pinia);

const authStore = useAuthStore()
const brandedStore = useBrandedStore()
const customerStore = useCustomerStore()
const styleStore = useStyleStore()

app.component('TouchLayout', TouchLayout);
app.component('Loading', Loading);
app.component('ApiImg', ApiImg);
app.component('AnimationStaggeredFade', AnimationStaggeredFade);
app.component('AnimationStaggeredSlide', AnimationStaggeredSlide);
app.component('ModalWindow', ModalWindow);

window.logoutPush = false;

app.config.productionTip = false;

import('./composables/svgEvents');

window.alertBox = Swal.mixin({
  customClass: {
    confirmButton: 'btn-action m-1',
    cancelButton: 'btn m-1',
    denyButton: 'btn m-1',
  },
  reverseButtons: true,
  buttonsStyling: false,
});

import enumModule from './enum';
import helpersModule from './helpers';

window.enum = enumModule
window.helpers = helpersModule

window.imageTypes = {
  [window.enum.imageType.EXTERNAL]: 'External',
  [window.enum.imageType.INTERNAL]: 'Internal',
  [window.enum.imageType.PLAN]: 'Bow/Bay Plan',
  [window.enum.imageType.GLASS_SCHEMATIC]: 'Schematic',
  [window.enum.imageType.FOLDING_SASH_VIEW]: 'Folding Sash Plan',
  [window.enum.imageType.SASH_PLAN_VIEW]: 'Sash Plan',
};

window.salesSectorTypes = {
  [window.enum.salesSectorType.NONE]: '-',
  [window.enum.salesSectorType.COMMERCIAL]: 'Commercial',
  [window.enum.salesSectorType.NEWBUILD]: 'New Build',
  [window.enum.salesSectorType.RETAIL]: 'Retail',
  [window.enum.salesSectorType.TRADE]: 'Trade',
};

window.priceRates = {
  [window.enum.priceRateType.EACH]: ' unit(s)',
  [window.enum.priceRateType.METER_SQUARED]: 'm<sup>2</sup>',
  [window.enum.priceRateType.PER_METER]: 'm',
  [window.enum.priceRateType.PERCENT]: '%',
  [window.enum.priceRateType.PER_KG]: 'kg',
};

app.mixin({
  computed: {
    enums() {
      return window.enum;
    },
    style() {
      return {
        fabricator_logo: styleStore.fabricator_logo,
        customer_logo: customerStore.customer_logo || styleStore.customer_logo,
        fabricator_name: styleStore.fabricator_name,
        customer_name: customerStore.customer_name,
        back_to_site_link: styleStore.back_to_site_link,
        fabricator: styleStore.fabricator,
      };
    },
    branded_mode_enabled() {
      if (this.touch_portal_installation) {
        return customerStore.customer_has_branded_mode_enabled;
      }
      return false;
    },
    branded_mode_settings() {
      return brandedStore.branded_mode_settings
    },
    authenticated_user() {
      return authStore.loggedIn === true;
    },
    is_admin_user() {
      return authStore.admin === true;
    },
    guest_mode_user() {
      return authStore.loggedIn === false;
    },
    touch_business_user() {
      return this.touch_business_installation && !this.guest_mode_user;
    },
    touch_portal_user() {
      return this.touch_portal_installation && !this.guest_mode_user;
    },
    branded_mode_user() {
      return this.touch_portal_installation && !this.guest_mode_user && authStore.usingBrandedModeToken
    },
    touch_portal_installation() {
      return window.VITE_APP_INSTALLATION_TYPE === 'portal';
    },
    store_url () {
      return import.meta.env.VITE_APP_STORE_PREFIX
    },
    touch_business_installation() {
      return window.VITE_APP_INSTALLATION_TYPE === 'business';
    },
    demo_mode() {
      return import.meta.env.VITE_APP_FORCE_DEMO === 'yes' || authStore.demoMode;
    },
    is_iOS() {
      return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
          navigator.platform,
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    },
  },
  methods: {
    setSentryUser() {
      Sentry.withScope((scope) => {
        scope.setUser({ id: authStore.id, email: authStore.email });
      });
    },
    positionAvatar(event) {
      const img = document.getElementById(event.target.id);
      if (img) {
        if (img.width >= img.height) {
          img.classList.add('h-full');
          img.classList.remove('w-full');
        } else {
          img.classList.add('w-full');
          img.classList.remove('h-full');
        }
      }
    },
    formatConsumer(consumer) {
      try {
        return [
          [consumer.firstName, consumer.lastName].filter(Boolean).join(' '),
          consumer.addressLine1,
          consumer.addressLine2,
          consumer.addressLine3,
          consumer.addressTown,
          consumer.addressCounty,
          consumer.addressIso,
        ]
          .filter(Boolean)
          .join(', ');
      } catch (e) {
        return 'Consumer not found';
      }
    },
    routerPush(url, onComplete = () => {}, onAbort = () => {}) {
      return this.$router.push(url, onComplete, onAbort);
    },
    routerReplace(url, onComplete = () => {}, onAbort = () => {}) {
      return this.$router.replace(url, onComplete, onAbort);
    },
    alertBox() {
      return window.alertBox;
    },
  },
});

window.VITE_APP_INSTALLATION_TYPE = import.meta.env.VITE_APP_INSTALLATION_TYPE;
window.touch = new Touch(
  import.meta.env.VITE_APP_ENDPOINT_URL,
  import.meta.env.VITE_APP_IMAGE_BASE_URL,
  router,
);

if (['development', 'test', 'staging', 'production'].includes(import.meta.env.MODE) && !window.location.href.includes('autotest')) {
  Sentry.init({
    app,
    dsn: 'https://4372a73ed9634417b01faf442f11a8f7@sentry.io/2524389',
    initialScope: {
      user: { id: authStore.id, email: authStore.email },
    },
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    environment: import.meta.env.VITE_APP_ENV,
    release: import.meta.env.VITE_APP_SENTRY_RELEASE,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.'],
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.event_id && !window.logoutPush) {
        if (event.exception && event.exception.values[0].type === 'SyntaxError') {
          Sentry.captureEvent({
            message: 'Reloaded Page - SyntaxError',
          });

          window.alertBox.fire('System updated, we need to refresh your page.').then(() => {
            window.location.reload();
          });

          return null;
        }

        const excludedExceptions = [
          'ResizeObserver loop limit exceeded',
          'ResizeObserver loop completed with undelivered notifications',
        ].map((x) => x.toLowerCase());

        if (
          !excludedExceptions.includes(event?.exception?.values[0]?.value?.toLowerCase()) &&
          ![400, 401, 403].includes(event?.extra?.status)
        ) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            user: {
              email: authStore.email,
              name: `${authStore.firstName} ${authStore.lastName}`,
            },
          });
        }
      }

      return event;
    },
  });
}

function setViewportProperty(doc) {
  let prevClientHeight;
  const customVar = '--vh';
  function handleResize() {
    const { clientHeight } = doc;
    if (clientHeight === prevClientHeight) return;
    requestAnimationFrame(() => {
      doc.style.setProperty(customVar, `${clientHeight * 0.01}px`);
      prevClientHeight = clientHeight;
    });
  }
  handleResize();
  return handleResize;
}
window.addEventListener('resize', setViewportProperty(document.documentElement));

app.mount('#app');
