import ChooseProduct from '@/views/Business/ChooseProduct.vue';

import ManageTouchAccount from '@/views/Business/ManageTouchAccount.vue';
import ManageTouchAccountCompanyDetails from '@/views/Business/ManageTouchAccount/CompanyDetails.vue';
import ManageTouchAccountStaff from '@/views/Business/ManageTouchAccount/Staff.vue';
import ManageTouchAccountDocumentsAndAgreements from '@/views/Business/ManageTouchAccount/DocumentsAndAgreements.vue';
import ManageTouchAccountCustomStyles from '@/views/Business/ManageTouchAccount/CustomStyles.vue';
import ManageTouchAccountStaffCreate from '@/views/Business/ManageTouchAccount/Staff/Create.vue';
import ManageTouchAccountStaffEdit from '@/views/Business/ManageTouchAccount/Staff/Edit.vue';
import ManageTouchAccountTaxRateCreate from '@/views/Business/ManageTouchAccount/TaxRate/Create.vue';
import ManageTouchAccountTaxRateEdit from '@/views/Business/ManageTouchAccount/TaxRate/Edit.vue';
import ManageTouchAccountQuoteStages from '@/views/Business/ManageTouchAccount/QuoteStages.vue';
import ManageTouchAccountDeliveryOptions from '@/views/Business/ManageTouchAccount/DeliveryOptions.vue';
import ManageTouchAccountPaymentGatewayStripeCreateAccount from '@/views/Business/ManageTouchAccount/PaymentGateway/StripeCreateAccount.vue';
import ManageTouchAccountPaymentGatewayStripeManageAccount from '@/views/Business/ManageTouchAccount/PaymentGateway/StripeManageAccount.vue';
import ManageTouchAccountPaymentGatewayFirstData from '@/views/Business/ManageTouchAccount/PaymentGateway/FirstData.vue';

import ProductSetupVouchers from '@/views/Business/ProductSetup/Vouchers.vue';
import ProductSetupStockParts from '@/views/Business/ProductSetup/StockParts.vue';
import ProductSetupPricing from '@/views/Business/ProductSetup/Pricing.vue';
import ProductSetupPricingPricing from '@/views/Business/ProductSetup/Pricing/Pricing.vue';
import ProductSetupPricingDiscounts from '@/views/Business/ProductSetup/Pricing/Discounts.vue';
import ProductSetupPricingFinancialCategories from '@/views/Business/ProductSetup/Pricing/FinancialCategories.vue';
import ProductSetupManageProductGroups from '@/views/Business/ProductSetup/ManageProductGroups.vue';
import ProductSetupTouchConnectDashboard from '@/views/Business/ProductSetup/TouchConnectDashboard.vue';
import ProductSetupTouchExtras from '@/views/Business/ProductSetup/Extras.vue';
import ProductSetupOptionLeadTimes from '@/views/Business/ProductSetup/OptionLeadTimes.vue';

import CustomVariables from '@/views/Business/CustomVariables.vue';

import TouchBusinessHome from '@/views/Business/Home.vue';

import ManageNews from '@/views/Business/ManageNews.vue';
import ManageNewsEdit from '@/views/Business/ManageNews/Edit.vue';
import ManageNewsCategory from '@/views/Business/ManageNews/Category.vue';

import Marketing from '@/views/Business/Marketing.vue';
import MarketingCategory from '@/views/Business/MarketingCategory.vue';

import StaffAndLicensesCreate from '@/components/shared/staffAndLicenses/Create.vue';
import StaffAndLicensesEdit from '@/components/shared/staffAndLicenses/Edit.vue';

import BranchIndex from '@/views/Business/Branch.vue';
import BranchAddress from '@/views/Business/Branch/Address.vue';
import BranchDetails from '@/views/Business/Branch/Details.vue';
import BranchStaffAndLicenses from '@/views/Business/Branch/StaffAndLicenses.vue';

import KPI from '@/views/Business/KPI.vue';
import FeedbackIndex from '@/views/Business/Feedback.vue';

import DealerNetworkSetup from '@/views/Business/DealerNetworkSetup.vue';
import DealerNetworkSetupWebsite from '@/views/Business/DealerNetworkSetup/Website.vue';
import DealerNetworkSetupCompany from '@/views/Business/DealerNetworkSetup/Company.vue';
import DealerNetworkSetupCustomStyles from '@/views/Business/DealerNetworkSetup/CustomStyles.vue';
import DealerNetworkSettingsProducts from '@/views/Business/DealerNetworkSetup/Products.vue';
import DealerNetworkOptionControl from '@/views/Business/DealerNetworkSetup/OptionControl.vue';

import DealerNetworkEnquiries from '@/views/Business/DealerNetworkEnquiries.vue';
import DealerNetworkEnquiriesAssign from '@/views/Business/DealerNetworkEnquiries/Assign.vue';
import DealerNetworkEnquiriesAssigned from '@/views/Business/DealerNetworkEnquiries/Assigned.vue';
import DealerNetworkEnquiriesAssignedStatuses from '@/views/Business/DealerNetworkEnquiries/AssignedStatuses.vue';

import Customers from '@/views/Business/Customers.vue';
import CustomersPortal from '@/views/Business/Customers/Portal.vue';
import CustomersBrandedMode from '@/views/Business/Customers/BrandedMode.vue';
import CustomersRegistrationRequests from '@/views/Business/Customers/RegistrationRequests.vue';


import Customer from '@/views/Business/Customer.vue';
import CustomerDiscounts from '@/views/Business/Customer/Discounts.vue';
import CustomerVouchers from '@/views/Business/Customer/Vouchers.vue';
import CustomerDetails from '@/views/Business/Customer/CustomerDetails.vue';
import CustomerStaffAndLicenses from '@/views/Business/Customer/StaffAndLicenses.vue';
import CustomerStockPartsWrapper from '@/views/Business/Customer/StockPartsWrapper.vue';
import CustomerSalesDashboard from '@/views/Business/Customer/SalesDashboard.vue';
import CustomerPricing from '@/views/Business/Customer/Pricing.vue';
import CustomerCreate from '@/components/shared/CustomerCreate.vue';
import CustomerAvailableProducts from '@/views/Business/Customer/AvailableProducts.vue';
import CustomerProcessingOptions from '@/views/Business/Customer/ProcessingOptions.vue';
import CustomerAddressCreate from '@/views/Business/Customer/AddressesCreate.vue';
import CustomerAddressEdit from '@/views/Business/Customer/AddressesEdit.vue';
import CustomerAddressesAndDeliveryDepots from '@/views/Business/Customer/AddressesAndDeliveryDepots.vue';

import Quote from '@/views/Business/Quote.vue';
import Order from '@/views/Business/Order.vue';
import NewQuote from '@/views/Shared/NewQuote.vue';
import Designer from '@/views/Business/Designer.vue';
import Basket from '@/views/Business/Basket.vue';
import NewOrder from '@/views/Shared/NewOrder.vue';
import Checkout from '@/views/Business/Checkout.vue';
import NewTest from '@/views/Business/NewTest.vue';

import AllOrders from '@/views/Business/AllOrders.vue';

import RackManager from '@/views/Business/RackManager.vue';
import RackManagerVehicleManagement from '@/views/Business/RackManager/VehicleManagement.vue';
import RackManagerRackTypeManagement from '@/views/Business/RackManager/RackManagement.vue';
import RackManagerCarrierManagement from '@/views/Business/RackManager/CarrierManagement.vue';
import RackManagerMap from '@/views/Business/RackManager/Map.vue';
import SwitchDesign from '@/views/Business/SwitchDesign.vue';

import TouchStore from '@/views/Business/TouchStore.vue';

import VisualiserUpload from '@/views/Shared/Visualiser.vue';
import VisualiserPlace from '@/views/Shared/Visualiser/Place.vue';

import { useAuthStore } from '@/pinia/auth';
import { useStyleStore } from '@/pinia/style';
import { useVisualiserStore } from '@/pinia/visualiser';
import { useCustomerStore } from '@/pinia/customer';
import { useBasketStore } from '@/pinia/basket';

const routes = [
  {
    path: '/all-orders',
    name: 'All Orders',
    meta: { active: 'all-orders', showSearch: true },
    component: AllOrders,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.branches?.length > 0 && !to.query.branchFilter) {
        next({
          ...to,
          query: {
            branchFilter: authStore.branches.find(x => x.isPrimary).branchCompanyId,
          }
        })
      } else {
        next();
      }
    },
  },
  {
    path: '/custom-variables',
    name: 'Manage Custom Variables',
    meta: { active: 'custom-variables' },
    component: CustomVariables,
  },
  {
    path: '/business',
    name: 'Admin Dashboard',
    meta: { active: 'home' },
    component: TouchBusinessHome,
  },
  {
    path: '/manage-news',
    name: 'Manage News',
    meta: { showSearch: true, active: 'manage-news' },
    component: ManageNews,
  },
  {
    path: '/manage-news/edit/:id',
    name: 'Edit News',
    meta: { active: 'manage-news' },
    component: ManageNewsEdit,
  },
  {
    path: '/manage-news/category',
    name: 'Manage News Categories',
    meta: { active: 'manage-news' },
    component: ManageNewsCategory,
  },
  {
    path: '/manage-marketing',
    name: 'Manage Marketing',
    meta: { showSearch: true, active: 'marketing' },
    component: Marketing,
  },
  {
    path: '/manage-downloads/category',
    name: 'Manage Download Categories',
    meta: { active: 'marketing' },
    component: MarketingCategory,
  },
  {
    path: '/customer',
    redirect: '/customers'
  },
  {
    path: '/customers',
    component: Customers,
    redirect: '/customers/portal',
    children: [
      {
        path: 'portal',
        name: 'Customers',
        meta: { showSearch: true, active: 'customer' },
        component: CustomersPortal,
        beforeEnter: (to, from, next) => {
          const authStore = useAuthStore()
          if (authStore.branches?.length > 0 && !to.query.branchFilter) {
            next({
              ...to,
              query: {
                branchFilter: authStore.branches.find(x => x.isPrimary).branchCompanyId
              }
            })
          } else {
            next();
          }
        },
      },
      {
        path: 'branded-mode',
        name: 'Branded Mode Customers',
        meta: { showSearch: true, active: 'customer' },
        component: CustomersBrandedMode,
        beforeEnter: (to, from, next) => {
          const authStore = useAuthStore()
          if (authStore.admin) {
            next();
          } else {
            window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
            next('/');
          }
        }
      },   
      {
        path: 'registration-requests',
        name: 'Registration Requests',
        meta: { showSearch: true, active: 'customer' },
        component: CustomersRegistrationRequests,
        beforeEnter: (to, from, next) => {
          const authStore = useAuthStore()
          if (authStore.admin) {
            next();
          } else {
            window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
            next('/');
          }
        }
      },
    ]
  },
  {
    path: '/customer/:id',
    name: 'Customer',
    meta: { active: 'customer' },
    component: Customer,
    redirect: to => {
      const { id } = to.params;
      return { path: `/customer/${id}/details` }
    },
    children: [
      {
        path: '/customer/:id/details',
        name: 'Customer Details',
        meta: { active: 'customer' },
        component: CustomerDetails,
      },
      {
        path: '/customer/:id/sales-dashboard',
        name: 'Customer Sales Dashboard',
        meta: { active: 'sales-dashboard', showSearch: true },
        component: CustomerSalesDashboard,
        beforeEnter: (to, from, next) => {
          const authStore = useAuthStore()
          if (authStore.branches?.length > 0 && !to.query.branchFilter) {
            next({
              ...to,
              query: {
                branchFilter: authStore.branches.find(x => x.isPrimary).branchCompanyId,
              }
            })
          } else {
            next();
          }
        },
      },
      {
        path: '/customer/:customerId/sales-dashboard/new-quote',
        name: 'Customer New Quote',
        meta: { showSidebar: false, active: 'sales-dashboard' },
        component: NewQuote,
      },
      {
        path: '/customer/:customerId/sales-dashboard/new-order',
        name: 'Customer New Order',
        meta: { showSidebar: false, active: 'sales-dashboard' },
        component: NewOrder,
      },
      {
        path: '/customer/:customerId/sales-dashboard/new-test',
        name: 'Customer New Test',
        meta: { showSidebar: false, active: 'sales-dashboard' },
        component: NewTest,
      },
      {
        path: '/customer/:customerId/sales-dashboard/design/:id',
        name: 'Customer Designer',
        meta: { showSidebar: false, active: 'designer' },
        component: Designer,
      },
      {
        path: '/customer/:customerId/sales-dashboard/checkout',
        name: 'Customer Checkout',
        meta: { showSidebar: false, active: 'designer' },
        component: Checkout,
        beforeEnter: (to, from, next) => {
          const basketStore = useBasketStore()
          if (basketStore.hasContract) {
            next()
          } else {
            next(`/customer/${basketStore.customerId}/sales-dashboard/new-quote`)
          }
        },
      },
      {
        path: '/customer/:customerId/sales-dashboard/basket',
        name: 'Customer Basket',
        meta: { showSidebar: false, active: 'designer' },
        component: Basket,
      },
      {
        path: '/customer/:customerId/sales-dashboard/choose-product',
        name: 'Customer Choose Product',
        meta: { showSidebar: false, active: 'sales-dashboard' },
        component: ChooseProduct,
      },
      {
        path: '/customer/:customerId/sales-dashboard/switch-design/:id/:component',
        name: 'Customer Switch Bay',
        meta: { showSidebar: false, active: 'designer' },
        component: SwitchDesign,
      },
      {
        path: '/customer/:customerId/sales-dashboard/order/:id/:jobId?',
        name: 'Customer Sales Order',
        meta: { active: 'sales-dashboard-view-order' },
        component: Order,
      },
      {
        path: '/customer/:customerId/sales-dashboard/quote/:id/:jobId?',
        name: 'Customer Sales Quote',
        meta: { active: 'sales-dashboard-view-quote' },
        component: Quote,
      },
      {
        path: '/customer/:id/addresses',
        name: 'Customer Manage Addresses',
        meta: { active: 'customer' },
        component: CustomerAddressesAndDeliveryDepots,
      },
      {
        path: '/customer/:id/staff',
        name: 'Customer Manage Staff',
        meta: { active: 'customer' },
        component: CustomerStaffAndLicenses,
      },
      {
        path: '/customer/:id/vouchers',
        name: 'Customer Vouchers',
        meta: { active: 'customer' },
        component: CustomerVouchers,
        beforeEnter: (to, from, next) => {
          const authStore = useAuthStore()
          if (authStore.admin) {
            next();
          } else {
            window.alertBox.fire({
              title: 'Redirected Home',
              text: 'Admin users only'
            });
            next('/');
          }
        }
      },
      {
        path: '/customer/:id/discounts',
        name: 'Customer Discounts',
        meta: { active: 'customer' },
        component: CustomerDiscounts,
        beforeEnter: (to, from, next) => {
          const authStore = useAuthStore()
          if (authStore.admin) {
            next();
          } else {
            window.alertBox.fire({
              title: 'Redirected Home',
              text: 'Admin users only'
            });
            next('/');
          }
        }
      },
      {
        path: '/customer/:id/processing-options',
        name: 'Customer Processing Options',
        meta: { active: 'customer' },
        component: CustomerProcessingOptions,
      },
      {
        path: '/customer/:id/pricing',
        name: 'Customer Pricing',
        meta: { active: 'customer', showSearch: true },
        component: CustomerPricing,
        beforeEnter: (to, from, next) => {
          const authStore = useAuthStore()
          if (authStore.admin) {
            next();
          } else {
            window.alertBox.fire({
              title: 'Redirected Home',
              text: 'Admin users only'
            });
            next('/');
          }
        }
      },
      {
        path: '/customer/:id/stock-parts',
        name: 'Customer Stock Parts',
        meta: { active: 'customer', showSearch: true },
        component: CustomerStockPartsWrapper,
        beforeEnter: (to, from, next) => {
          const authStore = useAuthStore()
          if (authStore.admin) {
            next();
          } else {
            window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
            next('/');
          }
        }
      },
      {
        path: '/customer/:id/available-products',
        name: 'Customer Available Products',
        meta: { active: 'customer' },
        component: CustomerAvailableProducts,
      },
      {
        path: '/customer/:id/visualiser',
        name: 'Visualiser Setup',
        meta: { active: 'customer', showSidebar: false },
        component: VisualiserUpload,
        beforeEnter: (to, from, next) => {
          const customerStore = useCustomerStore()
          customerStore.freshdeskWidgetEnabled = false;
          next();
        },
      },
      {
        path: '/customer/:id/visualiser/place',
        name: 'Visualiser',
        meta: { showSidebar: false },
        component: VisualiserPlace,
        beforeEnter: (to, from, next) => {
          const visualiserStore = useVisualiserStore()
          if (visualiserStore.images.length > 0 && visualiserStore.selectedImageId) {
            const customerStore = useCustomerStore()
            customerStore.freshdeskWidgetEnabled = false;
            next()
          } else {
            next('/visualiser');
          }
        },
      }
    ]
  },
  {
    path: '/create-customer',
    name: 'Create Customer',
    meta: { active: 'customer' },
    component: CustomerCreate,
  },
  {
    path: '/dealer-network',
    name: 'Dealer Network',
    meta: { active: 'dealer-network', showSearch: true  },
    component: DealerNetworkEnquiries,
  },
  {
    path: '/dealer-network/assign/:id',
    name: 'Dealer Network Assign',
    meta: { active: 'dealer-network-assign' },
    component: DealerNetworkEnquiriesAssign,
  },
  {
    path: '/dealer-network-assigned',
    name: 'Dealer Network Assigned',
    meta: { active: 'dealer-network-assigned', showSearch: true  },
    component: DealerNetworkEnquiriesAssigned,
  },
  {
    path: '/dealer-network-assigned-statuses/:id',
    name: 'Dealer Network Assigned Statuses',
    meta: { active: 'dealer-network-assigned-statuses' },
    component: DealerNetworkEnquiriesAssignedStatuses,
  },
  {
    path: '/manage-touch-account',
    redirect: '/manage-touch-account/company-details',
    component: ManageTouchAccount,
    children: [
      {
        path: '/manage-touch-account/company-details',
        name: 'company-details',
        meta: { active: 'manage-touch-account' },
        component: ManageTouchAccountCompanyDetails,
      },
      {
        path: '/manage-touch-account/staff',
        name: 'staff',
        meta: { active: 'staff', showSearch: true  },
        component: ManageTouchAccountStaff,
      },
      {
        path: '/manage-touch-account/documents-and-agreements',
        name: 'documents-and-agreements',
        meta: { active: 'manage-touch-account' },
        component: ManageTouchAccountDocumentsAndAgreements,
      },
      {
        path: '/manage-touch-account/custom-styles',
        name: 'custom-styles',
        meta: { active: 'manage-touch-account' },
        component: ManageTouchAccountCustomStyles,
      },
      {
        path: '/manage-touch-account/payment-gateway',
        name: 'Payment Gateway',
        beforeEnter: (to, from, next) => {
          const styleStore = useStyleStore()
          if (styleStore.fabricator && styleStore.fabricator.name === 'Virtuoso') {
            next('/manage-touch-account/payment-gateway/firstdata');
          } else {
            next('/manage-touch-account/payment-gateway/stripe');
          }
        }
      },
      {
        path: '/manage-touch-account/payment-gateway/stripe',
        name: 'Payment Gateway Stripe Account',
        component: ManageTouchAccountPaymentGatewayStripeManageAccount,
      },
      {
        path: '/manage-touch-account/payment-gateway/stripe/create',
        name: 'Payment Gateway Stripe Create',
        component: ManageTouchAccountPaymentGatewayStripeCreateAccount,
      },
      {
        path: '/manage-touch-account/payment-gateway/firstdata',
        name: 'Payment Gateway First Data',
        component: ManageTouchAccountPaymentGatewayFirstData,
      },
      {
        path: '/manage-touch-account/delivery-options',
        name: 'delivery-options',
        meta: { active: 'manage-touch-account', showSearch: true },
        component: ManageTouchAccountDeliveryOptions,
      },
      {
        path: '/manage-touch-account/quote-stages',
        name: 'quote-stages',
        meta: { active: 'quote-stages' },
        component: ManageTouchAccountQuoteStages,
      },
    ]
  },
  {
    path: '/onboarding',
    redirect: '/manage-touch-account/payment-gateway/stripe',
    meta: { active: 'payment-gateway' },
  },
  {
    path: '/onboarding/*',
    redirect: '/manage-touch-account/payment-gateway/stripe',
    meta: { active: 'payment-gateway' },
  },
  {
    path: '/product-setup',
    redirect: '/product-setup/product-selections',
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
        next('/');
      }
    }
  },
  {
    path: '/product-setup/vouchers',
    name: 'vouchers',
    meta: { active: 'product-setup' },
    component: ProductSetupVouchers,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
        next('/');
      }
    }
  },
  {
    path: '/product-setup/parts',
    name: 'parts',
    meta: { active: 'product-setup', showSearch: true },
    component: ProductSetupStockParts,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
        next('/');
      }
    }
  },
  {
    path: '/product-setup/extras',
    component: ProductSetupTouchExtras,
    meta: { active: 'product-setup' },
    name: 'extras',
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
        next('/');
      }
    }
  },
  {
    path: '/product-setup/pricing',
    component: ProductSetupPricing,
    meta: { meta: 'pricing' },
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
        next('/');
      }
    },
    children: [
      {
        path: '/product-setup/pricing',
        name: 'Pricing',
        meta: { active: 'pricing', showSearch: true },
        component: ProductSetupPricingPricing,
      },
      {
        path: '/product-setup/pricing/financial-categories',
        meta: { active: 'financial-categories'},
        name: 'Financial Categories',
        component: ProductSetupPricingFinancialCategories,
      },
      {
        path: '/product-setup/pricing/discounts',
        name: 'Discounts',
        meta: { active: 'discounts'},
        component: ProductSetupPricingDiscounts,
      },
    ]
  },
  {
    path: '/product-setup/product-selections',
    name: 'product-selections',
    meta: { active: 'product-setup' },
    component: ProductSetupManageProductGroups,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
        next('/');
      }
    }
  },
  {
    path: '/product-setup/touch-connect-business',
    name: 'touch-connect-business',
    meta: { active: 'product-setup' },
    component: ProductSetupTouchConnectDashboard,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.superuser) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Super users only'
        });
        next('/');
      }
    }
  },
  {
    path: '/product-setup/option-lead-times',
    name: 'option-lead-times',
    meta: { active: 'product-setup', showSearch: true },
    component: ProductSetupOptionLeadTimes,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
        next('/');
      }
    }
  },
  {
    path: '/create-tax-rate',
    name: 'Create Tax rate',
    meta: { active: 'manage-touch-account' },
    component: ManageTouchAccountTaxRateCreate,
  },
  {
    path: '/edit-tax-rate/:id',
    name: 'Edit Tax Rate',
    meta: { active: 'manage-touch-account' },
    component: ManageTouchAccountTaxRateEdit,
  },
  {
    path: '/create-user/:customerId',
    name: 'Create User',
    meta: { active: 'customer' },
    component: StaffAndLicensesCreate,
  },
  {
    path: '/edit-user/:customerId/:userId',
    name: 'Edit User',
    meta: { active: 'customer' },
    component: StaffAndLicensesEdit,
  },
  {
    path: '/create-staff',
    name: 'Create Staff',
    meta: { active: 'manage-touch-account' },
    component: ManageTouchAccountStaffCreate,
  },
  {
    path: '/edit-staff/:userId',
    name: 'Edit Staff',
    meta: { active: 'manage-touch-account' },
    component: ManageTouchAccountStaffEdit,
  },
  {
    path: '/create-address/:customerId',
    name: 'Create Address',
    meta: { active: 'customer' },
    component: CustomerAddressCreate,
  },
  {
    path: '/edit-address/:customerId/:addressId',
    name: 'Edit Address',
    meta: { active: 'customer' },
    component: CustomerAddressEdit,
  },
  {
    path: '/dealer-network-setup',
    name: 'Dealer Network Mode',
    meta: { active: 'dealer-network-setup' },
    component: DealerNetworkSetup,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.isDealerNetworkModeAvailable && authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: authStore.admin ? 'No Dealer Network license found': 'Admin users only' 
        });
        next('/');
      }
    }
  },
  {
    path: '/dealer-network-setup/company',
    name: 'Dealer Network Company',
    meta: { active: 'dealer-network-setup' },
    component: DealerNetworkSetupCompany,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.isDealerNetworkModeAvailable && authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: authStore.admin ? 'No Dealer Network license found': 'Admin users only' 
        });
        next('/');
      }
    }
  },
  {
    path: '/dealer-network-setup/custom-styles',
    name: 'Dealer Network Custom Styles',
    component: DealerNetworkSetupCustomStyles,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.isDealerNetworkModeAvailable && authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: authStore.admin ? 'No Dealer Network license found': 'Admin users only' 
        });
        next('/');
      }
    }
  },
  {
    path: '/dealer-network-setup/option-control',
    name: 'Dealer Network Option Control',
    meta: {  showSearch: true },
    component: DealerNetworkOptionControl,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.isDealerNetworkModeAvailable && authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: authStore.admin ? 'No Dealer Network license found': 'Admin users only' 
        });
        next('/');
      }
    }
  },
  {
    path: '/dealer-network-setup/products',
    meta: { active: 'dealer-network-setup' },
    name: 'Dealer Network Products',
    component: DealerNetworkSettingsProducts,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.isDealerNetworkModeAvailable && authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: authStore.admin ? 'No Dealer Network license found': 'Admin users only' 
        });
        next('/');
      }
    }
  },
  {
    path: '/dealer-network-setup/website',
    meta: { active: 'dealer-network-setup' },
    name: 'Dealer Network Website',
    component: DealerNetworkSetupWebsite,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.isDealerNetworkModeAvailable && authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: authStore.admin ? 'No Dealer Network license found': 'Admin users only' 
        });
        next('/');
      }
    }
  },
  {
    path: '/rack-manager',
    component: RackManager,
    redirect: '/rack-manager/map',
    children: [
      {
        name: 'Vehicle Management',
        path: '/rack-manager/vehicles',
        component: RackManagerVehicleManagement,
      },
      {
        name: 'Rack Management',
        path: '/rack-manager/rack-types',
        component: RackManagerRackTypeManagement,
      },
      {
        name: 'Carrier Management',
        path: '/rack-manager/carriers',
        component: RackManagerCarrierManagement,
      },
      {
        name: 'Rack Map',
        path: '/rack-manager/map',
        component: RackManagerMap,
      },
    ],
  },
  {
    path: '/feedback',
    name: 'Feedback Index',
    meta: { active: 'feedback' },
    component: FeedbackIndex,
  },
  {
    path: '/kpi',
    name: 'KPI Dashboard',
    meta: { active: 'kpi' },
    component: KPI,
  },
  {
    path: '/branch',
    name: 'Branches',
    meta: { showSearch: true, active: 'branch' },
    component: BranchIndex,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
        next('/');
      }
    }
  },
  {
    path: '/branch/:id/details',
    name: 'Admin Branch Details',
    meta: { active: 'branch' },
    component: BranchDetails,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
        next('/');
      }
    }
  },
  {
    path: '/branch/:id',
    name: 'Branch',
    meta: { active: 'branch' },
    component: BranchDetails,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
        next('/');
      }
    }
  },
  {
    path: '/branch/:id/address',
    name: 'Branch Address',
    meta: { active: 'branch' },
    component: BranchAddress,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
        next('/');
      }
    }
  },
  {
    path: '/branch/:id/staff',
    name: 'Manage Branch Staff',
    meta: { active: 'branch' },
    component: BranchStaffAndLicenses,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'Admin users only'
        });
        next('/');
      }
    }
  },
  {
    path: '/touch-store-admin',
    name: 'Touch Store Admin',
    meta: { active: 'touch-store' },
    component: TouchStore,
  },
];

export default routes.map((route) => {
  const meta = { ...route.meta, businessRoute: true };
  return { ...route, meta };
});
