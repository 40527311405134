<template>
  <div class="flex justify-between gap-3 py-1 px-5">
    <div class="py-1 flex flex-col justify-around">
      <div class="text-xs md:text-sm">
        {{ price.description }}
      </div>
    </div>
    <div class="flex ml-auto">
      <button class="btn-action" style="width: 6.6rem" @click="editGrid">Edit</button>
      <Teleport v-if="showGrid" defer to="#portal_popup">
        <select-grid
          :default-currency="defaultCurrency"
          :customer-id="customerId"
          :dataset-id="datasetId"
          :price="price"
          :is-new-base-price="isNewBasePrice"
          @close="showGrid = false"
        />
      </Teleport>
    </div>
    <div class="hidden xs:block w-8" />
  </div>
</template>

<script>
import SelectGrid from '@/components/shared/priceLines/List/SelectGrid.vue';

export default {
  components: {
    'select-grid': SelectGrid,
  },
  props: ['price', 'defaultCurrency', 'datasetId', 'customerId', 'isNewBasePrice'],
  data() {
    return {
      showGrid: false,
    };
  },
  methods: {
    editGrid() {
      this.showGrid = true;
    },
  },
};
</script>
