<template>
  <modal-window :modal_open="true" :title @close="$emit('close')">
    <loading :loading label="Saving">
      <div>
        <textarea
          v-model="text"
          placeholder="Your Note"
          class="form-control"
          rows="10"
          :maxlength="maxNoteLength"
        />
        <span v-if="text">{{ text.length }}/{{ maxNoteLength }}</span>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg xs:w-48" @click="save()">Save Changes</button>
      </div>
    </loading>
  </modal-window>
</template>

<script>
export default {
  components: {},
  props: ['contract', 'notes', 'noteType', 'loading', 'title'],
  data() {
    return {
      text: '',
      maxNoteLength: 2000,
    };
  },
  created() {
    this.text = this.notes ? this.notes.map(x => x.text).join('\n') : '';
  },
  methods: {
    save() {
      this.$emit('save', this.text);
    },
  },
};
</script>
