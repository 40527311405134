<template>
  <div>
    <div class="p-5 xs:p-10 min-h-screen">
      <div class="bg-white p-3 xs:p-10 border">
        <div class="flex flex-col sm:flex-row relative border-b mb-10 pb-10">
          <div class="flex flex-col">
            <div class="flex flex-col justify-around text-base mb-3">
              <div>Meta title</div>
            </div>
            <div class="">
              <input v-model="siteTitle" type="text" class="form-control max-w-md">
            </div>
            <div class="text-gray-600 text-base mt-3 max-w-3xl">
              Meta tags are used to provide additional information about a page to search engines and
              are displayed within the tab title.
            </div>
          </div>
        </div>
        <div class="flex flex-col xs:flex-row relative border-b mb-10 gap-10 pb-10">
          <div class="new-image-zone w-full flex-1">
            <div class="flex flex-col justify-around text-base">
              <div>Primary Login Image</div>
            </div>
            <div class="text-gray-600 text-base my-3">
              This is the primary image that is displayed on your TOUCH Portal login page where your
              customers will login.
            </div>
            <Dropzone
              :current_file="loginImage.src"
              @add-file="images.find((x) => x.imageType === loginImage.imageType).newImage = $event"
              @remove-file="resetImage(loginImage.imageType)"
            />
            <div class="text-gray-500 py-3">
              <div>Required file type: JPG, PNG.</div>
              <div>Recommended size: 440px by 220px</div>
              <div>Max File Size: 1MB</div>
            </div>
          </div>
          <div class="flex flex-col gap-5 w-full flex-1">
            <div v-if="loginImage.src" class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12" style="min-height: 300px; max-width: 400px;">
              <img :src="loginImage.src" style="max-height: 200px">
            </div>
            <div v-else class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12" style="min-height: 300px; max-width: 400px;">
              <img src="/images/login-image.png">
            </div>
          </div>
        </div>
        <div class="flex flex-col xs:flex-row relative border-b gap-10 mb-10 pb-10">
          <div class="new-image-zone w-full flex-1">
            <div class="flex flex-col justify-around text-base">
              <div>Login Page Background Image</div>
            </div>
            <div class="text-gray-600 text-base my-3">
              This is the background image that is displayed on your TOUCH Portal login page where your
              customers will login.
            </div>
            <div>
              <Dropzone
                :current_file="secondaryLoginImage.src"
                @add-file="images.find((x) => x.imageType === secondaryLoginImage.imageType).newImage = $event"
                @remove-file="resetImage(secondaryLoginImage.imageType)"
              />
            </div>
            <div class="text-gray-500 py-3">
              <div>Required file type: JPG, PNG.</div>
              <div>Recommended size: 440px by 220px</div>
              <div>Max File Size: 1MB</div>
            </div>
          </div>
          <div class="flex flex-col gap-5 w-full flex-1">
            <div
              v-if="secondaryLoginImage.src"
              class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
              style="min-height: 300px; max-width: 400px;"
            >
              <img :src="secondaryLoginImage.src" style="max-height: 200px">
            </div>
            <div v-else class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12" style="min-height: 300px; max-width: 400px;">
              <img src="/images/login-background.png">
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-5 relative pb-5">
          <div class="flex flex-col justify-around text-base">
            <div>Portal Login Page Text</div>
          </div>
          <div class="text-gray-600 text-base">
            <strong>Location:</strong> This is the text displayed on your TOUCH Portal login page where your customers will login.
          </div>
          <div class="flex flex-col">
            <div class="flex flex-col justify-around text-base mb-3">
              <div>Header</div>
              <div class="text-gray-600 text-base">
                This will be displayed on the Portal login page as the heading text.
              </div>
            </div>
            <div class="">
              <input v-model="loginHeader" type="text" class="form-control max-w-md">
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-col justify-around text-base mb-3">
              <div>Sub-Header</div>
              <div class="text-gray-600 text-base">
                This will be displayed on the Portal login page as the sub-heading text.
              </div>
            </div>
            <div class="">
              <input v-model="loginSubHeader" type="text" class="form-control max-w-md">
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-col justify-around text-base mb-3">
              <div>Body Text</div>
              <div class="text-gray-600 text-base">
                This will be displayed on the Portal login page as welcome text underneath the headers.
              </div>
            </div>
            <div class="">
              <input v-model="loginWelcomeText" type="text" class="form-control max-w-md">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sticky bottom-0 bg-white p-3 xs:p-4 text-right border-gray-300 border-t">
      <div class="flex flex-col xs:flex-row justify-between">
        <div class="ml-auto mt-2 xs:mt-0">
          <button class="btn-action btn-lg w-full xs:w-auto" @click.prevent.stop="save()">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useStyleStore } from '@/pinia/style';
import { useCustomerStore } from '@/pinia/customer';
import validate from 'validate.js';
import Dropzone from '@/components/shared/Dropzone.vue';

const imageTypeEnum = {
  LOGIN: 1,
  SECONDARY_LOGIN: 2,
};

export default {
  components: {
    Dropzone
  },
  data() {
    return {
      images: [
        {
          imageType: 1,
          src: '',
          newImage: '',
          reset: false,
          default: '',
        },
        {
          imageType: 2,
          src: '',
          newImage: '',
          reset: false,
          default: '',
        },
      ],
      loginHeader: undefined,
      loginSubHeader: undefined,
      loginWelcomeText: undefined,
      siteTitle: undefined,
      imageTypeEnum,
    };
  },
  computed: {
    ...mapStores(useStyleStore, useCustomerStore),
    loginImage() {
      return this.images.find((x) => x.imageType === imageTypeEnum.LOGIN);
    },
    secondaryLoginImage() {
      return this.images.find((x) => x.imageType === imageTypeEnum.SECONDARY_LOGIN);
    },
    newImages() {
      return typeof this.images.find((x) => x.newImage) !== 'undefined';
    },
    deletedImages() {
      return typeof this.images.find((x) => x.reset) !== 'undefined';
    },
  },
  async created() {
    await this.updateCurrentImages();
  },
  methods: {
    resetImage(imageType) {
      const index = this.images.findIndex((x) => x.imageType === imageType)
      this.images[index] = { ...this.images[index], reset: true, newImage: null }
    },
    async save() {
      const validationErrors = validate(
        {
          siteTitle: this.siteTitle,
        },
        {
          siteTitle: {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      if (this.deletedImages) {
        await this.styleStore.deleteCompanyStyling({
          portalPageLoginImage: this.loginImage.reset,
          portalPageLoginSecondaryImage: this.secondaryLoginImage.reset,
        });
        this.images = this.images.map((x) => {
          x.reset = false;
          return x;
        });
      }
      await this.styleStore.updateCompanyStyles({
        portalPageLoginImage: this.loginImage.newImage,
        portalPageLoginSecondaryImage: this.secondaryLoginImage.newImage,
        portalPageLoginHeader: this.loginHeader,
        portalPageSubHeader: this.loginSubHeader,
        portalPageLoginWelcomeText: this.loginWelcomeText,
        siteTitle: this.siteTitle,
      });
      await this.updateCurrentImages();
    },
    resetSrcsToDefault() {
      this.images = this.images.map((x) => {
        x.src = '';
        return x;
      });
    },
    async updateCurrentImages() {
      const styles = await this.styleStore.getCompanyStyles(this.customerStore.customer.companyId);
      this.resetSrcsToDefault();
      const indexLogin = this.images.findIndex((x) => x.imageType === imageTypeEnum.LOGIN)
      this.images[indexLogin] = {
        src: styles?.portalPageStyling?.loginImageUri,
        reset: false,
        imageType: imageTypeEnum.LOGIN,
      }
      const indexSecondaryLogin = this.images.findIndex((x) => x.imageType === imageTypeEnum.SECONDARY_LOGIN)
      this.images[indexSecondaryLogin] = {
        src: styles?.portalPageStyling?.loginSecondaryImageUri,
        reset: false,
        imageType: imageTypeEnum.SECONDARY_LOGIN,
      }

      this.loginHeader = styles?.portalPageStyling?.loginHeader;
      this.loginSubHeader = styles?.portalPageStyling?.loginSubHeader;
      this.loginWelcomeText = styles?.portalPageStyling?.loginWelcomeText;
      this.siteTitle = styles?.siteTitle;
    },
  },
};
</script>
<style scoped>
.input {
  display: flex;
  flex-direction: column;
}

.image {
  width: 200px;
}

.preview-section {
  width: 500px;
}

.new-image-zone {
  width: 100%;
}

@media (min-width: theme("screens.sm")) {
  .new-image-zone {
    width: 700px;
  }
}
</style>
