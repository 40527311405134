import { defineStore } from 'pinia';
import { useBrandedStore } from '@/pinia/branded';
import { useCustomerStore } from '@/pinia/customer';
import { useStyleStore } from '@/pinia/style';
import { useBasketStore } from '@/pinia/basket';
import { resetPinia } from '@/composables/resetPinia';
import { momentDate } from '@/composables/date';

export const useAuthStore = defineStore('auth', {
  persist: true,
  state: () => ({
    authToken: null,
    authTokenExpiry: undefined,
    installation: undefined,
    productInfo: undefined,
    demoMode: false,
    companyName: undefined,
    id: undefined,
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    email: undefined,
    username: undefined,
    image: undefined,
    loggedIn: false,
    processingLevel: 99,
    usingBrandedModeToken: false,
    isDealerNetworkMode: false,
    staffLogin: false,
    admin: false,
    isDealerNetworkModeAvailable: false,
    superuser: false,
    isBranchManagerAvailable: false,
    isBranchManager: 0,
    branches: [],
    accountSettings: {},
    login_url: '/login'
  }),
  actions: {
    setAuth({ property, token, tokenExpiry }) {
      this.installation = property
      this.authToken = this.authToken || {};
      this.authTokenExpiry = this.authTokenExpiry || {};
      this.authToken[property] = token;
      this.authTokenExpiry[property] = tokenExpiry;
      window.touch.setLogin(this.authToken, this.authTokenExpiry, this.installation);
    },
    setIsBranchManager(isBranchManager) {
      this.isBranchManager = isBranchManager;
    },
    login(user) {
      this.loggedIn = true;
      this.id = user.id;
      this.companyName = user.companyName;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.mobile = user.mobile;
      this.email = user.email;
      this.username = user.username;
      this.image = user.image;
      this.staffLogin = user.staffLogin;
      this.admin = user.admin;
      if (user.superuser) {
        this.superuser = user.superuser;
      }
      if (user.isDemo) {
        this.demoMode = user.isDemo;
      } else {
        this.demoMode = false;
      }
    },
    setAccountSettings(accountSettings) {
      this.accountSettings = accountSettings;
    },
    setBranches(branches) {
      this.branches = branches;
    },
    setBrandedMode(mode) {
      this.usingBrandedModeToken = mode;
    },
    setDealerNetworkMode(mode) {
      this.isDealerNetworkMode = mode;
    },
    setDealerNetworkModeAvailable(available) {
      this.isDealerNetworkModeAvailable = available;
    },
    setBranchManagerAvailable(available) {
      this.isBranchManagerAvailable = available;
    },
    async setAuthToken({ property, token }) {
      this.setAuthToken({ property, token })
    },
    async getProductInfo() {
      if (this.productInfo === undefined) {
        this.productInfo = await window.touch.authAuthorisedProducts()
      }
      return this.productInfo.products;
    },
    async GetJWT(property) {
      const response = await window.touch.authGetJWT();
      this.setAuth({
        property,
        token: response.data.token,
        tokenExpiry: response.data.expiresAt,
      })
    },
    async setProcessingLevel() {
      this.processingLevel = parseInt(await window.touch.processingGetProcessingLevel() ?? this.processingLevel, 10);
    },
    async setVendorLogin( { isDemo, isDealerNetwork, property, token, tokenExpiry }) {
      const brandedStore = useBrandedStore()
      const styleStore = useStyleStore()
      this.setAuth({ property, token, tokenExpiry })
      this.setProcessingLevel()
      this.setBrandedMode(true)
      this.setDealerNetworkMode(isDealerNetwork)
      this.login({ isDemo })
      brandedStore.setBrandedSettings(await window.touch.customerGetGuest())
      styleStore.refresh({})
    },
    setEndpoint(endpoint) {
      const hasTrailingSlash = endpoint.charAt(endpoint.length - 1) === '/';
      window.touch.setEndpoint(hasTrailingSlash ? endpoint : `${endpoint}/`);
    },
    setImageBaseURL(url) {
      window.touch.setImageBaseURL(url);
    },
    async setLogin() {
      const response = await window.touch.getUserDetails();
      this.login({
        id: response.userId,
        companyName: response.companyName,
        firstName: response.firstName,
        lastName: response.lastName,
        image: response.userImage,
        email: response.email,
        staffLogin: response.isStaff,
        admin: response.isAdmin,
      });
    },
    async validateLogin(property) {
      if (
        !this.authToken?.[property] ||
        this.authTokenExpiry?.[property] === undefined ||
        momentDate(this.authTokenExpiry?.[property]).isBefore() ||
        !this.loggedIn
      ) {
        return false;
      }

      if (property === 'public') {
        this.setBrandedMode(true)
      } else {
        this.setBrandedMode(false)
      }

      window.touch.setLogin(this.authToken, this.authTokenExpiry, property);

      return true;
    },
    async attemptPortalLogin({ password, username }) {
      const response = await window.touch.logincustomer(username, password);

      if (response === false) {
        resetPinia(['basket'])
        return false;
      }
      this.setAuth({
        property: 'customer',
        token: response.token,
        tokenExpiry: response.tokenExpiry,
      })

      window.logoutPush = false;
      this.login({
        id: response.id,
        companyName: response.companyName,
        firstName: response.firstName,
        lastName: response.lastName,
        image: response.image,
        mobile: response.mobile,
        email: username,
        username,
        staffLogin: false,
        admin: response.admin,
        isDemo: response.isDemo,
      });

      const basketStore = useBasketStore()
      const { data } = await window.touch.isProductAvailable(28);
      basketStore.setIsPaymentGateway(data.available)
      const customerStore = useCustomerStore()
      const customer = await customerStore.customerGet()
      customerStore.setCustomer(customer)
      const styleStore = useStyleStore()
      styleStore.refresh({})
      basketStore.setSalesSectorType(customerStore.customer?.salesSectorType)


      this.setAccountSettings(await window.touch.getAccountSettings())
      this.setProcessingLevel()
      const dealerNetworkModeAvailable = await window.touch.isProductAvailable(
        window.enum.productType.DEALER,
      );
      this.setDealerNetworkModeAvailable(dealerNetworkModeAvailable.data.available)

      window.gtag('event', 'Login', {
        fabricator: styleStore.fabricator.name,
        company: response.companyName,
        installation: 'portal',
        email: username,
      });

      return true;
    },
    async attemptSuperUserLogin({ password, username, email }) {
      const response = await window.touch.authLoginSuperUser(username, password, email);

      if (response === false) {
        resetPinia(['basket', 'touch.isDealerNetworkPartnerOnly'])
        return false;
      }

      const property = window.VITE_APP_INSTALLATION_TYPE === 'business' ? 'staff' : 'customer';

      this.setAuth({ property, token: response.token, tokenExpiry: response.tokenExpiry });
      this.login({
        id: response.id,
        companyName: response.companyName,
        firstName: response.firstName,
        lastName: response.lastName,
        image: response.image,
        mobile: response.mobile,
        email: email,
        username,
        staffLogin: window.VITE_APP_INSTALLATION_TYPE === 'business',
        admin: response.admin,
        superuser: response.superuser,
      });

      const basketStore = useBasketStore()
      const { data } = await window.touch.isProductAvailable(28);
      basketStore.setIsPaymentGateway(data.available)

      const customerStore = useCustomerStore()
      if (window.VITE_APP_INSTALLATION_TYPE === 'business') {
        const customer = await customerStore.customerGet(await window.touch.staffGetOrganisationCustomerId())
        customerStore.setCustomer(customer)
        const user = await window.touch.customerGetContact(response.id);
        this.setBranches(user.branches)
      } else {
        const customer = await customerStore.customerGet()
        customerStore.setCustomer(customer)
        basketStore.setSalesSectorType(customerStore.customer?.salesSectorType)

      }
      const styleStore = useStyleStore()
      styleStore.refresh({})
      if (!customerStore.customer) {
        resetPinia(['basket', 'touch.isDealerNetworkPartnerOnly'])
        return false
      }
      this.setAccountSettings(await window.touch.getAccountSettings())
      this.setProcessingLevel()
      const dealerNetworkModeAvailable = await window.touch.isProductAvailable(
        window.enum.productType.DEALER,
      );
      this.setDealerNetworkModeAvailable(dealerNetworkModeAvailable.data.available)
      const branchManagerAvailable = await window.touch.isProductAvailable(
        window.enum.productType.BRANCHES,
      );
      this.setBranchManagerAvailable(branchManagerAvailable.data.available)

      window.gtag('event', 'Login', {
        fabricator: styleStore.fabricator.name,
        company: response.companyName,
        installation: window.VITE_APP_INSTALLATION_TYPE,
        email: username,
      });

      return true;
    },
    async attemptBusinessLogin({ password, username }) {
      const response = await window.touch.loginstaff(username, password);

      if (response === false) {
        resetPinia(['basket', 'touch.isDealerNetworkPartnerOnly'])
        return false;
      }
      window.logoutPush = false;

      this.setAuth({
        property: 'staff',
        token: response.token,
        tokenExpiry: response.tokenExpiry,
      });

      this.login({
        id: response.id,
        companyName: response.companyName,
        firstName: response.firstName,
        lastName: response.lastName,
        image: response.image,
        mobile: response.mobile,
        email: username,
        username,
        staffLogin: true,
        admin: response.admin,
      });

      const user = await window.touch.customerGetContact(response.id);
      this.setBranches(user.branches)


      const basketStore = useBasketStore()
      const { data } = await window.touch.isProductAvailable(28);
      basketStore.setIsPaymentGateway(data.available)
      const customerStore = useCustomerStore()
      const customer = await customerStore.customerGet(await window.touch.staffGetOrganisationCustomerId())
      customerStore.setCustomer(customer)
      const styleStore = useStyleStore()
      styleStore.refresh({})
      const isBranchManager = customerStore.customer.users.filter(
        (usr) => usr.id === response.id,
      )[0].branchCompanyId;
      this.setIsBranchManager(isBranchManager)

      this.setAccountSettings(await window.touch.getAccountSettings())
      this.setProcessingLevel()
      const dealerNetworkModeAvailable = await window.touch.isProductAvailable(
        window.enum.productType.DEALER,
      );

      this.setDealerNetworkModeAvailable(dealerNetworkModeAvailable.data.available)
      const branchManagerAvailable = await window.touch.isProductAvailable(
        window.enum.productType.BRANCHES,
      );
      this.setBranchManagerAvailable(branchManagerAvailable.data.available)

      window.gtag('event', 'Login', {
        fabricator: styleStore.fabricator.name,
        company: response.companyName,
        installation: 'admin',
        email: username,
      });

      return true;
    },
    async requestPasswordReset(username) {
      return window.touch.requestPasswordReset(username).catch((error) => {
        const resp = {
          error: true,
          data: error,
        };
        return resp;
      });
    },
    async resetPassword({ token, newPassword }) {
      return window.touch.resetPassword(token, newPassword);
    },
    async checkPasswordResetToken(token) {
      return (await window.touch.checkPasswordResetToken(token)).data;
    },
    async getCompanyWebSettings() {
      return window.touch.authGetCompanyWebSettings();
    },
    async updateCompanyWebSettings({ key, value }) {
      return (await window.touch.authUpdateCompanyWebSettings(key, value)).data;
    },
    async getOrganisationWebSetting(searchKey) {
      const ret = await window.touch.authGetOrganisationWebSetting(searchKey);
      return ret.companyWebSettings[0];
    },
    async isDesignerAvailable(ecommerceAccountAlias) {
      const resp = await window.touch.isDesignerAvailable(ecommerceAccountAlias);
      return resp;
    },
    async isProductAvailable(product) {
      return window.touch.isProductAvailable(product);
    },
  },
  getters: {
    user(state) {
      return {
        id: state.id,
        companyName: state.companyName,
        firstName: state.firstName,
        lastName: state.lastName,
        image: state.image,
        email: state.email,
        mobile: state.mobile,
        staffLogin: state.staffLogin,
      };
    },
  },
});