<template>
  <Teleport defer to="#portal_popup">
    <modal-window
      v-if="!loading"
      ref="modal"
      :modal_open="true"
      title="Edit Address"
      @close="$emit('close')"
    >
      <AddressForm
        class="mt-5"
        :address="address"
        :is-lat-lng-required="true"
        :is-save-button="true"
        @address-updated="addressUpdated"
        @save-address="saveAddress"
      />
    </modal-window>
  </Teleport>
</template>

<script>
import validate from 'validate.js';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import AddressForm from '@/components/shared/address/AddressForm.vue';

export default {
  components: {
    AddressForm,
  },
  props: ['addressId', 'customerId'],
  data() {
    return {
      customer: {},
      address: {},
      loading: true,
      manualAddressEntry: true,
      address_picked: false,
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
    formatted_address() {
      return [
        this.address.line1,
        this.address.line2,
        this.address.line3,
        this.address.town,
        this.address.county,
        this.address.code,
      ]
        .filter((val) => val)
        .join(', ');
    },
  },
  async mounted() {
    await this.loadAddress();
  },
  methods: {
    addressUpdated(newAddress) {
      this.$refs.modal.isEdited = true;
      this.address.line1 = newAddress.addressLine1;
      this.address.line2 = newAddress.addressLine2;
      this.address.line3 = newAddress.addressLine3;
      this.address.town = newAddress.addressTown;
      this.address.county = newAddress.addressCounty;
      this.address.code = newAddress.addressCode;
      this.address.iso = newAddress.addressIso;
      this.address.latitude = newAddress.latitude;
      this.address.longitude = newAddress.longitude;
      this.address.isPrimary = newAddress.isPrimary;
    },
    async loadAddress() {
      this.loading = true;
      this.loadCustomer().then(() => {
        const address = this.customer.addresses.filter((addr) => addr.id == this.addressId)[0];  
        this.address = address;
        this.loading = false;
      });
    },
    async loadCustomer() {
      this.customer = await this.customerStore.customerGet(this.customerId);
    },
    async saveAddress() {
      this.loading = true;
      const validationErrors = validate(
        {
          line1: this.address.line1,
          town: this.address.town,
          country: this.address.iso,
          postcode: this.address.code,
        },
        {
          line1: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          town: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          country: {
            presence: { allowEmpty: false },
            length: {
              maximum: 3,
            },
          },
          postcode: {
            presence: { allowEmpty: false },
            length: {
              maximum: 16,
            },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        this.loading = false;
        return;
      }

      let addresses = this.customer.addresses;  
      const foundIndex = addresses.findIndex((address) => address.id == this.address.id);  
      addresses[foundIndex] = this.address;
      addresses = addresses.filter(
        (address, index, self) => index === self.findIndex((t) => t.id === address.id),
      );
      this.customer.addresses = addresses;
      await this.customerStore.customerUpdateAddresses({ customer: this.customer });
      this.loading = false;
      this.$emit('updated');
      this.$emit('close');
    },
    pickedAddress(address) {
      this.address.line1 = address.address1;
      this.address.line2 = address.address2;
      this.address.line3 = address.address3;
      this.address.town = address.town;
      this.address.county = address.county;
      this.address.code = address.postcode;
      this.address.latitude = address.latitude;
      this.address.longitude = address.longitude;
      this.address.isPrimary = address.isPrimary;
      this.address_picked = true;
    },
    clearAddress() {
      this.address.line1 = '';
      this.address.line2 = '';
      this.address.line3 = '';
      this.address.town = '';
      this.address.county = '';
      this.address.code = '';
      this.address.isPrimary = false;
      this.address.latitude = undefined;
      this.address.longitude = undefined;
      this.address_picked = false;
    },
  },
};
</script>
