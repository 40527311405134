<template>
  <div>
    <create-staff v-if="add_staff_popup_open" @close="$emit('close-add-staff-popup')" @saved="loadStaff" />
    <div class="bg-white border m-3 xs:m-10 p-3">
      <table class="table-lined xs:table-unstacked _with-hover w-full">
        <thead>
          <tr>
            <th colspan="2"> Name </th>
            <th> Email </th>
            <th> Mobile </th>
            <th> Admin </th>
            <th>
              <FilterDropdown
                label="License"
                query-label="licenseFilter"
                :filters="[
                  { id: enums.licenseStatusType.DISABLED, name: 'Disabled', dot: 'bg-red-500' },
                  { id: enums.licenseStatusType.ENABLED, name: 'Enabled', dot: 'bg-green-500' },
                ]"
              />
            </th>
            <th>
              <FilterDropdown
                label="Status"
                query-label="statusFilter"
                :filters="[
                  { id: enums.customerStatus.LIVE, name: 'Live', dot: 'bg-green-500' },
                  { id: enums.customerStatus.ARCHIVED, name: 'Archived', dot: 'bg-red-500' },
                ]"
              />
            </th>
            <th />
          </tr>
        </thead>
        <tbody v-if="realStaff.length > 0">
          <tr
            v-for="(user, index) in realStaff"
            :key="index"
            :class="{ '_hovered': hovered === index }"
            @click.stop.prevent="editing_user = user.id"
            @mouseover="hovered = index"
            @mouseleave="hovered = null"
          >
            <td style="width: 60px;">
              <div
                class="rounded-full border flex flex-col justify-around overflow-hidden relative"
                style="width: 35px; height: 35px;"
              >
                <img
                  :id="'userImg_' + user.id"
                  :src="staffImageUrl(user.image)"
                  class="m-auto"
                  @error="imgError"
                  @load="positionAvatar"
                >
              </div>
            </td>
            <td>
              <div class="td-title w-24"> Name: </div>
              <div class="td-content">{{ user.full_name }}</div>
            </td>
            <td>
              <div class="td-title w-24"> Email: </div>
              <div class="td-content break-all">{{ user.email }}</div>
            </td>
            <td>
              <div v-if="user.mobile" class="td-title w-24"> Mobile: </div>
              <div class="td-content">{{ user.mobile }}</div>
            </td>
            <td>
              <div class="td-title w-24"> Admin: </div>
              <div class="td-content">
                <template v-if="user.isAdmin">
                  Yes
                </template>
                <template v-else>
                  No
                </template>
              </div>
            </td>
            <td>
              <div class="td-title w-24"> License: </div>
              <div class="td-content text-center">
                <div v-if="user.licenseStatusType === enums.licenseStatusType.ENABLED" class="bg-green-500 rounded text-white px-2 py-1">
                  Enabled
                </div>
                <div v-else class="bg-red-500 rounded text-white px-2 py-1">
                  Disabled
                </div>
              </div>
            </td>
            <td>
              <div class="td-title w-24"> Status: </div>
              <div class="td-content text-center">
                <div v-if="user.archived" class="bg-red-500 rounded text-white px-2 py-1">
                  Archived
                </div>
                <div v-else class="bg-green-500 rounded text-white px-2 py-1">
                  Live
                </div>
              </div>
            </td>
            <td class="td-action-buttons">
              <div
                v-if="touch_business_user || loggedInUser.isAdmin || loggedInUser.id === user.id"
                class="table-edit-btn"
              >
                <i class="fa fa-pencil" />
              </div>
              <edit-staff
                v-if="editing_user === user.id"
                :user_id="user.id"
                @close="editing_user = null"
                @updated="loadStaff"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="realStaff.length === 0" key="none-found" class="p-3 xs:p-10">
        <div class="flex justify-between items-center">
          <div>
            No Staff to show
          </div>
          <a v-if="$route.query.statusFilter || $route.query.licenseFilter" class="ml-auto btn-action" @click.stop="$router.replace({ query: undefined }).catch(() => { });">
            Clear Filters
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import EditStaff from '@/views/Business/ManageTouchAccount/Staff/Edit.vue';
import CreateStaff from '@/views/Business/ManageTouchAccount/Staff/Create.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';

export default {
  components: {
    EditStaff,
    CreateStaff,
    FilterDropdown
  },
  props: [
    'add_staff_popup_open',
  ],
  data() {
    return {
      staff: [],
      hovered: null,
      editing_user: null,
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
    realStaff() {
      const isLive = this.enums.customerStatus.LIVE === Number(this.$route.query.statusFilter);
      const filterArchived = (archived, statusFilter) => !statusFilter || (archived && !isLive || !archived && isLive)
      const filterLicenseStatus = (licenseStatusType, licenseFilter) => !licenseFilter || licenseStatusType === Number(licenseFilter)
      const filterSearch = (search, full_name, email) => !search || full_name.includes(search) || email.includes(search)
      return this.staff.filter(staff =>
        !staff.isGuest &&
        filterArchived(staff.archived, this.$route.query.statusFilter) &&
        filterLicenseStatus(staff.licenseStatusType, this.$route.query.licenseFilter) &&
        filterSearch(this.$route.query.search?.toLowerCase(), staff.full_name?.toLowerCase(), staff.email?.toLowerCase())
      );
    },
  },
  mounted() {
    this.loadStaff();
  },
  methods: {
    async loadStaff() {
      this.staff = await this.customerStore.customerListContacts();
      this.staff = this.staff.map(x => ({
        ...x,
        full_name: x.firstName + ' ' + x.lastName
      }))
      this.staff.sort((a, b) =>
        a.full_name.localeCompare(b.full_name, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      )
    },
    staffImageUrl(id) {
      return `${window.touch.imageBaseURL}${id}`;
    },
    imgError(event) {
      const element = document.getElementById(event.target.id);
      element.src = '/images/blank-user.png';
    },
  },
};
</script>