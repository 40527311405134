import { defineStore } from 'pinia';
import { useBasketStore } from '@/pinia/basket';
import _ from 'lodash';

export const useDesignerStore = defineStore('designer', {
  persist: true,
  state: () => ({
    selectedComponents: [],
    openMenuType: 'specification',
    clickedComponentId: undefined,
    clickedComponentType: undefined,
    clickedMemberId: undefined,
    currentSelectionType: undefined,
    openSpecificationGroup: undefined,
    showSelectionForHeadingId: undefined,
    openQuestion: undefined,
    isQuickTip: true,
    quickTipClosed: undefined, 
    backgroundColourList: [
      // https://sashamaps.net/docs/resources/20-colors/
      '#e6194B',
      '#3cb44b',
      '#4363d8',
      '#f58231',
      '#911eb4',
      '#42d4f4',
      '#f032e6',
      '#bfef45',
      '#fabed4',
      '#469990',
      '#dcbeff',
      '#9A6324',
      '#fffac8',
      '#800000',
      '#aaffc3',
      '#808000',
      '#ffd8b1',
      '#000075',
      '#a9a9a9',
    ],
  }),
  actions: {
    SET_OPEN_MENU_TYPE(type) {
      this.openMenuType = type;
    },
    CLEAR_SELECTION() {
      this.clickedMemberId = undefined;
      this.clickedComponentId = undefined;
      this.currentSelectionType = undefined;
      this.clickedComponentType = undefined;
      this.selectedComponents = [];
    },
    SET_CLICKED_COMPONENT_TYPE(type) {
      this.clickedComponentType = type;
    },
    SET_CLICKED_COMPONENT_ID(id) {
      this.clickedComponentId = id;
    },
    CLEAR_CLICKED_MEMBER_ID() {
      this.clickedMemberId = undefined;
    },
    CLEAR_CLICKED_COMPONENT_ID() {
      this.clickedComponentId = undefined;
    },
    CLEAR_CLICKED_COMPONENT_TYPE() {
      this.clickedComponentType = undefined;
    },
    SET_CLICKED_MEMBER_ID(id) {
      this.clickedMemberId = id;
    },
    TOGGLE_CLICKED_COMPONENT_IN_LIST(clickedComponent) {
      if (
        !this.selectedComponents.some(
          (selectedComponent) => selectedComponent.id === clickedComponent.id,
        )
      ) {
        this.selectedComponents.push(clickedComponent);
        return;
      }

      this.selectedComponents = this.selectedComponents.filter(
        (selectedComponent) => selectedComponent.id !== clickedComponent.id,
      );
    },
    SET_SELECTION_TYPE_COMPONENT() {
      this.currentSelectionType = 'component';
    },
    SET_SELECTION_TYPE_MEMBER() {
      this.currentSelectionType = 'member';
    },
    SET_SELECTION_TYPE_NONE() {
      this.currentSelectionType = undefined;
    },
    CLEAR_SELECTED_COMPONENTS() {
      this.selectedComponents = [];
    },
    clearSpecificationGroup() {
      const currentGroup = this.openSpecificationGroup;
      this.openSpecificationGroup = undefined;
      setTimeout(() => {
        const specificationGroupElement = document.querySelectorAll(
          `[data-specification-group-id="${currentGroup}"]`,
        )[0];
        if (specificationGroupElement) {
          document.getElementById('designer_sidebar_scrolling_container').scrollTop =
            specificationGroupElement.offsetTop;
        }
      }, 100);
    },
    setOpenSpecificationGroup(group) {
      this.openSpecificationGroup = group;
      setTimeout(() => {
        const specificationGroupElement = document.querySelectorAll(
          `[data-specification-group-id="${group}"]`,
        )[0];
        if (specificationGroupElement) {
          document.getElementById('designer_sidebar_scrolling_container').scrollTop =
            specificationGroupElement.offsetTop;
        }
      }, 100);
    },
    CLEAR_SHOW_SELECTION_FOR_HEADING_ID() {
      this.showSelectionForHeadingId = undefined;
    },
    toggleShowSelectionForHeadingId(headingId) {
      if (headingId === this.showSelectionForHeadingId) {
        this.showSelectionForHeadingId = undefined;
        return;
      }
      this.showSelectionForHeadingId = headingId;
    },
    clearOpenQuestion() {
      this.openQuestion = undefined;
    },
    setOpenQuestion(question) {
      const designerOuterContainer = document.getElementById(
        'designer_sidebar_scrolling_container',
      );
      designerOuterContainer.scrollTop = 0;
      this.openQuestion = question;
    },
    setIsQuickTip(isQuickTip) {
      this.isQuickTip = isQuickTip;
      if (isQuickTip){
        this.quickTipClosed = undefined
      } else {
        this.quickTipClosed = new Date()
      }
    },
    beginSelection({ clickedComponent }) {
      this.SET_SELECTION_TYPE_COMPONENT()
      this.CLEAR_SELECTED_COMPONENTS()
      this.TOGGLE_CLICKED_COMPONENT_IN_LIST(clickedComponent)
    },
    clickedInComponentMode({ clickedComponent, componentId, memberId }) {
      if (this.currentSelectionType === 'member') {
        if (memberId === this.clickedMemberId) {
          this.CLEAR_CLICKED_MEMBER_ID()
          this.CLEAR_CLICKED_COMPONENT_ID()
          this.CLEAR_CLICKED_COMPONENT_TYPE()
          this.SET_SELECTION_TYPE_NONE()
          return;
        }

        if (this.clickedComponentType === clickedComponent.typeId) {
          return;
        }
      }

      this.SET_SELECTION_TYPE_COMPONENT()

      if (clickedComponent.memberIds.length === 1) {
      this.SET_SELECTION_TYPE_MEMBER()
      this.CLEAR_SELECTED_COMPONENTS()
      }

      // We have clicked twice to drill to member level
      if (
        componentId === this.clickedComponentId &&
        memberId &&
        memberId === this.clickedMemberId &&
        clickedComponent.memberIds.length > 1
      ) {
        this.SET_SELECTION_TYPE_MEMBER()
        this.CLEAR_SELECTED_COMPONENTS()
        return;
      }

      // We clicked a component of the same type
      if (this.clickedComponentType === clickedComponent.typeId) {
        this.TOGGLE_CLICKED_COMPONENT_IN_LIST(clickedComponent)
        return;
      }

      // We clicked a component of a different type
      if (this.clickedComponentType !== clickedComponent.typeId) {
        this.CLEAR_SELECTED_COMPONENTS()
        this.TOGGLE_CLICKED_COMPONENT_IN_LIST(clickedComponent)
      }
    },
    async selectorClicked({ componentId, memberId }) {
      this.CLEAR_SHOW_SELECTION_FOR_HEADING_ID()
      if (!componentId) {
        window.alertBox.fire('No Component - Bad click???');
        return false;
      }

      const basketStore = useBasketStore()
      const clickedComponent = basketStore.currentItem.simpleComponents.find(
        (simpleComponent) => simpleComponent.id === componentId,
      );

      if (!clickedComponent) {
        window.alertBox.fire('Could not find component in simpleComponents list');
        return false;
      }

      clickedComponent.memberIds = clickedComponent.members
        .filter((member) => member.hasSpecification)
        .map((member) => member.id);

      if (!this.currentSelectionType) {
        await this.beginSelection({
          clickedComponent,
        });
      } else {
        await this.clickedInComponentMode({
          componentId,
          memberId,
          clickedComponent,
        });
      }

      this.SET_CLICKED_COMPONENT_ID(componentId)
      this.SET_CLICKED_MEMBER_ID(memberId)
      this.SET_CLICKED_COMPONENT_TYPE(clickedComponent.typeId)
      return true;
    },
  },
  getters: {
    getSetupURL() {
      return window.VITE_APP_INSTALLATION_TYPE === 'business' ? '/dealer-network-setup' : '/setup';
    },
    listOfUnansweredForcedQuestions() {
      const basketStore = useBasketStore()
      const currentItem = basketStore.currentItem;

      if (!currentItem) {
        return [];
      }

      return currentItem.specification.filter(
        (spec) => spec.mustBeSelected && !spec.hasBeenSelected,
      );
    },
    optionHeadingForOpenQuestion(state) {
      return state.openQuestion;
    },
    idForUnansweredQuestion() {
      if (!this.itemHasUnansweredForcedQuestions) {
        return undefined;
      }

      const basketStore = useBasketStore()
      const currentItem = basketStore.currentItem;

      return currentItem.specification.find((spec) => spec.mustBeSelected && !spec.hasBeenSelected)
        .optionHeadingId;
    },
    itemHasUnansweredForcedQuestions() {
      const basketStore = useBasketStore()
      const currentItem = basketStore.currentItem;

      if (!currentItem) {
        return false;
      }

      return currentItem.specification.some((spec) => spec.mustBeSelected && !spec.hasBeenSelected);
    },
    specificationGroups() {
      const basketStore = useBasketStore()
      if (!basketStore.currentItem) {
        return false;
      }

      let spec = basketStore.currentItem.specification.map((specRow) => ({
        ...specRow,
        optionGroupName:
          specRow.optionGroupName === 'There is no group' ? specRow.name : specRow.optionGroupName,
        optionGroupOrder:
          specRow.optionGroupName === 'There is no group' ? Infinity : specRow.optionGroupOrder,
      }));

      if (this.somethingIsSelected) {
        spec = spec.filter((specification) => {
          if (this.interactiveHighlightState.currentSelectionType === 'member') {
            return specification.members.some(
              (member) => member.id === this.interactiveHighlightState.clickedMemberId,
            );
          }
          if (this.interactiveHighlightState.currentSelectionType === 'component') {
            return specification.components.some((component) =>
              this.interactiveHighlightState.selectedComponents.some(
                (selectedComponent) => selectedComponent.id === component.id,
              ),
            );
          }

          return false;
        });

        if (this.moreThanOneThingIsSelected) {
          spec = spec.filter(
            (specification) =>
              specification.visibilityTypeId !==
              window.enum.visibilityType.ONLY_FOR_DESIGN_SELECTION,
          );
        }
      } else {
        spec = spec.filter(
          (specification) =>
            specification.visibilityTypeId === window.enum.visibilityType.STANDARD &&
            specification.name !== 'Component Type' &&
            specification.name !== 'Sash Type',
        );
      }

      const specGroups = spec.reduce((obj, specItem) => {
        const groupName = specItem.optionGroupName;
         
        if (!obj.hasOwnProperty(groupName)) {
           
          obj[groupName] = [];
        }

        obj[groupName].push(specItem);

        return obj;
      }, {});

      const sortable = [];
      Object.keys(specGroups).forEach((specGroup) => {
        if (Object.prototype.hasOwnProperty.call(specGroups, specGroup)) {
          sortable.push([specGroup, specGroups[specGroup]]);
        }
      });

      const sortedQuestions = (specGroup) =>
        specGroup.slice().sort((a, b) => a.listOrder - b.listOrder);

      const sortedGroups = sortable.sort((a, b) => {
        const sortedQuestionsA = sortedQuestions(a[1]);
        const sortedQuestionsB = sortedQuestions(b[1]);
        if (sortedQuestionsA[0].optionGroupOrder < sortedQuestionsB[0].optionGroupOrder) {
          return -1;
        }
        if (sortedQuestionsA[0].optionGroupOrder > sortedQuestionsB[0].optionGroupOrder) {
          return 1;
        }
        return 0;
      });
      const result = sortedGroups.map((specification) => [
        specification[0],
        _.groupBy(sortedQuestions(specification[1]), 'optionHeadingId'),
      ]);
      return result.sort(
        (a, b) =>
          Object.values(a[1])[0][0].optionGroupOrder - Object.values(b[1])[0][0].optionGroupOrder,
      );
    },
    selectionForHeadingState(state) {
      if (state.showSelectionForHeadingId) {
        return this.specificationGroups
          .filter((group) => group[1][state.showSelectionForHeadingId] !== undefined)
          .flat()[1][state.showSelectionForHeadingId].map((selectionGroup, index) => ({
            componentIds:
              selectionGroup.members.length === 0
                ? selectionGroup.components.map((component) => component.id)
                : [],
            memberIds: selectionGroup.members.map((member) => member.id), // array of member ids or empty for component mode
            colour: state.backgroundColourList[index], // hex code for colour,
          }));
      }

      return [];
    },
    somethingIsSelected(state) {
      return (
        state.selectedComponents.length > 0 ||
        (state.currentSelectionType === 'member' && state.clickedMemberId)
      );
    },
    moreThanOneThingIsSelected(state) {
      return state.selectedComponents.length > 1;
    },
    interactiveHighlightState(state) {
      return {
        currentSelectionType: state.currentSelectionType,
        clickedMemberId: state.clickedMemberId,
        selectedComponents: state.selectedComponents,
      };
    },
    questionIsOpen(state) {
      return state.openQuestion !== undefined;
    },
  },
});
