<template>
  <div class="border border-gray-300 p-6 rounded-lg">
    <div class="xs:text-base mb-3">Product Selection</div>
    <div class="flex flex-col">
      <div
        v-for="stockPartCategory in stockPartsStore.stockPartCategories"
        :key="stockPartCategory.id"
        :style="`order: ${stockPartCategory.listIndex}`"
        :class="{ 'my-1': stockPartsStore.openStockPartCategoryId === stockPartCategory.id }"
        class="border mb-3 shadow rounded cursor-pointer"
        @click="openStockPartCategory = stockPartCategory"
      >
        <div class="p-3 text-base w-full">
          {{ stockPartCategory.description }}
        </div>
        <div v-if="stockPartsStore.openStockPartCategoryId === stockPartCategory.id">
          <div class="flex flex-col p-3 pt-0">
            <div
              v-for="stock_part_subcategory in stockPartsStore.stockPartSubcategoriesForOpenCategory"
              :key="stock_part_subcategory.id"
              :style="`order: ${stock_part_subcategory.listIndex}`"
              @click="openStockPartSubcategory = stock_part_subcategory"
            >
              <div
                class="mt-1 flex justify-between bg-gray-200 rounded-lg p-2 cursor-pointer group hover:bg-green-600 hover:text-white text-xs sm:text-sm"
                :class="{
                  'bg-green-600 text-white':
                    stockPartsStore.openStockPartSubcategoryId === stock_part_subcategory.id,
                }"
              >
                <div class="pr-6">{{ stock_part_subcategory.description }}</div>
                <div
                  v-if="stockPartsStore.openStockPartSubcategoryId === stock_part_subcategory.id"
                  class="flex flex-col justify-around pl-2"
                >
                  <i class="fa fa-caret-right opacity-0 group-hover:opacity-100 opacity-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useStockPartsStore } from '@/pinia/stock-parts';

export default {
  computed: {
    ...mapStores(useStockPartsStore),
    openStockPartSubcategory: {
      get() {
        return this.stockPartsStore.openStockPartSubcategory;
      },
      set(subcategory) {
        this.stockPartsStore.setOpenStockPartSubcategory(subcategory)
      },
    },
    openStockPartCategory: {
      get() {
        return this.stockPartsStore.openStockPartCategory;
      },
      set(category) {
        this.stockPartsStore.setOpenStockPartCategory(category)
      },
    },
  },
  watch: {
    'stockPartsStore.stockPartCategories': {
      handler() {
        this.stockPartsStore.selectValidCategory()
      },
      deep: true,
      immediate: true,
    },
    'stockPartsStore.stockPartSubcategoriesForOpenCategory': {
      handler() {
        this.stockPartsStore.selectValidSubcategory()
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.selected::before {
  overflow: visible;
  content: "";
  width: 0;
  height: 0;
  right: -1.1em;
  top: -0.375em;
  position: absolute;
  border-top: 1.2rem solid transparent;
  border-bottom: 1.2rem solid transparent;
  border-left: 1.2rem solid #239658;
}

.selected {
  color: white;
  overflow: visible;
  margin-right: -0.3rem;
  position: relative;
  background-color: #239658;
}

.selected span {
  color: white;
}
</style>

<style scoped></style>
