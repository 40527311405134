
 
export function newOrders() {
  return new Promise((resolve) => setTimeout(() => resolve([]), 250 + Math.random() * 1000));
}

 
export function newTickets() {
  return new Promise((resolve) => setTimeout(() => resolve([]), 250 + Math.random() * 1000));
}
