import { defineStore } from 'pinia';
import { useCustomerStore } from '@/pinia/customer';

export const useBrandedStore = defineStore('branded', {
  persist: true,
  state: () => ({
    isMeasurementsOn: false,
    slug: null,
    hideIntroPopUp: true,
    dateSinceStoreCleared: new Date(),
    showDesignNames: undefined,
    brandedSettings: undefined
  }),
  actions: {
    setShowDesignNames (showDesignNames) {
      this.showDesignNames = showDesignNames;
    },
    setHideIntroPopUp(hideIntroPopUp) {
      this.hideIntroPopUp = hideIntroPopUp;
    },
    setSlugAndKey({ slug }) {
      this.slug = slug;
    },
    setIsMeasurementsOn(isMeasurementsOn) {
      this.isMeasurementsOn = isMeasurementsOn;
    },
    setBrandedSettings(brandedSettings) {
      this.brandedSettings = brandedSettings;
    },
    async getAnomalies() {
      const customerStore = useCustomerStore();
      return window.touch.getAnomalies(customerStore.customer.datasetIds[0]);
    },
    async updateAnomalies(anomalies) {
      const customerStore = useCustomerStore();
      const Anomalies = anomalies.map((anom) => ({
        Id: anom.id,
        Value: Number(anom.overrideValue),
      }));
      return window.touch.updateAnomalies(
        customerStore.customer.datasetIds[0],
        Anomalies,
      );
    },
  },
  getters: {
    ready(state) {
      return state.slug !== null;
    },
    launchURL(state) {
      return `/launch/${state.slug}`;
    },
    thanksURL(state) {
      return `/${state.slug}/thanks`;
    },
    brandedItemLink(state) {
      return `/${state.slug}/design/:id`;
    },
    chooseProductURL(state) {
      return `/${state.slug}/choose-product`;
    },
    basketURL(state) {
      return `/${state.slug}/basket`;
    },
    branded_mode_settings(state) {
      try {
        let webAddress = state.brandedSettings.webAddress;

        if (webAddress && !webAddress.includes('//')) {
          webAddress = `http://${webAddress}`;
        }

        return {
          company_name: state.brandedSettings.companyName,
          phone: state.brandedSettings.mobilePhone,
          email: state.brandedSettings.email,
          webAddress,
          pricingDisplayMode: state.brandedSettings.priceDisplayMode,
          privacyPolicyDocumentId: state.brandedSettings.privacyPolicyDocumentId,
          restart_url: `/launch/${state.brandedSettings.alias}`,
        };
      } catch (error) {
        return false;
      }
    },
  },
})
