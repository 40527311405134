<template>
  <div>
    <div class="text-brand-primary hidden sm:block">
      <svg
        id="loginbackgroundshape"
        class="absolute top-0 right-0 -z-2 fill-current"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 640 640"
        height="100%"
      >
        <path
          id="b56U3ujvg"
          d="M640 0L0 0C-0.06 18.77 -0.06 35.84 0 51.21C0.06
      66.58 9.46 81.42 28.2 95.74L640 469.79L640 0Z"
        />
      </svg>
    </div>
    <div class="text-white hidden sm:block">
      <svg
        class="absolute top-0 right-0 -z-1 opacity-1 fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -200 1996 1268"
        width="40%"
      >
        <path
          d="M205.5 490C91.86 490 0 398.14 0 284.5C0 170.86 91.86 79 205.5
      79C319.14 79 411 170.86 411 284.5C411 398.14 319.14 490 205.5 490ZM608
      167C580.35 167 558 144.65 558 117C558 89.35 580.35 67 608 67C635.65 67
      658 89.35 658 117C658 144.65 635.65 167 608 167ZM815 172C767.44 172 729
      133.56 729 86C729 38.44 767.44 0 815 0C862.56 0 901 38.44 901 86C901
      133.56 862.56 172 815 172ZM1849 439C1786.51 439 1736 388.49 1736 326C1736
      263.51 1786.51 213 1849 213C1911.49 213 1962 263.51 1962 326C1962 388.49
      1911.49 439 1849 439ZM1779.5 1068C1659.78 1068 1563 971.22 1563 851.5C1563
      731.78 1659.78 635 1779.5 635C1899.22 635 1996 731.78 1996 851.5C1996 971.22
      1899.22 1068 1779.5 1068Z"
        />
      </svg>
    </div>
    <header class="bg-white w-full sm:border-none">
      <a :href="$t(store_url)" style="width: 285px">
        <img :src="style.fabricator_logo" class="h-full mx-auto">
      </a>
      <div v-if="style.back_to_site_link" class="flex flex-col">
        <a
          :href="style.back_to_site_link"
          class="bg-white rounded-full hover:bg-gray-200 my-auto mx-3 sm:py-2 sm:pr-6 sm:pl-5 sm:m-5 sm:font-medium"
        >
          <i class="fal fa-browser mr-3" /> <span>Back to site</span>
        </a>
      </div>
    </header>

    <div class="flex flex-col h-full bg-gray-200 sm:bg-transparent">
      <div class="mx-auto w-full flex justify-around" style="max-width: 1600px">
        <div class="login-form-wrapper">
          <transition name="fade-in" appear mode="out-in">
            <div v-if="token">
              <div class="text-center mb-4 bg-white py-4 sm:hidden">
                <div class="text-xl sm:text-4xl mb-2 sm:font-medium">Reset your password</div>
                <div class="sm:text-xl">Enter a new password.</div>
              </div>

              <div class="login-form">
                <div class="text-center mb-4 bg-white py-0 hidden sm:block">
                  <div class="text-4xl mb-2 font-medium">Reset your password</div>
                  <div class="text-xl">Enter a new password.</div>
                </div>

                <ErrorBox v-if="login_error" :message="login_error" />

                <div class="mb-4">
                  <label for="newPassword" class="block w-full">New Password</label>
                  <input
                    id="newPassword"
                    v-model="newPassword"
                    type="password"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                    autocomplete="newPassword"
                  >
                </div>

                <div class="mb-4">
                  <label for="newPasswordVerify" class="block w-full">Confirm New Password</label>
                  <input
                    id="newPasswordVerify"
                    v-model="newPasswordVerify"
                    type="password"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                    autocomplete="newPasswordVerify"
                  >
                </div>

                <div class="mb-4 text-right">
                  <router-link
                    to="/"
                    class="text-gray-600 text-xs hover:text-gray-800 cursor-pointer"
                  >
                    Return to login
                  </router-link>
                </div>

                <div class="btn-primary btn-lg w-full" @click.prevent.stop="setNewPassword()">
                  Reset Password
                </div>

                <div class="text-center mt-2">
                  <a
                    :href="$t(store_url)"
                    target="_blank"
                    class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
                  >
                    <div class="p-1">
                      <img src="../../assets/images/touch-logo.svg" width="75px">
                    </div>
                    <div class="">
                      <template v-if="touch_portal_installation">
                        <img src="/images/touch-portal-icon.png" style="max-height: 27px">
                      </template>
                    </div>
                    <div class="p-1 text-xs">By BM Group</div>
                  </a>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import ErrorBox from '@/components/shared/Error.vue';

export default {
  components: {
    ErrorBox
  },
  data() {
    return {
      login_error: false,
      email: null,
      newPassword: null,
      newPasswordVerify: null,
      token: this.$route.query.token,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  async mounted() {
    await this.checkTokenIsValid();
  },
  methods: {
    async checkTokenIsValid() {
      return this.authStore.checkPasswordResetToken(this.token).then((resp) => {
        if (!resp.valid) {
          this.alertBox().fire({
            title: 'Invalid Token',
            icon: 'error',
            html: `<div>${resp.message}</div>`,
          });
          this.routerPush('/login');
          return;
        }
        this.email = resp.email;
      });
    },
    async setNewPassword() {
      this.login_error = false;
      const { token, newPassword, newPasswordVerify } = this;
      if (newPassword !== newPasswordVerify) {
        this.login_error = 'Passwords do not match';
        return false;
      }
      if (!this.email || !this.newPassword || !this.newPasswordVerify) {
        this.login_error = 'All fields are required';
        return false;
      }

      return this.authStore.resetPassword({ token, newPassword })
        .then(() => {
          this.attemptLogin().then((loggedIn) => {
            if (loggedIn) {
              this.login_error = false;
              this.routerPush(this.$route.query.redirect ?? '/');
              return;
            }

            this.login_error = 'Unable to login, Please check your username and try again';
          });
        })
        .catch((error) => {
          this.login_error = error.data.message;
        });
    },
    attemptLogin() {
      const details = {
        username: this.email,
        password: this.newPassword,
      };
      if (this.touch_portal_installation) {
        return this.authStore.attemptPortalLogin(details);
      }
      return this.authStore.attemptBusinessLogin(details);
    },
  },
};
</script>
