<template>
  <div
    class="bg-white p-5 rounded-lg gap-x-5 xs:grid xs:grid-cols-2 lg:grid-cols-3 lg:gap-5 lg:auto-rows-fr"
  >
    <div class="p-5 col-span-2 lg:col-span-1">
      <div class="mb-2">
        <span>Hi {{ firstName }}...</span>
      </div>
      <div>
        <span class="text-xl">Welcome to your {{ companyName }} Portal</span>
      </div>
    </div>
    <a
      class="bg-red-200 rounded-lg flex items-end p-4"
      href="https://bmgroupholdingslimited.freshdesk.com/support/solutions/101000252285"
      target="_blank"
    >
      <div class="flex-1">
        <div>
          <span class="text-xl">Help Guides</span>
        </div>
        <div>
          <span>TOUCH Support Documents</span>
        </div>
      </div>
      <div class="flex-1">
        <img class="w-full" src="/images/illustrations-help-guide.png">
      </div>
    </a>
    <router-link class="bg-gray-200 rounded-lg flex items-end p-4 mt-4 xs:mt-0" to="/marketing">
      <div class="flex-1">
        <div>
          <span class="text-xl">Resources</span>
        </div>
        <div>
          <span>Brochures & Guides</span>
        </div>
      </div>
      <div class="flex-1">
        <img class="w-full" src="/images/illustrations-resources.png">
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    firstName: String,
    companyName: String,
  },
};
</script>
