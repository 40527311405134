import { useAuthStore } from '@/pinia/auth';
import { useBasketStore } from '@/pinia/basket';
import { useBrandedStore } from '@/pinia/branded';
import { useCustomerStore } from '@/pinia/customer';
import { useDesignerStore } from '@/pinia/designer';
import { useFeatureStore } from '@/pinia/feature';
import { useKnowledgeBaseStore } from '@/pinia/knowledge-base';
import { useSandboxStore } from '@/pinia/sandbox';
import { useStyleStore } from '@/pinia/style';
import { useStockPartsStore } from '@/pinia/stock-parts';
import { useTouchStore } from '@/pinia/touch';
import { useVisualiserStore } from '@/pinia/visualiser';

export function resetPinia(persistedKeys = []) {
  let persistedData = {}
  persistedKeys = persistedKeys.concat(['style', 'feature','designer.isQuickTip', 'auth.login_url'])

  const stores = [
    { name: 'auth', data: useAuthStore() },
    { name: 'basket', data: useBasketStore() },
    { name: 'branded', data: useBrandedStore() },
    { name: 'customer', data: useCustomerStore() },
    { name: 'designer', data: useDesignerStore() },
    { name: 'feature', data: useFeatureStore() },
    { name: 'knowledgeBase', data: useKnowledgeBaseStore() },
    { name: 'sandbox', data: useSandboxStore() },
    { name: 'style', data: useStyleStore() },
    { name: 'stockParts', data: useStockPartsStore() },
    { name: 'touch', data: useTouchStore() },
    { name: 'visualiser', data: useVisualiserStore() },
  ]

  persistedKeys.forEach(persistedKey => {
    const persistedStoreKey = persistedKey.split('.')[0];
    const persistedStateKey = persistedKey.split('.')[1];
    const store = stores.find(x => x.name === persistedStoreKey).data
    if (persistedStateKey) {
      if (!persistedData[persistedStoreKey]) {
        persistedData[persistedStoreKey] = {}
      }
      persistedData[persistedStoreKey][persistedStateKey] = store[persistedStateKey];
    } else {
      persistedData[persistedStoreKey] = JSON.parse(JSON.stringify(store.$state))
    }
  });
  stores.forEach(store => {
    store.data.$reset()
    store.data.$patch(persistedData[store.name])
  })
}
