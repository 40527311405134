<template>
  <div class="w-full border-t">
    <div class="bg-white overflow-x-auto pl-3 md:pl-10">
      <div class="flex bg-white gap-10 border-t-4 border-white">
        <router-link
          v-if="!isLeadGen"
          to="/product-setup/pricing"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': $route.meta.active === 'pricing',
            'border-white': $route.meta.active !== 'pricing',
          }"
        >
          Pricing
        </router-link>
        <router-link
          v-if="!isLeadGen"
          to="/product-setup/pricing/financial-categories"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': $route.meta.active === 'financial-categories',
            'border-white': $route.meta.active !== 'financial-categories',
          }"
        >
          Financial Categories
        </router-link>
        <router-link
          v-if="!isLeadGen"
          to="/product-setup/pricing/discounts"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': $route.meta.active === 'discounts',
            'border-white': $route.meta.active !== 'discounts',
          }"
        >
          Discounts
        </router-link>
        <div class="pr-5" />
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useStyleStore } from '@/pinia/style';

export default {
  computed: {
    ...mapStores(useStyleStore),
    isLeadGen() {
      return (
        this.styleStore.fabricator?.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
  },
};

</script>
