<template>
  <transition-group
    appear
    name="staggered-fade"
    tag="div"
    :start-delay="startDelay"
    :css="false"
    @before-enter="staggeredFadeBeforeEnter"
    @enter="staggeredFadeEnter($event, startDelay)"
    @leave="staggeredFadeLeave($event, startDelay)"
  >
    <slot />
  </transition-group>
</template>

<script>
import { staggeredFadeBeforeEnter, staggeredFadeEnter, staggeredFadeLeave } from '@/composables/animation';

export default {
  props: {
    startDelay: {
      default: 0,
      type: Number,
    },
  },
  setup() {
    return { staggeredFadeBeforeEnter, staggeredFadeEnter, staggeredFadeLeave }
  },
};
</script>
