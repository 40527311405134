<template>
  <div class="sm:py-2">
    <div class="sm:hidden">
      <div class="border-solid border-t border-gray-800" />
      <div class="p-2">
        <MenuButton icon="fa-home" label="Home" url="/" :active="'home'" />
      </div>
      <div class="border-solid border-t border-gray-800" />
    </div>
    <div :class="{ 'p-2': featureStore.isCreateQuoteEnabled || featureStore.isCreateOrderEnabled }">
      <MenuButton
        v-if="featureStore.isCreateQuoteEnabled"
        icon="fa-shopping-basket"
        label="Create Quote"
        url="/new-quote"
        :active="'designer'"
      />
      <MenuButton
        v-if="featureStore.isCreateOrderEnabled"
        icon="fa-calculator"
        label="Create Order"
        url="/new-order"
        :active="'designer'"
      />
    </div>
    <div v-if="featureStore.isCreateOrderEnabled || featureStore.isCreateQuoteEnabled" class="border-solid border-t border-gray-800 my-2" />
    <div class="p-2">
      <router-link to="/enquiries">
        <div
          class="flex items-center hover:bg-blue-700"
          :class="{ 'bg-blue-700': $route.meta.active === 'enquiries' }"
        >
          <div class="p-1">
            <div class="rounded-full h-5 w-5 border-solid border-2 border-yellow-500 mr-4" />
          </div>
          <span class="text-white">View Enquiries</span>
        </div>
      </router-link>
      <router-link v-if="featureStore.isViewQuotesEnabled" to="/quotes">
        <div
          class="flex items-center hover:bg-blue-700"
          :class="{ 'bg-blue-700': $route.meta.active === 'quotes' }"
        >
          <div class="p-1">
            <div class="rounded-full h-5 w-5 border-solid border-2 border-orange-500 mr-4" />
          </div>
          <span class="text-white">View Quotes</span>
        </div>
      </router-link>
      <router-link v-if="featureStore.isViewOrdersEnabled" to="/orders">
        <div
          class="flex items-center hover:bg-blue-700"
          :class="{ 'bg-blue-700': $route.meta.active === 'orders' }"
        >
          <div class="p-1">
            <div class="rounded-full h-5 w-5 border-solid border-2 border-green-500 mr-4" />
          </div>
          <span class="text-white">View Orders</span>
        </div>
      </router-link>
    </div>
    <div class="border-solid border-t border-gray-800 my-2" />
    <div class="p-2">
      <router-link v-if="branded_mode_enabled" to="/setup">
        <div
          class="flex items-center hover:bg-blue-700"
          :class="{ 'bg-blue-700': $route.meta.active === 'branded-mode' }"
        >
          <div class="p-1 mr-4">
            <i class="fa-thin fa fa-fw fa-desktop text-white" />
          </div>
          <span class="text-white mr-2">Branded Mode</span>
          <span class="text-white bg-purple-500 py-0.5 px-1 rounded-sm text-xs">New</span>
        </div>
      </router-link>
      <MenuButton
        icon="fa-book-open"
        label="Resource Centre"
        url="/marketing"
        :active="'marketing'"
      />
      <button
        v-if="styleStore.fabricator.name === 'Selecta Systems Ltd'"
        class="w-full" 
        @click="knowledgeBaseStore.generateLinkWeb2Print({ jwt_token: authStore.authToken['customer'] })"
      >
        <div
          class="flex items-center hover:bg-blue-700"
          :class="{ 'bg-blue-700': $route.meta.active === 'branded-mode' }"
        >
          <div class="p-1 mr-4">
            <i class="fa-light fa fa-fw fa-print text-white" />
          </div>
          <span class="text-white mr-2">Web2Print</span>
          <span class="text-white bg-purple-500 py-0.5 px-1 rounded-sm text-xs">New</span>
        </div>
      </button>
      <MenuButton icon="fa-megaphone" label="News" url="/news" :active="'news'" />
    </div>
    <div class="border-solid border-t border-gray-800 my-2" />
    <div class="p-2">
      <MenuButton
        icon="fa-store"
        label="TOUCH Store"
        url="/touch-store"
        :active="'touch-store'"
      />
      <MenuButton
        icon="fa-square-question"
        label="Knowledge Base"
        :external_link="true"
        url="https://bmgroupholdingslimited.freshdesk.com/support/solutions/101000252285"
        :active="''"
      />
      <MenuButton
        icon="fa-window"
        label="Quick Support Pop-up"
        url="?hideIntroPopUp=true"
        :active="''"
      />
    </div>
    <router-link to="/my-account" class="sm:hidden">
      <div class="text-center p-3">
        <div class="btn-action">My Account</div>
      </div>
    </router-link>
    <SupportPopUp :company-name="authStore.companyName" />
  </div>
</template>

<script>
import SupportPopUp from '@/components/shared/nav/SupportPopUp.vue';
import MenuButton from '@/components/shared/nav/MenuButton.vue';
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useFeatureStore } from '@/pinia/feature';
import { useStyleStore } from '@/pinia/style';
import { useKnowledgeBaseStore } from '@/pinia/knowledge-base';

export default {
  name: 'PortalMenuItems',
  components: {
    MenuButton,
    SupportPopUp,
  },
  computed: {
    ...mapStores(useAuthStore, useFeatureStore, useKnowledgeBaseStore, useStyleStore),
  },
};
</script>
