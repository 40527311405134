<template>
  <Teleport defer to="#portal_popup">
    <modal-window :modal_open="true" label="Saving" :loading="saving" :title="'Quote your customer'" @close="$emit('close')">
      <div class="mb-3">Create your customer quote by adding your sell price below</div>
      <div v-if="can_be_item_level_requote" class="flex mb-3 justify-end">
        <div
          :class="{ 'triangle-after': requoter_type === 'items' }"
          @click.prevent="requoter_type = 'items'"
        >
          <div
            class="cursor-pointer p-3 border-r border-l border-t"
            :class="{
              'text-gray-600 bg-transparent border-b': requoter_type !== 'items',
              'bg-white shadow-tab': requoter_type === 'items',
            }"
          >
            <div class="flex flex-col justify-around text-center w-full">Individual Items</div>
          </div>
        </div>
        <div
          :class="{ 'triangle-after': requoter_type === 'total' }"
          @click.prevent="requoter_type = 'total'"
        >
          <div
            class="cursor-pointer p-3 border-r border-t"
            :class="{
              'text-gray-600 bg-transparent border-b': requoter_type !== 'total',
              'bg-white shadow-tab': requoter_type === 'total',
            }"
          >
            <div class="flex flex-col justify-around text-center w-full">Total Price</div>
          </div>
        </div>
      </div>
      <table class="table-lined 2xs:table-unstacked w-full">
        <thead>
          <tr>
            <th>Item</th>
            <th v-text="'Your Net Cost'" />
            <th v-text="'Your selling price (exc VAT)'" />
            <th v-show="requoter_type === 'total'">Margin On Products</th>
            <th v-show="requoter_type === 'items'">Margin</th>
          </tr>
        </thead>
        <tbody v-if="requoter_type === 'items'">
          <tr v-for="line_item in item_override_prices" :key="line_item.itemKey">
            <td>
              <div class="td-title w-32">Item:</div>
              <div class="td-content">{{ line_item.quantity }}x {{ line_item.description }}</div>
            </td>
            <td v-html="formatCurrency(job.customerCurrency, line_item.original_price)" />
            <td>
              <div class="td-title w-32" v-text="'Your Net Cost:'" />
              <div class="td-content">
                <div class="flex relative flex-grow xs:w-48">
                  <div class="absolute py-2 px-3 border-r border-black h-full">
                    {{ currencySymbol(job.customerCurrency) }}
                  </div>
                  <input
                    v-model="line_item.price"
                    type="number"
                    min="0"
                    class="form-control pl-16"
                    :readonly="requoter_type != 'items'"
                    :class="{ 'bg-gray-300': requoter_type != 'items' }"
                  >
                </div>
              </div>
            </td>
            <td>
              <div class="td-title w-32" v-text="'Your selling price (exc VAT):'" />
              <div
                class="td-content"
                v-html="formatCurrency(job.customerCurrency, line_item.price - line_item.original_price)"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-if="requoter_type === 'total'">
          <tr>
            <td>
              <div class="td-title w-32">Item:</div>
              <div class="td-content">Item(s) Price</div>
            </td>
            <td>
              <div class="td-title w-32" v-text="'Your Net Cost:'" />
              <div
                class="td-content"
                v-html="formatCurrency(job.customerCurrency, job_item_price)"
              />
            </td>
            <td>
              <div class="td-title w-32" v-text="'Your selling price (exc VAT):'" />
              <div class="td-content">
                <div class="flex relative flex-grow xs:w-48">
                  <div class="absolute py-2 px-3 border-r border-black h-full">
                    {{ currencySymbol(job.customerCurrency) }}
                  </div>
                  <input
                    v-model="job_override_item_price"
                    type="number"
                    min="0"
                    class="form-control pl-16"
                    :readonly="requoter_type != 'total'"
                    :class="{ 'bg-gray-300': requoter_type != 'total' }"
                  >
                </div>
              </div>
            </td>
            <td>
              <div class="td-title w-32">Margin</div>
              <div
                class="td-content"
                v-html="formatCurrency(job.customerCurrency, job_override_item_price - job_item_price)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveRequote()">Save</button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import { formatCurrency, currencySymbol } from '@/composables/currency';
import { setJob } from '@/composables/job';

export default {
  props: ['job', 'markup'],
  setup() {
    return { formatCurrency, currencySymbol }
  },
  data() {
    const job = setJob(this.job);

    const sharedInfo = {
      requoter_type: job.wasRequotedAtJobLevel() ? 'total' : 'items',
      saving: false,
    };

    return {
      job_item_price: job.priceBeforeTax,
      job_override_item_price: this.job.priceBeforeTax,
      job_fit_price: job.isFit() ? job.fittingPrice : 0,
      item_override_prices: this.job.items.map((item) => ({
        isExtra: item.inputType === window.enum.inputType.CUSTOMER,
        itemKey: item.key,
        fittingItem: item.fittingItem,
        description: item.description,
        original_price: item.calculatedPrice,
        quantity: item.quantity,
        price: item.overridePrice,
      })),
      ...sharedInfo,
    };
  },
  computed: {
    ...mapStores(useTouchStore),
    can_be_item_level_requote() {
      return false;
    },
  },
  methods: {
    saveTotalOverride() {
      this.saving = true
      this.touchStore.saveJobTotalOverride({
        contractId: this.job.contractId,
        jobId: this.job.jobKey,
        overridePrice: this.job_override_item_price,
        fitPrice: this.job_override_fit_price,
      })
        .then(() => {
          this.saving = false
          this.$emit('saved')
          this.$emit('close')
        })
    },
    async saveItemOverride() {
      this.touchStore.saveItemOverride({
        contractId: this.job.contractId,
        jobId: this.job.jobKey,
        overridePrices: this.item_override_prices,
      })
        .then(() =>
          this.touchStore.saveContract({
            contractId: this.job.contractId,
          }),
        )
        .then(() => {
          this.$emit('saved');
        })
        .catch((error) => {
          if (error.message) {
            this.alertBox().fire({
              title: 'Unable to save',
              text: error.message,
            });
            this.$emit('close');
            return;
          }

          throw new Error(error);
        });
    },
    async saveRequote() {
      if (this.requoter_type === 'total') {
        this.saveTotalOverride();
      } else {
        this.saveItemOverride();
      }
    },
  },
};
</script>
