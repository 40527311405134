<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['Touch Store']]" />
    </template>
    <template #header_page_title> Branded Mode </template>
    <main class="p-5 overflow-x-scroll pb-10 bg-gray-200 h-full">
      <div class="border-solid border border-gray-200 mt-10 rounded-t-lg overflow-hidden bg-white">
        <div class="px-10 py-5 mx-auto" style="max-width: 1800px;">
          <div class="xs:flex py-3 items-center mb-5 text-center sm:text-start">
            <span class="text-xl">
              <span class="font-medium">TOUCH Branches</span>  - £150 + VAT <span class="text-sm">per branch, per month</span>
            </span>
          </div>
          <div class="grid xs:grid-cols-2 lg:grid-cols-3 gap-5 mb-10">
            <div class="grid lg:grid-cols-2 lg:col-span-2 gap-5">
              <div class="max-w-lg">
                <img src="/images/touch-store/branches.jpg">
              </div>
              <div class="flex flex-col gap-2">
                <span class="text-lg font-medium">Description</span>
                <span>
                  Got more than one branch? TOUCH Branches is the ultimate solution for multi-branch businesses. It empowers each
                  location to operate independently with tools for quoting, ordering and customer management.
                </span>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <span class="text-lg font-medium">Features</span>
              <div class="grid">
                <div class="flex rounded-lg gap-2">
                  <i class="text-2xl fal fa-square-check align-middle" />
                  <span class="text-sm">Local quoting and ordering</span>
                </div>
                <div class="flex rounded-lg gap-2">
                  <i class="text-2xl fal fa-square-check align-middle" />
                  <span class="text-sm">Convenient in-store and phone ordering</span>
                </div>
                <div class="flex rounded-lg gap-2">
                  <i class="text-2xl fal fa-square-check align-middle" />
                  <span class="text-sm">Independent customer management</span>
                </div>
                <div class="flex rounded-lg gap-2">
                  <i class="text-2xl fal fa-square-check align-middle" />
                  <span class="text-sm">Flexible standalone operations</span>
                </div>
                <div class="flex rounded-lg gap-2">
                  <i class="text-2xl fal fa-square-check align-middle" />
                  <span class="text-sm">Dedicated local support</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex w-full">
            <div class="ml-auto">
              <a
                class="btn-action btn-md col-span-2"
                :href="store_url + 'shop'"
                target="_blank" 
              >
                Buy Now
              </a>
            </div>
          </div>
        </div>
      </div>
      <Redirect ref="redirect" />
    </main>
  </touch-layout>
</template>

<script>
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Redirect from '@/components/branded/touchStore/Redirect.vue';

export default {
  components: {
    Breadcrumbs,
    Redirect,
  }
};
</script>
