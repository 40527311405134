import { errorCallback, successCallback } from '@/composables/validate';

export async function marketingCategories() {
  return (await this.authenticatedPost('common/GetDocumentCategories')).data;
}

export async function marketingItems(options) {
  const fileResponse = await this.authenticatedPost('common/ListDocuments', {
    CategoryId: Number(options.categoryId),
    Type: window.enum.documentType.RESOURCE_FILE,
  });

  const videoResponse = await this.authenticatedPost('common/ListDocuments', {
    CategoryId: Number(options.categoryId),
    Type: window.enum.documentType.RESOURCE_VIDEO,
  });

  return [...fileResponse.data.documents, ...videoResponse.data.documents];
}

export async function documentDetails(id) {
  return (
    await this.authenticatedPost('common/DocumentDetails', {
      Id: Number(id),
    })
  ).data;
}

export async function uploadDocumentForm(newDocument) {
  return this.parseResponse(
    await this.authenticatedPost('customer/UploadDocumentForm', newDocument),
    errorCallback,
    (r)  => successCallback('Save', r.data.id)
  );
}

export async function addDocumentCategory(categoryName) {
  await this.authenticatedPost('staff/AddDocumentCategory', {
    Name: categoryName,
  });
}

export async function updateDocumentCategory(category) {
  await this.authenticatedPost('staff/UpdateDocumentCategory', {
    Id: Number(category.id),
    Name: category.name,
  });
}

export async function deleteDocumentCategories(categoryIds) {
  return this.authenticatedPost('staff/DeleteDocumentCategories', {
    Ids: categoryIds,
  }).catch((error) => error.response);
}

export async function deleteMarketingDocuments(documentIds) {
  return this.parseResponse(
    await this.authenticatedPost('staff/DeleteDocuments', {
      Ids: documentIds,
    }),
    errorCallback,
    () => successCallback('Delete')
  )
}

export async function generateLinkWeb2Print({ jwt_token, prefix }) {
  return this.parseResponse(
    await this.axios.post(import.meta.env.VITE_APP_STORE_PREFIX + 'api/vpress-register', { jwt_token, prefix }),
    errorCallback,
  )
}