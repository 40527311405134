<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Dealer Network Enquiries', '/dealer-network'],
          ['Assigned', '/dealer-network-assigned'],
        ]"
      />
    </template>
    <template #header_page_title>
      Assigned <span class="hidden xs:inline">&nbsp;Dealer Network&nbsp;</span> Enquiries
    </template>
    <Teleport defer to="#portal_search">
      <SearchBox class="flex" placeholder="Search enquiries by consumer details or postcode..." />
    </Teleport>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <router-link to="/dealer-network" class="btn-action whitespace-nowrap">
          View All
        </router-link>
      </div>
    </template>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 xs:p-10"
    >
      <div
        key="orders_list"
        class="bg-white p-3 xs:p-10 pb-6 rounded-lg"
        data-index="1"
      >
        <table class="table-lined xs:table-unstacked _with-hover w-full ">
          <thead>
            <tr>
              <th>
                <div class="flex items-center gap-2">
                  Created
                  <div class="relative">
                    <div v-if="isDatePicker" class="absolute w-full h-full" style="top:100%">
                      <VueDatePicker
                        v-model="created_range"
                        :ui="{ calendar: 'font-thin text-xs', menu: 'font-thin text-xs', calendarCell: 'h-6' }"
                        class="z-50"
                        model-type="yyyy-MM-dd"
                        inline
                        range
                        multi-calendars
                        auto-apply
                        :enable-time-picker="false"
                        :hide-offset-dates="true"
                        :disabled-dates="(date) => date > new Date()"
                        :year-range="[(new Date()).getFullYear() - 10, (new Date()).getFullYear()]"
                      />
                    </div>
                    <div class="relative bg-gray-400 rounded p-1 text-white clickable cursor-pointer" @click="isDatePicker = !isDatePicker">
                      <div v-if="$route.query.fromDate && $route.query.toDate" class="flex items-center gap-2">
                        <span>{{ (new Date($route.query.fromDate)).toLocaleDateString() }}</span>
                        <span>-</span>
                        <span>{{ (new Date($route.query.toDate)).toLocaleDateString() }}</span>
                        <i class="fal fa-times mx-1 cursor-pointer z-50" @click.stop="created_range = undefined, isDatePicker = false" />
                      </div>
                      <div v-else class="flex py-1 items-center">
                        <i class="fal fa-filter mx-1" />
                        <i v-if="isDatePicker" class="fal fa-chevron-up mx-1" />
                        <i v-else class="fal fa-chevron-down mx-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </th>
              <th>Enquiry Number</th>
              <th>Consumer Details</th>
              <th>Postcode</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in orders"
              :key="order.id"
              :class="{ _hovered: hovered === order.id }"
              @mouseover="hovered = order.id"
              @mouseleave="hovered = null"
              @click="$router.push(getOrderUrl(order.id))"
            >
              <td>
                <div class="td-title w-24">Created</div>
                <div class="td-content">{{ formatDate(order.date) }}</div>
              </td>
              <td>
                <div class="td-title w-24">Enquiry Number:</div>
                <div class="td-content">{{ order.id }}</div>
              </td>
              <template v-if="order.consumer">
                <td>
                  <div class="td-title w-24">Consumer:</div>
                  <div class="td-content">{{ formatConsumer(order.consumer) }}</div>
                </td>
                <td>
                  <div class="td-title w-24">Postcode:</div>
                  <div class="td-content">{{ order.consumer.addressCode }}</div>
                </td>
              </template>
              <td v-else colspan="2">
                <div class="td-title w-24">Consumer:</div>
                <div class="td-content">No Consumer Record Found</div>
              </td>
              <td>
                <div class="td-title w-24">Status</div>
                <div class="td-content">{{ getEnquiryStatusName(order.enquiryStatus) }}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="orders.length > 0" class="flex mt-5">
          <div>
            Total Assigned: <b>{{ total_items }}</b>
          </div>
        </div>
        <div v-else key="none-found" class="bg-white p-10">
          <div v-if="$route.query" class="flex justify-between">
            <div>No Enquiries match {{ $route.query.search }}</div>
            <div>
              <a class="btn-action" @click="$router.push({ query: undefined })"> Clear Search </a>
            </div>
          </div>
        </div>
      </div>
      <pagination class="mt-3 pb-10" :total_items="total_items" :limit="limit" @load-feed="loadFeed" />
    </loading>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import { formatDate } from '@/composables/date';
import Pagination from '@/components/shared/Pagination.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
  components: {
    pagination: Pagination,
    Breadcrumbs,
    SearchBox,
    VueDatePicker
  },
  setup() {
    return { formatDate }
  },
  data() {
    return {
      assignees: [],
      orders: [],
      loading: true,
      myId: 0,
      limit: 10,
      total_items: 0,
      hovered: null,
      created_range: undefined,
      isDatePicker: false
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  watch: {
    '$route.query': {
      handler() {
        this.loadFeed();
      },
      immediate: true,
    },
  },
  mounted() {
    this.created_range = this.$route.query.fromDate && this.$route.query.toDate
      ? [this.$route.query.fromDate, this.$route.query.toDate]
      : undefined;

    setTimeout(() => {
      this.$watch('created_range', (to) => {
        this.$router.push({
          query: {
            ...this.$route.query,
            fromDate: to ? to[0] : undefined,
            toDate: to ? to[1] + ' 23:59:59' : undefined
          }
        }).catch(() => { })
        this.isDatePicker = false
      });
    }, 0);
  },
  methods: {
    getAssignee(customerId) {
      return this.assignees.filter((assignee) => assignee.customerId === customerId)[0];
    },
    getEnquiryStatusName(orderStatus) {
      const status = Object.keys(this.enums.EnquiryStatus).find(
        (key) => this.enums.EnquiryStatus[key] === orderStatus,
      );
      return status.toLowerCase();
    },
    getOrderUrl(enquiryId) {
      const url = `/dealer-network-assigned-statuses/${enquiryId}`;
      return url;
    },
    assignedToDetails(contract) {
      const assignedTo = this.getAssignee(contract.customerId);

      return `${assignedTo.name} ${assignedTo.email}`;
    },
    async loadFeed(offset = 0) {
      this.loading = true;
      this.myId = await window.touch.staffGetOrganisationCustomerId();
      const response = await this.touchStore.loadDealerNetworkAssignedList({
        limit: this.limit,
        offset,
        search: this.$route.query.search,
        fromDate: this.$route.query.fromDate,
        toDate: this.$route.query.toDate,
      });
      this.orders = response.orders;
      this.assignees = response.assignees;
      this.total_items = response.total_items;
      this.loading = false;
    },
  },
};
</script>
