<template>
  <Teleport defer to="#portal_popup"> 
    <modal-window :modal_open="true" title="Edit Financial Details" @close="$emit('close')">
      <div class="flex flex-wrap">
        <div class="w-full xs:w-1/2 xs:pr-5">
          <div class="font-medium">Currency</div>
          <manage-currency
            v-model="customer.financial.currencyId"
            :edit_mode="true"
          />
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:mt-0 xs:pl-5">
          <div class="font-medium">Minimum Order Value</div>
          <div class="flex relative">
            <div class="absolute py-2 px-3 border border-black rounded-l">£</div>
            <input
              v-model="customer.delivery.minimumOrderValue"
              :disabled="uneditable['min']"
              :class="{ 'text-gray-400': uneditable['min'] }"
              type="number"
              placeholder="0.00"
              class="flex-grow bg-white border-black p-2 rounded pl-16 w-32 border border-l-0"
              @change="formatTo2dp"
            >
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-4 xs:w-1/2 xs:mt-8 xs:pr-5">
          <div class="font-medium">Default Tax Rate</div>
          <select v-model="customer.financial.defaultTaxRateId" class="form-control">
            <option :value="0" hidden>None</option>
            <option v-for="(tax_rate, index) in tax_rates" :key="index" :value="tax_rate.id">
              {{ tax_rate.name }} - {{ tax_rate.value }}%
            </option>
          </select>
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:mt-8 xs:pl-5">
          <div class="font-medium">Delivery Charge</div>
          <div class="flex relative">
            <div class="absolute py-2 px-3 border border-black rounded-l">£</div>
            <input
              v-model="customer.delivery.deliveryCharge"
              :disabled="uneditable['del']"
              :class="{ 'text-gray-400': uneditable['del'] }"
              placeholder="0.00"
              type="number"
              class="flex-grow bg-white border-black p-2 rounded pl-16 w-32 border border-l-0"
              @change="formatTo2dp"
            >
          </div>
        </div>
      </div>
      <div class="flex flex-wrap w-full mt-4 xs:w-1/2 xs:mt-8 xs:pr-5">
        <div class="w-full xs:w-1/2 xs:pr-5">
          <div class="font-medium">Payment with order</div>
          <div class="flex h-full">
            <label class="flex flex-nowrap mr-3">
              <div class="flex flex-col justify-around">
                <input
                  v-model="customer.financial.paymentWithOrder"
                  type="radio"
                  name="payment_with_order"
                  :value="true"
                  :disabled="false"
                  @click="validatePaymentWithOrder()"
                >
              </div>
              <div class="flex flex-col justify-around p-1">Yes</div>
            </label>
            <label class="flex flex-nowrap">
              <div class="flex flex-col justify-around">
                <input
                  v-model="customer.financial.paymentWithOrder"
                  type="radio"
                  name="payment_with_order"
                  :value="false"
                  :disabled="false"
                >
              </div>
              <div class="flex flex-col justify-around p-1">No</div>
            </label>
          </div>
        </div>

        <div class="w-full xs:w-1/2 xs:pr-5">
          <div class="font-medium">See Price Breakdown</div>
          <div class="flex h-full">
            <label class="flex flex-nowrap mr-3">
              <div class="flex flex-col justify-around">
                <input
                  v-model="customer.financial.omitPriceBreakdown"
                  type="radio"
                  name="show_price_breakdown"
                  :value="false"
                  :disabled="false"
                >
              </div>
              <div class="flex flex-col justify-around p-1">Yes</div>
            </label>
            <label class="flex flex-nowrap">
              <div class="flex flex-col justify-around">
                <input
                  v-model="customer.financial.omitPriceBreakdown"
                  type="radio"
                  name="show_price_breakdown"
                  :value="true"
                  :disabled="false"
                >
              </div>
              <div class="flex flex-col justify-around p-1">No</div>
            </label>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap w-full mt-4 xs:w-1/2 xs:mt-8 xs:pr-5">
        <div
          class="flex items-center cursor-pointer"
          @click.prevent="
            customer.delivery.useCustomerCharge = !customer.delivery.useCustomerCharge
          "
        >
          <div class="relative">
            <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
            <div
              class="switch-toggle"
              :class="{ _active: customer.delivery.useCustomerCharge }"
            />
          </div>
          <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">Use Customer Charge</div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveFinancialDetails()">
          Save Changes
        </button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { useBasketStore } from '@/pinia/basket';
import manageCurrency from '@/components/shared/ManageCurrency.vue';

export default {
  components: {
    'manage-currency': manageCurrency,
  },
  props: ['customer', 'loggedInUser', 'tax_rates'],
  data() {
    return {
      uneditable: {
        del: false,
        min: false,
      },
    };
  },
  computed: {
    ...mapStores(useCustomerStore, useBasketStore),
  },
  mounted() {
    this.formatTo2dp();
  },
  methods: {
    formatTo2dp() {
      this.customer.delivery.minimumOrderValue = this.customer.delivery.minimumOrderValue
        ? parseFloat(this.customer.delivery.minimumOrderValue).toFixed(2)
        : 0.0;
      this.customer.delivery.deliveryCharge = this.customer.delivery.deliveryCharge
        ? parseFloat(this.customer.delivery.deliveryCharge).toFixed(2)
        : 0.0;
    },
    validatePaymentWithOrder() {
      if (!this.basketStore.isPaymentGateway) {
        this.alertBox()
          .fire({
            title: 'Payments Setup Required.',
            text: 'Please contact your account manager to setup payments.',
            icon: 'error',
          })
          .then(() => {
            this.customer.financial.paymentWithOrder = false;
          });
      } else if (this.customer.customer.skipSopOrderAcceptance) {
        this.alertBox()
          .fire({
            title: 'Unable to make payment with order and skip SOP.',
            text: 'Skip SOP toggle can be found in company details section.',
            icon: 'error',
          })
          .then(() => {
            this.customer.financial.paymentWithOrder = false;
          });
      } else {
        this.customer.financial.paymentWithOrder = true;
      }
    },
    async saveFinancialDetails() {
      if (this.customer.financial.defaultTaxRateId === 0) {
        this.alertBox().fire({
          title: 'Default Tax Rate Required.',
          text: 'Please select a default tax rate',
        });
        return;
      }
      this.$emit('isLoading', true);
      await this.customerStore.customerUpdateRaw({
        Financial: {
          currencyId: Number(this.customer.financial.currencyId),
          defaultTaxRateId: Number(this.customer.financial.defaultTaxRateId),
          omitPriceBreakdown: Boolean(this.customer.financial.omitPriceBreakdown),
          paymentWithOrder: this.customer.financial.paymentWithOrder,
          vatEnabled: undefined,
          requoteMarkup: undefined,
          taxValue: undefined,
        },
        Delivery: {
          deliveryCharge: this.customer.delivery.deliveryCharge,
          minimumOrderValue: this.customer.delivery.minimumOrderValue,
          useCustomerCharge: this.customer.delivery.useCustomerCharge,
        },
        CustomerId: this.customer.customerId,
      });
      this.$emit('isLoading', false);
      this.$emit('close');
    },
  },
};
</script>
