<template>
  <div class="flex flex-col md:flex-row gap-5 p-5 xs:p-10">
    <div class="flex flex-col gap-5 flex-1">
      <div>
        <div class="text-2xl font-medium">
          Connect Sources
        </div>
      </div>
      <div class="flex-grow flex flex-col gap-2 bg-white border p-5">
        <div class="flex items-center border-b pb-1">
          <b>Name</b>
        </div>
        <div class="flex items-center py-1">
          <span>Stock</span> 
          <div v-if="!loading" class="flex gap-3 items-center ml-auto">
            <i v-if="isSourceTypeEnabled(enums.connectType.STOCK)" class="fas fa-check text-green-500 text-lg" />
            <label v-else class="btn-action" @click="enableSource(enums.connectType.STOCK)">
              Enable
            </label>   
          </div>
        </div>
        <div class="flex items-center py-1">
          <span>Products</span> 
          <div v-if="!loading" class="flex gap-3 items-center ml-auto">
            <i v-if="isSourceTypeEnabled(enums.connectType.PRODUCTS)" class="fas fa-check text-green-500 text-lg" />
            <label v-else class="btn-action" @click="enableSource(enums.connectType.PRODUCTS)">
              Enable
            </label>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-5">
    <div class="flex flex-col gap-5 flex-1">
      <div class="flex gap-5">
        <div class="text-2xl font-medium">
          Connect Subscriptions
        </div>
        <div class="ml-auto">
          <button class="btn-action" @click="isSubscribe = true">Subscribe</button>
        </div>
      </div>
      <Teleport v-if="isSubscribe" defer to="#portal_popup"> 
        <modal-window
          :modal_open="isSubscribe"
          :is-form="false"
          title="Subscribe to Touch Connect Source"
          @close="reset"
        > 
          <form @submit.prevent="subscribe">
            <div class="flex flex-col gap-5">
              <div class="grid grid-cols-2 gap-2">
                <div class="p-3 w-full">
                  <div class="font-medium">Code</div>
                  <div>
                    <input v-model="code" class="form-control">
                  </div>
                </div>
                <div class="p-3 w-full">
                  <div class="font-medium">Type</div>
                  <select
                    v-model="type"
                    class="form-control"
                  >
                    <option value="" disabled selected>Select a Type</option>
                    <option :value="0">Components</option>
                    <option :value="1">Products</option>
                    <option :value="2">Stock</option>
                  </select>
                </div>
                <div class="p-3 w-full">
                  <div class="font-medium">Email</div>
                  <div>
                    <input v-model="email" type="email" class="form-control">
                  </div>
                </div>
                <div class="p-3 w-full">
                  <div class="font-medium">Password</div>
                  <div>
                    <input v-model="password" type="password" class="form-control">
                  </div>
                </div>
              </div>
              <div class="ml-auto mt-10">
                <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="reset()">
                  Cancel
                </button>
                <button class="btn-action btn-lg" type="submit">Subscribe</button>
              </div>
            </div>
          </form>
        </modal-window>
      </Teleport>
      <div class="flex-grow bg-white border p-5">
        <div v-if="connectSubscriptions.length > 0">
          <div class="flex items-center border-b pb-1">
            <b>Name</b>
            <b class="ml-auto">Type</b>
          </div>
          <div v-for="connectSubscription in connectSubscriptions" :key="connectSubscription.id" class="flex items-center py-3">
            <span>{{ connectSubscription.name }}</span>
            <span
              v-if="connectSubscription.type == enums.connectType.COMPONENTS"
              class="ml-auto"
            >
              Components
            </span>
            <span
              v-else-if="connectSubscription.type == enums.connectType.PRODUCTS"
              class="ml-auto"
            >
              Products
            </span>
            <span
              v-else-if="connectSubscription.type == enums.connectType.STOCK"
              class="ml-auto"
            >
              Stock
            </span>
          </div>
        </div>
        <div v-else class="flex flex-col items-center justify-center flex-grow h-full">
          <span>No Touch Connect Subscriptions</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import validate from 'validate.js';

export default {
  data() {
    return {
      connectSources: [],
      connectSubscriptions: [],
      isSubscribe: false,
      code: undefined,
      type: undefined,
      email: '',
      password: '',
      loading: true
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  async created() {
    this.connectSources = await this.touchStore.connectGetConnectSources();
    this.connectSubscriptions = await this.touchStore.connectGetConnectSubscriptions();
    this.loading = false
  },
  methods: {
    async subscribe() {
      const validationErrors = validate(
        {
          type: this.type,
          code: this.code
        },
        {
          type: {
            presence: { allowEmpty: false },
          },
          code: {
            presence: { allowEmpty: false },
          },
        },
      );
      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }
      if (this.type === this.enums.connectType.STOCK) {
        await window.touch.createStockSubscription(this.email, this.password, this.code);
      } else {
        await window.touch.CreateConnectSubscription(this.email, this.password, this.code, this.type);
      }
      this.connectSubscriptions = await this.getConnectSubscriptions();
      this.reset();
    },
    reset() {
      this.isSubscribe = undefined;
      this.code = '';
      this.type = '';
      this.email = '';
      this.password = '';
    },
    isSourceTypeEnabled(type) {
      return this.connectSources.some((source) => source.type === type);
    },
    comingSoon() {
      this.alertBox().fire('Feature coming soon');
    },
    async enableSource(source) {
      await this.touchStore.addConnectSource(source);
      this.connectSources = await this.touchStore.connectGetConnectSources();
    },
  },
};
</script>

<style scoped></style>
