<template>
  <div>
    <slot name="custom-installer-email" />
    <slot name="text-area-confirmation-text" />
  </div>
</template>

<script>
export default {
  props: {
    setup: String,
  },
};
</script>

<style scoped></style>
