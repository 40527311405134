<template>
  <div class="line-item pb-2" @click.prevent="showDetail = !showDetail">
    <div v-for="(extra, index) in extras" :key="extra.itemKey" class="line-item-header _extras">
      <div class="_header-attributes-group">
        <div class="_header-attribute">
          <div v-show="index === 0" class="_label">Extras</div>
          <div class="_description">
            {{ extra.description }}
            <span v-if="extra.size">({{ extra.size.split(" ")[0] }})</span>
          </div>
        </div>
      </div>
      <div class="_header-attributes-group">
        <div class="_header-attribute" />
        <div class="_header-attribute" />
      </div>
      <div class="_header-attributes-group">
        <div class="_header-attribute _item-qty">
          <div v-show="index === 0" class="_label">Qty</div>
          <div class="_description">
            {{ extra.quantity }}
          </div>
        </div>
        <div class="_header-attribute _item-price">
          <div v-show="index === 0" class="_label">Item Price</div>
          <div class="_description">
            <div
              class="_description"
              v-html="formatCurrency(job.currency(), extra.price / extra.quantity)"
            />
          </div>
        </div>
        <div class="_header-attribute _item-price">
          <div v-show="index === 0" class="_label">Total Price</div>
          <div class="_description">
            <div class="_description" v-html="formatCurrency(job.currency(), extra.price)" />
          </div>
        </div>
        <div class="_show-detail-control">
          <i v-if="showDetail" class="fa fa-caret-down" :class="{ 'opacity-0': index !== 0 }" />
          <i v-else class="fa fa-caret-right" :class="{ 'opacity-0': index !== 0 }" />
        </div>
      </div>
    </div>
    <div v-if="showDetail" class="line-item-detail">
      <div class="line-item-summary">
        <div class="item-summary-group">
          <header class="group-title">
            <h2>Extras</h2>
          </header>
          <div v-for="extra in extras" :key="`extra_${extra.itemKey}`" class="group-attributes">
            <div class="attribute-label">
              <strong>{{ extra.quantity }} x {{ extra.description }}
                <span v-html="extra.optionDescription" /></strong>
            </div>
            <div class="attribute-value">
              <span v-html="formatCurrency(job.currency(), extra.price)" />
              <span v-if="extra.size"> - {{ extra.size }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import { formatCurrency } from '@/composables/currency';

export default {
  props: ['extras', 'job'],
  setup() {
    return { formatCurrency }
  },
  data() {
    return {
      showDetail: false,
    };
  },
  computed: {
    ...mapStores(useBasketStore),
    hasTime() {
      return [window.enum.unitOfMeasure.FITTING_DAYS].includes(this.extra.unitOfMeasure);
    },
    hasLength() {
      return (
        !this.extra.useSizesFromProduct &&
        [window.enum.unitOfMeasure.LENGTH, window.enum.unitOfMeasure.VOLUME].includes(
          this.extra.unitOfMeasure,
        )
      );
    },
    hasWidth() {
      return (
        !this.extra.useSizesFromProduct &&
        [
          window.enum.unitOfMeasure.WIDTH,
          window.enum.unitOfMeasure.AREA,
          window.enum.unitOfMeasure.PERIMETER,
          window.enum.unitOfMeasure.VOLUME,
        ].includes(this.extra.unitOfMeasure)
      );
    },
    hasHeight() {
      return (
        !this.extra.useSizesFromProduct &&
        [
          window.enum.unitOfMeasure.HEIGHT,
          window.enum.unitOfMeasure.AREA,
          window.enum.unitOfMeasure.PERIMETER,
          window.enum.unitOfMeasure.VOLUME,
        ].includes(this.extra.unitOfMeasure)
      );
    },
  },
  methods: {
    updateValues() {
      if (this.extra.customerItemId) {
        return this.basketStore.updateExistingExtraItem({
          params: {
            Length: this.hasLength ? parseFloat(this.$refs.length.value) : undefined,
            Time: this.hasTime ? parseFloat(this.$refs.time.value) : undefined,
            Width: this.hasWidth ? parseFloat(this.$refs.width.value) : undefined,
            Height: this.hasHeight ? parseFloat(this.$refs.height.value) : undefined,
          },
          itemId: this.extra.key,
        });
      }

      return this.basketStore.updateNewExtraItem({
        params: {
          Length: this.hasLength ? parseFloat(this.$refs.length.value) : undefined,
          Width: this.hasWidth ? parseFloat(this.$refs.width.value) : undefined,
          Time: this.hasTime ? parseFloat(this.$refs.time.value) : undefined,
          Height: this.hasHeight ? parseFloat(this.$refs.height.value) : undefined,
        },
        itemId: this.extra.key,
      });
    },
  },
};
</script>
