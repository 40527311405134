export async function listRackTypes() {
  return (await this.authenticatedPost('rack/ListTypes')).data;
}

export async function createRackType(name) {
  await this.authenticatedPost('rack/CreateType', {
    Name: name,
  });
}

export async function updateRackType(id, name) {
  await this.authenticatedPost('rack/UpdateType', {
    Id: id,
    Name: name,
  });
}

export async function getRack(Barcode) {
  await this.authenticatedPost('rack/Get', {
    Barcode,
  });
}

export async function getRackList() {
  return this.parseResponse(await this.authenticatedPost('rack/List'));
}
