<template>
  <div class="border border-gray-300 p-6 rounded-lg bg-white">
    <div class="xs:text-base mb-3">Your Stock Parts</div>
    <table class="w-full table-pricing">
      <thead>
        <tr>
          <th class="text-left font-normal px-3 py-2 font-medium border-b">Part No.</th>
          <th class="text-left font-normal px-3 py-2 font-medium w-24 border-b">Order Qty.</th>
          <th class="text-right font-normal px-3 py-2 font-medium w-10 border-b" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in stockPartsStore.cartItems" :key="item.id">
          <td class="text-left font-normal px-3 py-2 bg-gray-300" :title="item.description">
            {{ item.code }}
          </td>
          <td class="text-left font-normal px-3 py-2 bg-gray-300">
            <input
              :value="item.quantity"
              type="number"
              class="w-16 border rounded border-gray-300 px-3 py-1"
              @change="
                stockPartsStore.updateQtyForItem({
                  itemId: item.stockItemId,
                  quantity: Number.isNaN($event.target.value) ? 0 : $event.target.value,
                })"
            >
          </td>
          <td
            class="text-right font-normal px-3 py-2 bg-gray-300 cursor-pointer"
            @click="stockPartsStore.deleteItem(item.itemKey)"
          >
            <i class="fal fa-trash" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useStockPartsStore } from '@/pinia/stock-parts';

export default {
  computed: {
    ...mapStores(useStockPartsStore),
  },
};
</script>

<style scoped></style>
