<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['My Branded Mode Account', '/setup'],
          ['Branded Mode Designer'],
        ]"
      />
    </template>
    <template #header_page_title> Branded Mode Designer </template>
    <Website setup="Branded Mode" />
  </touch-layout>
</template>

<script>
import Website from '@/components/shared/setup/Website.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Website,
    Breadcrumbs,
  },
};
</script>
