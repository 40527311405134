import { defineStore } from 'pinia';

export const useKnowledgeBaseStore = defineStore('knowledgeBase', {
  persist: true,
  state: () => ({
    articles: [
      {
        duration: undefined,
        title: 'Portal Setup Checklist',
        id: '5',
        imageURL: 'Portal Setup Checklist.png',
        url: import.meta.env.VITE_APP_STORE_PREFIX + 'portal/how-to-set-up'
      },
      {
        duration: undefined,
        title: 'How to Buy Branded Mode',
        id: '1',
        imageURL: 'How to Buy Branded Mode.png',
        url: import.meta.env.VITE_APP_STORE_PREFIX + 'branded-mode/how-to-purchase'
      },
      {
        duration: undefined,
        title: 'Branded Mode Setup Guide',
        id: '2',
        imageURL: 'Branded Mode Setup Guide.png',
        url:import.meta.env.VITE_APP_STORE_PREFIX + 'branded-mode/how-to-set-up'
      },
      {
        duration: undefined,
        title: 'Deploying Branded Mode',
        id: '3',
        imageURL: 'Deploying Branded Mode.png',
        url: import.meta.env.VITE_APP_STORE_PREFIX + 'branded-mode/deployment'
      },
      {
        duration: undefined,
        title: 'Increase Sales with Branded Mode',
        id: '4',
        imageURL: 'Increase Sales with Branded Mode.png',
        url: import.meta.env.VITE_APP_STORE_PREFIX + 'branded-mode/benefits'
      },
    ],
    hiddenArticlesIDs: [],
  }),
  actions: {
    addHiddenArticlesIDs(Id) {
      this.hiddenArticlesIDs.push(Id);
    },
    removeHiddenArticlesIDs( Id) {
      const index = this.hiddenArticlesIDs.indexOf(Id);
      this.hiddenArticlesIDs.splice(index, 1);
    },
    async generateLinkWeb2Print ({ jwt_token }) {
      const parsedUrl = process.env.NODE_ENV === 'development' 
        ? new URL(import.meta.env.VITE_APP_ENDPOINT_URL)
        : new URL(window.location)
      const prefix = parsedUrl.hostname.split('.')[0];
      const { url } = await window.touch.generateLinkWeb2Print({
        jwt_token,
        prefix
      })
      const response = window.open(url, '_blank');
      if (!response) {
        window.location.href = url
      }
    }
  },
});
