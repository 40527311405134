import { defineStore } from 'pinia';

export const useSandboxStore = defineStore('sandbox', {
  persist: true,
  state: () => ({
    selectedSandbox: null,
  }),
  actions: {
    SET_SANDBOX(sandbox) {
      this.selectedSandbox = sandbox;
    },
    loadFromStorage() {
      if (this.selectedSandbox) {
        this.SET_SANDBOX(this.selectedSandbox)
      }
    },
    processingUrl(url) {
      if (this.sandboxIsActive) {
        return `v1/sandbox/${this.selectedSandbox.id}/${url}`;
      }

      return url;
    },
    async addNoteOnSandbox({ sandboxId, contractId, note }) {
      return window.touch.testAddNote(contractId, sandboxId, note);
    },
  },
  getters: {
    sandboxIsActive(state) {
      return window.VITE_APP_INSTALLATION_TYPE === 'business' && state.selectedSandbox !== null;
    },
  },
});
