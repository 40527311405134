<template>
  <button v-if="!omitPriceBreakdown" @click="showBreakdown(false)">
    <i class="fas fa-search-dollar" />
  </button>
</template>

<script>

import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { useBasketStore } from '@/pinia/basket';
import { formatCurrency } from '@/composables/currency';

export default {
  setup() {
    return { formatCurrency }
  },
  data() {
    return {
      priceRates: window.priceRates,
      omitPriceBreakdown: true
    };
  },
  computed: {
    ...mapStores(useCustomerStore, useBasketStore),
  },
  async mounted() {
    if (this.$route.params.customerId) {
      const customer = await this.customerStore.customerGet(this.$route.params.customerId)
      this.omitPriceBreakdown = customer?.financial?.omitPriceBreakdown
    } else {
      this.omitPriceBreakdown = this.customerStore.customer?.financial?.omitPriceBreakdown
    }
  },
  methods: {
    async getBreakdown(refresh) {
      const breakdown = await this.basketStore.getBreakdown(refresh);

      const header = `<table class="w-full table-striped">
        <thead>
        <tr>
            <th class="text-left"></th>
            <th>Value</th>
        </tr>
        </thead>
        <tbody>`;

      const content = breakdown
        .map((row) => {
          let rowHtml = `<tr><td class="text-left font-bold">${row.description}</td>`;
          rowHtml += `<td>${this.formatCurrency(this.basketStore.getCurrency, row.value)}</td></tr>`;
          return rowHtml;
        })
        .join('');

      const footer = `</tbody>
        <tfoot></tfoot>
        </table>`;

      return header + content + footer;
    },
    async showBreakdown(refresh) {
      this.alertBox()
        .fire({
          title: 'Price Calculation Breakdown',
          html: await this.getBreakdown(refresh),
          showCancelButton: true,
          cancelButtonText: 'refresh',
        })
        .then((result) => {
          if (result.isDismissed && result.dismiss === 'cancel') {
            this.alertBox().close();
            this.showBreakdown(true);
          }
        });
    },
  },
};
</script>

<style scoped></style>
