<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['My Account', '/my-account'], ['Products']]" />
    </template>
    <template #header_page_title>
      Products
    </template>
    <Menu />
    <div class="flex flex-col flex-grow bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-5 xs:p-10">
      <loading v-if="loading" :loading="true" class="w-full h-full" style="flex: 1 1 0" />
      <div v-else class="bg-white p-5 xs:p-10 border">
        <table class="table-lined xs:table-unstacked w-full">
          <thead>
            <tr>
              <th />
              <th>Product</th>
              <th>Requote Markup</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(product, index) in product_overrides"
              :key="index"
            >
              <td>
                <div class="w-20">
                  <api-img :src="'/api/image/product/' + product.productId" alt="" class="h-12 mx-auto" />
                </div>
              </td>
              <td>
                <span>{{ product.productName }}</span>
              </td>
              <td>
                <div class="inline-block" :class="{ 'pointer-events-none opacity-50': !customerStore.customer.financial.allowProductRequoteMarkups }">
                  <div class="absolute py-2 px-3 border rounded-l-lg">%</div>
                  <input
                    v-model.number="product.productRequoteMarkup"
                    type="number"
                    class="flex-grow bg-white p-2 rounded-lg pl-16 border border-l-0"
                    min="0"
                    @input="hasChangedItems = true"
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-if="is_admin_user && !loading"
      class="sticky bottom-0 bg-white p-3 xs:p-4 text-right border-gray-300 border-t flex items-center"
    >
      <div class="text-red-600 flex flex-col justify-around text-center xs:text-left">
        <span v-show="!isUnChanged"> You have unsaved changes. </span>
      </div>
      <div class="ml-auto">
        <div
          v-if="!demo_mode"
          class=" mt-2 xs:mt-0"
          :class="{ 'pointer-events-none opacity-50': loading || isUnChanged }"
        >
          <button class="btn-action btn-lg w-full xs:w-auto" @click.prevent.stop="saveSettings()">
            Save all Changes
          </button>
        </div>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Menu from '@/components/portal/myAccount/Menu.vue';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';

export default {
  components: {
    Breadcrumbs,
    Menu,
  },
  data() {
    return {
      product_overrides: [],
      original_product_overrides: [],
      loading: true,
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
    isUnChanged() {
      return JSON.stringify(this.product_overrides) === JSON.stringify(this.original_product_overrides)
    }
  },
  async mounted() {
    await this.loadSettings()
    this.loading = false
  },
  methods: {
    async loadSettings() {
      this.product_overrides = await this.customerStore.customerGetProductOverrides();
      this.product_overrides.sort((a, b) =>
        a.productName.localeCompare(b.productName, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      );
      this.original_product_overrides = JSON.parse(JSON.stringify(this.product_overrides));
    },
    async saveSettings() {
      this.loading = true;

      if (!this.product_overrides.some(x => x.enabled) && this.branded_mode_enabled) {
        this.alertBox().fire({
          title: 'You must leave at least 1 product enabled',
          icon: 'error',
        });
        this.loading = false;
        return;
      }
      const product_overrides_changes = this.compareObjects(this.product_overrides, this.original_product_overrides)
      await this.customerStore.customerUpdateProductOverrides(product_overrides_changes);
      await this.loadSettings()
      this.loading = false;
    },
    compareObjects(objA, objB) {
      let result = [];
      for (let key in objA) {
        if (Object.prototype.hasOwnProperty.call(objA, key)) {
          if (JSON.stringify(objA[key]) !== JSON.stringify(objB[key])) {
            result.push(objA[key]);
          }
        }
      }
      return result;
    },
  }
};
</script>
