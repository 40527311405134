<template>
  <div :id="id" />
</template>

<script>

import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';

export default {
  props: {
    id: String,
    modelValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: {
        modules: {
          toolbar: true,
        },
        theme: 'snow'
      },
      quill: undefined,
    }
  },
  watch: {
    modelValue(newValue) {
      if (this.quill.root.innerHTML !== newValue) {
        this.quill.root.innerHTML = newValue;
      }
    },
  },
  mounted() {
    this.quill = new Quill(`#${this.id}`, this.options);
    this.quill.root.innerHTML = this.modelValue;
    this.quill.on('text-change', () => {
      const rawHtml = this.quill.root.innerHTML;
      const sanitizedHtml = DOMPurify.sanitize(rawHtml);
      this.$emit('update:modelValue', sanitizedHtml);
    });
  },
};

</script>
