<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Dealer Network Mode Setup', '/dealer-network-setup'],
          ['Option Control'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex gap-5 items-center">
        <span>Option Control</span>
        <span v-if="overrideOptionControl === true" class="text-sm font-normal bg-yellow-500 text-black rounded px-2 py-1 ">
          Changes active. <u class="cursor-pointer" @click="setOverrideOptionControl(false)">Click here</u> to revert to default configuration.
        </span>
      </div>
    </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center gap-3">
        <button
          v-if="brandedCustomer" 
          class="flex items-center gap-3 btn-primary"
          @click="validateTestMode"
        >
          Test Mode
          <Tooltip :is-left="true" :width="200" class="normal-case">
            <span>Visualise changes in <u>{{ brandedCustomer.name }}</u> branded mode<span v-if="overrideOptionControl === false"> before overriding</span>.</span>
          </Tooltip>
        </button>
        <button v-if="overrideOptionControl === false" class="flex items-center gap-3 btn-danger normal-case" @click="setOverrideOptionControl(true)">
          Override Option Control
          <Tooltip :is-left="true" :width="200">
            <span>Override configuration in Branded  Mode.</span>
          </Tooltip>
        </button>
      </div>
    </template>
    <div class="flex bg-white px-5 border-t-4 border-white whitespace-nowrap">
      <router-link
        to="/dealer-network-setup/products"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black border-white"
      >
        Products
      </router-link>
      <router-link
        to="/dealer-network-setup/option-control"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 border-black"
      >
        Option Control 
      </router-link>
    </div>
    <div class="flex flex-col flex-grow overflow-y-auto">
      <div class="flex flex-col md:flex-row gap-5 p-5 flex-grow bg-gray-200 w-full" style="flex: 1 1 0">
        <div class="flex flex-col gap-2" style="flex: 1 1 0">
          <div class="bg-white px-5 py-2 border border-gray">
            <div class="flex items-center gap-5">
              <div class="flex items-center gap-3">
                <span class="text-lg">Headings Inactive</span>
              </div>
            </div>
          </div>
          <loading v-if="isLoadingOptionHeadings" :loading="true" class="bg-white p-5 border border-gray h-full" style="flex: 1 1 0" />
          <div
            v-else-if="headingsInActive.length === 0" 
            class="flex items-center justify-center bg-white p-5 border border-gray h-full"
            style="flex: 1 1 0; min-height: 200px"
          >
            <span> No hidden headings available.</span>
          </div>
          <div v-else class="bg-white p-5 border border-gray overflow-y-scroll" style="flex: 1 1 0; min-height: 200px;">
            <div class="flex flex-col gap-2 w-full">
              <div
                v-for="heading in headingsInActive"
                :id="heading.id"
                :key="heading.id"
                class="bg-gray-200 rounded lg p-2 flex gap-3 items-center"
              >
                <div v-if="heading.consumerDescription" class="flex items-center gap-3">
                  <span class="px-2">{{ heading.consumerDescription }}</span>
                  <span class="text-xs px-2 py-1 bg-blue-500 rounded text-white">Alias</span>
                </div>
                <span v-else class="px-2">{{ heading.name }}</span>
                <div
                  v-if="!originalHiddenHeadings.some((x) => JSON.stringify(x) === JSON.stringify(heading) && heading.consumerListIndex === 0)" 
                  class="text-xs bg-orange-500 px-2 py-1 rounded text-white"
                >
                  <span>Pending</span>
                </div>
                <div class="ml-auto cursor-pointer" @click.stop="validateOptionItems(() => { addHeading(heading.id) })">
                  <i class="flex text-xl fa-sharp fa-regular text-green-500 fa-circle-plus" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2" style="flex: 1 1 0">
          <div class="bg-white px-5 py-2 border border-gray">
            <div class="flex items-center gap-5">
              <div class="flex items-center gap-3">
                <span class="text-lg">Headings Active</span>
              </div>
            </div>
          </div>
          <loading v-if="isLoadingOptionHeadings" :loading="true" class="bg-white p-5 border border-gray h-full" style="flex: 1 1 0" />
          <div
            v-else-if="headingsActive.length === 0" 
            class="flex items-center justify-center bg-white p-5 border border-gray h-full"
            style="flex: 1 1 0; min-height: 200px"
          >
            <span> No active headings selected.</span>
          </div>
          <div v-else class="bg-white p-5 border border-gray overflow-y-auto" style="flex: 1 1 0; min-height: 200px;">
            <div v-if="headingId" class="mb-5 flex gap-3">
              <span class="text-sm opacity-50">Selected: </span>
              <strong>{{ headingsActive.filter((x) => x.id === headingId)[0].name }}</strong>
            </div>
            <draggable v-model="headingsActive" class="flex flex-col gap-2 w-full" :tag="'div'" handle=".handle" @change="afterDrag">
              <div
                v-for="(heading, index) in headingsActive"
                :id="heading.id"
                :key="heading.id"
                class="bg-gray-200 rounded lg p-2 cursor-pointer border hover:border-gray-500 flex gap-3 items-center"
                :class="[heading.id === headingId ? '!border-black' : 'border-gray-200']"
                @click="validateOptionItems(() => { headingId = heading.id === headingId ? undefined : heading.id, editId = undefined })"
                @mouseenter="hoverId = heading.id"
                @mouseleave="hoverId = undefined"
              >
                <div class="flex gap-3 items-center">
                  <i class="td-content cursor-move fa fa-align-justify handle" />
                  <div class="flex items-center pl-2 h-8 rounded bg-white" @click.stop>
                    <span>Q:</span>
                    <input
                      :value="heading.consumerListIndex"
                      type="text"
                      class="w-8 text-center"
                      @input="inputSort($event, heading.id)"
                    >
                  </div>
                  <div>
                    <div
                      v-if="editId === heading.id || (heading.consumerDescription && heading.consumerDescription !== heading.name)" 
                      class="flex items-center gap-3 field-sizing-content"
                    >
                      <input
                        :id="`${heading.id}-input`"
                        v-model="heading.consumerDescription"
                        :placeholder="heading.consumerDescription ? heading.consumerDescription : heading.name"
                        class="h-8 rounded px-2"
                        autocomplete="off"
                        type="text"
                        @click.stop="editId = heading.id"
                      >
                    </div>
                    <div v-else class="flex items-center gap-3">
                      <span class="px-2">{{ `${heading.name}` }}</span>
                      <i
                        v-show="editId !== heading.id && hoverId === heading.id"
                        class="td-content fa fa-pencil"
                        @click.stop="editId = heading.id; setFocus(`${heading.id}-input`)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="!originalVisibleHeadings.some((x, originalIndex) => JSON.stringify(x) === JSON.stringify(heading) && index === originalIndex)" 
                  class="text-xs bg-orange-500 px-2 py-1 rounded text-white"
                >
                  <span>Pending</span>
                </div>
                <div class="ml-auto cursor-pointer" @click.stop="validateOptionItems(() => { removeHeading(heading.id) })">
                  <i class="flex text-xl fa-sharp fa-regular text-red-500 fa-circle-minus" />
                </div>
              </div>
            </draggable>
          </div>
        </div>
        <div class="flex flex-col gap-2" style="flex: 1 1 0">
          <div class="bg-white px-5 py-2  border border-gray">
            <div class="flex items-center gap-5">
              <span class="text-lg">Option Items</span>
              <div class="ml-auto">
                <Tooltip v-if="items.length > 0" :width="200">
                  <span>Enable or disable individual options for the Retail Designer.</span>
                </Tooltip>
              </div>
            </div>
          </div>
          <loading
            v-if="isLoadingOptionHeadings || isLoadingOptionItems"
            :loading="true"
            class="bg-white p-5 border border-gray h-full"
            style="flex: 1 1 0"
          />
          <div v-else class="bg-white p-5 border border-gray overflow-y-auto" style="flex: 1 1 0; min-height: 200px;">
            <div class="flex flex-col gap-2 w-full">
              <div
                v-for="item in items"
                :key="item.id"
                class="flex items-center gap-5 bg-gray-200 rounded lg p-2"
              >
                <span>{{ item.name }}</span>
                <div class="ml-auto flex items-center gap-5">
                  <span class="text-xs opacity-50">{{
                    item.consumer ? 'Enabled' : 'Disabled'
                  }}</span>
                  <div class="relative cursor-pointer" @click.stop="item.consumer = !item.consumer">
                    <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
                    <div class="switch-toggle" :class="{ _active: item.consumer }" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sticky bottom-0 text-right p-3 bg-white border-gray-300 border-t">
        <button
          class="btn-action btn-lg"
          :class="{
            'opacity-50 pointer-events-none bg-gray-500 border-gray-500': noChanges
          }"
          @click="validateSave()"
        >
          Save Changes
        </button>
      </div>
    </div>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { useAuthStore } from '@/pinia/auth';
import Tooltip from '@/components/shared/Tooltip.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { VueDraggableNext } from 'vue-draggable-next'

export default {
  components: {
    Breadcrumbs,
    Tooltip,
    draggable: VueDraggableNext
  },
  async beforeRouteEnter(to, from, next) {
    const authStore = useAuthStore()
    if (authStore.superuser) {
      next();
    } else {
      next('/')
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.noChanges) {
      next()
    } else {
      this.alertBox()
        .fire({
          title: 'There are unsaved changes.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Go Back',
          confirmButtonText: 'Ignore & Continue',
        })
        .then(result => {
          if (result.isConfirmed) {
            next()
          }
        });
    }
  },
  data() {
    return {
      headingsInActive: [],
      originalHiddenHeadings: [],
      headingsActive: [],
      originalVisibleHeadings: [],
      headingId: undefined,
      items: [],
      originalItems: [],
      isHeaderPopUp: false,
      isLoadingOptionHeadings: false,
      isLoadingOptionItems: false,
      brandedCustomer: undefined,
      timeout: undefined,
      overrideOptionControl: undefined,
      hoverId: undefined,
      editId: undefined
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
    baseURL() {
      return import.meta.env.VITE_APP_ENV === 'Local'
        ? 'http://localhost:8080/launch/'
        : window.touch.endpoint.replace('server', 'portal').replace('api', 'launch')
    },
    noChanges() {
      return JSON.stringify(this.items) === JSON.stringify(this.originalItems) &&
        JSON.stringify(this.headingsInActive) === JSON.stringify(this.originalHiddenHeadings) &&
        JSON.stringify(this.headingsActive) === JSON.stringify(this.originalVisibleHeadings)
    }
  },
  watch: {
    headingId: {
      async handler() {
        if (this.headingId) {
          this.items = await this.getCustomerOptionItems(this.headingId);
          this.originalItems = JSON.parse(JSON.stringify(this.items));
        } else {
          this.items = []
          this.originalItems = []
        }
      },
    },
  },
  async created() {
    await this.getOverrideOptionControl()
    await this.setHeadings()
    const resp = await window.touch.ListCustomerBrandedDetails({
      limit: 1,
      Offset: 0,
      BrandedEnabled: true
    })
    this.brandedCustomer = resp?.customerBrandedSummaries[0]
  },
  methods: {
    setFocus(headingId) {
      setTimeout(() => {
        document.getElementById(headingId).focus()
      }, 50);
    },
    // Index for headers are not in a readable order. Setting all active ones from 1... x and inactive to 0
    async oneTimeIndex() {
      this.headingsInActive = this.headingsInActive.map(headingHidden => {
        return { ...headingHidden, consumerListIndex: 0 };
      });
      this.index()
      if (!this.noChanges) {
        await this.save()
      }
    },
    afterDrag({ moved }) {
      this.index()
      this.headingId = this.headingsActive[moved.newIndex].id
    },
    async setHeadings() {
      const headings = await this.getOptionHeadings();
      this.headingsInActive = headings.filter(x => !x.consumer)
      this.headingsActive = headings.filter(x => x.consumer)
      this.sort()
      this.originalHiddenHeadings = JSON.parse(JSON.stringify(this.headingsInActive));
      this.originalVisibleHeadings = JSON.parse(JSON.stringify(this.headingsActive));
      await this.oneTimeIndex()
    },
    sort() {
      this.headingsInActive.sort((a, b) =>
        (a.consumerDescription ? a.consumerDescription : a.name).localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }),
      );
      this.headingsActive.sort((a, b) => a.consumerListIndex - b.consumerListIndex);
    },
    addHeading(headingId) {
      const index = this.headingsInActive.findIndex(x => x.id === headingId)
      const [heading] = this.headingsInActive.splice(index, 1)
      heading.consumer = true
      heading.consumerListIndex = 0
      this.headingsActive.unshift(heading)
      this.index()
      this.headingId = headingId
      this.sort()
      this.$nextTick(() => {
        this.scrollTo(headingId)
      })
    },
    removeHeading(headingId) {
      const index = this.headingsActive.findIndex(x => x.id === headingId)
      const [heading] = this.headingsActive.splice(index, 1)
      heading.consumer = false
      heading.consumerListIndex = 0
      this.headingsInActive.push(heading)
      this.index()
      this.headingId = undefined
      this.sort()
      this.$nextTick(() => {
        this.scrollTo(headingId)
      })
    },
    inputSort(event, headingId) {
      clearTimeout(this.timeout)
      if (event.target.value) {
        this.timeout = setTimeout(() => {
          const index = this.headingsActive.findIndex(x => x.id === headingId)
          if (this.headingsActive[index].consumerListIndex < parseInt(event.target.value)) {
            this.headingsActive[index].consumerListIndex = parseInt(event.target.value) + 1
          } else {
            this.headingsActive[index].consumerListIndex = parseInt(event.target.value) - 1
          }
          this.$nextTick(() => {
            this.index()
            this.headingId = headingId
            this.scrollTo(headingId)
          })
          this.sort()
        }, 500);
      }
    },
    async getOverrideOptionControl() {
      const { companyWebSettings } = await window.touch.authGetOrganisationWebSetting('overrideOptionControl')
      if (companyWebSettings.length) {
        this.overrideOptionControl = JSON.parse(companyWebSettings[0].value)
      } else {
        this.overrideOptionControl = false
      }
    },
    setOverrideOptionControl(override) {
      this.alertBox()
        .fire({
          title: override
            ? 'Are you sure you want to override the default configuration?'
            : 'Are you sure you want to revert to default configuration?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.isLoadingOptionHeadings = true
            this.isLoadingOptionItems = true
            await window.touch.authUpdateCompanyWebSettings('overrideOptionControl', override.toString())
            await this.getOverrideOptionControl()
            this.isLoadingOptionHeadings = false
            this.isLoadingOptionItems = false
          }
        });
    },
    async getOptionHeadings() {
      this.isLoadingOptionHeadings = true
      const { headings } = await window.touch.GetFenestrationOptionHeadings(this.customerStore.customer.datasetIds[0]);
      headings.sort((a, b) => a.consumerListIndex - b.consumerListIndex);
      this.isLoadingOptionHeadings = false
      return headings;
    },
    async getCustomerOptionItems(headingId) {
      this.isLoadingOptionItems = true
      const { items } = await window.touch.staffGetFenestrationOptionItemsForHeading(
        this.customerStore.customer.datasetIds[0],
        headingId,
        this.customerStore.customer.customerId,
      );
      items.sort((a, b) => a.listIndex - b.listIndex);
      this.isLoadingOptionItems = false
      return items;
    },
    validateOptionItems(action) {
      if (JSON.stringify(this.items) !== JSON.stringify(this.originalItems)) {
        this.alertBox()
          .fire({
            title: 'There are unsaved changes.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Go Back',
            confirmButtonText: 'Ignore & Continue',
          })
          .then(result => {
            if (result.isConfirmed) {
              action()
            }
          });
      } else {
        action()
      }
    },
    getChanged() {
      let changedHeadings = [];
      let changedItems = [];
      const hiddenHeadingsChanged = JSON.stringify(this.headingsInActive) !== JSON.stringify(this.originalHiddenHeadings)
      if (hiddenHeadingsChanged) {
        for (let index = 0; index < this.headingsInActive.length; index += 1) {
          if (!this.originalHiddenHeadings.some((x) => JSON.stringify(x) === JSON.stringify(this.headingsInActive[index]) && this.headingsInActive[index].consumerListIndex === 0)) {
            changedHeadings.push({
              Id: this.headingsInActive[index].id,
              ListIndex: 0,
              ConsumerDescription: this.headingsInActive[index].consumerDescription,
              Consumer: this.headingsInActive[index].consumer,
              Name: this.headingsInActive[index].name,
            });
          }
        }
      }
      const visibleHeadingsChanged = JSON.stringify(this.headingsActive) !== JSON.stringify(this.originalVisibleHeadings)
      if (visibleHeadingsChanged) {
        for (let index = 0; index < this.headingsActive.length; index += 1) {
          if (!this.originalVisibleHeadings.some((x, originalIndex) => JSON.stringify(x) === JSON.stringify(this.headingsActive[index]) && index === originalIndex)) {
            changedHeadings.push({
              Id: this.headingsActive[index].id,
              ListIndex: index + 1,
              ConsumerDescription: this.headingsActive[index].consumerDescription,
              Consumer: this.headingsActive[index].consumer,
              Name: this.headingsActive[index].name,
            });
          }
        }
      }
      if (JSON.stringify(this.items) !== JSON.stringify(this.originalItems)) {
        for (let index = 0; index < this.items.length; index += 1) {
          if (!this.originalItems.some((x, originalIndex) => x.id === this.items[index].id && index === originalIndex && x.consumer === this.items[index].consumer) || this.items[index].listIndex !== index) {
            changedItems.push({
              Id: this.items[index].id,
              ListIndex: index,
              OptionHeadingId: this.items[index].optionHeadingId,
              Consumer: this.items[index].consumer,
              Name: this.items[index].name,
            });
          }
        }
      }
      return {
        changedHeadings,
        changedItems,
      }
    },
    index() {
      this.headingsActive.forEach((item, index) => {
        item.consumerListIndex = index + 1;
      });
    },
    scrollTo(headingId) {
      this.$nextTick(() => {
        document.getElementById(headingId).scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      })
    },
    validateTestMode() {
      const url = `${this.baseURL}${this.brandedCustomer.brandedAlias}?optionControlTestMode=true`
      if (this.noChanges) {
        window.open(url, '_blank');
      } else {
        this.alertBox()
          .fire({
            title: 'There are unsaved changes.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Go Back',
            confirmButtonText: 'Ignore & Continue',
          })
          .then(result => {
            if (result.isConfirmed) {
              window.open(url, '_blank');

            }
          });
      }
    },
    async validateSave() {
      this.alertBox()
        .fire({
          title: 'Save Changes',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.save()
          }
        });
    },
    async save() {
      const groups = []
      const { changedItems, changedHeadings } = this.getChanged()
      this.isLoadingOptionHeadings = true
      this.isLoadingOptionItems = true
      await window.touch.UpdateOptionConfigurations(
        this.customerStore.customer.datasetIds[0],
        groups,
        changedHeadings,
        changedItems,
      );
      this.originalItems = JSON.parse(JSON.stringify(this.items));
      this.originalHiddenHeadings = JSON.parse(JSON.stringify(this.headingsInActive));
      this.index()
      this.originalVisibleHeadings = JSON.parse(JSON.stringify(this.headingsActive));
      this.isLoadingOptionHeadings = false
      this.isLoadingOptionItems = false
      this.editId = undefined
    }
  },
};
</script>
