export async function commonGetCurrencies() {
  return this.parseResponse(await this.authenticatedPost('common/GetCurrencies'), () => {
    throw new Error('Unable to download Currencies');
  });
}

export async function commonGetDatasets() {
  return this.parseResponse(await this.authenticatedPost('setup/GetDatasets'), () => {
    throw new Error('Unable to download Datasets');
  });
}

export async function commonOrganisationDetails() {
  const { data } = await this.post('common/OrganisationDetails');

  return data;
}

export async function getTermsAndConditionsDocument() {
  const response = await this.authenticatedPost('common/ListDocuments', {
    Type: Number(1),
    MyDocuments: true,
  });
  return response.data.documents[0];
}

export async function getPrivacyPolicyDocument() {
  const response = await this.authenticatedPost('common/ListDocuments', {
    Type: Number(2),
    MyDocuments: true,
  });
  return response.data.documents[0];
}

export async function commonGetProductSelectionGroups() {
  return this.parseResponse(await  this.authenticatedPost('common/GetProductSelectionGroups'));
}

export async function commonGetProductSelectionTypes() {
  return this.parseResponse(await this.authenticatedPost('common/GetProductSelectionTypes'));
}

export async function commonGetCustomerStatuses() {
  return this.parseResponse(await this.authenticatedPost('common/GetCustomerStatuses'));
}
