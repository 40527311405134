<template>
  <touch-layout />
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useBasketStore } from '@/pinia/basket';
import { useVisualiserStore } from '@/pinia/visualiser';
import { forceReload } from '@/composables/forceReload';

export default {
  computed: {
    ...mapStores(useAuthStore, useBasketStore, useVisualiserStore),
    url() {
      if (this.touch_business_installation) {
        return `/customer/${this.$route.params.customerId}/sales-dashboard`;
      }
      return '';
    },
  },
  created() {
    forceReload();
  },
  async mounted() {
    await this.visualiserStore.clear();
    await this.basketStore.setCustomerId(this.$route.params.customerId);
    if (await this.basketStore.createQuote()) {
      await this.authStore.setProcessingLevel()
      if (this.basketStore.salesSectorType === this.enums.salesSectorType.RETAIL) {
        const { quoteId } = await this.basketStore.saveQuote(false);
        this.routerReplace(`${this.url}/quote/${quoteId}`);
      } else {
        this.routerReplace(`${this.url}/choose-product`);
      }
    } else {
      this.routerPush('/');
    }
  },
};
</script>
