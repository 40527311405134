<template>
  <modal-window :modal_open="open" label="Saving" :loading="saving" title="Send Customer Quote" @close="$emit('close')">
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="font-medium">Subject</div>
        <input v-model="emailSubject" type="text" class="form-control">
      </div>
      <div class="w-full mt-4 xs:mt-8">
        <div class="font-medium">Body Text</div>
        <textarea v-model="emailBodyText" class="form-control" />
      </div>
    </div>
    <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
      <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
        Cancel
      </button>
      <button class="btn-action btn-lg" @click="send()">Send</button>
    </div>
  </modal-window>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';

export default {
  props: ['job', 'customer', 'open'],
  data() {
    return {
      emailSubject: '',
      emailBodyText: '',
      saving: false
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  mounted() {
    if (this.job.requoteEmailSubject !== null) {
      this.emailSubject = this.job.requoteEmailSubject;
    } else {
      this.emailSubject = this.customer.customer.requoteEmailSubject ?? '';
    }

    if (this.job.requoteEmailBodyText !== null) {
      this.emailBodyText = this.job.requoteEmailBodyText;
    } else {
      this.emailBodyText = this.customer.customer.requoteEmailBodyText ?? '';
    }
  },
  methods: {
    async send() {
      this.saving = true
      await this.touchStore.updateJobRequote({
        contractId: this.job.contractId,
        jobKey: this.job.jobKey,
        emailSubject: this.emailSubject,
        emailBodyText: this.emailBodyText,
      })
        .then((response) => {
          if (response.status === 200) {
            this.touchStore.sendRequoteEmailToConsumer({
              contractId: this.job.contractId,
              jobKey: this.job.jobKey,
            })
              .then(() => {
                this.saving = false
                this.$emit('saved');
                this.$emit('close');
              });
          }
        });
    },
  },
};
</script>
