import { errorCallback, successCallback } from '@/composables/validate';

export async function connectGetConnectSubscriptions() {
  return this.parseResponse(await this.authenticatedPost('connect/GetConnectSubscriptions'));
}

export async function connectGetConnectSources() {
  return this.parseResponse(await this.authenticatedPost('connect/GetConnectSources'));
}

export async function connectUpdateConnectSource(
  id,
  type,
  datasetId,
) {
  return this.parseResponse(
    await this.authenticatedPost('connect/UpdateConnectSource', {
      Id: id,
      Type: type,
      DatasetId: datasetId,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
}

export async function connectUpdateConnectCustomerProductLinks(id, link) {
  return this.parseResponse(
    await this.authenticatedPost('connect/UpdateConnectCustomerProductLinks', {
      DatasetId: id,
      ProductLink: link,
    }),
  );
}

export async function getConnectCodes() {
  return this.parseResponse(await this.authenticatedPost('connect/GetConnectCodes'));
}

export async function createStockSubscription(
  Username,
  Password,
  Code,
) {
  return this.parseResponse(
    await this.authenticatedPost('connect/CreateStockSubscription', {
      Username,
      Password,
      Code,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
}

export async function CreateConnectSubscription(
  Username,
  Password,
  Code,
  ConnectSourceType
) {
  return this.parseResponse(
    await this.authenticatedPost('connect/CreateConnectSubscription', {
      Username,
      Password,
      Code,
      ConnectSourceType
    }),
    errorCallback,
    () => successCallback('Save'),
  );
}
