<template>
  <div class="flex flex-col 3xs:flex-row">
    <div class="flex">
      <div class="w-24 lg:w-32">
        <img v-if="!fallback_image" :src="imgUrl" @error="imgFallback()">
        <img v-else :src="fallback_image">
      </div>
    </div>
    <div class="flex-grow pl-3 flex flex-col">
      <div>
        <strong>{{ extra.description }}</strong>
        <span v-if="extra.customerItemOptionId !== 0"> ({{ extra.optionDescription }})</span>
      </div>
      <div v-html="formatCurrency(basketStore.getCurrency, extra.price / item_quantity)" />
      <div v-if="!extra.useSizesFromProduct">
        <div class="flex items-center">
          <div class="flex flex-col">
            <label v-if="hasLength" :for="extra.itemKey + ':length'" class="mt-2 p-2">
              <span>Length (mm)</span>
            </label>
            <label v-if="hasWidth" :for="extra.itemKey + ':width'" class="mt-2 p-2">
              <span>Width (mm)</span>
            </label>
            <label v-if="hasHeight" :for="extra.itemKey + ':height'" class="mt-2 p-2">
              <span>Height (mm)</span>
            </label>
            <label v-if="hasTime" :for="extra.itemKey + ':time'" class="mt-2 p-2">
              <span>Time</span>
            </label>
          </div>
          <div class="flex-1 flex flex-col ml-3 p-1">
            <input
              v-if="hasLength"
              :id="extra.itemKey + ':length'"
              ref="length"
              :value="extra.length"
              class="mt-2 w-full 2xs:w-24 xs:w-full md:w-24 border border-solid border-gray-400 rounded p-2 text-xs appearance-none"
              type="text"
              @change="updateValues"
            >
            <input
              v-if="hasWidth"
              :id="extra.itemKey + ':width'"
              ref="width"
              :value="extra.width"
              class="mt-2 w-full 2xs:w-24 xs:w-full md:w-24 border border-solid border-gray-400 rounded p-2 text-xs appearance-none"
              type="text"
              @change="updateValues"
            >
            <input
              v-if="hasHeight"
              :id="extra.itemKey + ':height'"
              ref="height"
              :value="extra.height"
              class="mt-2 w-full 2xs:w-24 xs:w-full md:w-24 border border-solid border-gray-400 rounded p-2 text-xs appearance-none"
              type="text"
              @change="updateValues"
            >
            <input
              v-if="hasTime"
              :id="extra.itemKey + ':time'"
              ref="time"
              :value="extra.time"
              class="mt-2 w-full 2xs:w-24 xs:w-full md:w-24 border border-solid border-gray-400 rounded p-2 text-xs appearance-none"
              type="text"
              @change="updateValues"
            >
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-auto">
        <div class="p-1 ml-1 flex items-center mr-2">
          <div class="mr-12 hidden lg:block">Qty</div>
          <div class="flex" style="height: 37px">
            <button
              class="border rounded-l-full p-2"
              :class="{ 'cursor-not-allowed': extra.quantity < 2, 'cursor-wait': qtyLoading }"
              :disabled="extra.quantity < 2 || qtyLoading"
              @click.stop="setQty(extra.quantity - 1)"
            >
              -
            </button>
            <input
              :value="extra.quantity"
              class="w-8 border-t border-b text-center"
              :class="{ 'cursor-wait': qtyLoading }"
              @click.stop
              @change="setQty($event.target.value)"
            >
            <button
              class="border rounded-r-full p-2"
              :class="{ 'cursor-wait': qtyLoading }"
              :disabled="qtyLoading"
              @click.stop="setQty(extra.quantity + 1)"
            >
              +
            </button>
          </div>
        </div>
        <div
          class="border border-gray-300 rounded p-2 ml-0 md:ml-1 m-1 flex hover:bg-gray-200 transition duration-500 ease-in-out"
          @click="$emit('delete')"
        >
          <i class="fal fa-trash-alt" />
          <span class="text-xs flex flex-col justify-around cursor-pointer hidden lg:inline ml-2">
            <span>Delete <span class="">Item</span></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import { formatCurrency } from '@/composables/currency';

export default {
  props: ['item', 'extra'],
  setup() {
    return { formatCurrency }
  },
  data() {
    return {
      qtyLoading: false,
      qty: this.extra.quantity,
      fallback_image: false,
      item_quantity: this.item ? this.item.quantity : 1,
    };
  },
  computed: {
    ...mapStores(useBasketStore),
    imgUrl() {
      return window.touch.imageBaseURL + this.extra.imageUrl;
    },
    hasTime() {
      return [window.enum.unitOfMeasure.FITTING_DAYS].includes(this.extra.unitOfMeasure);
    },
    hasLength() {
      return (
        !this.extra.useSizesFromProduct &&
        [window.enum.unitOfMeasure.LENGTH, window.enum.unitOfMeasure.VOLUME].includes(
          this.extra.unitOfMeasure,
        )
      );
    },
    hasWidth() {
      return (
        !this.extra.useSizesFromProduct &&
        [
          window.enum.unitOfMeasure.WIDTH,
          window.enum.unitOfMeasure.AREA,
          window.enum.unitOfMeasure.PERIMETER,
          window.enum.unitOfMeasure.VOLUME,
        ].includes(this.extra.unitOfMeasure)
      );
    },
    hasHeight() {
      return (
        !this.extra.useSizesFromProduct &&
        [
          window.enum.unitOfMeasure.HEIGHT,
          window.enum.unitOfMeasure.AREA,
          window.enum.unitOfMeasure.PERIMETER,
          window.enum.unitOfMeasure.VOLUME,
        ].includes(this.extra.unitOfMeasure)
      );
    },
  },
  methods: {
    async setQty(qty) {
      this.qty = parseInt(qty, 10);
      await this.updateValues();
    },
    async updateValues() {
      await this.basketStore.updateExistingExtraItem({
        params: {
          Quantity: this.qty,
          Length: this.hasLength ? parseFloat(this.$refs.length.value) : undefined,
          Width: this.hasWidth ? parseFloat(this.$refs.width.value) : undefined,
          Time: this.hasTime ? parseFloat(this.$refs.time.value) : undefined,
          Height: this.hasHeight ? parseFloat(this.$refs.height.value) : undefined,
        },
        itemId: this.extra.key,
      });
      await this.basketStore.refresh(true);
      this.$emit('setBreakdown');
    },
    imgFallback() {
      this.fallback_image = '/images/no-extra-image-provided.png';
    },
  },
};
</script>
