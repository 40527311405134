<template>
  <touch-layout>
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch xs:p-10">
      <div key="title" class="flex my-3 xs:my-0 p-3 xs:p-0 xs:mb-10" data-index="0">
        <div class="flex">
          <span class="text-xl sm:text-2xl flex flex-col justify-around">
            <i class="fal fa-users" />
          </span>
          <span class="text-xl xs:text-2xl sm:text-3xl flex flex-col justify-around pl-3">
            Custom Variables
          </span>
        </div>
      </div>
      <div class="bg-white p-3 xs:p-10 rounded-lg xs:mt-10">
        <div class="flex justify-between">
          <span class="text-base flex flex-col justify-around p-3 pb-0">List:</span>
          <div class="p-3 pb-0">
            <button class="btn" @click="createNewSetting">Create New</button>
          </div>
        </div>
        <hr class="mt-5 mb-5 xs:mt-10 xs:mb-10">
        <table v-if="settings && settings.length > 0" class="table-striped _hover w-full">
          <thead>
            <tr class="hidden xs:table-row">
              <th class="text-left font-normal p-3 text-base">ID</th>
              <th class="text-left font-normal p-3 text-base">Key</th>
              <th class="text-left font-normal p-3 text-base">Value</th>
              <th class="text-left font-normal p-3 text-base" />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(setting, index) in settings"
              :key="index"
              class="cursor-pointer flex flex-col xs:flex-row xs:flex-wrap xs:table-row relative"
            >
              <td class="px-3 py-1 pt-3 md:p-3">
                <span class="xs:hidden"> ID: </span> {{ setting.id }}
              </td>
              <td class="px-3 py-1 md:p-3">
                <span class="xs:hidden"> Key: </span> {{ setting.key }}
              </td>
              <td class="px-3 py-1 md:p-3">
                <span class="xs:hidden"> Value: </span> {{ setting.value }}
              </td>
              <td class="p-3 text-right absolute right-0 xs:relative">
                <!--                  <i class="fal fa-search-plus"></i>-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';

export default {
  data() {
    return {
      settings: [],
      loading: true,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  async mounted() {
    await this.loadFeed();
  },
  methods: {
    async createNewSetting() {
      const { value: formValues } = await this.alertBox().fire({
        title: 'Create new setting',
        html:
          '<h1>Name</h1><input id="swal-input1" class="swal2-input">' +
          '<h2>Content</h2><textarea id="swal-input2" class="swal2-textarea"></textarea>',
        focusConfirm: false,
        preConfirm: () => [
          document.getElementById('swal-input1').value,
          document.getElementById('swal-input2').value,
        ],
      });

      if (formValues) {
        this.authStore.updateCompanyWebSettings({
          key: formValues[0],
          value: formValues[1],
        }).then(() => {
          this.loadFeed();
        });
      }
    },
    async loadFeed() {
      this.authStore.getCompanyWebSettings().then((response) => {
        this.addSettings(response.data.companyWebSettings);
      });
    },
    addSettings(settings) {
      this.settings = settings;
    },
  },
};
</script>
