<template>
  <div class="flex-1 flex flex-col">
    <div class="bg-green-100 border p-3 h-full">
      <div class="border-b font-bold mb-3 pb-3 flex justify-between">
        <div>Quoted At Price</div>
      </div>
      <table class="w-full">
        <tbody>
          <tr>
            <td class="pb-1 w-32">Subtotal:</td>
            <td class="pb-1 text-brand-primary">
              <span v-html="formatCurrency(job.customerCurrency, job.requotePriceBeforeTax)" />
              <span v-if="job.thirdPartyGlassPrice > 0">
                (Including
                <span v-html="formatCurrency(job.customerCurrency, job.thirdPartyGlassPrice)" />
                3rd Party Glass)</span>
            </td>
          </tr>
          <tr>
            <td class="pb-1 w-32">VAT:</td>
            <td
              class="pb-1 text-brand-primary"
              v-html="formatCurrency(job.customerCurrency, job.requoteTax)"
            />
          </tr>
          <tr>
            <td class="w-32">Total:</td>
            <td
              class="text-brand-primary"
              v-html="formatCurrency(job.customerCurrency, job.requotePriceIncludingTax)"
            />
          </tr>
        </tbody>
      </table>
      <button class="btn-action float-right mt-5" @click="$emit('edit-requote')">Re-quote <span class="hidden lg:inline">Customer</span></button>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/composables/currency';

export default {
  props: ['job'],
  setup() {
    return { formatCurrency }
  },
};
</script>

<style scoped></style>
