<template>
  <touch-layout />
</template>

<script>
export default {
  created() {
    this.routerReplace(
      `/order/${this.$route.params.contractId}?orderPaymentStatus=${this.enums.PaymentStatus.SUCCESS}`,
    );
  },
};
</script>
