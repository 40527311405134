<template>
  <div class="p-1 xs:px-2 xs:py-4 w-1/2 xs:w-40 xs:flex-shrink-0 h-full" @click="updateOption()">
    <div
      class="hover-bg-brand-primary_light sm:shadow-md bg-white text-center rounded-lg p-2 md:p-4 cursor-pointer w-full h-full"
      :class="{ 'cursor-wait': loading }"
    >
      <label class="cursor-pointer" :class="{ 'cursor-wait': loading }">
        <div class="cursor-pointer flex flex-col justify-around retail-designer-question-tile">
          <div v-if="option.imageUrl != ''" class="relative inline">
            <api-img
              :src="option.imageUrl"
              class="mx-auto retail-designer-question-tile-image"
              :class="{ border: option.description == 'White' }"
            />
            <div
              v-if="option.isCurrentOption"
              class="rounded-full text-white border absolute bg-brand-primary text-xs flex flex-col justify-around"
              style="width: 20px; height: 20px; bottom: -5px; right: -5px"
            >
              <i class="fal fa-check" />
            </div>
          </div>
          <template v-if="option.imageUrl == ''">
            <div
              class="rounded-full text-white border mx-auto p-1 flex flex-col justify-around"
              :class="{
                'bg-brand-primary': option.isCurrentOption,
                'bg-gray-200': !option.isCurrentOption,
              }"
              style="width: 35px; height: 35px"
            >
              <i v-if="option.isCurrentOption" class="fal fa-check" />
            </div>
          </template>
          <span class="inline-block flex flex-col justify-around text-xs">
            <span v-if="!(option.textEntry && text)">{{ option.description }}</span>
            <span v-if="option.textEntry && text">{{ text }}</span>
          </span>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: ['option', 'loading', 'title', 'text', 'textValidation'],
  mounted() {
    if (this.option.isCurrentOption) {
      if (this.option.textEntry) {
        this.showInputBox();
      }
    }
  },
  methods: {
    updateOption() {
      if (this.option.textEntry) {
        this.showInputBox();
        return;
      }

      this.$emit('update-option', this.option.id);
    },
    showInputBox() {
      this.alertBox()
        .fire({
          title: this.title,
          input: 'text',
          inputLabel: this.option.description,
          inputValue: this.text,
          inputAttributes: {
            maxlength:
              this.textValidation && this.textValidation.maxLength > 0
                ? this.textValidation.maxLength
                : 30,
          },
          inputValidator: (text) => {
            if (!this.textValidation) {
              return undefined;
            }

            const errors = [];

            if (text.length > this.textValidation.maxLength && this.textValidation.maxLength > 0) {
              errors.push(`Must be ${this.textValidation.maxLength} characters or less.`);
            }

            const regex = new RegExp(this.textValidation.regex);

            if (!regex.test(text)) {
              errors.push(this.textValidation.regexError);
            }

            if (errors.length > 0) {
              return `<ul><li>${errors.join('</li><li>')}</li></ul>`;
            }

            return undefined;
          },
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: `Save ${this.title}`,
          cancelButtonText: '<i class="fal fa-times absolute top-0 right-0 p-3"></i>',
          customClass: {
            confirmButton: 'btn-action m-1 btn-lg',
            title: 'designer-popup-swal-title',
            input: 'bg-gray-200 p-2 h-12 rounded border-0 w-full designer-popup-swal-input',
          },
        })
        .then(({ isConfirmed, value: input }) => {
          if (isConfirmed) {
            this.$emit('set-text', input);
          }
        });
    },
  },
};
</script>
