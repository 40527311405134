<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          [`Customer (${customer.company.name})`, `/customer/${customer.customerId}`],
          ['Vouchers'],
        ]"
      />
    </template>
    <template #header_page_title> Vouchers </template>
    <Menu />
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden p-5"
    >
      <div class="p-5 bg-white border">
        <div class="flex flex-col xs:table w-full border-collapse">
          <div class="hidden xs:table-row">
            <b class="table-cell px-1 pb-2">Code</b>
            <b class="table-cell px-1 pb-2">Discount</b>
            <b class="hidden lg:table-cell px-1 pb-2">Limit</b>
            <b class="table-cell px-1 pb-2">Start / End Date</b>
            <b class="sm:hidden lg:table-cell px-1 pb-2">Type</b>
            <div class="table-cell px-1 pb-2">
              <div class="flex gap-2 items-center">
                <FilterDropdown
                  label="Status"
                  query-label="statusFilter"
                  :filters="[
                    { id: enums.discountStatus.DISABLED, name: 'Disabled', dot: 'bg-red-500' },
                    { id: enums.discountStatus.ENABLED, name: 'Enabled', dot: 'bg-green-500' },
                    { id: enums.discountStatus.EXPIRED, name: 'Expired', dot: 'bg-red-500' },
                    { id: enums.discountStatus.REDEEMED, name: 'Redeemed', dot: 'bg-blue-500' }
                  ]"
                />
              </div>
            </div>
          </div>
          <div><div colspan="100%" class="hidden xs:table-cell border-t" /></div>
          <div
            v-for="voucher in vouchers"
            :key="voucher.id" 
            class="table-row border-b last:border-b-0 pb-5 mb-5 xs:brder-b-0 xs:pb-0 xs:mb-0"
          >
            <div class="flex xs:table-cell p-1 xs:px-1 xs:py-4 pr-3">
              <b class="xs:hidden w-24">Code</b>
              <div class="flex gap-2 items-center relative">
                <Tooltip class="hidden xs:inline" :is-left="false" :width="200">
                  <span>{{ voucher.description }}</span>
                </Tooltip>
                <div
                  class="text-white py-1 px-2 rounded cursor-pointer flex gap-5 items-center max-w-xs"
                  :class="[
                    copiedCode === voucher.code ? 'bg-green-600' : 'bg-brand-primary',
                  ]"
                  @click.prevent="copyURL(voucher.code)"
                >
                  <span>{{ voucher.code }}</span>
                  <transition name="fade-in" mode="out-in">
                    <i
                      v-if="copiedCode === voucher.code"
                      class="fal fa-check ml-3 ml-auto"
                    />
                    <i v-else class="fal fa-copy ml-3 ml-auto" />
                  </transition>
                </div>
              </div>
            </div>
            <div class="flex xs:table-cell p-1 xs:px-1 xs:py-4">
              <b class="xs:hidden w-24">Discount</b>
              <strong v-if="voucher.discountType === enums.discountType.PERCENTAGE">
                {{ voucher.discountAmount.toPrecision(3) }}%
              </strong>
              <strong v-else-if="voucher.discountType === enums.discountType.FIXED && currencyCode">
                <span>{{ currencySymbol(currencyCode) + voucher.discountAmount.toPrecision(3) }}</span>
              </strong>
            </div>
            <div class="flex xs:hidden lg:table-cell p-1 xs:px-1 xs:py-4">
              <b class="xs:hidden w-24">Limit</b>
              {{ voucher.usesPerAccount }}
            </div>
            <div class="flex xs:table-cell p-1 xs:px-1 xs:py-4">
              <b class="xs:hidden w-24">Start / <br> End Date</b>
              <div>{{ toLocaleDateString(voucher.startDate) + ' - ' + toLocaleDateString(voucher.endDate) }}</div>
            </div>
            <div class="p-1 xs:px-1 xs:py-4 flex sm:hidden lg:table-cell">
              <b class="xs:hidden w-24">Type</b>
              <span v-if="voucher.perOrderOrItem === enums.discountPerOrderOrItem.ORDER">Order</span>
              <span v-else-if="voucher.perOrderOrItem === enums.discountPerOrderOrItem.ITEM">Item</span>
            </div>
            <div class="xs:w-32 flex xs:table-cell p-1 xs:px-1 xs:py-4">
              <b class="xs:hidden w-24">Status</b>
              <div
                class="text-white text-center px-2 py-1 rounded capitalize"
                :class="{ 'bg-red-500': voucher.status === 0 || voucher.status === 2, 'bg-green-500': voucher.status === 1, 'bg-blue-500': voucher.status === 3 }"
              >
                {{ setDiscountStatus(voucher.status) }}
              </div>
            </div>    
          </div>
        </div>
        <div v-if="vouchers.length === 0" key="none-found" class="flex items-center w-full bg-white p-3 xs:p-10">
          <div>
            No vouchers to show
          </div>
          <a v-if="$route.query.statusFilter" class="ml-auto btn-action" @click.stop="$router.replace({ query: undefined }).catch(() => { });">
            Clear Filters
          </a>
        </div>
      </div>
      <Pagination
        class="mt-3 pb-10"
        :limit="limit"
        :total_items="total"
        @load-feed="listDiscountVouchers"
      />
    </loading>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import { currencySymbol } from '@/composables/currency';
import Menu from '@/components/business/customer/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
import Pagination from '@/components/shared/Pagination.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';

export default {
  components: {
    Menu,
    Breadcrumbs,
    Tooltip,
    Pagination,
    FilterDropdown
  },
  props: {
    customer: Object,
  },
  setup() {
    return { currencySymbol }
  },
  data() {
    return {
      currencyCode: undefined,
      vouchers: [],
      limit: 20,
      offset: 0,
      total: 0,
      loading: true,
      copiedCode: undefined,
      timeout: undefined,
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  watch: {
    '$route.query': {
      async handler() {
        await this.listDiscountVouchers();
      },
    },
  },
  async created() {
    this.currencyCode = await this.getCurrencyCode()
    await this.listDiscountVouchers()
    this.loading = false
  },
  methods: {
    toLocaleDateString(date_string) {
      const date = new Date(date_string)
      const dateString = date.toDateString().split(' ')
      dateString.shift()
      dateString.pop()
      return dateString.join(' ')
    },
    setDiscountStatus(status) {
      const discountStatus = Object.keys(this.enums.discountStatus).map((key) => [key, this.enums.discountStatus[key]]);
      return discountStatus[status.toString()][0].toLowerCase()
    },
    async getCurrencyCode() {
      const currencies = (await this.touchStore.commonGetCurrencies())
      const currency = currencies.find((currency) => currency.isDefault);
      return currency.code
    },
    async listDiscountVouchers(Offset = 0) {
      this.loading = true
      const Status = this.$route.query.statusFilter ? parseInt(this.$route.query.statusFilter) : undefined
      const { vouchers, totalVouchers } = await window.touch.ListDiscountVouchers({ Limit: this.limit, Offset, Status, CustomerIds: [this.customer.customerId] })
      this.vouchers = vouchers
      this.vouchers.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
      this.total = totalVouchers
      this.loading = false
    },
    async copyURL(code) {
      clearTimeout(this.timeout)
      this.copiedCode = undefined;
      this.copiedCode = code;

      try {
        await navigator.clipboard.writeText(code);
      } catch ($e) {
        this.alertBox().fire('Copy Failed');
        this.copiedCode = undefined;
        return;
      }

      this.timeout = setTimeout(() => {
        this.copiedCode = undefined;
      }, 2000);
    },
  }
};
</script>
