<template>
  <transition-group
    appear
    name="staggered-slide"
    tag="div"
    start-delay="startDelay"
    :css="false"
    @before-enter="staggeredSlideBeforeEnter"
    @enter="staggeredSlideEnter"
    @leave="staggeredSlideLeave"
  >
    <slot />
  </transition-group>
</template>

<script>
import { staggeredSlideBeforeEnter, staggeredSlideEnter, staggeredSlideLeave } from '@/composables/animation';

export default {
  props: {
    startDelay: {
      default: 0,
      type: Number,
    },
  },
  setup() {
    return { staggeredSlideBeforeEnter, staggeredSlideEnter, staggeredSlideLeave }
  },
};
</script>
