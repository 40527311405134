<template>
  <Teleport defer to="#portal_popup"> 
    <modal-window :modal_open="true" title="Edit Address" @close="$emit('close')">
      <AddressForm
        :address="address"
        :is-save-button="true"
        @address-updated="addressUpdated"
        @save-address="saveAddress"
      />
    </modal-window>
  </Teleport>
</template>

<script>
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import AddressForm from '@/components/shared/address/AddressForm.vue';

export default {
  components: {
    AddressForm,
  },
  props: ['address', 'branch'],
  data() {
    return {
      manualAddressEntry: true,
      address_picked: false,
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
    formatted_address() {
      return [
        this.address.line1,
        this.address.line2,
        this.address.line3,
        this.address.town,
        this.address.county,
        this.address.code,
      ]
        .filter((val) => val)
        .join(', ');
    },
  },
  methods: {
    addressUpdated(newAddress) {
      this.address.line1 = newAddress.addressLine1;
      this.address.line2 = newAddress.addressLine2;
      this.address.line3 = newAddress.addressLine3;
      this.address.town = newAddress.addressTown;
      this.address.county = newAddress.addressCounty;
      this.address.code = newAddress.addressCode;
      this.address.iso = newAddress.addressIso;
      this.address.latitude = newAddress.latitude;
      this.address.longitude = newAddress.longitude;
      this.address.isPrimary = newAddress.isPrimary;
    },
    async saveAddress() {
      this.loading = true;
      this.address.branchId = this.branch.id;
      await this.customerStore.branchUpdateAddress(this.address);
      this.loading = false;
      this.$emit('updated');
      this.$emit('close');
    },
  },
};
</script>
