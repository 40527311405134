<template>
  <div>Loading...</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { resetPinia } from '@/composables/resetPinia';

export default {
  computed: {
    ...mapStores(useAuthStore)
  },
  mounted() {
    resetPinia()
    this.routerPush(this.authStore.login_url);
  },
};
</script>
