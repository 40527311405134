<template>
  <touch-layout />
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useBasketStore } from '@/pinia/basket';
import { useVisualiserStore } from '@/pinia/visualiser';

export default {
  computed: {
    ...mapStores(useAuthStore, useBasketStore, useVisualiserStore),
  },
  async mounted() {
    this.visualiserStore.clear();

    await this.basketStore.setCustomerId(this.$route.params.customerId);
    await this.basketStore.createTest();
    await this.authStore.setProcessingLevel()

    this.routerPush(`/customer/${this.$route.params.customerId}/sales-dashboard/choose-product`);
  },
};
</script>
