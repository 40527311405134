<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Dealer Network Mode Setup', '/dealer-network-setup'],
          ['Dealer Network Mode Designer'],
        ]"
      />
    </template>
    <template #header_page_title> Dealer Network Mode Designer </template>
    <Website setup="Dealer Network Designer" :customer-id="customerStore.customer.customerId" />
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import Website from '@/components/shared/setup/Website.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Website,
    Breadcrumbs,
  },
  computed: {
    ...mapStores(useCustomerStore),
  }
};
</script>
