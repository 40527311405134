<template>
  <div class="w-full h-full overflow-y-auto scrolling-touch">
    <div ref="custom-styles" class="bg-gray-200 p-5 xs:p-10">
      <div>
        <div class="mx-auto" style="max-width: 1550px">
          <div class="bg-white p-5 xs:p-10 border">
            <ImagesAndText
              v-if="$route.query.active_section === 'images-and-text'"
              ref="images-and-text"
              :setup="setup"
              :settings="settings"
              :enquiry-welcome-image-details="enquiryWelcomeImageDetails"
              :enquiry-completion-image-details="enquiryCompletionImageDetails"
              :virtual-home-banner-details="virtualHomeBannerDetails"
              :virtual-home-background-details="virtualHomeBackgroundDetails"
              @add-file="settings.images.find((x) => x.imageType === $event.imageType).newImage = $event.file"
              @remove-file="resetImage"
            />
            <Emails v-else-if="$route.query.active_section === 'emails'" ref="emails" :setup="setup">
              <template #custom-installer-email>
                <slot name="custom-installer-email" />
              </template>
              <template #text-area-confirmation-text>
                <slot name="text-area-confirmation-text" />
              </template>
            </Emails>
            <Paperwork
              v-else-if="$route.query.active_section === 'paperwork'"
              ref="paperwork"
              :setup="setup"
              :settings="settings"
              :email-equiry-image-details="emailEquiryImageDetails"
              :enquiry-report-first-page-image-details="enquiryReportFirstPageImageDetails"
              :enquiry-report-last-page-image-details="enquiryReportLastPageImageDetails"
              @add-file="settings.images.find((x) => x.imageType === $event.imageType).newImage = $event.file"
              @remove-file="resetImage"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="is_admin_user"
      class="sticky bottom-0 bg-white p-3 xs:p-4 text-right border-gray-300 border-t"
    >
      <div class="flex flex-col xs:flex-row justify-between">
        <div v-if="!demo_mode" class="ml-auto mt-2 xs:mt-0">
          <button class="btn-action btn-lg w-full xs:w-auto" @click.prevent.stop="saveSettings()">
            Save all Changes
          </button>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useStyleStore } from '@/pinia/style';
import ImagesAndText from '@/components/shared/setup/customStyles/ImagesAndText.vue';
import Emails from '@/components/shared/setup/customStyles/Emails.vue';
import Paperwork from '@/components/shared/setup/customStyles/Paperwork.vue';

export default {
  components: {
    ImagesAndText,
    Emails,
    Paperwork,
  },
  props: {
    setup: String,
  },
  data() {
    return {
      loading: true,
      customer: null,
      settings: {
        enquiryPageHeading: '',
        enquiryPageSubHeading: '',
        enquiryPageWelcomeText: '',
        thankYouHeadingText: '',
        thankYouSubHeadingText: '',
        thankYouText: '',
        enquiryPdfFooterHeader: '',
        enquiryPdfFooterText: '',
        enquiryEmailInstallerSubject: '',
        enquiryEmailInstallerBodyText: '',
        enquiryEmailDealerConfirmationText: '',
        enquiryEmailBrandedConfirmationText: '',
        enquiryPageDesignNames: undefined,
        images: [
          {
            imageType: 2,
            src: '',
            newImage: '',
            error: false,
            reset: false,
            default: null,
          },
          {
            imageType: 3,
            src: '',
            newImage: '',
            error: false,
            reset: false,
            default: null,
          },
          {
            imageType: 4,
            src: '',
            newImage: '',
            error: false,
            reset: false,
            default: null,
          },
          {
            imageType: 5,
            src: '',
            newImage: '',
            error: false,
            reset: false,
            default: null,
          },
          {
            imageType: 6,
            src: '',
            newImage: '',
            error: false,
            reset: false,
            default: null,
          },
          {
            imageType: 7,
            src: '',
            newImage: '',
            error: false,
            reset: false,
            default: null,
          },
          {
            imageType: 8,
            src: '',
            newImage: '',
            error: false,
            reset: false,
            default: null,
          },
        ],
      },
      imageTypeEnum: {
        virtualHomeBanner: 2,
        virtualHomeBackground: 3,
        enquiryWelcomeImage: 4,
        enquiryCompletionImage: 5,
        enquiryReportFirstPageImage: 6,
        enquiryReportLastPageImage: 7,
        emailEquiryImage: 8,
      },
    };
  },
  computed: {
    ...mapStores(useStyleStore),
    virtualHomeBannerDetails() {
      return this.settings.images.find((x) => x.imageType === this.imageTypeEnum.virtualHomeBanner);
    },
    virtualHomeBackgroundDetails() {
      return this.settings.images.find(
        (x) => x.imageType === this.imageTypeEnum.virtualHomeBackground,
      );
    },
    enquiryWelcomeImageDetails() {
      return this.settings.images.find(
        (x) => x.imageType === this.imageTypeEnum.enquiryWelcomeImage,
      );
    },
    enquiryCompletionImageDetails() {
      return this.settings.images.find(
        (x) => x.imageType === this.imageTypeEnum.enquiryCompletionImage,
      );
    },
    enquiryReportFirstPageImageDetails() {
      return this.settings.images.find(
        (x) => x.imageType === this.imageTypeEnum.enquiryReportFirstPageImage,
      );
    },
    enquiryReportLastPageImageDetails() {
      return this.settings.images.find(
        (x) => x.imageType === this.imageTypeEnum.enquiryReportLastPageImage,
      );
    },
    emailEquiryImageDetails() {
      return this.settings.images.find((x) => x.imageType === this.imageTypeEnum.emailEquiryImage);
    },
  },
  async created() {
    if (!this.$route.query.active_section) {
      this.$router.replace({ query: { active_section: 'images-and-text' } });
    }
    await this.updateCurrentStyling();
    this.$nextTick(() => {
      this.$watch('$route.query', this.reset);
    })
  },
  methods: {
    async reset() {
      if (this.$refs['custom-styles']) {
        this.$refs['custom-styles'].scrollTop = 0;
      }
      await this.updateCurrentStyling();
    },
    async updateCurrentStyling() {
      this.loading = true;
      const styles = await this.styleStore.getCompanyStyles();
      const vhBanner = this.getImageDetails(this.imageTypeEnum.virtualHomeBanner);
      const vhBackground = this.getImageDetails(this.imageTypeEnum.virtualHomeBackground);
      const enquiryWelcomeImage = this.getImageDetails(this.imageTypeEnum.enquiryWelcomeImage);
      const enquiryCompletionImage = this.getImageDetails(
        this.imageTypeEnum.enquiryCompletionImage,
      );
      const emailEquiryImage = this.getImageDetails(this.imageTypeEnum.emailEquiryImage);
      this.resetSrcsToDefault();
      vhBanner.src = styles?.virtualHomePageStyling?.bannerImageUri;
      vhBanner.default = '/images/visualiser-banner-2.png';
      vhBackground.src = styles?.virtualHomePageStyling?.backgroundImageUri;
      vhBackground.default = `${this.$t('/images/visualiser-start-background.jpg')}`;
      enquiryWelcomeImage.src = styles?.enquiryPageStyling?.welcomeImageUri;
       
      enquiryWelcomeImage.default = `${this.$t(`/images/${this.isDealerNetworkMode ? 'dealer' : 'branded'}-intro`)}-1.png`;
      enquiryCompletionImage.src = styles?.enquiryPageStyling?.completionImageUri;
       
      enquiryCompletionImage.default = this.$t(
        `/images/${this.isDealerNetworkMode ? 'dealer' : 'branded'}-thanks.png`,
      );
      this.settings.enquiryPageHeading = styles?.enquiryPageStyling?.welcomeHeadingText;
      this.settings.enquiryPageSubHeading = styles?.enquiryPageStyling?.welcomeSubHeadingText;
      this.settings.enquiryPageWelcomeText = styles?.enquiryPageStyling?.welcomeText;
      this.settings.thankYouHeadingText = styles?.enquiryPageStyling?.thankYouHeadingText;
      this.settings.thankYouSubHeadingText = styles?.enquiryPageStyling?.thankYouSubHeadingText;
      this.settings.thankYouText = styles?.enquiryPageStyling?.thankYouText;
      const indexEnquiryReportFirstPageImage = this.settings.images.findIndex((x) => x.imageType === this.imageTypeEnum.enquiryReportFirstPageImage)
      this.settings.images[indexEnquiryReportFirstPageImage] = {
        src: styles?.enquiryReportStyling?.firstPageImageUri,
        imageType: this.imageTypeEnum.enquiryReportFirstPageImage,
        reset: false
      }
      const indexEnquiryReportLastPageImage = this.settings.images.findIndex((x) => x.imageType === this.imageTypeEnum.enquiryReportLastPageImage)
      this.settings.images[indexEnquiryReportLastPageImage] = {
        src: styles?.enquiryReportStyling?.lastPageImageUri,
        imageType: this.imageTypeEnum.enquiryReportLastPageImage,
        reset: false
      }
      this.settings.enquiryPdfFooterHeader = styles?.enquiryReportStyling?.footerHeader;
      this.settings.enquiryPdfFooterText = styles?.enquiryReportStyling?.footerText;
      this.settings.enquiryEmailDealerConfirmationText =
        styles?.enquiryEmailStyling?.dealerConfirmationText;
      this.settings.enquiryEmailBrandedConfirmationText =
        styles?.enquiryEmailStyling?.brandedConfirmationText;
      this.settings.enquiryPageDesignNames = styles?.enquiryPageStyling?.showDesignNames;

      this.$emit('setInstaller', {
        installerSubject: styles?.enquiryEmailStyling?.installerSubject,
        installerBodyText: styles?.enquiryEmailStyling?.installerBodyText,
        brandedConfirmationText: styles?.enquiryEmailStyling?.brandedConfirmationText
      });

      emailEquiryImage.src = styles?.enquiryEmailStyling?.headerImageUri;
      this.loading = false;
    },
    resetSrcsToDefault() {
      this.settings.images = this.settings.images.map((x) => {
        x.src = '';
        return x;
      });
    },
    resetImage(imageType) {
      const index = this.settings.images.findIndex((x) => x.imageType === imageType)
      this.settings.images[index] = { ...this.settings.images[index], reset: true, newImage: null }
    },
    getImageDetails(imageType) {
      return this.settings.images.find((x) => x.imageType === imageType);
    },
    async saveSettings() {
      this.loading = true;
      if (this.settings.images.filter((x) => x.reset).length) {
        await this.styleStore.deleteCompanyStyling({
          virtualHomeBanner: this.virtualHomeBannerDetails.reset,
          virtualHomeBackground: this.virtualHomeBackgroundDetails.reset,
          enquiryWelcomeImage: this.enquiryWelcomeImageDetails.reset,
          enquiryCompletionImage: this.enquiryCompletionImageDetails.reset,
          enquiryPDFFirstImage: this.enquiryReportFirstPageImageDetails.reset,
          enquiryPDFLastImage: this.enquiryReportLastPageImageDetails.reset,
          emailEquiryImage: this.emailEquiryImageDetails.reset,
        });
         
        this.settings.images = this.settings.images.map((x) => {
          x.reset = false;
          return x;
        });
         
      }

      await this.styleStore.updateCompanyStyles({
        virtualHomeBanner: this.virtualHomeBannerDetails.newImage,
        virtualHomeBackground: this.virtualHomeBackgroundDetails.newImage,
        enquiryWelcomeImage: this.enquiryWelcomeImageDetails.newImage,
        enquiryCompletionImage: this.enquiryCompletionImageDetails.newImage,
        enquiryPDFFirstImage: this.enquiryReportFirstPageImageDetails.newImage,
        enquiryPDFLastImage: this.enquiryReportLastPageImageDetails.newImage,
        enquiryHeader: this.settings.enquiryPageHeading,
        enquirySubHeader: this.settings.enquiryPageSubHeading,
        enquiryWelcome: this.settings.enquiryPageWelcomeText,
        emailEquiryImage: this.emailEquiryImageDetails.newImage,
        thankYouHeadingText: this.settings.thankYouHeadingText,
        thankYouSubHeadingText: this.settings.thankYouSubHeadingText,
        thankYouText: this.settings.thankYouText,
        enquiryPdfFooterHeader: this.settings.enquiryPdfFooterHeader,
        enquiryPdfFooterText: this.settings.enquiryPdfFooterText,
        enquiryEmailInstallerSubject: this.settings.enquiryEmailInstallerSubject,
        enquiryEmailInstallerBodyText: this.settings.enquiryEmailInstallerBodyText,
        enquiryEmailDealerConfirmationText: this.settings.enquiryEmailDealerConfirmationText,
        enquiryEmailBrandedConfirmationText: this.settings.enquiryEmailBrandedConfirmationText,
        enquiryPageDesignNames: this.settings.enquiryPageDesignNames,
      });
      await this.updateCurrentStyling();
    },
  },
};
</script>
