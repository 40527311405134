<template>
  <router-view />
</template>

<script>

import { useAuthStore } from '@/pinia/auth';

export default {
  async beforeRouteEnter(to, from, next) {
    const authStore = useAuthStore();
    if (authStore.admin) {
      next();
    } else {
      window.alertBox.fire({
        title: 'Unauthorised Access',
        html: 'Redirected user to home page.',
      });
      next('/');
    }
  },
};

</script>
