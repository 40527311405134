<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        v-if="quote"
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          ['Customer (' + customer.company.name + ')', '/customer/' + customer.customerId],
          ['Sales Dashboard', '/customer/' + customer.customerId + '/sales-dashboard'],
          ['Quote ' + quote.customerCounter],
        ]"
      />
    </template>
    <template v-if="quote && $refs.quoteComponent.job" #header_page_title>
      <div>
        #{{ quote.customerCounter
        }}<span v-show="quote.jobs.length > 1"> - {{ $refs.quoteComponent.job.jobKey }}</span>
      </div>
    </template>
    <template v-if="quote && $refs.quoteComponent.job" #header_page_actions>
      <div class="flex md:px-10 gap-5 items-center pr-3">
        <div
          v-if="customer.customer.salesSectorType === enums.salesSectorType.RETAIL"
          class="hidden xs:block"
        >
          <span class="font-medium mr-2">Sales Sector</span>
          <span class="text-brand-primary whitespace-nowrap"> Retail </span>
        </div>
        <div class="hidden xs:block">
          <span class="font-medium mr-2">Reference</span>
          <span class="text-brand-primary whitespace-nowrap">
            {{ quote.reference }}
            <span v-show="quote.jobs.length > 1">({{ $refs.quoteComponent.job.reference }})</span>
          </span>
        </div>
        <div v-if="quote.childContractIds && quote.childContractIds.length > 0">
          <router-link :to="`/customer/${$route.params.customerId}/sales-dashboard/order/${quote.childContractIds[0]}`" class="btn-action">
            View Order
          </router-link>
        </div>
      </div>
    </template>
    <QuoteComponent
      ref="quoteComponent"
      :can-skip-sop="canSkipSop"
      breakdown="Price"
      :customer="customer"
      @set-visibility="setVisibility"
      @set-customer-id="setCustomerId"
      @redirect-to-basket="redirectToBasket"
      @redirect-to-checkout="redirectToCheckout"
      @set-quote="setQuote"
      @is-order-job="isOrderJob"
      @is-edit-job="(isEditJob) => isEditJob()"
    >
      <template v-if="quote && !authStore.accountSettings.emailOff" #send-quote-notification>
        <send-quote-notification
          :job="$refs.quoteComponent.job"
          @reload="$refs.quoteComponent.sentEmail"
        />
      </template>
      <template #quote_pdf>
        <div v-if="quote" class="m-1">
          <a class="btn-action" @click="$refs.quoteComponent.getQuotePDF()"> Download Quote PDF </a>
        </div>
      </template>
      <template #portal_ctas>
        <div key="action-btns" class="xs:flex flex-wrap -mx-3 mt-3">
          <div
            v-if="!quote.finalised && customer &&
              customer.customer.salesSectorType === enums.salesSectorType.RETAIL &&
              $refs.quoteComponent.sortedItems.length > 0"
            class="flex-1 p-3"
          >
            <a
              class="btn-action whitespace-nowrap font-medium flex justify-between w-full p-3 px-6 relative"
              @click="$refs.quoteComponent.showSurveyPdf()"
            >
              Download Survey Sheet
              <i class="fa fa-file-download text-sm" />
              <info-popup
                :id="'job:download_survey_sheet'"
                class="-m-3 absolute right-0 top-0"
                :info_message="'If you wish to carry out a survey, you can download and print a copy of the quote to add technical details. These can then be added to the quote via the edit button.'"
                :next_id="'job:generate_customer_quote'"
              />
            </a>
          </div>
        </div>
      </template>
      <template #quote-stages>
        <QuoteStagesDropdown
          v-if="$refs.quoteComponent"
          class="flex-1"
          placeholder="None"
          :contract-id="$route.params.id"
          :current-staff-id="$refs.quoteComponent.job.customQuoteStageId"
        />
      </template>
      <template #surveyor>
        <ContractRolesDropdown
          v-if="$refs.quoteComponent"
          class="flex-1"
          :contract-id="$route.params.id"
          :job-key="$refs.quoteComponent.job.jobKey"
          :current-staff-id="$refs.quoteComponent.job.surveyorId"
          placeholder="Surveyor"
          :role="16"
        />
      </template>
      <template #sales-person>
        <ContractRolesDropdown
          v-if="$refs.quoteComponent"
          class="flex-1"
          :contract-id="$route.params.id"
          :job-key="$refs.quoteComponent.job.jobKey"
          :current-staff-id="$refs.quoteComponent.job.salesContactId"
          placeholder="Sales"
          :role="8"
        />
      </template>
      <template
        v-if="customer && customer.customer.salesSectorType === enums.salesSectorType.RETAIL"
        #consumer_details
      >
        <div key="consumer-details" data-index="0" class="bg-white p-3 border">
          <consumer-details
            v-if="$refs.quoteComponent"
            :read-only="$refs.quoteComponent.readOnly"
            :consumer="$refs.quoteComponent.quote.consumer"
            @edit="$refs.quoteComponent.edit_consumer_open = true"
          />
        </div>
      </template>
      <template #financial_breakdown>
        <div v-if="is_admin_user && false" class="mt-auto">
          <button class="btn-action w-full mt-5" @click="getFinancialBreakdown">
            Financial Breakdown
          </button>
        </div>
      </template>
      <template #download_history>
        <DownloadHistory :customer="customer" />
      </template>
    </QuoteComponent>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useBasketStore } from '@/pinia/basket';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SendQuoteNotification from '@/components/shared/job/send-quote-notification.vue';
import QuoteComponent from '@/components/shared/Quote.vue';
import QuoteStagesDropdown from '@/components/shared/QuoteStagesDropdown.vue';
import ContractRolesDropdown from '@/components/shared/ContractRolesDropdown.vue';
import ConsumerDetails from '@/components/shared/job/ConsumerDetails.vue';
import DownloadHistory from '@/components/business/customer/DownloadHistory.vue';

export default {
  components: {
    QuoteComponent,
    Breadcrumbs,
    SendQuoteNotification,
    QuoteStagesDropdown,
    ContractRolesDropdown,
    ConsumerDetails,
    DownloadHistory
  },
  props: {
    customer: Object
  },
  data() {
    return {
      quote: undefined,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useBasketStore),
    canSkipSop() {
      return this.customer?.customer?.skipSopOrderAcceptance;
    },
  },
  methods: {
    setQuote(quote) {
      this.quote = quote;
    },
    async setVisibility() {
      const res = await window.touch.contractUpdateVisibility(this.order.id, {
        visibleToPortal: !this.order.visibleToPortal,
      });
      if (res.status === 200) {
        this.order.visibleToPortal = !this.order.visibleToPortal;
        window.alertBox.fire('Order is now shown to portal');
      }
    },
    async setCustomerId(customerId) {
      await this.basketStore.setCustomerId(customerId);
    },
    redirectToBasket() {
      this.routerPush(`/customer/${this.$route.params.customerId}/sales-dashboard/basket`);
    },
    async redirectToCheckout(orderId) {
      if (this.canSkipSop) {
        this.routerPush(`/customer/${this.$route.params.customerId}/sales-dashboard/checkout`);
      } else {
        await window.touch.processingSaveContract(this.$route.params.customerId, orderId);
        await this.routerPush(`/customer/${this.basketStore.customerId}/sales-dashboard`);
      }
    },
    async getFinancialBreakdown() {
      await window.touch.downloadPriceBreakdown(this.$route.params.id, this.$refs.quoteComponent.jobId)
    },
    isOrderJob(isOrderJob) {
      if (this.customer.customer.generalLicenseStatus === window.enum.generalLicenseStatus.DISABLED) {
        this.alertBox().fire({
          title: 'This account is not active, please contact your fabricator',
        });
      } else {
        isOrderJob()
      }
    }
  },
};
</script>
