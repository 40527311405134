import moment from 'moment';
moment.locale(navigator.language);

export const momentDate = (date, format) => moment(date, format)

export const isValidDate = (date) =>
  date && Object.prototype.toString.call(date) === '[object Date]' && !Number.isNaN(date);

export const addMinutesToDate = (date, minutes) => new Date(date.getTime() + minutes * 60000);

export const addDays = (date, days) => {
  const dateCopy = new Date(date.getTime());
  dateCopy.setDate(dateCopy.getDate() + days);
  return dateCopy;
};

export const getDateFromJsDate = (date) => momentDate(date).format('YYYY-MM-DD');

export const getAllDatesBetweenDatesInclusive = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate.getTime());
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dates;
};

export const todaysDate = moment().format('ddd, LL');

export function formatDate(date, format = 'DD/MM/YYYY') {
  return momentDate(date).format(format);
}

export function formatHumanDate(date) {
  return momentDate(date).fromNow();
}

export function expiringInXDays(date, days){
  if (date) {
    const inXDaysTime = new Date()
    inXDaysTime.setDate(inXDaysTime.getDate() + days);
    if (inXDaysTime > new Date(date) && new Date(date) > new Date()) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
