<template>
  <Teleport defer to="#portal_popup">
    <modal-window :modal_open="modal_open" title="Edit Tax Rates" @close="$emit('close')">
      <div class="flex flex-wrap">
        <div class="w-full mt-4 xs:w-1/2 relative xs:pr-5">
          <div class="font-medium">Default Tax Rate</div>
          <select v-model="defaultRate" class="form-control">
            <option v-for="(taxRate, index) in tax_rates" :key="index" :value="taxRate.name">
              {{ taxRate.name }}
            </option>
          </select>
        </div>
        <div class="w-full mt-4 xs:w-1/2 relativ xs:pl-5">
          <div class="font-medium">VAT Registration Number</div>
          <div class="flex items-center">
            <input
              v-model="vatRegistrationNumber"
              :disabled="!is_admin_user || demo_mode"
              type="text"
              class="form-control"
            >
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div
          v-for="(tax_rate, index) in tax_rates"
          :key="index"
          class="w-full mt-4 xs:w-1/2 relative"
          :class="{ 'xs:pr-5': index % 2 === 0, 'xs:pl-5': index % 2 !== 0, 'xs:mt-8': index > 1 }"
        >
          <div class="font-medium">
            {{ tax_rate.name }}
          </div>
          <div class="flex items-center">
            <input v-model="tax_rate.value" class="form-control">
            <div class="ml-3 cursor-pointer" @click="deleteTaxRate(tax_rate.id)">
              <i class="fal fa-trash text-red-600" />
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveTaxRate">Save Changes</button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import validate from 'validate.js';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';

export default {
  props: ['prop_tax_rates', 'modal_open', 'customer'],
  data() {
    return {
      loading: true,
      tax_rates: JSON.parse(JSON.stringify(this.prop_tax_rates)),
      vatRegistrationNumber: this.customer.eCommerceIntegration.vatRegistrationNumber ?? '',
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
    defaultRate: {
      get() {
        return this.tax_rates?.find((x) => x.isDefault)?.name ?? '';
      },
      set(name) {
        const existingDefault = this.tax_rates.find((x) => x.isDefault);
        if (existingDefault !== undefined) {
          existingDefault.isDefault = false;
        }
        const newDefault = this.tax_rates.find((x) => x.name === name);
        if (newDefault !== undefined) {
          newDefault.isDefault = true;
        }
      },
    },
  },
  methods: {
    validateTaxRate(taxRate) {
      return validate(
        {
          name: taxRate.name,
          value: taxRate.value,
        },
        {
          name: {
            presence: { allowEmpty: false },
          },
          value: {
            presence: { allowEmpty: false },
            numericality: true,
          },
        },
      );
    },
    async saveTaxRate() {
      let validationErrors;

      if (
        this.tax_rates.some((taxRate) => {
          validationErrors = this.validateTaxRate(taxRate);
          return validationErrors;
        })
      ) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      if (this.vatRegistrationNumber !== this.customer.eCommerceIntegration.vatRegistrationNumber) {
        const ecommerceUpdates = {
          ECommerceIntegration: {
            vatRegistrationNumber: this.vatRegistrationNumber,
          },
        };
        await this.customerStore.customerUpdateRaw({
          ...ecommerceUpdates,
          CustomerId: this.customer.customerId,
        });
        const customer = await this.customerStore.customerGet(this.customerStore.customer.customerId)
        this.customerStore.setCustomer(customer)
      }

      await Promise.all(this.tax_rates.map((taxRate) => this.customerStore.staffUpdateTaxRate(taxRate)));

      this.$emit('updated');
      this.$emit('close');
    },
    async deleteTaxRate(id) {
      this.alertBox()
        .fire({
          title: 'Are you sure?',
          text: 'Are you sure you want to delete this Tax Rate?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then((result) => {
          if (result.value) {
            this.customerStore.staffDeleteTaxRate(id).then(() => {
              this.$emit('updated');
              this.$emit('close');
            });
          }
        });
    },
  },
};
</script>
