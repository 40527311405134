<template>
  <div>
    <hr class="-mx-10">
    <div class="flex flex-wrap-reverse justify-between items-center m-3 xs:m-10 mb-0">
      <div class="text-2xl font-medium" v-text="'Working Days'" />
    </div>
    <div class="flex flex-col gap-5 xs:flex-row flex-wrap bg-white border m-3 xs:m-10 mt-4 relative p-3">
      <div
        v-for="day in Object.keys(WorkingDaysEnum).filter(
          (day) => workingDays & WorkingDaysEnum[day],
        )"
        :key="day"
        class="flex flex-row items-center mr-4 gap-2"
      >
        <i class="fas fa-check-square text-green-500 text-lg" />
        {{ day }}
      </div>
      <div class="ml-auto mr-2">
        <div
          class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
          @click="isEditing = true"
        >
          <i class="fa fa-pencil" />
        </div>
      </div>
    </div>
    <Teleport v-if="isEditing" defer to="#portal_popup"> 
      <modal-window :modal_open="true" title="Working Days" @close="isEditing = false">
        <div class="flex flex-row flex-wrap bg-white border relative p-3 mx-3 my-5">
          <div v-for="day in Object.keys(WorkingDaysEnum)" :key="day" class="flex flex-row">
            {{ `${WorkingDaysEnum[day] === 1 ? "" : "| "}${day}` }}
            <input
              type="checkbox"
              class="mr-2 ml-2"
              :checked="workingDays & WorkingDaysEnum[day]"
              @change="updateDays(WorkingDaysEnum[day], !(workingDays & WorkingDaysEnum[day]))"
            >
          </div>
        </div>
        <div class="text-right mt-auto flex flex-col 2xs:block">
          <button
            class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0"
            @click.prevent="getDays(), (isEditing = false)"
          >
            Cancel
          </button>
          <button class="btn-action btn-lg" @click.prevent.stop="uploadDays">
            Save Working Days
          </button>
        </div>
      </modal-window>
    </Teleport>
  </div>
</template>
<script>
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';

const WorkingDaysEnum = {
  Sunday: 1,
  Monday: 2,
  Tuesday: 4,
  Wednesday: 8,
  Thursday: 16,
  Friday: 32,
  Saturday: 64,
};

export default {
  data() {
    return {
      originalWorkingDays: 0,
      workingDays: 0,
      dayNames: [],
      WorkingDaysEnum,
      uploading: false,
      companyId: undefined,
      isEditing: false,
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
    uploadEnabled() {
      return this.originalWorkingDays !== this.workingDays && !this.uploading;
    },
  },
  async mounted() {
    this.getDays();
    this.companyId = this.customerStore.customer?.companyId;
  },
  methods: {
    updateDays(dayIntVal, toggledOn) {
      if (toggledOn) {
        this.workingDays = this.workingDays | dayIntVal;
      } else {
        this.workingDays = this.workingDays & ~dayIntVal;
      }
    },
    async uploadDays() {
      if (this.uploading) {
        return;
      }
      this.uploading = true;
      await window.touch.organisationUpdate(this.companyId, undefined, undefined, this.workingDays);
      this.getDays();
      this.uploading = false;
      this.isEditing = false;
    },
    async getDays() {
      this.workingDays = (await window.touch.organisationGet())?.company?.workingDays;
      this.originalWorkingDays = this.workingDays;
    },
  },
};
</script>
