<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['My Account', '/manage-touch-account/company-details'],
          ['Custom Styles'],
        ]"
      />
    </template>
    <template #header_page_title> Custom Styles </template>
    <Menu />
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden">
      <CustomStyles />
    </div>
  </touch-layout>
</template>

<script>
import CustomStyles from '@/components/business/manageTouchAccount/CustomStyles.vue';
import Menu from '@/components/business/manageTouchAccount/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Menu,
    CustomStyles,
    Breadcrumbs,
  },
};
</script>
