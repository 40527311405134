<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Resource Centre', '/marketing'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex items-center gap-2">
        Resource Centre
        <help-point freshdesk-article-id="101000455645" />
      </div>
    </template>
    <Marketing ref="marketing" @redirect-to-u-r-l="redirectToURL">
      <template #manage_marketing>
        <add-file
          v-if="add_file_modal_open"
          :categories="$refs.marketing.categories"
          @added="$refs.marketing.loadMarketingItemsByCategory"
          @close="add_file_modal_open = false"
        />
        <add-video
          v-if="add_video_modal_open"
          :categories="$refs.marketing.categories"
          @added="$refs.marketing.loadMarketingItemsByCategory"
          @close="add_video_modal_open = false"
        />
        <div class="flex mx-auto 2xs:mr-0 gap-1 items-center">
          <div>
            <a class="btn-action" @click="add_file_modal_open = true">Add File</a>
          </div>
          <div>
            <a class="btn-action" @click="add_video_modal_open = true">Add Video</a>
          </div>
          <div>
            <router-link to="/manage-downloads/category" class="btn-action">
              Manage Categories
            </router-link>
          </div>
        </div>
      </template>
    </Marketing>
  </touch-layout>
</template>

<script>
import Marketing from '@/components/shared/Marketing.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import HelpPoint from '@/components/shared/HelpPoint.vue';
import AddFile from '@/components/shared/marketing/AddFile.vue';
import AddVideo from '@/components/shared/marketing/AddVideo.vue';

export default {
  components: {
    Marketing,
    Breadcrumbs,
    HelpPoint,
    AddFile,
    AddVideo,
  },
  data() {
    return {
      add_file_modal_open: false,
      add_video_modal_open: false,
    };
  },
  methods: {
    redirectToURL() {
      this.$router.push({ name: 'Manage Download Categories' });
    },
  },
};
</script>
