<template>
  <div>
    <div class="flex flex-col">
      <div class="flex gap-2">
        <div class="font-medium">Linked Branches</div>
        <div v-if="matchingBranches.filter(x => !x.delete && !x.isPrimary).length > 0">
          <strong class="cursor-pointer underline text-blue-600" @click="setPrimary = true">Set Primary</strong>
          <modal-window
            :modal_open="setPrimary"
            title="Set Primary Branch"
            :is-form="false"
            @close="setPrimary = false"
          >
            <div class="flex items-center gap-2">
              <div
                v-for="branch in matchingBranches.filter(x => !x.delete)"
                :key="branch.id"
                class="gap-2 px-4 py-2 rounded-lg cursor-pointer"
                :class="[branch.isPrimary ? 'bg-blue-500 text-white pointer-events-none' : 'bg-gray-200']"
                @click="setPrimaryBranch(branch.branchCompanyId)"
              >
                <span v-if="branch.name">{{ branch.name }}</span>
                <span v-else>{{ availableBranches.find(x => x.id === branch.branchCompanyId).name }}</span>
              </div>
            </div>
          </modal-window>
        </div>
      </div>
      <div>
        <div class="flex items-center gap-2" @click.stop>
          <div class="flex-auto font-medium">
            <input
              ref="branchInput"
              v-model="search"
              placeholder="Search branch..."
              class="form-control"
            >
          </div>
        </div>
        <div class="relative">
          <div
            v-if="search.length > 0"
            class="absolute top-0 bg-gray-300 flex flex-col gap-2 w-full "
          >
            <div
              v-for="branch in filteredBranches"
              :key="branch.id"
              class="mr-2 cursor-pointer hover:bg-gray-400 p-2 w-full"
              @click="addBranch(branch)"
            >
              <span>{{ branch.name }}</span>
            </div>
          </div>
        </div>
        <div class="col-span-2 mt-2 flex flex-wrap gap-2">
          <div
            v-for="branch in matchingBranches.filter((x) => !x.delete)"
            :key="branch.id"
            class="flex items-center gap-2 px-4 py-2 rounded-lg cursor-pointer"
            :class="[branch.isPrimary ? 'bg-blue-500 text-white' : 'bg-gray-200']"
            @click="removeBranch(branch)"
          >
            <span v-if="branch.name">{{ branch.name }}</span>
            <span v-else>{{ availableBranches.find(x => x.id === branch.branchCompanyId).name }}</span>
            <i class="fa fa-times" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    availableBranches: Array,
    branches: Array
  },
  data() {
    return {
      postCode: '',
      search: '',
      matchingBranches: [],
      originalBranches: [],
      setPrimary: false
    };
  },
  computed: {
    filteredBranches() {
      return this.availableBranches
        .filter(
          (x) =>
            x.name.toLowerCase().includes(this.search.toLowerCase()) &&
            !this.matchingBranches.some((y) => y.branchCompanyId === x.id && !y.delete),
        )
        .slice(0, 5);
    },
  },
  async created() {
    this.matchingBranches = this.branches || []
    this.originalBranches = JSON.parse(JSON.stringify(this.matchingBranches));
  },
  methods: {
    addBranch(branch) {
      this.search = '',
        this.$refs.branchInput.focus()
      if (this.matchingBranches.some(x => x.branchCompanyId === branch.id && x.delete)) {
        const index = this.matchingBranches.findIndex((x) => x.branchCompanyId === branch.id);
        this.matchingBranches[index].delete = false
        if (this.matchingBranches.filter(x => x.isPrimary).length === 0) {
          this.matchingBranches[index].isPrimary = true
        } else {
          this.matchingBranches[index].isPrimary = false
        }
      }
      else if (this.matchingBranches.filter(x => x.isPrimary).length === 0) {
        this.matchingBranches.push({ branchCompanyId: branch.id, name: branch.name, isPrimary: true })
      } else {
        this.matchingBranches.push({ branchCompanyId: branch.id, name: branch.name })
      }
      this.$emit('setBranches', this.matchingBranches)
    },
    removeBranch(branch) {
      const index = this.matchingBranches.findIndex((x) => x.branchCompanyId === branch.branchCompanyId);
      if (branch.branchCompanyId && this.originalBranches.some((x) => x.branchCompanyId === branch.branchCompanyId)) {
        this.matchingBranches[index].delete = true
        this.matchingBranches[index].isPrimary = false
      } else {
        this.matchingBranches.splice(index, 1);
      }
      this.$emit('setBranches', this.matchingBranches)
    },
    setPrimaryBranch(branchId) {
      for (let i = 0; i < this.matchingBranches.length; i++) {
        this.matchingBranches[i].isPrimary = false
      }

      const index = this.matchingBranches.findIndex(x => x.branchCompanyId === branchId)
      this.matchingBranches[index].isPrimary = true
      this.$emit('setBranches', this.matchingBranches)
      this.setPrimary = false
    }
  }
};
</script>

<style scoped>
.container {
  min-height: 650px;
}
</style>
