<template>
  <aside
    tabindex="0"
    class="bg-white shadow-xl sm:w-1/3 overflow-y-auto scrolling-touch p-3 2xs:p-5 xl:p-10 h-full absolute left-0 right-0 bottom-0 sm:relative z-10 sm:z-auto"
    @focusout="close()"
  >
    <div class="m-2 md:p-5 lg:p-10 m-auto">
      <div class="cursor-pointer hover:text-gray-800" @click="close()">
        <i class="fal fa-times" /> <span class="pl-4">Close</span>
      </div>
    </div>
    <div class="text-center border p-6 mb-6">
      <div class="text-xl mb-3">
        <i class="fa fa-envelope-open-o mr-2" />
        <strong>Send to Customer</strong>
      </div>
      <div>
        <p>Send a copy of this quote to your customer.</p>
      </div>
      <div class="mt-4">
        <a
          class="btn-action btn-lg w-full"
          href="https://projects.invisionapp.com/share/XPYI8TKNDYW#/screens/475021817_Deposit"
          target="_blank"
        >SENT TO CUSTOMER</a>
      </div>
    </div>
    <div class="text-center border p-6">
      <div class="text-xl mb-3">
        <i class="far fa-file-contract mr-2" />
        <strong>Contract Mode</strong>
      </div>
      <div>
        <p>Ask your customer to pay a deposit for this basket.</p>
      </div>
      <div class="w-full mt-4">
        <a
          class="btn-action btn-lg w-full"
          href="https://projects.invisionapp.com/share/XPYI8TKNDYW#/screens/475021817_Deposit"
          target="_blank"
        >ENTER CONTRACT MODE</a>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
