<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['Basket']]" />
    </template>
    <template #header_page_title> Basket </template>
    <template #header_page_actions>
      <div class="pr-3 md:px-10 gap-5 flex items-center flex items-center cursor-pointer">
        <div class="flex gap-2 items-center" @click="isCostsHidden = !isCostsHidden">
          <div class="font-medium whitespace-nowrap">
            <b v-text="!isCostsHidden ? 'Costs On' : 'Costs Off'" />
          </div>
          <div class="relative">
            <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
            <div class="switch-toggle" :class="{ '_active': !isCostsHidden }" />
          </div>
        </div>
        <div v-if="availableBranches.length > 0">
          <FilterDropdown
            label="Branch"
            query-label="branchFilter"
            :filters="availableBranches"
            :default-required="true"
          />
        </div>
      </div>
    </template>
    <Basket
      v-if="customer"
      :customer="customer"
      :can-skip-sop="canSkipSop"
      :show-place-order-button="showPlaceOrderButton"
      :show-save-product-button="showSaveProductButton"
      :is-costs-hidden="isCostsHidden"
      :basket-items="basketStore.basket"
      :basket-main-items="basketMainItems"
      :hide-virtual-home-banner="hideVirtualHomeBanner"
      url=""
      breakdown="Cost"
      @place-order-process="placeOrderProcess"
      @save-quote="saveQuote"
      @set-available-branches="setAvailableBranches"
    >
      <template #pricing="{ basketCurrency, price, quantity, override }">
        <div :class="{ 'ml-auto': !quantity }">
          <div
            v-if="((override || basketMainItems.some(x => x.override === true)) && !quantity && !basketStore.job.discountApplied)"
            class="line-through opacity-50"
            v-html="formatCurrency(
              basketCurrency,
              basketStore.job.calculatedPriceTaxable + basketStore.deliveryCharge + basketStore.surveyPrice + basketStore.fittingPrice
            )"
          />
          <div class="flex gap-2">
            <div v-html="formatCurrency(basketCurrency, price)" />
            <span v-if="quantity > 1"> (<span class="font-medium" v-html="formatCurrency(basketCurrency, price / quantity)" /> each) </span>  
          </div>
        </div>
      </template>
    </Basket>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { useBasketStore } from '@/pinia/basket';
import { useStyleStore } from '@/pinia/style';
import { formatCurrency } from '@/composables/currency';
import Basket from '@/components/shared/basket/Basket.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';

export default {
  components: {
    Breadcrumbs,
    Basket,
    FilterDropdown
  },
  setup() {
    return { formatCurrency }
  },
  data() {
    return {
      customer: undefined,
      isCostsHidden: true,
      availableBranches: []
    };
  },
  computed: {
    ...mapStores(useCustomerStore, useBasketStore, useStyleStore),
    basketMainItems() {
      return this.basketStore.basket.filter(
        (item) => item.inputType === window.enum.inputType.FENESTRATION,
      );
    },
    canSkipSop() {
      return this.customer?.customer?.skipOrderAcceptance;
    },
    showPlaceOrderButton() {
      return this.customer?.customer?.skipOrderAcceptance;
    },
    showSaveProductButton() {
      return !this.customer?.customer?.skipOrderAcceptance;
    },
    hideVirtualHomeBanner() {
      return (
        this.basketStore.basket.filter((x) => x.omitFromVisualiser).length === this.basketStore.basket.length ||
        !this.branded_mode_enabled ||
        this.basketMainItems.length === 0
      );
    },
  },
  async created() {
    this.customer = await this.customerStore.customerGet();
  },
  methods: {
    async placeOrderProcess({ canSkipSop, bypassSOP, callback }) {
      await this.styleStore.loadFabricatorStyle()
      if (this.styleStore.fabricator.portalOnStop) {
        window.alertBox.fire({
          title: 'System under temporary maintenance',
          text: 'Sorry for the inconvenience. Please check back soon.'
        });
        callback()
      } else {
        this.basketStore.skipSop = canSkipSop && bypassSOP;
        await this.basketStore.updateOrder();
        if (this.basketStore.skipSop) {
          await this.routerPush('/checkout');
        } else {
          await this.routerPush('/orders');
        }
      }
    },
    async saveQuote(callback) {
      await this.styleStore.loadFabricatorStyle()
      if (this.styleStore.fabricator.portalOnStop) {
        window.alertBox.fire({
          title: 'System under temporary maintenance',
          text: 'Sorry for the inconvenience. Please check back soon.'
        });
        callback()
      } else {
        const response = await this.basketStore.saveQuote();
        if (response) {
          await this.routerPush('/quotes');
        } else {
          callback()
        }
      }
    },
    setAvailableBranches(availableBranches) {
      this.availableBranches = availableBranches
      if (this.availableBranches.length > 0 && !this.$route.query.branchFilter) {
        this.$router.replace({ query: { branchFilter: this.customer.branches.find(x => x.isPrimary).branchCompanyId } }).catch(() => { })
      }

      setTimeout(() => {
        this.$watch('$route.query.branchFilter', async (branchCompanyId) => {
          await window.touch.UpdateBranch({
            BranchCompanyId: branchCompanyId, ContractId: this.basketStore.contractIdentifier.contractId, CustomerId: this.customer?.customerId
          })
        });
      }, 0);
    }
  },
};
</script>
