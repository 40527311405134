<template>
  <BasicPrice
    :list-value="listValue"
    :price="price"
    :subtitle="subtitle"
    :icon="icon"
    :default-currency="defaultCurrency"
    :hide-customer-override-button="hideCustomerOverrideButton"
    :format-currency-with0="formatCurrencyWith0(defaultCurrency, listValue)"
    :is-price-selectable="isPriceSelectable"
  />
</template>

<script>

import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { formatCurrencyWith0, currencySymbol } from '@/composables/currency';
import BasicPrice from '@/components/shared/priceLines/SimpleValues/BasicPrice.vue';

export default {
  components: { BasicPrice },
  props: [
    'price',
    'listValue',
    'defaultCurrency',
    'hideCustomerOverrideButton',
    'isPriceSelectable',
  ],
  setup() {
    return { formatCurrencyWith0, currencySymbol }
  },
  data() {
    return {
      icon: '',
      subtitle: 'Please set subtitle',
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  created() {
    this.icon =
      window.VITE_APP_INSTALLATION_TYPE === 'business'
        ? this.currencySymbol(this.defaultCurrency)
        : this.currencySymbol(this.customerStore.customer.currency);

    switch (this.price.financialRateType) {
      case window.enum.priceRateType.NONE:
        this.subtitle = '';
        break;
      case window.enum.priceRateType.EACH:
        this.subtitle = 'Each';
        break;
      case window.enum.priceRateType.PER_KG:
        this.subtitle = 'Per KG';
        break;
      case window.enum.priceRateType.PERCENT:
        this.subtitle = 'Percentage';
        this.icon = '%';
        break;
      case window.enum.priceRateType.PER_METER:
        this.subtitle = 'Per Meter';
        break;
      case window.enum.priceRateType.METER_SQUARED:
        this.subtitle = 'Per m<sup>2</sup>';
        break;
      default:
        this.subtitle = '';
    }
  }
};
</script>
