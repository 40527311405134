<template>
  <iframe
    sandbox="allow-same-origin"
    :srcdoc="html"
    frameborder="no"
    scrolling="no"
    @load="resizeIframe"
  />
</template>

<script>

export default {
  props: {
    html: String,
  },
  methods: {
    resizeIframe() {
      const rootElement = this.$el;
      const iframe = rootElement
      const link = iframe.contentWindow.document.createElement('link')
      link.href = 'https://fonts.googleapis.com/css?family=Roboto:400,500,900&display=swap'
      link.rel = 'stylesheet'
      iframe.style.height = (iframe.contentWindow.document.body.scrollHeight + 50) + 'px'
      iframe.contentWindow.document.head.appendChild(link)
      iframe.contentWindow.document.body.style.fontFamily = "'roboto', sans-serif"
    }
  },
};
</script>

<style>
iframe {
  width: 100%;
  overflow: hidden;
}
</style>