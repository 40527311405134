<template>
  <a
    :href="store_url + 'branded-mode/subscriptions'"
    target="_blank"
  >
    <div class="relative overflow-hidden rounded">
      <Transition name="slide">
        <div v-if="imageRotation % 2 === 0" :key="0" class="z-30 relative mt-5 md:mt-0 rounded-lg overflow-hidden text-center xs:mt-0 cursor-pointer">
          <img class="rounded-lg" src="/images/sellmore.jpg">
        </div>
        <div v-else :key="1" class="z-30 relative mt-5 md:mt-0 rounded-lg overflow-hidden text-center xs:mt-0 cursor-pointer">
          <img class="rounded-lg" src="/images/sellmore 2.jpg">
        </div>
      </Transition>
      <div class="absolute top-0 left-0 w-full h-full" style="background-color: #1b1c2c;" />
    </div>
  </a>
</template>

<script>

export default {
  data() {
    return {
      interval: undefined,
      imageRotation: 0
    }
  },
  created() {
    this.interval = setInterval(() => {
      this.imageRotation += 1
    }, 7500);
  },
  beforeUnmount() {
    clearInterval(this.interval)
  }
}

</script>

<style>
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}

.slide-leave-active {
  position: absolute !important;
}

.slide-enter-from {
  transform: translate(100%, 0);
}

.slide-leave-to {
  transform: translate(-100%, 0);
}
</style>
