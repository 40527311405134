<script setup>

import { useDropzone } from 'vue3-dropzone';
import { ref, watch } from 'vue'

const props = defineProps({
  current_file: String,
  removable: {
    type: Boolean,
    default: true
  },
  orientation: {
    type: String,
    default: 'landscape'
  },
  compatible: {
    type: Array,
    default() {
      return ['.jpg', '.jpeg', '.png', '.svg', '.gif', '.bmp', '.tiff', '.tif']
    }
  },
  maxSize: {
    type: Number,
    default: 1000000
  },
  label: {
    type: String,
    default: 'image'
  },
  output: {
    type: String,
    default: 'dataURL'
  }
})

const emit = defineEmits(['inFocus', 'submit'])
const file = ref(props.current_file)
const isFile = ref(false)

if (file.value && file.value.includes('api/document')) {
  isFile.value = true
}

if (isURL(file.value)) {
  emit('ignoreFile')
}


watch(() => props.current_file, (newValue) => {
  if (!isURL(file.value)) {
    file.value = newValue
  }
})

function onDrop(acceptFiles, rejectReasons) {
  if (rejectReasons.length) {
    const errors = rejectReasons[0].errors.map(x => x.message)
    window.alertBox.fire({
      title: 'Error',
      html: `<ul>${Object.values(errors).join('</li><li>')}</ul>`,
    });
  } else {
    if (acceptFiles[0].type === 'application/pdf') {
      file.value = acceptFiles[0].name
      isFile.value = true
      emit('addFile', acceptFiles[0])
    } else {
      const reader = new FileReader();
      if (props.output === 'dataURL') {
        reader.onload = (e) => {
          let encoded = e.target.result.toString().replace(/^data:(.*,)?/, '');
          if (encoded.length % 4 > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
          }
          file.value = encoded
          emit('addFile', file.value, acceptFiles[0].name)
        };
        reader.readAsDataURL(acceptFiles[0]);
      } else if (props.output === 'file') {
        emit('addFile', acceptFiles[0], acceptFiles[0].name);
      }
    }
  }
}

function addDataUrlPrefix(src, mimeType = 'image/png') {
  if (isURL(src)) {
    return src
  } else {
    return `data:${mimeType};base64,${src}`;
  }
}

function isURL(url) {
  try {
    new URL(url)
    return true
  } catch (error) {
    return false
  }
}


function removeImage() {
  file.value = null
  emit('removeFile')
}

const { getRootProps, getInputProps } = useDropzone({
  onDrop, maxSize: props.maxSize, accept: props.compatible, maxFiles: 1
});

</script>

<template>
  <div>
    <div v-if="file" class="text-center block bg-gray-200 p-6 text-black">
      <div class="flex items-center mx-auto gap-3 w-full" :class="[orientation === 'landscape' ? 'flex-row' : 'flex-col']">
        <span v-if="isFile">{{ file }}</span>
        <img v-else :src="addDataUrlPrefix(file)" alt="thumbnail" style="max-width: 200px;" @error="file = null">
        <a v-if="removable" class="btn-action w-32" :class="{ 'ml-auto': orientation === 'landscape' }" @click="removeImage"> Remove Image </a>
        <a v-else class="btn-action w-32" :class="{ 'ml-auto': orientation === 'landscape' }" v-bind="getRootProps()">
          <input v-bind="getInputProps()">
          Replace {{ props.label }}
        </a>
      </div>
    </div>
    <div v-else v-bind="getRootProps()">
      <input v-bind="getInputProps()">
      <div class="text-center block bg-gray-200 p-6 flex flex-col justify-around text-black hover:bg-gray-200 cursor-pointer">
        <div>
          <div class="mb-2">Drag and drop {{ props.label }}</div>
          <div class="mb-2">or</div>
          <a class="btn-action w-32"> Select {{ props.label }} </a>
        </div>
      </div>
    </div>
  </div>
</template>