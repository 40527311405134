<template>
  <div>
    <div v-if="isGoogleMaps" class="mb-6">
      <GoogleMaps
        :latitude="Number(newAddress.latitude)"
        :longitude="Number(newAddress.longitude)"
        @hide-google-maps="isGoogleMaps = false"
        @save-coordinates="saveCoordinates"
      />
    </div>
    <div v-show="!isGoogleMaps">
      <div class="mb-3">
        <label v-if="isFullForm">
          Address 1
          <span
            v-if="isManualMode"
            class="opacity-50"
          >(Manual mode - auto-complete deactivated)</span>
        </label>
        <label v-else>Address Search</label>
        <div v-if="isManualMode">
          <input v-model="newAddress.addressLine1" type="text" class="form-control">
        </div>
        <input
          v-else
          id="address1"
          v-model="newAddress.addressLine1"
          type="text"
          class="form-control"
        >
        <div class="mt-3 cursor-pointer opacity-50">
          <span
            v-if="!isFullForm"
            @click="(isFullForm = true), (isManualMode = true)"
          >Enter address manually</span>
        </div>
      </div>
      <div v-show="isFullForm">
        <div class="mb-3 flex gap-3">
          <div class="flex-1">
            <label>Address 2</label>
            <input
              id="address2"
              v-model="newAddress.addressLine2"
              type="text"
              class="form-control"
            >
          </div>
          <div class="flex-1">
            <label>Address 3</label>
            <input
              id="address3"
              v-model="newAddress.addressLine3"
              type="text"
              class="form-control"
            >
          </div>
        </div>
        <div class="mb-3 flex gap-3">
          <div class="flex-1">
            <label>Town</label>
            <input id="town" v-model="newAddress.addressTown" type="text" class="form-control">
          </div>
          <div class="flex-1">
            <label>County</label>
            <input
              id="county"
              v-model="newAddress.addressCounty"
              type="text"
              class="form-control"
            >
          </div>
        </div>
        <div class="mb-3 flex gap-3">
          <div class="flex-1">
            <label>Country</label>
            <country-select
              v-model="newAddress.addressIso"
              class="form-control bg-white"
            />
          </div>
          <div class="flex-1">
            <label>Postcode</label>
            <input
              id="postcode"
              v-model="newAddress.addressCode"
              type="text"
              class="form-control"
            >
          </div>
        </div>
        <div v-if="isLatLngRequired" class="mb-3 flex gap-3">
          <div class="flex-1">
            <label>Latitute</label>
            <input id="latitude" v-model="newAddress.latitude" class="form-control">
          </div>
          <div class="flex-1">
            <label>Longitude</label>
            <input id="longitude" v-model="newAddress.longitude" class="form-control">
          </div>
        </div>
        <div class="mb-6">
          <label v-if="isPrimaryToggle">
            <input id="isPrimary" v-model="newAddress.isPrimary" type="checkbox">
            Primary Address
          </label>
          <div v-if="isLatLngRequired" class="mb-3 cursor-pointer opacity-50 float-right">
            <span @click="isGoogleMaps = true">
              <span v-if="newAddress.latitude === '' && newAddress.longitude === ''">Add</span>
              <span v-else>Adjust</span>
              Coordinates
            </span>
          </div>
        </div>
        <div v-if="isSaveButton" class="text-right">
          <button class="btn-action btn-lg rounded-sm" @click.prevent="saveAddress">
            Save Address
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountrySelect from '@/components/shared/address/CountrySelect.vue';
import GoogleMaps from '@/components/shared/address/GoogleMaps.vue';
import validate from 'validate.js';

export default {
  components: {
    'country-select': CountrySelect,
    GoogleMaps,
  },
  props: {
    isPrimaryByDefault: {
      type: Boolean,
      default: true,
    },
    isLatLngRequired: {
      type: Boolean,
      default: false,
    },
    isSaveButton: {
      type: Boolean,
      default: false,
    },
    isPrimaryToggle: {
      type: Boolean,
      default: true,
    },
    address: Object,
  },
  data() {
    return {
      newAddress: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressTown: '',
        addressCounty: '',
        addressCode: '',
        addressIso: 'GB',
        latitude: undefined,
        longitude: undefined,
        isPrimary: true,
      },
      isGoogleMaps: false,
      isFullForm: false,
      isManualMode: false,
    };
  },
  created() {
    if (this.address) {
      this.newAddress = {
        addressLine1: this.address.line1,
        addressLine2: this.address.line2,
        addressLine3: this.address.line3,
        addressTown: this.address.town,
        addressCounty: this.address.county,
        addressCode: this.address.code,
        addressIso: this.address.iso,
        isPrimary: this.address.isPrimary,
        latitude: this.address.latitude,
        longitude: this.address.longitude,
      };
      this.isFullForm = true;
    } else {
      this.newAddress.isPrimary = this.isPrimaryByDefault;
    }
  },
  mounted() {
    const instance = this;
    this.$nextTick(() => {
      this.setUpPredictiveAddresses(instance);
    });
    this.$watch('newAddress', this.updateAddress, { deep: true });
  },
  methods: {
    updateAddress(address) {
      this.$emit('address-updated', address);
    },
    saveCoordinates(latitude, longitude) {
      this.newAddress.latitude = latitude;
      this.newAddress.longitude = longitude;
    },
    setUpPredictiveAddresses(instance = this) {
      const self = instance;
      const txt = document.getElementById('address1');
       
      new window.data8.predictiveaddressui(txt, {
        ajaxKey: 'MSS8-FTK3-3VI8-VF8T',
        placeholder: 'Please enter your address...',
        minDelay: 1000,
        fields: [
          { element: 'address1', field: 'line1' },
          { element: 'address2', field: 'line2' },
          { element: 'address3', field: 'line3' },
          { element: 'town', field: 'town' },
          { element: 'county', field: 'county' },
          { element: 'country', field: 'country' },
          { element: 'postcode', field: 'postcode' },
        ],
        selectAddress(address) {
          self.isFullForm = true;
          try {
            self.newAddress.latitude = address.RawAddress.Location.Latitude;
            self.newAddress.longitude = address.RawAddress.Location.Longitude;
          } catch (e) {
            self.newAddress.latitude = undefined;
            self.newAddress.longitude = undefined;
          }
        },
      });
    },
    saveAddress() {
      const validationErrors = validate(
        {
          line1: this.newAddress.addressLine1,
          town: this.newAddress.addressTown,
          country: this.newAddress.addressIso,
          postcode: this.newAddress.addressCode,
        },
        {
          line1: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          town: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          country: {
            presence: { allowEmpty: false },
            length: {
              maximum: 3,
            },
          },
          postcode: {
            presence: { allowEmpty: false },
            length: {
              maximum: 16,
            },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        this.loading = false;
      } else if (
        (!this.newAddress.latitude || !this.newAddress.longitude) &&
        this.isLatLngRequired
      ) {
        this.alertBox()
          .fire({
            title: 'Warning',
            text: 'Latitude & Longtitude are required for geographical selection via dealer network enquiries.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Continue & Save',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('saveAddress');
            }
          });
      } else {
        this.$emit('saveAddress');
      }
    },
  },
};
</script>
