<template>
  <div class="xs:flex">
    <div
      v-if="groups.length > 0"
      class="relative m-3"
      tabindex="1"
      @focusout="select_group_dropdown_open = false"
    >
      <div
        class="border border-t-4 border-l-0 border-r-0 border-b-0 border-white w-full"
        :class="{ '!border-green-600': filter.groupId !== undefined }"
      />
      <div
        class="border flex justify-between cursor-pointer"
        @click="
          (select_group_dropdown_open = !select_group_dropdown_open),
          (select_type_dropdown_open = false),
          $emit('close-number-of-squares-dropdown')"
      >
        <div class="p-3 whitespace-nowrap text-gray-600">Select Group</div>
        <div class="p-3">
          <i v-if="select_group_dropdown_open" class="fa fa-caret-up" />
          <i v-else class="fa fa-caret-down" />
        </div>
      </div>
      <div
        v-show="select_group_dropdown_open"
        class="absolute bg-gray-200 w-full md:w-64 border border-t-0 z-10 text-xs sm:text-sm"
      >
        <div
          v-for="group in groups"
          :key="group.productSelectionGroupId"
          class="flex items-center gap-3 justify-between cursor-pointer m-2 p-3 rounded-lg hover:bg-brand-primary hover:text-white"
          :class="[filter.groupId == group.productSelectionGroupId ? '!bg-brand-primary text-white' : 'bg-white']"
          :title="group.description"
          @click="selectGroup(group.productSelectionGroupId)"
        >
          <span>
            {{ group.title }}
          </span>
          <div v-if="filter.groupId === group.productSelectionGroupId" class="ml-auto">
            <i class="fal fa-check" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="types.length > 0"
      class="relative m-3"
      tabindex="2"
      @focusout="select_type_dropdown_open = false"
    >
      <div
        class="border border-t-4 border-l-0 border-r-0 border-b-0 border-white w-full"
        :class="{ '!border-green-600': filter.typeId !== undefined }"
      />
      <div
        class="border flex justify-between cursor-pointer"
        @click="
          (select_type_dropdown_open = !select_type_dropdown_open),
          (select_group_dropdown_open = false),
          $emit('close-number-of-squares-dropdown')"
      >
        <div class="p-3 whitespace-nowrap text-gray-600">Select Type</div>
        <div class="p-3">
          <i v-if="select_type_dropdown_open" class="fa fa-caret-up" />
          <i v-else class="fa fa-caret-down" />
        </div>
      </div>
      <div
        v-show="select_type_dropdown_open"
        class="absolute bg-gray-200 w-full md:w-64 border border-t-0 z-10 text-xs sm:text-sm"
      >
        <div
          v-for="type in types"
          :key="type.productSelectionTypeId"
          class="flex items-center gap-3 placeholder:justify-between cursor-pointer m-2 p-3 rounded-lg hover:bg-brand-primary hover:text-white"
          :class="[filter.typeId === type.productSelectionTypeId ? '!bg-brand-primary text-white' : 'bg-white']"
          :title="type.description"
          @click="selectType(type.productSelectionTypeId)"
        >
          <span>
            {{ type.title }}
          </span>
          <div v-if="filter.typeId === type.productSelectionTypeId" class="ml-auto">
            <i class="fal fa-check" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import { useBasketStore } from '@/pinia/basket';

export default {
  props: {
    number_of_squares_dropdown_open: Boolean,
    filter: Object
  },
  data() {
    return {
      groups: [],
      types: [],
      select_group_dropdown_open: false,
      select_type_dropdown_open: false,
    };
  },
  computed: {
    ...mapStores(useBasketStore, useTouchStore),
  },
  watch: {
    number_of_squares_dropdown_open() {
      if (this.number_of_squares_dropdown_open) {
        this.select_group_dropdown_open = false;
        this.select_type_dropdown_open = false;
      }
    },
  },
  async mounted() {
    const groups = await this.touchStore.processingGetAvailableProductSelectionGroups(this.basketStore.customerId)
    if (groups) {
      this.groups = groups
        .map((group) => group.groups)
        .filter(Boolean)
        .sort((a, b) => a.listOrder - b.listOrder);

      this.groups.unshift({
        productSelectionGroupId: undefined,
        title: 'All',
      });
    }

    const types = await this.touchStore.processingGetAvailableProductSelectionTypes({ customerId: this.basketStore.customerId })
    if (types) {
      this.types = types
        .map((type) => type.types)
        .filter(Boolean)
        .sort((a, b) => a.listOrder - b.listOrder);

      this.types.unshift({
        productSelectionTypeId: undefined,
        title: 'All',
      });
    }

    this.$emit('input', {
      groupId: undefined,
      typeId: undefined,
      selectedGroupName: null,
      selectedTypeName: null,
    });
  },
  methods: {
    async selectGroup(groupId) {
      this.select_group_dropdown_open = false;
      this.types = (
        await this.touchStore.processingGetAvailableProductSelectionTypes({
          customerId: this.basketStore.customerId,
          groupId: groupId,
        })
      )
        .filter((type) => type.types !== undefined)
        .map((type) => type.types)
        .sort((a, b) => a.listOrder - b.listOrder);

      this.types.unshift({
        productSelectionTypeId: undefined,
        title: 'All',
      });
      this.$emit('updateFilter', {
        groupId: groupId,
        typeId: undefined,
        selectedGroupName: this.getGroupName(groupId),
        selectedTypeName: 'All',
      });
    },
    selectType(typeId) {
      this.select_type_dropdown_open = false;
      this.$emit('updateFilter', {
        typeId: typeId,
        selectedTypeName: this.getTypeName(typeId),
      });
    },
    getGroupName(groupId) {
      const currentGroup = this.groups.filter((group) => groupId === group.productSelectionGroupId);
      if (currentGroup.length > 0) {
        return currentGroup[0].title;
      }
      return 'All';
    },
    getTypeName(typeId) {
      const currentType = this.types.filter((type) => typeId === type.productSelectionTypeId);
      if (currentType.length > 0) {
        return currentType[0].title;
      }
      return 'All';
    },
  },
};
</script>

<style scoped></style>
