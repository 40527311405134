<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Resource Centre', '/marketing'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex items-center">
        Resource Centre
        <help-point class="ml-2" freshdesk-article-id="101000455645" />
      </div>
    </template>
    <Marketing />
  </touch-layout>
</template>

<script>
import Marketing from '@/components/shared/Marketing.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import HelpPoint from '@/components/shared/HelpPoint.vue';

export default {
  components: {
    Marketing,
    Breadcrumbs,
    HelpPoint,
  },
};
</script>
