<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['Touch Store', '/touch-store'], ['More Benefits']]"
      />
    </template>
    <template #header_page_title> Touch Store Benefits </template>
    <template #header_page_actions>
      <LiveChatButton class="mr-4" />
    </template>
    <main
      class="pb-10 overflow-x-scroll pb-10 bg-white h-full"
      :style="{ 'scroll-behavior': 'smooth' }"
    >
      <div class="relative relative mb-20 overflow-hidden">
        <div class="absolute top-0 left-0 w-full h-full">
          <img class="w-full h-full" src="/images/touch-store/banner-brandedmode-bg.jpg">
        </div>
        <div
          class="flex grid grid-cols-1 xs:grid-cols-2 relative items-center justify-center mx-auto"
          :style="{ 'max-width': '1500px' }"
        >
          <div class="flex-1 flex flex-col gap-5 text-white p-10 text-center xs:text-left">
            <div>
              <strong class="text-4xl md:text-6xl" :style="{ 'line-height': '1.1em' }">
                TOUCH Branded Mode for installers
              </strong>
            </div>
            <div>
              <span class="text-xl md:text-2xl">
                Branded Mode is a lite version of {{ fabricatorName }} Designer.
              </span>
            </div>
            <div>
              <button class="btn-action btn-lg" @click="$refs.redirect.redirect()">
                Buy Branded Mode
              </button>
            </div>
          </div>
          <div class="flex-1 px-10 pt-10">
            <img src="/images/touch-store/banner-brandedmode.png">
          </div>
        </div>
      </div>
      <div class="px-5 md:px-10 lg:px-20 pb-10 max-w-2xl">
        <div>
          <strong class="text-2xl md:text-4xl">
            A simplified interface and fewer options, allowing homeowners to design and generate an
            enquiry directly with you.
          </strong>
        </div>
        <div class="border-b-2 border-black mt-5 inline-block cursor-pointer" @click="scrollTo()">
          <div class="flex items-center">
            <div class="py-3 mr-10">
              <span class="text-lg">Discover more about Branded Mode</span>
            </div>
            <i class="fa fa-arrow-down ml-auto text-lg" />
          </div>
        </div>
      </div>
      <div
        id="overflow-x"
        class="flex flex-row overflow-x-scroll px-5 lg:pl-20 w-100vw gap-5 mb-20"
      >
        <div class="relative flex-shrink-0 w-1/2 max-w-4xl">
          <img class="w-full" src="/images/touch-store/brandedmode-moreinfo-interface.jpg">
          <div class="absolute bottom-0 right-0 z-10 p-5">
            <span class="text-white text-lg">'Lite' easy to use interface</span>
          </div>
        </div>
        <div class="relative flex-shrink-0 w-1/2 max-w-4xl">
          <img class="w-full" src="/images/touch-store/brandedmode-moreinfo-branded.jpg">
          <div class="absolute bottom-0 right-0 z-10 p-5">
            <span class="text-lg">Fully branded to your company</span>
          </div>
        </div>
        <div v-if="!isLeadGen" class="relative flex-shrink-0 w-1/2 max-w-4xl">
          <img class="w-full" src="/images/touch-store/brandedmode-moreinfo-orders.jpg">
          <div class="absolute bottom-0 right-0 z-10 p-5">
            <span class="text-white text-lg">Quote & Order Management</span>
          </div>
        </div>
        <div class="relative flex-shrink-0 w-1/2 max-w-4xl">
          <img class="w-full" src="/images/touch-store/brandedmode-moreinfo-online.jpg">
          <div class="absolute bottom-0 right-0 z-10 p-5">
            <span class="text-white text-lg">Be Online in Minutes</span>
          </div>
        </div>
        <div class="relative flex-shrink-0 w-1/2 max-w-4xl">
          <img class="w-full" src="/images/touch-store/brandedmode-moreinfo-setup.jpg">
          <div class="absolute bottom-0 right-0 z-10 p-5">
            <span class="text-white text-lg">Easy Setup</span>
          </div>
        </div>
      </div>
      <div class="mb-20">
        <div class="relative px-5 py-10 text-center overflow-hidden">
          <div class="absolute top-0 left-0 w-full h-full z-0">
            <img class="w-full h-full" src="/images/touch-store/banner-brandedmode-bg.jpg">
          </div>
          <div class="relative">
            <strong class="text-white text-2xl md:text-4xl">
              As a business we understand the problems you face.
            </strong>
          </div>
          <div
            class="relative flex flex-col 2xs:grid grid-cols-2 xs:flex xs:flex-row items-center gap-5 xs:gap-10 justify-center my-10"
          >
            <div
              class="pb-2 cursor-pointer border-b-2 border-transparent"
              :class="{ 'border-white': tab === 0 }"
              @click="tab = 0"
            >
              <span class="text-lg text-white">Generating Enquiries</span>
            </div>
            <div
              class="pb-2 cursor-pointer border-b-2 border-transparent"
              :class="{ 'border-white': tab === 1 }"
              @click="tab = 1"
            >
              <span class="text-lg text-white">Closing a Sale</span>
            </div>
            <div
              class="pb-2 cursor-pointer border-b-2 border-transparent"
              :class="{ 'border-white': tab === 2 }"
              @click="tab = 2"
            >
              <span class="text-lg text-white">Dated Brochures</span>
            </div>
            <div
              class="pb-2 cursor-pointer border-b-2 border-transparent"
              :class="{ 'border-white': tab === 3 }"
              @click="tab = 3"
            >
              <span class="text-lg text-white">Limited Selling Times</span>
            </div>
          </div>
          <div class="max-w-2xl mx-auto mb-10 relative">
            <div>
              <div
                class="relative bubble bubble-left p-5 bg-white rounded-lg inline-block text-lg mb-10"
              >
                <div class="flex items-center justify-center gap-2 mb-2">
                  <i class="fa-sharp fa-regular fa-face-unamused" />
                  <strong>The Problem</strong>
                </div>
                <div>
                  <span>{{ tabs[tab].problem }}</span>
                </div>
              </div>
              <br>
              <div
                class="relative bubble bubble-right p-5 bg-white rounded-lg inline-block text-lg mb-10"
              >
                <div class="flex items-center justify-center gap-2 mb-2">
                  <i class="fa-sharp fa-regular fa-face-smile" />
                  <strong>How Branded Mode solves this issue</strong>
                </div>
                <div>
                  <span> {{ tabs[tab].solution }} </span>
                </div>
              </div>
            </div>
          </div>
          <div class="relative">
            <button class="btn-action btn-lg" @click="$refs.redirect.redirect()">
              Buy Branded Mode
            </button>
          </div>
        </div>
      </div>
      <div class="px-5">
        <div class="text-center mb-10">
          <strong class="text-2xl md:text-4xl">How does Branded Mode help your business?</strong>
        </div>
        <div
          class="grid xs:grid-cols-2 lg:grid-cols-3 gap-5 auto-rows-fr mx-auto pb-20"
          :style="{ 'max-width': '1500px' }"
        >
          <div class="bg-gray-200 rounded-lg p-5 flex flex-col gap-5">
            <div>
              <i class="fa-regular fa-calculator text-4xl" />
            </div>
            <div>
              <span class="text-2xl">Boosts your retail presence</span>
            </div>
            <div>
              <span>
                Improve your online presence by keeping customers on your website longer, improving
                interactivity, utilize and highlight your Branded Mode to improve your marketing.
              </span>
            </div>
          </div>
          <div class="bg-gray-200 rounded-lg p-5 flex flex-col gap-5">
            <div>
              <i class="fa-regular fa-cart-shopping-fast text-4xl" />
            </div>
            <div>
              <span class="text-2xl">You can receive more accurate enquiries</span>
            </div>
            <div>
              <span>
                Any enquiries you receive via Branded Mode will built using the same rule base from
                {{ fabricatorName }} ordering system this eliminates errors and means homeowners
                cant make an enquiry for something that can be manufactured.
              </span>
            </div>
          </div>
          <div class="bg-gray-200 rounded-lg p-5 flex flex-col gap-5">
            <div>
              <i class="fa-regular fa-file-spreadsheet text-4xl" />
            </div>
            <div>
              <span class="text-2xl">You dont need to buy your own system</span>
            </div>
            <div>
              <span>
                Bespoke lead generation software can cost around £6,000+, creating accurate data and
                rules around the product options is costly and time consuming. We have done all the
                work for you, Branded Mode is a low cost solution with a huge amount of benefits.
              </span>
            </div>
          </div>
          <div class="bg-gray-200 rounded-lg p-5 flex flex-col gap-5">
            <div>
              <i class="fa-regular fa-chart-line-up text-4xl" />
            </div>
            <div>
              <span class="text-2xl">There's no need for a salesman</span>
            </div>
            <div>
              <span>
                Branded sits on your website 24/7, meaning while you are busy with other important
                areas of your business you can still be generating enquiries. Branded Mode is a cost
                effective member of your team.
              </span>
            </div>
          </div>
          <div class="bg-gray-200 rounded-lg p-5 flex flex-col gap-5">
            <div>
              <i class="fa-regular fa-people-roof text-4xl" />
            </div>
            <div>
              <span class="text-2xl">You can use it in your showroom</span>
            </div>
            <div>
              <span>
                Improve the interactivity within your showroom and display your designer on a tablet
                or interactive screen. Its a powerful marketing tool and can allow customers to
                design their perfect product without having to trawl through an outdated brochure.
              </span>
            </div>
          </div>
          <div v-if="!isLeadGen" class="bg-gray-200 rounded-lg p-5 flex flex-col gap-5">
            <div>
              <i class="fa-regular fa-crystal-ball text-4xl" />
            </div>
            <div>
              <span class="text-2xl">Removes the need to re-input orders</span>
            </div>
            <div>
              <span>
                Any enquiry that you generate via Branded Mode can be pushed directly to
                {{ fabricatorName }} Portal to be converted into a quote. From their you can tweak
                options and add a price without the need to re-create your customers enquiry.
              </span>
            </div>
          </div>
        </div>
        <div class="grid relative md:grid-cols-2 mt-5 items-center text-center overflow-hidden">
          <div class="absolute top-0 left-0 w-full h-full z-0">
            <img class="w-full h-full" src="/images/touch-store/banner-brandedmode-bg.jpg">
          </div>
          <div class="relative">
            <img class="w-full" src="/images/touch-store/brandedmodeconnected-bg.jpg">
            <div
              class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-sm px-5"
            >
              <strong class="text-white text-2xl md:text-4xl">
                TOUCH Branded Mode Powered by {{ fabricatorName }}
              </strong>
            </div>
          </div>
          <div class="relative p-5 flex flex-col gap-5 items-center justify-center">
            <div>
              <img src="/images/touch-store/brandedmodeconnected.png">
            </div>
            <div>
              <span class="text-white text-xl">{{ fabricatorName }} must use TOUCH Portal</span>
            </div>
          </div>
        </div>
      </div>
      <div class="px-5">
        <div class="bg-gray-200">
          <div
            class="grid gap-10 md:gap-0 md:grid-cols-2 px-10 py-20 mx-auto"
            :style="{ 'max-width': '1500px' }"
          >
            <div class="flex flex-col justify-center max-w-2xl mx-auto">
              <div>
                <span>PROFESSIONAL PAPERWORK</span>
              </div>
              <div>
                <strong class="text-4xl md:text-6xl" :style="{ 'line-height': '1.1em' }">
                  Stunning Paperwork branded for you
                </strong>
              </div>
            </div>
            <div>
              <img src="/images/touch-store/brandedmodeshowcase-paperwork@2x.png">
            </div>
          </div>
        </div>
      </div>
      <div class="px-5">
        <div class="grid md:grid-cols-2 px-10 mx-auto" :style="{ 'max-width': '1500px' }">
          <div class="flex flex-col justify-center max-w-xl mx-auto pt-20 md:py-20">
            <div>
              <span>HOME VISUALISER</span>
            </div>
            <div class="mb-2">
              <strong class="text-4xl md:text-6xl" :style="{ 'line-height': '1.1em' }">
                Wow customers & increase sales
              </strong>
            </div>
            <div>
              <span>
                Unlike standard visualisers, TOUCH Visualiser allows homeowners to upload images of
                every façade of their property, from both inside and out.
              </span>
            </div>
          </div>
          <div class="pt-20">
            <img src="/images/touch-store/brandedmodeshowcase-sales.png">
          </div>
        </div>
      </div>
      <div class="px-5">
        <div
          class="bg-black flex flex-col gap-5 sm:flex-row items-center px-10 py-5 text-white text-center sm:text-left"
        >
          <div class="flex flex-col sm:flex-row items-center gap-5">
            <div>
              <strong class="text-lg md:text-2xl xl:text-4xl sm:border-r-2 border-white pr-5">
                Branded Mode with ONE MONTH FREE
              </strong>
            </div>
            <div>
              <span class="md:text-lg">Click to register for a price</span>
            </div>
          </div>
          <div class="sm:ml-auto">
            <button class="btn-action lg:btn-lg" @click="$refs.redirect.redirect()">
              Buy Branded Mode
            </button>
          </div>
        </div>
      </div>
      <div v-if="!isLeadGen" class="px-5">
        <div class="bg-gray-200">
          <div class="grid md:grid-cols-2 px-10 mx-auto" :style="{ 'max-width': '1500px' }">
            <div class="flex flex-col justify-center max-w-xl mx-auto pt-20 md:py-20">
              <div>
                <span>QUOTE YOUR CUSTOMER</span>
              </div>
              <div class="mb-2">
                <strong class="text-4xl md:text-6xl" :style="{ 'line-height': '1.1em' }">
                  Increase profit;<br>
                  Add prices to quotes
                </strong>
              </div>
              <div>
                <span>
                  All enquiries will land in your Portal account with {{ fabricatorName }}. Simply
                  convert it to a quote and add the price you want to sell it for.
                </span>
              </div>
            </div>
            <div class="pt-20">
              <img src="/images/touch-store/brandedmodeshowcase-profit.png">
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isLeadGen" class="px-5">
        <div class="grid md:grid-cols-2 px-10 mx-auto" :style="{ 'max-width': '1500px' }">
          <div class="flex flex-col justify-center max-w-xl mx-auto pt-20 md:py-20">
            <div>
              <span>CONNECTED TO TOUCH PORTAL</span>
            </div>
            <div class="mb-2">
              <strong class="text-4xl md:text-6xl" :style="{ 'line-height': '1.1em' }">
                Convert enquiries into orders
              </strong>
            </div>
            <div>
              <span>
                Once you have sent your priced up quote to your customer you can then convert it to
                a complete order. This order will then be placed with {{ fabricatorName }}.
              </span>
            </div>
          </div>
          <div class="pt-20">
            <img src="/images/touch-store/brandedmodeshowcase-conversions@2x.png">
          </div>
        </div>
      </div>
      <div v-if="!isLeadGen" class="px-5">
        <div
          class="bg-black flex flex-col gap-5 sm:flex-row items-center px-10 py-5 text-white text-center sm:text-left"
        >
          <div class="flex flex-col sm:flex-row items-center gap-5">
            <div>
              <strong class="text-lg md:text-2xl xl:text-4xl sm:border-r-2 border-white pr-5">
                Branded Mode with ONE MONTH FREE
              </strong>
            </div>
            <div>
              <span class="md:text-lg">Click to register for a price</span>
            </div>
          </div>
          <div class="sm:ml-auto">
            <button class="btn-action lg:btn-lg" @click="$refs.redirect.redirect()">
              Buy Branded Mode
            </button>
          </div>
        </div>
      </div>
      <div class="px-5">
        <div class="bg-gray-200">
          <div
            class="grid gap-10 md:grid-cols-2 px-10 py-20 mx-auto"
            :style="{ 'max-width': '1500px' }"
          >
            <div class="flex flex-col justify-center max-w-md mx-auto gap-2">
              <div>
                <strong class="text-2xl md:text-4xl">How to buy Branded Mode</strong>
              </div>
              <div>
                <span>
                  The process could not be more simple. Once your direct debit has been setup, you
                  can be live on your own website in just minutes.
                </span>
              </div>
              <div>
                <span>
                  Remember Branded Mode comes with one free month and can be cancelled at any time.
                </span>
              </div>
            </div>
            <div class="mx-auto">
              <div class="border-solid border-l-2 border-purple-500 my-5 text-lg">
                <div class="flex pb-5" :style="{ 'margin-left': '-16px' }">
                  <div
                    class="rounded-full border-solid border-purple-500 bg-purple-500 mr-5 text-center"
                    :style="{
                      'min-width': '30px',
                      'min-height': '30px',
                      'max-width': '30px',
                      'max-height': '30px',
                    }"
                  >
                    <span class="text-white" :style="{ 'line-height': '30px' }">1</span>
                  </div>
                  <span>Login to {{ fabricatorName }} Portal</span>
                </div>
                <div class="flex pb-5" :style="{ 'margin-left': '-16px' }">
                  <div
                    class="rounded-full border-solid border-purple-500 bg-purple-500 mr-5 text-center"
                    :style="{
                      'min-width': '30px',
                      'min-height': '30px',
                      'max-width': '30px',
                      'max-height': '30px',
                    }"
                  >
                    <span class="text-white" :style="{ 'line-height': '30px' }">2</span>
                  </div>
                  <div>
                    <span>Click TOUCH Store button</span>
                  </div>
                </div>
                <div class="flex pb-5" :style="{ 'margin-left': '-16px' }">
                  <div
                    class="rounded-full border-solid border-purple-500 bg-purple-500 mr-5 text-center"
                    :style="{
                      'min-width': '30px',
                      'min-height': '30px',
                      'max-width': '30px',
                      'max-height': '30px',
                    }"
                  >
                    <span class="text-white" :style="{ 'line-height': '30px' }">3</span>
                  </div>
                  <span>Register for the Store</span>
                </div>
                <div class="flex pb-5" :style="{ 'margin-left': '-16px' }">
                  <div
                    class="rounded-full border-solid border-purple-500 bg-purple-500 mr-5 text-center"
                    :style="{
                      'min-width': '30px',
                      'min-height': '30px',
                      'max-width': '30px',
                      'max-height': '30px',
                    }"
                  >
                    <span class="text-white" :style="{ 'line-height': '30px' }">4</span>
                  </div>
                  <span>Setup your direct debit - No charge 1st month</span>
                </div>
                <div class="flex pt-5" :style="{ 'margin-left': '-16px' }">
                  <div
                    class="rounded-full border-solid border-purple-500 bg-purple-500 mr-5 text-center"
                    :style="{
                      'min-width': '30px',
                      'min-height': '30px',
                      'max-width': '30px',
                      'max-height': '30px',
                    }"
                  >
                    <span class="text-white" :style="{ 'line-height': '30px' }">5</span>
                  </div>
                  <span>Setup with contact details & logo</span>
                </div>
                <div class="flex pt-5" :style="{ 'margin-left': '-16px' }">
                  <div
                    class="rounded-full border-solid border-purple-500 bg-purple-500 mr-5 text-center"
                    :style="{
                      'min-width': '30px',
                      'min-height': '30px',
                      'max-width': '30px',
                      'max-height': '30px',
                    }"
                  >
                    <span class="text-white" :style="{ 'line-height': '30px' }">6</span>
                  </div>
                  <span>Go Live on your website</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-5">
        <div class="bg relative overflow-hidden">
          <div class="absolute top-0 left-0 w-full h-full z-0">
            <img class="w-full h-full" src="/images/touch-store/banner-brandedmode-bg.jpg">
          </div>
          <div
            class="flex grid grid-cols-1 xs:grid-cols-2 relative items-center justify-center mx-auto"
            :style="{ 'max-width': '1500px' }"
          >
            <div class="flex-1 flex flex-col gap-5 text-white p-10 text-center xs:text-left">
              <div>
                <strong class="text-4xl md:text-6xl" :style="{ 'line-height': '1.1em' }">
                  View a Branded Mode demo
                </strong>
              </div>
              <div>
                <span class="text-xl md:text-2xl">
                  Register today to get access to our Free demo.
                </span>
              </div>
              <div>
                <button class="btn-action btn-lg" @click="$refs.redirect.redirect()">
                  Buy Branded Mode
                </button>
              </div>
            </div>
            <div class="flex-1 pl-10 pt-10 -mb-5">
              <img src="/images/touch-store/brandedmode-demo@2x.png">
            </div>
          </div>
        </div>
      </div>
      <Redirect ref="redirect" />
    </main>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useStyleStore } from '@/pinia/style';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Redirect from '@/components/branded/touchStore/Redirect.vue';
import LiveChatButton from '@/components/shared/nav/LiveChatButton.vue';

export default {
  components: {
    Breadcrumbs,
    Redirect,
    LiveChatButton,
  },
  data() {
    return {
      tab: 0,
      tabs: [
        {
          problem: 'In a packed market place generating enquiries online can be difficult.',
          solution:
            'Branded Mode helps you standout from the crowd, add it to your website or on social media. Its a great promotional tool helping customers to interact with you.',
        },
        {
          problem: 'A customer may have had any number of quotes from competitors in your area.',
          solution:
            'Use Branded Mode to wow customers with high quality, accurate product imagery and stunning quotation paperwork. They can even see how there products will look with Virtual Home Visualiser.',
        },
        {
          problem:
            'We all know how expensive and how quickly brochures can become obsolete and don’t mention lugging them from home to home.',
          solution:
            'Branded Mode is always accurate and up to date and easy to demo on any tablet or laptop.',
        },
        {
          problem:
            'In a world where everyone is in a rush, its difficult keeping peoples attention or getting them what they need instantly.',
          solution:
            'Branded Mode is instantly accessible via your website and is automated to allow people to play, designs and make enquiries at their leisure.',
        },
      ],
    };
  },
  computed: {
    ...mapStores(useStyleStore),
    fabricatorName() {
      return this.styleStore.fabricator.name;
    },
    isLeadGen() {
      return (
        this.styleStore.fabricator.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
  },
  unmounted() {
    window.LiveChatWidget.off('ready', this.readyCallback);
    window.LiveChatWidget.off('availability_changed', this.availabilityChangedCallback);
  },
  methods: {
    scrollTo() {
      document.getElementById('overflow-x').scrollIntoView();
    },
  },
};
</script>

<style>
.bubble:before {
  content: '';
  position: absolute;
  top: 100%;
  width: 0;
  border-top: 20px solid white;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

.bubble-left:before {
  left: 50px;
}

.bubble-right:before {
  right: 50px;
}
</style>
