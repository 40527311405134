<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['My Account', '/my-account'], ['Knowledge Base']]"
      />
    </template>
    <template #header_page_title> Hidden Articles </template>
    <Menu />
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-5">
      <div class="flex flex-col gap-5">
        <div
          v-for="item in hiddenArticles"
          :key="item.id"
          class="bg-white rounded-lg flex items-center p-5"
        >
          <div>
            <img :src="'/images/knowledge-base/' + item.imageURL" class="w-40">
          </div>
          <div class="p-4 mb-auto">
            <div>
              <span class="text-xs">{{ item.duration }}</span>
            </div>
            <div class="mb-3">
              <span class="text-lg">{{ item.title }}</span>
            </div>
          </div>
          <div class="btn-action ml-auto" @click="Id = item.id">Show Article</div>
        </div>
      </div>
    </div>
    <Teleport v-if="Id" defer to="#portal_popup">
      <modal-window :modal_open="true" title="Show Article" @close="Id = undefined">
        <div>
          This article will show on the
          <router-link class="underline text-blue-500" to="/">Dashboard</router-link>.
        </div>
        <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="Id = undefined">
            Cancel
          </button>
          <button
            class="btn-action btn-lg"
            @click.prevent.stop="knowledgeBaseStore.removeHiddenArticlesIDs(Id), (Id = undefined)"
          >
            Show Article
          </button>
        </div>
      </modal-window>
    </Teleport>
  </touch-layout>
</template>

<script>
import Menu from '@/components/portal/myAccount/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapStores } from 'pinia'
import { useKnowledgeBaseStore } from '@/pinia/knowledge-base';

export default {
  components: {
    Breadcrumbs,
    Menu,
  },
  data() {
    return {
      Id: undefined,
    };
  },
  computed: {
    ...mapStores(useKnowledgeBaseStore),
    hiddenArticles() {
      const { articles, hiddenArticlesIDs } = this.knowledgeBaseStore;
      return articles.filter((x) => hiddenArticlesIDs.includes(x.id));
    },
  },
  watch: {
    hiddenArticles: {
      handler() {
        if (this.hiddenArticles.length === 0) {
          this.$router.replace('/').catch(() => { });
          this.alertBox().fire({
            title: 'All Articles Visible.',
          });
        }
      },
      immediate: true,
    },
  },
};
</script>
