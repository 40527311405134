<template>
  <Teleport defer to="#portal_popup"> 
    <modal-window
      :modal_open="true"
      :title="isCreatingNewCarrier ? 'Add Carrier' : 'Update Carrier'"
      @close="$emit('close')"
    >
      <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
        * Required Fields
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Name <span class="text-green-600">*</span></div>
          <input v-model="name" type="text" class="form-control">
          <div class="font-medium mt-4">Web <span class="text-green-600">*</span></div>
          <input v-model="web" type="text" class="form-control">
          <div class="font-medium mt-4">Email <span class="text-green-600">*</span></div>
          <input v-model="email" type="email" class="form-control">
          <div class="font-medium mt-4">Phone <span class="text-green-600">*</span></div>
          <input v-model="phone" type="number" class="form-control">
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="validateAndSave">Save</button>
      </div>
    </modal-window>
  </Teleport>
</template>
<script>
import validate from 'validate.js';

export default {
  props: {
    createCarrierModalModel: Object,
  },
  data() {
    return {
      id: this.createCarrierModalModel.id || 0,
      name: this.createCarrierModalModel.name || '',
      web: this.createCarrierModalModel.web || '',
      email: this.createCarrierModalModel.email || '',
      phone: this.createCarrierModalModel.phone || '',
    };
  },
  computed: {
    isCreatingNewCarrier() {
      return !this.id;
    },
  },
  methods: {
    validateAndSave() {
      const validationErrors = validate(
        {
          name: this.name,
          web: this.web,
          email: this.email,
          phone: this.phone,
        },
        {
          name: {
            presence: { allowEmpty: false },
          },
          web: {
            presence: { allowEmpty: false },
          },
          email: {
            presence: { allowEmpty: false },
          },
          phone: {
            presence: { allowEmpty: false },
            numericality: true,
          },
        },
      );
      if (validationErrors) {
        this.alertBox().fire({
          title: 'Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }
      this.$emit(this.id ? 'update' : 'save', {
        id: this.id,
        name: this.name,
        web: this.web,
        email: this.email,
        phone: this.phone,
      });
    },
  },
};
</script>
