<template>
  <div class="w-full">
    <div class="bg-white overflow-x-auto pl-3 md:pl-10">
      <div class="flex bg-white gap-10 border-t-4 border-white">
        <router-link
          to="/my-account/details"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'details',
            'border-white': active_section !== 'details',
          }"
        >
          My Details
        </router-link>
        <router-link
          to="/my-account/staff"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'staff',
            'border-white': active_section !== 'staff',
          }"
        >
          Staff & Licenses
        </router-link>
        <router-link
          :to="touch_business_user ? `/customer/${$route.params.id}/addresses` : '/my-account/addresses'"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'addresses',
            'border-white': active_section !== 'addresses',
          }"
        >
          Addresses & Delivery Days
        </router-link>
        <router-link
          to="/my-account/custom-styles"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'custom-styles',
            'border-white': active_section !== 'custom-styles',
          }"
        >
          Custom Styles
        </router-link>
        <router-link
          v-if="!isLeadGen && authStore.superuser"
          to="/my-account/touch-connect"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'touch-connect',
            'border-white': active_section !== 'touch-connect',
          }"
        >
          Touch Connect
        </router-link>
        <router-link
          v-if="authStore.superuser"
          to="/my-account/products"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'products',
            'border-white': active_section !== 'products',
          }"
        >
          Products
        </router-link>
        <router-link
          v-if="customerStore.customer.customer.useCustomerOnlyGlass"
          to="/my-account/my-prices"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'my-prices',
            'border-white': active_section !== 'my-prices',
          }"
        >
          My Prices
        </router-link>
        <router-link
          v-if="knowledgeBaseStore.hiddenArticlesIDs.length > 0"
          to="/my-account/hidden-articles"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'hidden-articles',
            'border-white': active_section !== 'hidden-articles',
          }"
        >
          Hidden Articles
        </router-link>
        <div class="pr-5" />
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import { useStyleStore } from '@/pinia/style';
import { useKnowledgeBaseStore } from '@/pinia/knowledge-base';

export default {
  computed: {
    ...mapStores(useAuthStore, useCustomerStore, useStyleStore, useKnowledgeBaseStore),
    isLeadGen() {
      return (
        this.styleStore.fabricator.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
    active_section() {
      const substrSection = this.$route.path
        .substring(this.$route.path.lastIndexOf('/') + 1)
        .replace(this.$route.params.id, '');
      if (substrSection === '') {
        return 'details';
      }
      return substrSection;
    },
  },
};
</script>
