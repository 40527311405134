<template>
  <tr v-if="isWidthByHeight">
    <td class="p-1">
      <input
        :value="price.minimumHeight"
        type="text"
        class="h-10 w-24 p-2 rounded border border-gray-300 focus:text-black"
        @keyup="updateDimensions('minimumHeight', $event.target.value)"
      >
    </td>
    <td class="p-1">
      <input
        :value="price.maximumHeight"
        type="text"
        class="h-10 w-24 p-2 rounded border border-gray-300 focus:text-black"
        @keyup="updateDimensions('maximumHeight', $event.target.value)"
      >
    </td>
    <td class="p-1">
      <input
        :value="price.minimumWidth"
        type="text"
        class="h-10 w-24 p-2 rounded border border-gray-300 focus:text-black"
        @keyup="updateDimensions('minimumWidth', $event.target.value)"
      >
    </td>
    <td class="p-1">
      <input
        :value="price.maximumWidth"
        type="text"
        class="h-10 w-24 p-2 rounded border border-gray-300 focus:text-black"
        @keyup="updateDimensions('maximumWidth', $event.target.value)"
      >
    </td>
    <td class="p-1">
      <div class="relative">
        <div
          v-if="position === 'prefix'"
          class="h-10 absolute border-r border-gray-300 px-3 flex flex-col justify-around"
          v-html="icon"
        />

        <input
          :class="{
            'text-gray-400': value == 0 || isFOC,
            'pl-10': position === 'prefix',
            'pr-10': position === 'suffix',
          }"
          :value="value"
          type="text"
          class="h-10 w-24 p-2 rounded border border-gray-300 focus:text-black"
          @focus="$event.target.select()"
          @keyup="updateValue($event.target.value)"
        >

        <div
          v-if="position === 'suffix'"
          class="h-10 absolute top-0 right-0 border-l border-gray-300 px-3 flex flex-col justify-around"
          v-html="icon"
        />
      </div>
    </td>
    <td v-if="isCustomerPricing" class="py-1 px-3">
      <div @click="updateFOC()">
        <label>FOC: <input class="pointer-events-none" type="checkbox" :checked="isFOC"></label>
      </div>
    </td>
  </tr>
  <tr v-else>
    <td class="py-1 px-3">
      {{ price.description }} <span v-if="subtitle">({{ subtitle }})</span>
    </td>
    <td class="p-1">
      <div class="relative">
        <div
          v-if="position === 'prefix'"
          class="h-10 absolute border-r border-gray-300 px-3 flex flex-col justify-around"
          v-html="icon"
        />

        <input
          :class="{
            'text-gray-400': value == 0 || isFOC,
            'pl-10': position === 'prefix',
            'pr-10': position === 'suffix',
          }"
          :value="value"
          type="text"
          class="h-10 w-24 p-2 rounded border border-gray-300 focus:text-black"
          @focus="$event.target.select()"
          @keyup="updateValue($event.target.value)"
        >

        <div
          v-if="position === 'suffix'"
          class="h-10 absolute top-0 right-0 border-l border-gray-300 px-3 flex flex-col justify-around"
          v-html="icon"
        />
      </div>
    </td>
    <td v-if="isCustomerPricing" class="py-1 px-3">
      <div @click="updateFOC()">
        <label>FOC: <input class="pointer-events-none" type="checkbox" :checked="isFOC"></label>
      </div>
    </td>
  </tr>
</template>

<script>

export default {
  props: {
    defaultCurrency: {
      required: false,
    },
    price: {
      required: true,
    },
    editable: {
      default: true,
    },
    value: {
      required: true,
    },
    isCustomerPricing: {
      type: Boolean,
    },
    isWidthByHeight: Boolean,
    subtitle: {
      type: String,
      default: 'Please set subtitle',
    },
    icon: {
      type: String,
      default: 'Please Set Icon'
    },
    position: {
      type: String,
      default: 'prefix'
    },
  },
  data() {
    return {
      isFOC: false,
    };
  },
  mounted() {
    this.isFOC = this.price.foc;
  },
  methods: {
    updateFOC() {
      this.isFOC = !this.price.foc;
      this.$emit('updatePrice', { value: this.value, foc: this.isFOC });
    },
    updateValue(value) {
      if (this.isCustomerPricing) {
        this.isFOC = value == 0;  
      }
      this.$emit('updatePrice', { value, foc: this.isFOC });
    },
    updateDimensions(property, value) {
      this.$emit('updateDimensions', { property, value });
    },
  },
};
</script>
