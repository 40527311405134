<template>
  <div class="flex flex-wrap w-full justify-center">
    <div
      v-for="(product, i) in products"
      :key="i"
      class="flex flex-col w-full xs:w-1/2 sm:w-1/4 lg:w-1/5 p-3 lg:p-5"
    >
      <div
        class="flex flex-col h-full bg-gray-200 rounded-lg cursor-pointer p-3 gap-3"
        @click="$emit('selected', product.productId)"
      >
        <strong v-if="!showDesignNames" class="text-center">{{ product.name }}</strong>
        <div>
          <img :src="product.imageUrl" class="rounded-t-lg mx-auto">
        </div>
        <div class="text-center flex flex-col h-full">
          <div class="mt-auto">
            <button v-if="showDesignNames" class="btn-action lg:text-xs lg:py-3 lg:px-10 w-full">Choose {{ product.name || product.retailName }}</button>
            <button v-else class="btn-action lg:text-xs lg:py-3 lg:px-10 w-full">Select</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['products', 'showDesignNames'],
};
</script>
