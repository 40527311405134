<template>
  <button class="btn btn-action w-full" @click="change">
    {{ spec.name }}
  </button>
</template>
<script>
export default {
  components: {},
  props: ['spec', 'loading', 'showAdvancedModeToggle'],
  data() {
    return {
      open_option_group: null,
      open_search: null,
      searchQuery: '',
    };
  },
  computed: {},
  methods: {
    change() {
      const heading = this.spec.optionHeadingId;
      const value = this.spec.instantSelectionOptionItemId;
      this.$emit('change-value', { heading, value });
    },
  },
};
</script>
